import { Vector3 } from '@babylonjs/core';

export default {
  simple: [
    {
      position: new Vector3(5,2.4,0.5),
      cameraPosition: new Vector3(9, 3, 6),
      text: '<b>Каркас – Швеллер 120 мм, Уголок 90х90 мм</b><br/>' +
        '<br/>' +
        'Гарантия надежного каркаса БК или Модульного Здания - это прочные швеллеры. Выбор швеллера 120 и уголка 90 мм - оптимальный вариант. При экономии металла, швеллеры 120 мм и уголки 90 мм еще и увеличивают прочность и долговечность, при этом не сильно увеличивая вес конструкции.<br/>' +
        'Дополнительное усиление каркаса доступно в разделе «Каркас».'
    },
    {
      position: new Vector3(3.6,1.6,0.5),
      cameraPosition: new Vector3(5, 3, 6),
      text: '<b>Окно - Деревянное 75 х 85 см</b><br/>' +
        '<br/>' +
        'Окно, в базовой комплектации 0,75 х 0,85 см, деревянное, с двойным остеклением. Прекрасно подходит для сохранения тепла внутри помещения и для повышения уровня шумоизоляции. В конфигураторе доступны более 20 видов различных пластиковых и деревянных окон.'
    },
    {
      position: new Vector3(2,1,0.5),
      cameraPosition: new Vector3(2, 3, 7),
      text: '<b>Внешняя отделка - Оцинкованный профлист С-8</b><br/>' +
        '<br/>' +
        'Внешняя отделка оцинкованным профлистом С-8 защищает от негативных воздействий погоды и утепляет Модульное Здание снаружи. Выбирайте свой вариант отделки в разделе «Внешняя отделка».'
    },
    {
      position: new Vector3(0,2,0.5),
      cameraPosition: new Vector3(-3, 2, 5),
      text: '<b>Дверь входная - ДВП</b><br/>' +
        '<br/>' +
        'В базовой комплектации поставляется входная дверь из ДВП, 80 х 200 см. В разделе конфигуратора «Двери» доступны несколько видов металлических, оргалитовых и ПВХ-дверей с различными вариантами остекления.'
    },
    {
      position: new Vector3(0,2.3,-1.9),
      cameraPosition: new Vector3(-4, 8, 5),
      text: '<b>Внутренняя отделка - ДВП (оргалит)</b><br/>' +
        '<br/>' +
        'Отделка стен ДВП-панелями в базовой комплектации – является одним из самых востребованных способов отделки БК и Модульных Зданий. ДВП подходит для потолков, стен, создания перегородок. Панели создают ощущение натуральных материалов, не уступают, а по многим качествам превосходят их.<br/>' +
        'Загляните в раздел внутренней отделки конфигуратора. Для Вас - только лучшие материалы!'
    }
  ],
  improved: []
}
