import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Container, Rectangle, Shape } from '@createjs/easeljs';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import FastVector from 'fast-vector';
import WindowView from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem/WindowView';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import WallItemActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem/WallItemActionsView';
import DoorView from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem/DoorView';
import WallConnector from '@/components/BlocksEditor/CreateJsBlocksEditor/WallConnector';
import Box from './Box';

export class DoorHandle {
  static Left = 'left'
  static Right = 'right'
}

class WallItem extends BaseEditorElement {

  /**
   * @type {WallItem[]}
   */
  static WallItemsList = []

  static _ID = 0

  /**
   * @type Number
   */
  _id

  dictionaryData

  static Types = {
    Window: 'Window',
    Door: 'Door',
    Additional: 'Additional'
  }

  /**
   * @private {WallItemView}
   */
  _view

  /**
   * @type {String}
   * @private
   */
  _type

  /**
   * @type {Wall}
   * @private
   */
  _wall = null

  /**
   * @type {number}
   * @private
   */
  pointOnWall = 0

  /**
   * @type {Wall}
   * @private
   */
  _intersectedWall = null

  _width = 0
  _height = 0

  commonRotation = 0
  rotation = 0

  /**
   * @type {string}
   */
  handle = ''

  /**
   * @type {easeljs.Container}
   * @private
   */
  _mouseActionsContainerView

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _mouseActionsBgShape

  /**
   * @type {easeljs.Rectangle}
   * @private
   */
  _rectangle = new Rectangle()

  /**
   * @type {WallItemActionsView}
   * @private
   */
  _wallItemActionsView

  static Create({width, height, type, dictionaryData}) {
    WallItem._ID ++
    const wallItem = new WallItem()
    wallItem.setId(WallItem._ID)
    wallItem.setDictionaryData(dictionaryData)
    wallItem.setSize({width, height})
    wallItem.setType(type)
    wallItem.handle = DoorHandle.Right

    WallItem.WallItemsList.push(wallItem)

    return wallItem
  }

  static FromJSON(data) {
    if (data.id > WallItem._ID) WallItem._ID = data.id

    const wallItem = new WallItem()
    wallItem.setId(data.id)
    wallItem.setDictionaryData(data.dictionaryData)
    wallItem.setSize(data)
    wallItem.setType(data.type)
    wallItem.setWall(Wall.GetById(data.wallId))
    wallItem.commonRotation = data.commonRotation
    wallItem.rotation = data.rotation
    wallItem.pointOnWall = data.pointOnWall
    wallItem.handle = data.handle

    wallItem.draw()

    WallItem.WallItemsList.push(wallItem)
  }

  static GetById(id) {
    const list = this.WallItemsList.filter(item => item.id === id)
    return list.length > 0 ? list[0] : null
  }

  constructor() {
    super();
    this.elementType = BaseEditorElement.ELEMENT_TYPE_WALL_ITEM
    Grid.Instance.addWallItem(this)
  }

  toJson() {
    const data = {
      id: this.id,
      boxId: this._wall == null ? null : this._wall.box.id,
      wallId: this._wall !== null ? this._wall.id : null,
      handle: this.handle ? this.handle : DoorHandle.Right,
      type: this._type,
      commonRotation: this.commonRotation,
      rotation: this.rotation,
      pointOnWall: this.pointOnWall,
      width: this._width,
      height: this._height,
      dictionaryData: this.dictionaryData
    }

    return data
  }

  get id() {
    return this._id
  }

  get type() {
    return this._type
  }
  /**
   *
   * @return {Wall}
   */
  get wall() {
    return this._wall || this._intersectedWall
  }

  /**
   * @returns {easeljs.Rectangle}
   */
  get rectangle() {
    const wall = this.wall

    if (wall.vertical) {
      this._rectangle.x = this.x - 4 - this._height / 2
      this._rectangle.y = this.y - this._width / 2
      this._rectangle.width = this._height + 8
      this._rectangle.height = this._width
    } else {
      this._rectangle.x = this.x - this._width / 2
      this._rectangle.y = this.y - 4 - this._height / 2
      this._rectangle.width = this._width
      this._rectangle.height = this._height + 8
    }
    return this._rectangle
  }

  /**
   * @return {easeljs.Rectangle}
   */
  get setupRectangle() {
    const [x,y] = [this.x, this.y ]
    const rect = new Rectangle(x - 10, y - 10, 20, 20)
    return rect
  }

  get rotationAvailable() {
    return this._type === WallItem.Types.Door
  }

  /**
   * @returns {boolean}
   */
  get isSetuped() {
    return this._wall !== null
  }

  /**
   * @return {boolean}
   */
  get isFullWidthGate() {
    const gateTypes = ['vorota', 'rolstavni']
    return gateTypes.indexOf(this.dictionaryData.id) >= 0
  }

  get copyAvailable() {
    const available = this.copyAvailableFromLeft || this.copyAvailableFromRight
    return available
  }

  get leftCopyPosition () {
    return this.pointOnWall - this._width - 10
  }

  get rightCopyPosition () {
    return this.pointOnWall + this._width + 10
  }

  get copyAvailableFromLeft () {
    const newPointOnWall = this.leftCopyPosition

    let available = true
    if (newPointOnWall < 0) {
      available = false
    }

    return available
  }

  get copyAvailableFromRight () {
    const newPointOnWall = this.rightCopyPosition

    let available = true
    if (newPointOnWall + this._width > + this._wall.wallLength) {
      available = false
    }

    return available
  }

  /**
   * @return {boolean}
   */
  get gate() {
    const gateTypes = ['vorota', 'rolstavni', 'luk']
    return gateTypes.indexOf(this.dictionaryData.id) >= 0
  }

  copy() {
    const dictionaryData = JSON.parse(JSON.stringify(this.dictionaryData))
    CreateJsBlocksEditor.Instance.createWallItem({
      width: this._width,
      height: this._height,
      type: this._type,
      dictionaryData: dictionaryData
    })
    this.emitChangeByTimeout()
  }

  setSize({width, height}) {
    this._width = width
    this._height = height
  }

  /**
   * @param id {number}
   */
  setId(id) {
    this._id = id
  }

  /**
   * @param type {string}
   */
  setType(type) {
    this._type = type
    this._createView()
  }

  setDictionaryData(dictionaryData) {
    this.dictionaryData = dictionaryData
  }

  getHitTestRectangle (x, y) {
    const vertical = !!this.wall?.vertical
    const width = (vertical ? this._height : this._width) + 8
    const height = (vertical ? this._width : this._height) + 8

    return new Rectangle(x - width / 2, y - height / 2, width, height)
  }

  willIntersectAnotherItem(x, y) {
    const rect = this.getHitTestRectangle(x, y)

    let intersect = false

    for (let i in WallItem.WallItemsList) {
      const item = WallItem.WallItemsList[i]
      if (item !== this && item.getHitTestRectangle(item.x, item.y).intersection(rect) !== null) {
        intersect = true
        break
      }
    }

    return intersect
  }

  _checkWallsIntersection() {
    const needUpdate = this.isFullWidthGate || !(this._intersectedWall && this._intersectedWall.rectangle.intersects(this.setupRectangle))

    if (needUpdate) {
      this._intersectedWall = null

      const wallsList = Wall.TempWallsList.concat(Wall.InsideWallsList)
      wallsList.forEach(wall => {
        if (this.isFullWidthGate) {
          if (wall.wallLength < 300 && wall.rectangle.intersects(this.setupRectangle)) {
            this._intersectedWall = wall
          }
        } else {
          if (wall.rectangle.intersects(this.setupRectangle)) {
            this._intersectedWall = wall
          }
        }
      })
    }

    if (this._intersectedWall) {
      if (this._intersectedWall.vertical) {
        this.x = this._intersectedWall.firstConnector.x
        this.y = CreateJsBlocksEditor.Instance._mousePosition.y

        if (this.y - this._width / 2 < this._intersectedWall.firstConnector.y + 4) {
          this.y = this._intersectedWall.firstConnector.y + this._width / 2 + 4
        }

        if (this.y + this._width / 2 > this._intersectedWall.lastConnector.y - 4) {
          this.y = this._intersectedWall.lastConnector.y - this._width / 2 - 4
        }

      } else {
        this.x = CreateJsBlocksEditor.Instance._mousePosition.x
        this.y = this._intersectedWall.firstConnector.y

        if (this.x - this._width / 2 < this._intersectedWall.firstConnector.x + 4) {
          this.x = this._intersectedWall.firstConnector.x + this._width / 2 + 4
        }

        if (this.x + this._width / 2 > this._intersectedWall.lastConnector.x - 4) {
          this.x = this._intersectedWall.lastConnector.x - this._width / 2 - 4
        }
      }
    }
  }

  /**
   * @param delta {FastVector}
   */
  move(delta) {
    if (this.isSetuped && this.isSelected()) {
      const mouseVector = delta
      const newPointOnWall = this.pointOnWall + (this._wall.vertical ? mouseVector.y : mouseVector.x)

      let x, y
      if (this._wall.vertical) {
        y = this._wall.firstConnector.y + newPointOnWall
        x = this._wall.firstConnector.x
      } else {
        x = this._wall.firstConnector.x + newPointOnWall
        y = this._wall.firstConnector.y
      }

      if (!this.willIntersectAnotherItem(x,y)) {
        this.pointOnWall = newPointOnWall
        this._updatePosition()
        this.draw()
      }

      this.emitChangeByTimeout()
    }
  }

  flipPositionOnWall() {
    if (this.isSetuped) {
      this.pointOnWall = this._wall.wallLength - this.pointOnWall
      this.rotate()
    }
  }

  rotate() {
    if (this.rotationAvailable) {
      if (!this.gate && this.dictionaryData.id !== 'rolstavni') {
        if (this.handle === DoorHandle.Right) {
          this.handle = DoorHandle.Left
        } else {
          this.handle = DoorHandle.Right
          this.rotation += 180
        }
      } else {
        this.rotation += 180
      }
      this.emitChangeByTimeout()
    }

    this.draw()
  }

  /**
   * @param wallForSet {Wall}
   */
  setWall(wallForSet) {

    if (!wallForSet.tempDashed) {
      if (!wallForSet.temp) {
        wallForSet = wallForSet.intersectedWalls[0]
      }

      this._wall = wallForSet
      this._wall.addWallItem(this)

      this._intersectedWall = null

      if (this._wall.vertical) {
        this.pointOnWall = this.y - this._wall.firstConnector.y
      } else {
        this.pointOnWall = this.x - this._wall.firstConnector.x
      }
      this._updatePosition()
    } else {

      const box = wallForSet.intersectedWalls[0].box
      /**
       * @type {WallConnector[]}
       */
      const newConnectors = []
      const originalConnectors = [wallForSet.firstConnector, wallForSet.lastConnector]

      originalConnectors.forEach((c) => {
        newConnectors.push(WallConnector.Create(c))
      })

      const [c1, c2] = newConnectors
      if (wallForSet.vertical) {
        c1.y += 4
        c2.y -= 4
      } else {
        c1.x += 4
        c2.x -= 4
      }

      const wall = Wall.CreateInsideBoxWallWithConnectors(newConnectors, box)
      this.setWall(wall)

      this.emitChangeByTimeout()
    }
  }

  _updatePosition() {
    if (this.isSetuped) {
      if (this.pointOnWall < this._width / 2 + 4) this.pointOnWall = this._width / 2 + 4
      if (this.pointOnWall > this._wall.wallLength - this._width / 2 - 4) this.pointOnWall = this._wall.wallLength - this._width / 2 - 4

      if (this._wall.vertical) {
        this.y = this._wall.firstConnector.y + this.pointOnWall
        this.x = this._wall.firstConnector.x
      } else {
        this.x = this._wall.firstConnector.x + this.pointOnWall
        this.y = this._wall.firstConnector.y
      }
    }
  }

  _updateRotation() {
    const wall = this._wall || this._intersectedWall

    if (!this.isSetuped && this.type !== WallItem.Types.Window) {
      if (wall && wall.box) {
        if (wall.insideBoxWall) {
          this.commonRotation = wall.vertical ? 180 : 0
        } else {
          switch (wall.boxSide) {
            case Box.Side.Right:
              this.commonRotation = 180
              break
            case Box.Side.Left:
              this.commonRotation = 0
              break
            case Box.Side.Top:
              this.commonRotation = 180
              break
            case Box.Side.Bottom:
              this.commonRotation = 0
              break
          }
        }
      }
    }
  }

  trySetup() {
    if (!this.isSetuped && this._intersectedWall) {
      this.setWall(this._intersectedWall)
      this.draw()
      this.emitChangeByTimeout()
    }
  }

  /**
   * @param x {Number}
   * @param y {Number}
   */
  setPosition(x, y) {
    if (!this.isSetuped) {

      if (!this.willIntersectAnotherItem(x, y)) {
        this.x = x
        this.y = y

        this._checkWallsIntersection()
        this.draw()
      }
    }
  }

  draw() {
    this._updatePosition()
    this._updateRotation()

    super.draw()
    if (this._view) this._view.draw()
    if (this._wallItemActionsView) this._wallItemActionsView.draw()
  }

  destroy() {
    if (this._view) this._view.destroy()
    if (this._wall) this._wall.removeWallItem(this)

    Grid.Instance.removeWallItem(this)

    WallItem.WallItemsList = WallItem.WallItemsList.filter((wallItem) => wallItem !== this)

    this.emitChangeByTimeout()

    super.destroy();
  }

  _createView() {
    if (this._view) {
      this._view.destroy()
      this._view = null
    }

    let view = null

    switch (this._type) {
      case WallItem.Types.Additional:
        view = new WindowView(this)
        break
      case WallItem.Types.Window:
        view = new WindowView(this)
        break
      case WallItem.Types.Door:
        view = new DoorView(this)
        break
    }

    if (view) {
      this._view = view
      this._wallItemActionsView = new WallItemActionsView(this)
    }
  }
}

export default WallItem
