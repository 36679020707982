import BoxItemView from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/BoxItemView';
import { Bitmap, Container } from '@createjs/easeljs';

class WashStandBigView extends BoxItemView {

  /**
   * @type {Bitmap}
   * @private
   */
  _image

  /**
   * @param boxItem {BoxItem}
   */
  constructor(boxItem) {
    super(boxItem)

    this._image = new Bitmap(require('@/assets/images/editor/boxItems/wash-stand-big.png'))
    const imageContainer = new Container()
    imageContainer.addChild(this._image)

    this.container.addChild(imageContainer)

    imageContainer.scale = 0.28

    imageContainer.x = - (192 * imageContainer.scale) / 2
    imageContainer.y = - (192 * imageContainer.scale) / 2
  }
}

export default WashStandBigView
