import { Shape } from '@createjs/easeljs';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid'
import WallConnector from '@/components/BlocksEditor/CreateJsBlocksEditor/WallConnector'
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall'
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement'

import { store } from '@/store/index'

class Pillar extends BaseEditorElement {

  static enabled = false

  /**
   *
   * @type {Pillar[]}
   */
  static PillarsList = []
  static StagePillarsList = {}

  /**
   * На каком растоянии раставлять столбики на стенках
   * @type {number}
   */
  static WallDistantionDelta = 200

  size = 10

  /**
   *
   * @private {easeljs.Shape}
   */
  _shape

  onTempWall = false


  static UpdatePillars() {
    Pillar.PillarsList.forEach(item => item.destroy())
    Pillar.PillarsList = []

    const connectorsData = []

    const getAllWallsConnectedToCoordinates = function({x,y}) {
      const sameConnectors = WallConnector.WallConnectorsList.filter((connector) => connector.x === x && connector.y === y)

      const walls = []
      sameConnectors.forEach((connector) => {
        connector.walls.forEach((wall) => {
          if (walls.indexOf(wall) < 0) walls.push(wall)
        })
      })

      return walls
    }

    const pillarExists = function ({x,y}) {
      const list = Pillar.PillarsList.filter((item) => item.x === x && item.y === y)
    }


    Pillar.StagePillarsList[store.getters.stage] = []
    const wallPillars = []

    WallConnector.WallConnectorsList.forEach(connectorItem => {
      const insideWallConnector = connectorItem.walls.filter((wall) => wall.insideBoxWall).length > 0 || connectorItem.walls.length === 0

      if (!insideWallConnector) {
        if (!pillarExists(connectorItem)) {
          const wallsList = getAllWallsConnectedToCoordinates({
            x: connectorItem.x,
            y: connectorItem.y
          })

          const dashedWallsCount = wallsList.filter((wall) => wall.temp && wall.tempDashed)
          const pillar = new Pillar(dashedWallsCount.length >= 4)

          pillar.setPosition({
            x: connectorItem.x,
            y: connectorItem.y
          })

          if (dashedWallsCount.length >= 4) {
            wallPillars.push({
              x: connectorItem.x,
              y: connectorItem.y,
              static: false
            })
          }
        }
      }
    })

    if (Pillar.enabled) {
      Wall.TempWallsList
        .filter((wall) => wall.temp && wall.tempDashed)
        .forEach((wall) => {
          const pillarsCount = Math.floor(wall.wallLength / Pillar.WallDistantionDelta) > 1 ? 2 : 0
          const delta = Math.round(wall.wallLength / pillarsCount)

          for (let i = 1; i < pillarsCount; i++) {
            let position = null
            if (!wall.vertical) {
              position = {
                x: wall.firstConnector.x + i * delta,
                y: wall.firstConnector.y,
                static: false
              }
            } else {
              position = {
                x: wall.firstConnector.x,
                y: wall.firstConnector.y + i * delta,
                static: false
              }
            }

            const intersectsInsideWall = Wall.InsideWallsList
              .filter(insideWall => insideWall.rectangle.contains(position.x, position.y))
              .length > 0

            if (!intersectsInsideWall) {
              let pillar = new Pillar(true)
              pillar.setPosition(position)
              wallPillars.push(position)
            }
          }
        })
    }

    Pillar.StagePillarsList[store.getters.stage] = wallPillars
  }

  static ClearState(stage) {
    delete Pillar.StagePillarsList[stage]
  }

  constructor(onTempWall = false) {
    super()

    this.onTempWall = onTempWall
    this._shape = new Shape()

    this.container.addChild(this._shape)

    Pillar.PillarsList.push(this)
    Grid.Instance.addPillar(this)
  }

  setPosition({x, y}) {
    this.x = x
    this.y = y
    this.draw()
  }

  draw() {
    this._shape.graphics
      .clear()

    this._shape.graphics.beginFill('#0558AB')
      .drawRect(-this.size / 2, -this.size / 2, this.size , this.size)
      .endFill()
  }

  destroy() {
    if (!this.destroyed) {
      super.destroy()
      Grid.Instance.removePillar(this)
    }
  }
}

export default Pillar
