<template lang="pug">
  vue-custom-scrollbar(class="section-menu-content")
    .section-items-list
      .section(v-for="(section, index) in items", :key="index")
        .section-title(v-if="section.title") {{section.title}}
        .section-items
          .section-item(v-for="(item, index) in section.items", :key="'item_'+index", @click="onItemClick(item)")
            .icon
              img(:src="item.menuIcon")
            .info
              .title(:title="item.title")
                span {{item.title}}
                tooltip-icon(v-if="item.id === 'batareya_otopleniya'", :text="'Выбирайте батареи отопления только если на участок подведена горячая вода'")
              .cost(v-if="item.data.cost") {{item.data.cost| currency }}
</template>
<script>
import { mapGetters } from 'vuex';
import LeftMenuData from '@/components/BlocksEditor/LeftMenu/leftMenuData';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import TooltipIcon from '@/components/_common/ui/TooltipIcon';

export default {
  name: 'ItemsList',
  components: {
    TooltipIcon,
    vueCustomScrollbar},
  data() {
    return {}
  },

  computed: {
    ...mapGetters([
      'selectedSection'
    ]),

    items() {
      const sections = []
      LeftMenuData[this.selectedSection.id].items.forEach(item => {
        const filtered = sections.filter(sectionItem => sectionItem.title === item.section)
        let sectionItem

        if (filtered.length > 0) {
          sectionItem = filtered[0]
        } else {
          sectionItem = {
            title: item.section,
            items: []
          }

          sections.push(sectionItem)
        }

        sectionItem.items.push(item)
      })

      return sections
    }
  },

  methods: {
    onItemClick(item) {
      eventBus.$emit(EventBusEvents.EditorMenuClicked, item)
    }
  }
}
</script>
