<template lang="pug">
  .kraus-select.no-select.equipment-select(:class="{opened: optionsVisible}")
    .selected-value-wrapper(@click="onSelectedValueClick")
      .selected-value(v-if="selectedValue")
        .icon(v-if="selectedValue.icon", :style="{backgroundColor: selectedValue.icon.color}", :class="{image: selectedValue.icon.dataURL}")
          .type-image(v-if="selectedValue.icon.dataURL")
            .type-image-icon(:style="{backgroundImage: 'url(' + selectedValue.icon.dataURL + ')' }")
        .selected-value-content
          .title {{selectedValue.title}}
      .arrow(v-if="optionsListValues.length > 0")
</template>
<script>
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';

export default {
  name: 'KrausEquipmentSelect',
  props: {
    hideCost: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: false
    },
    values: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedValue: null,
      optionsVisible: false
    }
  },

  mounted() {
    this.selectedValue = this.value
  },

  computed: {
    optionsListValues() {
      return this.values.filter(item => item !== this.selectedValue)
    }
  },

  watch: {
    value(val) {
      if (val !== this.selectedValue) {
        this.selectedValue = val
      }
    },

    selectedValue() {
      this.$emit('input', this.selectedValue)
    }
  },

  methods: {
    onItemClick(item) {
      if (item) this.selectedValue = item
      this.optionsVisible = false
    },

    onSelectedValueClick() {
      if (this.optionsListValues.length > 0) {
        this.optionsVisible = !this.optionsVisible
      }

      eventBus.$emit(EventBusEvents.KrausEquipmentSelectOptions, {
        el: this.$el,
        component: this,
        options: this.optionsVisible ? this.optionsListValues : null,
        hideCost: this.hideCost
      })
    }
  }
}
</script>
