<template lang="pug">
  .content.content-additional
    .additional-list(v-if="items.length > 0")
      .additional-item(v-for="(item, index) in items", :key="index", @click="onAdditionalItemClick(item)")
        .additional-item-icon(:style="{backgroundImage: 'url(' + item.icon + ')'}")
        .additional-item-content
          .additional-item-title {{item.title}}
          .additional-item-info
            .additional-item-info-options {{item.options}}
            .additional-item-info-cost {{item.cost| currency }}

    template(v-else)
      .content-header(style="margin-bottom: 30px")
        .content-option-name Нет добавленных элементов

    comment-form(v-if="!isOnOrderPage")
</template>
<script>
import CommentForm
  from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Additional/CommentForm';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapGetters } from 'vuex';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import BoxItem from '../../../CreateJsBlocksEditor/BoxItem';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
export default {
  name: 'Additional',
  components: { CommentForm },
  data() {
    return {
      items: [
        // {
        //   icon: require('@/assets/images/editor/building-equipment/additional/ventilation.svg'),
        //   title: 'Вытяжка',
        //   options: '100x100, поворотное',
        //   cost: '10000'
        // },
        // {
        //   icon: require('@/assets/images/editor/building-equipment/additional/heater.svg'),
        //   title: 'Обогреватель',
        //   options: '100x100, поворотное',
        //   cost: '10000'
        // },
        // {
        //   icon: require('@/assets/images/editor/building-equipment/additional/external-stairs.svg'),
        //   title: 'Лестница уличная',
        //   options: '100x100, поворотное',
        //   cost: '10000'
        // },
        // {
        //   icon: require('@/assets/images/editor/building-equipment/additional/internal-stairs.svg'),
        //   title: 'Лестница внутр.',
        //   options: '100x100, поворотное',
        //   cost: '10000'
        // },
        // {
        //   icon: require('@/assets/images/editor/building-equipment/additional/square.svg'),
        //   title: 'Площадка',
        //   options: '100x100, поворотное',
        //   cost: '10000'
        // }
      ]
    }
  },

  created() {
    this.updateItems()
  },

  watch: {
    stagesData: {
      deep: true,
      handler: function () {
        this.updateItems()
      }
    }
  },

  computed: {
    ...mapGetters([
      'stagesData'
    ]),

    isOnOrderPage() {
      return this.$route.name === 'Ordering'
    }
  },

  methods: {
    updateItems() {
      const plumbingTypes = [
        BoxItem.Types.WashStandSmall,
        BoxItem.Types.WashStandBig,
        BoxItem.Types.Shower,
        BoxItem.Types.ToiletBowl,
        BoxItem.Types.Boiler,
        BoxItem.Types.Conditioner
      ]

      const additionalKeys = []
      EquipmentCategories.getCategory('dop_elementy').options.forEach(item => {
        if (item.key === 'lestnicy') {
          item.options.forEach(stairItem => additionalKeys.push(stairItem.key))
        } else {
          additionalKeys.push(item.key)
        }
      })

      this.items = []

      for (const stage in this.stagesData) {
        const stageJson = this.stagesData[stage]

        if (stageJson) {
          stageJson.boxItems.forEach(item => {
            if (plumbingTypes.indexOf(item.type) < 0) {
              this.items.push({
                id: item.id,
                icon: item.dictionaryData.menuIcon,
                title: item.dictionaryData.data.raw.title,
                options: item.dictionaryData.option ? item.dictionaryData.option.title : '',
                cost: item.dictionaryData.option ? item.dictionaryData.option.raw.price : item.dictionaryData.data.raw.price,
                insideBox: true,
                stage: parseInt(stage)
              })
            }
          })

          stageJson.wallItems.forEach(item => {
            if (item.type === WallItem.Types.Additional) {
              this.items.push({
                id: item.id,
                icon: item.dictionaryData.menuIcon,
                title: item.dictionaryData.data.raw.title,
                options: item.dictionaryData.option ? item.dictionaryData.option.title : '',
                cost: item.dictionaryData.option ? item.dictionaryData.option.raw.price : item.dictionaryData.data.raw.price,
                insideBox: true,
                stage: parseInt(stage)
              })
            }
          })

          stageJson.externalItems.forEach(item => {
            this.items.push({
              id: item.id,
              icon: item.dictionaryData.menuIcon,
              title: item.dictionaryData.data.raw.title,
              options: item.dictionaryData.option ? item.dictionaryData.option.title : 'Стандарт',
              cost: item.dictionaryData.option ? item.dictionaryData.option.raw.price : item.dictionaryData.data.raw.price,
              insideBox: false,
              stage: parseInt(stage)
            })
          })
        }
      }
    },

    onAdditionalItemClick(item) {
      if (item.insideBox) {
        eventBus.$emit(EventBusEvents.SelectItemInEditor, {
          type: BaseEditorElement.ELEMENT_TYPE_BOX_ITEM,
          id: item.id,
          stage: item.stage
        })
      } else {
        eventBus.$emit(EventBusEvents.SelectItemInEditor, {
          type: BaseEditorElement.ELEMENT_TYPE_EXTERNAL_ITEM,
          id: item.id,
          stage: item.stage
        })
      }
    }
  }
}
</script>
