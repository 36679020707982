<template lang="pug">
  .site-header-menu
    .menu-button(@click="visible = !visible")
    .menu-items.no-select(v-if="visible")
      .menu-item(v-for="(item, index) in items", :key="index", @click="onItemClick(item)")
        .icon(:class="item.action")
        .label {{item.label}}
</template>
<script>

import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import PopupsNames from '@/components/_common/ui/Popup/popupNames';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SiteHeaderMenu',
  data() {
    return {
      visible: false,
      items: [
        {
          label: 'Отправить на почту',
          action: 'email',
        },
        {
          label: 'Скачать заявку (PDF)',
          action: 'download',
        },
        {
          label: 'Помощь',
          action: 'question',
        }
      ]
    }
  },

  computed: {
    ...mapGetters([
      'projectId',
      'editorType'
    ])
  },

  methods: {
    ...mapActions([
      'setEditorType'
    ]),

    onItemClick(item) {
      this.visible = false
      switch (item.action) {
        case 'email':
          eventBus.$emit(EventBusEvents.ShowPopup, PopupsNames.SendProject)
          break

        case 'download':
          if (this.editorType !== '2D') {
            this.setEditorType('2D')
            this.$nextTick(() => {
              eventBus.$emit(EventBusEvents.CreateStageImages, 'for-pdf')
            })
          } else {
            eventBus.$emit(EventBusEvents.CreateStageImages, 'for-pdf')
          }
          break

        case 'question':
          eventBus.$emit(EventBusEvents.ShowTooltips)
          break
      }
    }
  }
}
</script>
