<template lang="pug">
  .kraus-tooltip(v-if="visible")
    .background
    .tooltip-wrapper(:style="wrapperStyle")
      .tooltip-corner-wrapper(:style="cornerWrapperStyle")
        .tooltip-corner(:class="cornerPosition")
      .tooltip-content(v-if="this.currentData")
        .title {{this.currentData.title}}
        .text {{this.currentData.text}}
        tooltip-controller(:stepsCount="currentList.length", :currentStep="this.step", @next="onNextStep", @skipAll="onSkipAll")
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import TooltipController from '@/components/_common/ui/Tooltip/TooltipController';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';

export default {
  name: 'KrausTooltip',
  components: { TooltipController },
  data() {
    return {
      visible: false,
      cornerPosition: 'top',
      cornerDelta: 0,
      size: {
        width: 380,
        height: 182
      },
      step: 0,
      data: {
        '2D': [
          {
            selector: null,
            centerOffset: {
              x: -301,
              y: 20
            },
            title: 'Добавление контейнеров',
            text: 'Нажимайте на плюсы для добавления новых контейнеров.'
          },
          {
            selector: '.left-menu-item.box',
            title: 'Добавление объектов',
            text: 'Перетаскивайте необходимые перегородки, окна, двери и другие объекты прямо на схему.'
          },
          {
            selector: '.history-buttons',
            title: 'Отмена действия',
            text: 'Отменяйте предыдущие действия в любой момент.'
          },
          {
            selector: '.stage-select',
            title: 'Этажи',
            text: 'Переключайтесь между этажами, добавляйте или копируйте этажи.'
          },
          {
            selector: '.site-header .menu-button',
            title: 'Сохранение заявки',
            text: 'Скачайте PDF с Заявкой или отправьте Заявку себе на почту, вместе со ссылкой для дальнейшего редактирования.'
          },
          {
            selector: '.camera-button',
            title: 'Скриншоты',
            text: 'Сделайте скриншот получившейся схемы.'
          },
          {
            selector: '.clear-project-button',
            title: 'Начать заново',
            text: 'В любой момент можно начать новый проект.'
          },
          {
            selector: '.container-items',
            centerOffset: {x: -170, y: -230},
            title: 'Детализация модульного здания',
            text: 'Проверяйте состав модульного здания по разделам и управляйте деталями. В разделе “Доп. элементы” можно оставить особые указания для наших менеджеров.'
          },
          {
            selector: '.site-header .kraus-switcher',
            title: 'Режим просмотра готового здания',
            text: 'Посмотрите как будет выглядеть ваше модульное здание в 3D.'
          },
          {
            selector: '.result-information .button-wrapper',
            title: 'Оформление заказа',
            text: 'Перейдите к оформлению заказа. Далее вы сможете указать адрес доставки и условия монтажа.'
          },
          {
            selector: '.tooltip-button',
            title: 'Подсказки',
            text: 'Воспользуйтесь подсказками в любой момент.'
          },
        ],
        '3D': [
          {
            selector: '.site-header .kraus-switcher',
            title: 'Режим редактирования',
            text: 'Для редактирования проекта перейдите в 2D-режим.'
          },
          {
            selector: '.editor-left-menu .left-menu-item.decoration',
            title: 'Отделка',
            text: 'Меняйте цвета каркаса и внешних стен контейнеров.'
          },
          {
            selector: '.walls-mode-select',
            title: 'Прозрачные стены',
            text: 'Включите этот режим, чтобы видеть сквозь стены.'
          },
          {
            selector: '.site-header .camera-button',
            title: 'Скриншоты',
            text: 'Вы всегда можете сделать несколько скриншотов вашего модульного здания.'
          },
          {
            selector: '.tooltip-button',
            title: 'Подсказки',
            text: 'Воспользуйтесь подсказками в любой момент.'
          },
        ]
      }
    }
  },

  created() {
    eventBus.$on(EventBusEvents.ShowTooltips, this.show)
    eventBus.$on(EventBusEvents.HideTooltips, this.hide)
  },

  destroyed() {
    eventBus.$off(EventBusEvents.ShowTooltips, this.show)
    eventBus.$off(EventBusEvents.HideTooltips, this.hide)
  },

  computed: {
    ...mapGetters([
      'containerType',
      'editorType'
    ]),

    currentList() {
      return this.data[this.editorType]
    },

    currentData() {
      return this.currentList[this.step]
    },

    point() {
      const {selector, centerOffset} = this.currentData
      const point = {x: 0, y: 0}

      if (selector) {
        const element = document.querySelector(selector)
        const rect = element.getBoundingClientRect()
        point.x = rect.x + rect.width / 2
        point.y = rect.y + rect.height / 2

        if (centerOffset) {
          point.x += centerOffset.x
          point.y += centerOffset.y
        }

      } else if (centerOffset) {
        point.x = (window.innerWidth - 324) / 2 + centerOffset.x
        point.y = window.innerHeight / 2 + centerOffset.y
      }

      return point
    },

    wrapperPosition() {
      const position = {x: this.point.x - 330, y: this.point.y + 60}
      this.cornerPosition = 'top'
      this.cornerDelta = 0

      if (this.point.y > window.innerHeight / 2) {
        this.cornerPosition = 'bottom'
        position.y = this.point.y - 240
      }

      if (position.x < 0) {
        position.x = this.point.x + 60
        position.y = this.point.y - 50
        this.cornerPosition = 'left'
      }

      if (this.point.y > window.innerHeight / 2 - 100 && this.point.x > window.innerWidth - 350) {
        this.cornerPosition = 'right'
        position.x = this.point.x - 400
        position.y = this.point.y - 50
      }

      if (position.y + this.size.height > window.innerHeight) {
        const delta = ((position.y + this.size.height) - window.innerHeight) + 20
        position.y -= delta
        this.cornerDelta = delta
      }

      return position
    },

    wrapperStyle() {
      return {
        top: this.wrapperPosition.y + 'px',
        left: this.wrapperPosition.x + 'px'
      }
    },

    cornerWrapperStyle() {
      if (this.cornerPosition === 'left') {
        return {
          top: this.cornerDelta + 'px'
        }
      } else if (this.cornerPosition === 'bottom') {
        return {
          top: null,
          right: '50px',
          bottom: '10px'
        }
      } else if (this.cornerPosition === 'right') {
        return {
          top: null,
          right: '10px',
          bottom: '150px'
        }
      }
    }
  },

  watch: {
    visible(visible) {
      if (visible) this.step = 0
    },

    containerType(val) {
      if (val) {
        this.tooltipsShowed(this.editorType).then((showed) => {
          if (!showed) {
            this.addTooltipsToShowedList(this.editorType)
            this.show()
          }
        })
      }
    }
  },

  methods: {
    ...mapActions([
      'tooltipsShowed',
      'addTooltipsToShowedList'
    ]),

    onNextStep() {
      this.step ++
      if (this.step >= this.currentList.length) this.hide()
    },

    onSkipAll() {
      this.hide()
    },

    hide() {
      this.visible = false
      if (this.editorType === '3D') {
        eventBus.$emit(EventBusEvents.ShowSmallTooltip, {
          text: 'Используйте левую кнопку мыши или тачпада для вращения здания, и правую кнопку для перетаскивания здания'
        })
      } else {
        eventBus.$emit(EventBusEvents.ShowSmallTooltip, {
          text: 'Нажмите на контейнер, чтобы изменить его длину. Пока он выделен красным, тяните его за угол, чтобы растянуть или уменьшить длину контейнера'
        })
      }
    },

    show() {
      if (!this.visible) {
        this.visible = true
      }
    }
  }
}
</script>
