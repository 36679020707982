const costsModule = {
  state: () => ({
    totalCost: 0,
    containersCost: {
      total: 0
    },
    carcassCost: {
      total: 0
    },
    externalFinishingCost: {
      total: 0
    },
    internalFinishingCost: {
      total: 0
    },
    roofCost: {
      total: 0
    },
    windowsCost: {
      total: 0
    },
    doorsCost: {
      total: 0
    },
    insideWallsCost: {
      total: 0
    },
    floorCost: {
      total: 0
    },
    plumbingCost: {
      total: 0
    },
    warmingCost: {
      total: 0
    },
    waterProofCost: {
      total: 0
    },
    electricCost: {
      total: 0
    },
    additionalCost: {
      total: 0
    },
    mountingCost: {
      total: 0
    }
  }),

  mutations: {
    setTotalCost(state, value) {
      state.totalCost = value
    },

    setContainersCost(state, value) {
      state.containersCost = value
    },

    setCarcassCost(state, value) {
      state.carcassCost = value
    },

    setExternalFinishingCost(state, value) {
      state.externalFinishingCost = value
    },

    setInternalFinishingCost(state, value) {
      state.internalFinishingCost = value
    },

    setRoofCost(state, value) {
      state.roofCost = value
    },

    setWindowsCost(state, value) {
      state.windowsCost = value
    },

    setDoorsCost(state, value) {
      state.doorsCost = value
    },

    setInsideWallsCost(state, value) {
      state.insideWallsCost = value
    },

    setFloorCost(state, value) {
      state.floorCost = value
    },

    setPlumbingCost(state, value) {
      state.plumbingCost = value
    },

    setWarmingCost(state, value) {
      state.warmingCost = value
    },

    setWaterProofCost(state, value) {
      state.waterProofCost = value
    },

    setElectricCost(state, value) {
      state.electricCost = value
    },

    setAdditionalCost(state, value) {
      state.additionalCost = value
    },

    setMountingCost(state, value) {
      state.mountingCost = value
    }
  },
  actions: {
    setTotalCost({commit}, value) {
      commit('setTotalCost', value)
    },

    setContainersCost({commit}, value) {
      commit('setContainersCost', value)
    },

    setCarcassCost({commit}, value) {
      commit('setCarcassCost', value)
    },

    setExternalFinishingCost({commit}, value) {
      commit('setExternalFinishingCost', value)
    },

    setInternalFinishingCost({commit}, value) {
      commit('setInternalFinishingCost', value)
    },

    setRoofCost({commit}, value) {
      commit('setRoofCost', value)
    },

    setWindowsCost({commit}, value) {
      commit('setWindowsCost', value)
    },

    setDoorsCost({commit}, value) {
      commit('setDoorsCost', value)
    },

    setInsideWallsCost({commit}, value) {
      commit('setInsideWallsCost', value)
    },

    setFloorCost({commit}, value) {
      commit('setFloorCost', value)
    },

    setPlumbingCost({commit}, value) {
      commit('setPlumbingCost', value)
    },

    setWarmingCost({commit}, value) {
      commit('setWarmingCost', value)
    },

    setWaterProofCost({commit}, value) {
      commit('setWaterProofCost', value)
    },

    setElectricCost({commit}, value) {
      commit('setElectricCost', value)
    },

    setAdditionalCost({commit}, value) {
      commit('setAdditionalCost', value)
    },

    setMountingCost({commit}, value) {
      commit('setMountingCost', value)
    }
  },
  getters: {
    totalCost: state => state.totalCost,
    containersCost: state => state.containersCost,
    carcassCost: state => state.carcassCost,
    externalFinishingCost: state => state.externalFinishingCost,
    internalFinishingCost: state => state.internalFinishingCost,
    roofCost: state => state.roofCost,
    windowsCost: state => state.windowsCost,
    doorsCost: state => state.doorsCost,
    insideWallsCost: state => state.insideWallsCost,
    floorCost: state => state.floorCost,
    plumbingCost: state => state.plumbingCost,
    warmingCost: state => state.warmingCost,
    waterProofCost: state => state.waterProofCost,
    electricCost: state => state.electricCost,
    additionalCost: state => state.additionalCost,
    mountingCost: state => state.mountingCost
  }
}

export default costsModule
