import { Mesh, SceneLoader, Vector3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';

class BoxItem {
  _scene
  _boxData
  _data
  _stage

  _mesh

  constructor({scene, boxData, data, stage}) {
    this._scene = scene
    this._boxData = boxData
    this._data = data
    this._stage = stage
    this._mesh = new Mesh('box-item', this._scene)

    this._loadModel()
  }

  get _rotation() {
    let radian = Math.PI / 180
    let rotation = radian * this._data.rotation

    if (['dush', 'tualet', 'tulpan_60', 'podvesnaya_40', 'batareya_otopleniya', 'obogrevatel_elektricheskiy', 'sushilka_dlya_ruk'].indexOf(this._data.dictionaryData.data.raw.key) >= 0) {
      rotation -= 90 * radian
    }

    if (this._data.dictionaryData.data.raw.key === 'boyler') {
      rotation -= Math.PI
    }

    return rotation + Math.PI / 2
  }

  get modelUrl() {
    return {
      path: '/models/box-items/',
      fileName: this._data.dictionaryData.data.raw.key + '.glb'
    }
  }

  _loadModel() {
    const meshContainer = new Mesh('container', this._scene)
    SceneLoader.LoadAssetContainer(this.modelUrl.path, this.modelUrl.fileName, this._scene, (container) => {
      container.meshes.forEach(meshItem => {
        if (!meshItem.parent) meshItem.parent = meshContainer
      })

      container.addAllToScene()
      meshContainer.parent = this._mesh
      this._onModelLoaded()
    })
  }

  _onModelLoaded() {
    this._mesh.scaling = new Vector3(100 * BlocksConstructor.Scale, 100 * BlocksConstructor.Scale, 100 * BlocksConstructor.Scale)
    this._mesh.position.x = (-this._boxData.x - this._data.position.x) * BlocksConstructor.Scale
    this._mesh.position.z = (this._boxData.y + this._data.position.y) * BlocksConstructor.Scale
    this._mesh.position.y = ((this._stage - 1) *  BlocksConstructor.StageHeight) * BlocksConstructor.Scale + 4 * BlocksConstructor.Scale
    this._mesh.rotation.y = this._rotation
  }

  dispose() {
    this._mesh.dispose()
  }
}

export default BoxItem
