<template lang="pug">
  .kraus-equipment-select-options(:style="style")
    .kraus-select.no-select.equipment-select
      .options-list.visible(v-if="options && options.length > 0", v-click-outside="onClickOutside", :style="{maxHeight: style.maxHeight}")
        vue-custom-scrollbar
          .option-item(v-for="(item, index) in options", :key="index", @click="onItemClick(item)")
            .icon(v-if="item.icon", :style="{backgroundColor: item.icon.color}", :class="{image: item.icon.dataURL}")
              .type-image(v-if="item.icon.dataURL")
                .type-image-icon(:style="{backgroundImage: 'url(' + item.icon.dataURL + ')' }")
            .option-item-content
              .title {{item.title}}
              .cost(v-if="item.cost && !hideCost") {{item.cost| currency }}
              .no-cost(v-else-if="!hideCost && item.cost !== undefined") без доплаты
</template>
<script>
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import ClickOutside from 'vue-click-outside'
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  directives: {
    ClickOutside
  },
  components: {vueCustomScrollbar},
  name: 'KrausEquipmentSelectOptions',
  data() {
    return {
      options: null,
      el: null,
      component: null,
      boundingClientRect: null,
      inited: false,
      height: 0,
      hideCost: false
    }
  },

  created() {
    eventBus.$on(EventBusEvents.KrausEquipmentSelectOptions, this.onOptionsList)
    document.addEventListener('resize', this.clear)
    document.querySelectorAll('*').forEach(item => {
      item.addEventListener('wheel', this.clear)
    })
  },

  destroyed() {
    eventBus.$off(EventBusEvents.KrausEquipmentSelectOptions, this.onOptionsList)
    document.removeEventListener('resize', this.clear)
    document.querySelectorAll('*').forEach(item => {
      item.removeEventListener('wheel', this.clear)
    })
  },

  computed: {
    style() {
      if (this.boundingClientRect) {
        let showAbove = this.boundingClientRect.top + 50 + this.height > window.innerHeight - 10
        let maxHeight = null

        if (this.boundingClientRect.top - this.height - 20 < 100 ) {
          showAbove = false
          maxHeight = window.innerHeight - (this.boundingClientRect.top + 50) - 100
        }

        if (showAbove) {
          return {
            top: (this.boundingClientRect.top - this.height - 20) + 'px',
            left: this.boundingClientRect.left + 'px',
            width: this.boundingClientRect.width + 'px'
          }
        } else {
          return {
            top: (this.boundingClientRect.top + 50) + 'px',
            left: this.boundingClientRect.left + 'px',
            width: this.boundingClientRect.width + 'px',
            maxHeight: maxHeight + 'px'
          }
        }
      } else {
        return {}
      }
    }
  },

  watch: {
    el(element) {
      if (element) {
        this.updateRect()
      }
    }
  },

  methods: {
    clear(e) {
      if (!e || e.target.closest('.kraus-equipment-select-options') === null) {
        if (this.options) {
          this.options = null
          this.component.onItemClick(null)
        }
      }
    },
    updateRect() {
      if (this.el) {
        this.boundingClientRect = this.el.getBoundingClientRect()
      }
    },

    onOptionsList(data) {
      this.hideCost = data.hideCost
      this.component = data.component
      this.el = data.el
      this.options = data.options

      this.inited = false

      this.updateRect()

      this.$nextTick(() => {
        this.height = this.$el.getBoundingClientRect().height
      })

      setTimeout(() => {
        this.inited = true
      }, 100)
    },

    onItemClick(item) {
      this.component.onItemClick(item)
      this.options = null
    },

    onClickOutside() {
      if (this.inited) {
        this.clear()
      }
    }
  }
}
</script>
