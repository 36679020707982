import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';
import ExternalItem
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/ExternalItemsConstructor/ExternalItem';
import BoxItem
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/BoxItemsConstructor/BoxItem';

class BoxItemsConstructor {
  _stagesData
  _scene

  _itemsList = []

  constructor({scene, stagesData}) {
    this._scene = scene
    this._stagesData = stagesData

    this._createItems()
  }

  _createItems() {
    for (let stage in this._stagesData) {
      let stageData = this._stagesData[stage]

      if (stageData) {
        stageData.boxes.forEach(boxData => {
          if (boxData.boxItems) {
            boxData.boxItems.forEach((data) => {
              const item = new BoxItem({
                scene: this._scene,
                boxData: boxData,
                data,
                stage
              })
              this._itemsList.push(item)
            })
          }
        })
      }
    }
  }

  dispose() {
    this._itemsList.forEach(item => item.dispose())
  }
}

export default BoxItemsConstructor
