<template lang="pug">
  #content
    site-header
    .ordering-wrapper(:style="style", v-if="step <= 2 && !saved")
      .ordering
        .ordering-label
          .label Оформление заказа
          .step Шаг {{step}} из 2

        .ordering-columns
          ordering-equipment(v-if="step === 1")
          ordering-contacts-form(v-else, ref="contactsForm")
          ordering-result(:final="step >= 2", @continue="onContinue")

    ordering-success(v-else-if="saved", :projectId="pdfProjectId", :pid="pid")
</template>
<script>
import SiteHeader from '@/components/SiteHeader';
import OrderingEquipment from '@/components/Ordering/OrderingEquipment';
import OrderingResult from '@/components/Ordering/OrderingResult';
import OrderingContactsForm from '@/components/Ordering/OrderingContactsForm';
import OrderingSuccess from '@/components/Ordering/OrderingSuccess';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import api from '@/common/http/api';
import { mapActions, mapGetters } from 'vuex';
import EquipmentCategories from '@/common/EquipmentCategories';

export default {
  name: 'Ordering',
  components: {OrderingSuccess, OrderingContactsForm, OrderingResult, OrderingEquipment, SiteHeader },
  data() {
    return {
      windowHeight: 0,
      step: 1,
      saved: false,
      pdfProjectId: null,
      pid: null
    }
  },

  created() {
    if (!this.containerType) {
      this.$router.replace('/')
    }
  },

  mounted() {
    this.updateWindowHeight()
    eventBus.$on(EventBusEvents.SiteHeaderBackButtonClicked, this.onBackButtonClicked)
    window.addEventListener('resize', this.updateWindowHeight)
    setTimeout(() => {
      window.scroll(0, 0)
    }, 100)
  },

  destroyed() {
    eventBus.$off(EventBusEvents.SiteHeaderBackButtonClicked, this.onBackButtonClicked)
    window.removeEventListener('resize', this.updateWindowHeight)
  },

  computed: {
    ...mapGetters([
      'requestId',
      'projectId',
      'orderingStageImages',
      'images3d',
      'customerContacts',
      'delivery',
      'customerCraneUnloading',
      'mounting',
      'projectComment',

      'stagesData',
      'containerType',
      'cornerPosts',
      'carcassColor',
      'carcassStrengthening',
      'wallMaterial',
      'wallColor',
      'insideWallMaterial',
      'insideWallDifferentMaterial',
      'roofType',
      'roofColor',
      'roofMaterial',
      'roofMetalTrusses',
      'additionalFloor',
      'additionalLags',
      'metalLags',
      'metalLagsCount',
      'mainFloor',
      'additionalMainFloor',
      'floorCoating',
      'warming',
      'waterproofing',
      'electricPacket',
      'electricSockets',
      'electricLights',
      'mounting',

      'totalCost',
      'containersCost',
      'carcassCost',
      'externalFinishingCost',
      'internalFinishingCost',
      'roofCost',
      'windowsCost',
      'doorsCost',
      'insideWallsCost',
      'floorCost',
      'plumbingCost',
      'warmingCost',
      'waterProofCost',
      'electricCost',
      'additionalCost',
      'mountingCost',

      'square'
    ]),

    projectData() {
      return {
        projectId: this.projectId,
        info: {
          square: this.square
        },
        stagesImages: this.imagesList,
        images3d: this.images3d.map(item => item.dataURL),
        fullJson: this.stagesData,
        dictionary: EquipmentCategories.rawData,
        customerContacts: this.customerContacts,
        projectComment: this.projectComment,
        mounting: this.mounting,
        customerCraneUnloading: this.customerCraneUnloading,
        equipment: {
          containerType: this.containerType,
          cornerPosts: this.cornerPosts,
          carcassColor: this.carcassColor,
          carcassStrengthening: this.carcassStrengthening,
          wallMaterial: this.wallMaterial,
          wallColor: this.wallColor,
          insideWallMaterial: this.insideWallMaterial,
          insideWallDifferentMaterial: this.insideWallDifferentMaterial,

          roofType: this.roofType,
          roofColor: this.roofColor,
          roofMaterial: this.roofMaterial,
          roofMetalTrusses: this.roofMetalTrusses,

          additionalFloor: this.additionalFloor,
          additionalLags: this.additionalLags,
          metalLags: this.metalLags,
          metalLagsCount: this.metalLagsCount,
          mainFloor: this.mainFloor,
          additionalMainFloor: this.additionalMainFloor,
          floorCoating: this.floorCoating,

          warming: this.warming,

          waterproofing: this.waterproofing,

          electricPacket: this.electricPacket,
          electricSockets: this.electricSockets,
          electricLights: this.electricLights,

          mounting: this.mounting
        },
        costs: {
          delivery: this.delivery,
          totalCost: this.totalCost,
          containersCost: this.containersCost,
          carcassCost: this.carcassCost,
          externalFinishingCost: this.externalFinishingCost,
          internalFinishingCost: this.internalFinishingCost,
          roofCost: this.roofCost,
          windowsCost: this.windowsCost,
          doorsCost: this.doorsCost,
          insideWallsCost: this.insideWallsCost,
          floorCost: this.floorCost,
          plumbingCost: this.plumbingCost,
          warmingCost: this.warmingCost,
          waterProofCost: this.waterProofCost,
          electricCost: this.electricCost,
          additionalCost: this.additionalCost,
          mountingCost: this.mountingCost
        }
      }
    },

    imagesList() {
      const imagesList = []

      for (let key in this.orderingStageImages) {
        if (this.orderingStageImages[key]) {
          imagesList.push({
            stage: key,
            data: this.orderingStageImages[key].dataURL
          })
        }
      }

      return imagesList
    },

    style() {
      return {
        height: this.windowHeight - 80 + 'px'
      }
    }
  },

  methods: {
    ...mapActions([
      'setProjectId',
      'setRequestId',
    ]),

    onBackButtonClicked() {
      this.step--
      if (this.step < 1 || this.step === 2) {
        this.$router.push('/')
      }
    },

    updateWindowHeight() {
      this.windowHeight = window.innerHeight
    },

    onContinue() {
      if (!this.$refs.contactsForm || !this.$refs.contactsForm.hasErrors()) {
        this.step++
      }

      if (this.step >= 3) {
        api.post('/order/save', {
          pid: this.requestId,
          data: this.projectData,
        }, {
          headers: {
            'Content-Encoding': 'deflate, gzip'
          }
        }).then((response) => {
          this.pdfProjectId = response.data._id
          this.pid = response.data.pid
          this.setProjectId(null)
          this.setRequestId(null)
          this.saved = true
        })
      }
    }
  }
}
</script>
