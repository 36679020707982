import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import ToiletView from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/ToiletView';
import BoilerView from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/BoilerView';
import ConditionerView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/ConditionerView';
import WaterHeatingView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/WaterHeatingView';
import ElectricHeatingView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/ElectricHeatingView';
import ShowerView from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/ShowerView';
import StairsView from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/StairsView';
import VentilationView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/VentilationView';
import WashStandBigView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/WashStandBigView';
import WashStandSmallView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/WashStandSmallView';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall';
import { Rectangle } from '@createjs/easeljs';
import FastVector from 'fast-vector';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import BoxItemActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/BoxItemActionsView';

export default class BoxItem extends BaseEditorElement {
  static Types = {
    ToiletBowl: 'ToiletBowl',
    Boiler: 'Boiler',
    Conditioner: 'Conditioner',
    WaterHeating: 'WaterHeating',
    ElectricHeating: 'ElectricHeating',
    Shower: 'Shower',
    Stairs: 'Stairs',
    Ventilation: 'Ventilation',
    WashStandBig: 'WashStangBig',
    WashStandSmall: 'WashStandSmall'
  }

  static _ID = 0

  /**
   * @type {BoxItem[]}
   */
  static BoxItemsList = []

  /**
   * @type {string}
   */
  elementType

  /**
   * @type {Object}
   */
  dictionaryData

  /**
   * @type {number}
   */
  rotation = 0

  /**
   * @type Number
   */
  _width

  /**
   * @type Number
   */
  _height

  /**
   * @type {Box}
   * @private
   */
  _box = null

  /**
   * @type {number}
   * @private
   */
  _id

  /**
   * @type {easeljs.Rectangle}
   * @private
   */
  _rectangle = new Rectangle()

  /**
   * @type {FastVector}
   * @private
   */
  _position = FastVector.zero

  /**
   * @type {FastVector}
   */
  boxPosition = FastVector.zero

  /**
   * @type {BoxItemView}
   * @private
   */
  _view

  /**
   * @type BoxItemActionsView
   * @private
   */
  _actionsView

  /**
   * @param dictionaryData {Object}
   * @return {BoxItem}
   * @constructor
   */
  static Create(dictionaryData) {
    BoxItem._ID ++
    const boxItem = new BoxItem()
    boxItem.setId(BoxItem._ID)
    boxItem.setDictionaryData(dictionaryData)
    boxItem.initSizeByType()
    boxItem.createView()

    BoxItem.BoxItemsList.push(boxItem)

    return boxItem
  }

  static FromJSON(data) {
    if (data.id > BoxItem._ID) BoxItem._ID = data.id
    const boxItem = new BoxItem()
    boxItem.setId(data.id)
    boxItem.setDictionaryData(data.dictionaryData)
    boxItem.initSizeByType()
    boxItem.createView()
    boxItem.boxPosition = new FastVector(data.position.x, data.position.y)
    boxItem.rotation = data.rotation

    boxItem.setBox(Box.GetById(data.boxId))

    boxItem.draw()

    BoxItem.BoxItemsList.push(boxItem)
  }

  /**
   * @param id
   * @return {BoxItem|null}
   * @constructor
   */
  static GetById(id) {
    const list = BoxItem.BoxItemsList.filter((boxItem) => boxItem.id === id)
    return list.length > 0 ? list[0] : null
  }

  constructor() {
    super();
    this.elementType = BaseEditorElement.ELEMENT_TYPE_BOX_ITEM
    Grid.Instance.addBoxItem(this)
  }

  toJson() {
    const data = {
      id: this.id,
      type: this.type,
      boxId: this._box.id,
      rotation: this.rotation,
      dictionaryData: this.dictionaryData,
      position: {
        x: this.boxPosition.x,
        y: this.boxPosition.y
      }
    }

    return data
  }

  /**
   * @return {boolean}
   */
  get isSetuped() {
    return this._box !== null
  }

  /**
   * @returns {easeljs.Rectangle}
   */
  get rectangle() {
    this._rectangle.x = this.position.x - this.width / 2
    this._rectangle.y = this.position.y - this.height / 2
    this._rectangle.width = this.width
    this._rectangle.height = this.height

    return this._rectangle
  }

  /**
   * @returns {FastVector}
   */
  get position() {
    if (this.isSetuped) {
      this._position.x = Math.round(this._box.x + this.boxPosition.x)
      this._position.y = Math.round(this._box.y + this.boxPosition.y)
    } else {
      this._position.x = Math.round(this.x)
      this._position.y = Math.round(this.y)
    }

    return this._position
  }

  /**
   * @return {Number}
   */
  get width() {
    return this.rotation === 90 || this.rotation === 270 ? this._height : this._width
  }

  /**
   * @return {Number}
   */
  get height() {
    return this.rotation === 90 || this.rotation === 270 ? this._width : this._height
  }

  /**
   * @return {number}
   */
  get id() {
    return this._id
  }

  /**
   * @return {string}
   */
  get type() {
    return this.dictionaryData ? this.dictionaryData.editorType : null
  }

  /**
   * @return {Box}
   */
  get box() {
    return this._box
  }

  /**
   * @return {boolean}
   */
  get rotationAvailable() {
    let available = false
    const testRect = this.rectangle.clone()
    const center = new FastVector(testRect.x + testRect.width / 2, testRect.y + testRect.height / 2)
    const oldWidth = testRect.width
    const oldHeight = testRect.height

    testRect.width = oldHeight
    testRect.height = oldWidth
    testRect.x = center.x - testRect.width / 2
    testRect.y = center.y - testRect.height / 2

    if (this.isSetuped) {
      const rect = this._box.rectangle.intersection(testRect)
      if (rect && rect.width === testRect.width && rect.height === testRect.height) {
        available = true
      }
    }

    if (available) {
      BoxItem.BoxItemsList.forEach((item) => {
        if (item !== this && item.rectangle.intersects(testRect)) {
          available = false
        }
      })
    }

    return this.type === BoxItem.Types.Stairs ? true : available
  }

  /**
   * @param dictionaryData {Object}
   */
  setDictionaryData(dictionaryData) {
    this.dictionaryData = dictionaryData
  }

  /**
   * @param id {number}
   */
  setId(id) {
    this._id = id
  }

  initSizeByType() {
    switch (this.type) {
      case BoxItem.Types.ToiletBowl:
        this._width = 87
        this._height = 122
        break

      case BoxItem.Types.Boiler:
        this._width = 64
        this._height = 64
        break

      case BoxItem.Types.Conditioner:
        this._width = 22
        this._height = 24
        break

      case BoxItem.Types.WaterHeating:
        this._width = 80
        this._height = 22
        break

      case BoxItem.Types.ElectricHeating:
        this._width = 74
        this._height = 22
        break

      case BoxItem.Types.Shower:
        this._width = 81
        this._height = 83
        break

      case BoxItem.Types.Stairs:
        this._width = 210
        this._height = 285
        break

      case BoxItem.Types.Ventilation:
        this._width = 54
        this._height = 54
        break

      case BoxItem.Types.WashStandBig:
        this._width = 55
        this._height = 55
        break

      case BoxItem.Types.WashStandSmall:
        this._width = 40
        this._height = 40
        break
    }
  }

  createView() {
    let view = null

    switch (this.type) {
      case BoxItem.Types.ToiletBowl:
        view = new ToiletView(this)
        break

      case BoxItem.Types.Boiler:
        view = new BoilerView(this)
        break

      case BoxItem.Types.Conditioner:
        view = new ConditionerView(this)
        break

      case BoxItem.Types.WaterHeating:
        view = new WaterHeatingView(this)
        break

      case BoxItem.Types.ElectricHeating:
        view = new ElectricHeatingView(this)
        break

      case BoxItem.Types.Shower:
        view = new ShowerView(this)
        break

      case BoxItem.Types.Stairs:
        view = new StairsView(this)
        break

      case BoxItem.Types.Ventilation:
        view = new VentilationView(this)
        break

      case BoxItem.Types.WashStandBig:
        view = new WashStandBigView(this)
        break

      case BoxItem.Types.WashStandSmall:
        view = new WashStandSmallView(this)
        break
    }

    if (view) {
      if (this._view) {
        this._view.destroy()
        this._view = null
      }

      this._view = view
    }

    this._actionsView = new BoxItemActionsView(this)
  }

  canBeSetuped() {
    if (!this.isSetuped) {
      let can = false

      Box.BoxesList.forEach((box) => {
        const rect = box.rectangle.intersection(this.rectangle)
        if (rect && rect.width === this.rectangle.width && rect.height === this.rectangle.height) {
          can = true
        }
      })

      if (can) {
        BoxItem.BoxItemsList.forEach((boxItem) => {
          if (boxItem !== this && boxItem.rectangle.intersects(this.rectangle)) can = false
        })
      }

      return can
    }

    return true
  }

  trySetup() {
    if (!this.isSetuped) {
      const box = this.getIntersectedBox()
      if (box && this.canBeSetuped()) {
        this.boxPosition.x = this.x - box.x
        this.boxPosition.y = this.y - box.y

        this.setBox(box)

        CreateJsBlocksEditor.Instance.setSelectedElement(null)
        this.emitChangeByTimeout()
      }
    } else {
      CreateJsBlocksEditor.Instance.setSelectedElement(this)
    }

    this.draw()
  }

  setBox(box) {
    this._box = box
    this._box.addBoxItem(this)
  }

  /**
   * @return {Box | null}
   */
  getIntersectedBox() {
    let currentBox = null

    Box.BoxesList.forEach((box) => {
      const rect = box.rectangle.intersection(this.rectangle)
      if (rect && rect.width === this.rectangle.width && rect.height === this.rectangle.height) {
        currentBox = box
      }
    })

    return currentBox
  }

  /**
   * @param x {number}
   * @param y {number}
   */
  setPosition(x, y) {
    this.x = Math.round(x)
    this.y = Math.round(y)

    if (this.type === BoxItem.Types.Stairs) {
      Box.BoxesList.forEach((box) => {
        const rect = box.rectangle.intersection(this.rectangle)
        if (rect && (rect.width === this.rectangle.width || rect.height === this.rectangle.height)) {
          this.rotation = box.width > box.height ? 90 : 0
        }
      })
    }

    this.draw()
  }

  /**
   * @param delta {FastVector}
   * @return {FastVector}
   */
  getUpdatedMoveDelta(delta) {

    const selfCenter = new FastVector(this.x, this.y)
    const selfRect = this.rectangle

    /**
     * @type {easeljs.Rectangle[]}
     */
    const checkRects = []

    this._box.boxItems.forEach((boxItem) => {
      if (boxItem !== this) checkRects.push(boxItem.rectangle)
    })

    this._box.insideWalls.forEach((wall) => {
      checkRects.push(wall.rectangle)
    })

    checkRects.forEach((rect) => {
      const testSelfRect = selfRect.clone()
      testSelfRect.x += delta.x
      testSelfRect.y += delta.y

      const hitRectangle = rect.intersection(testSelfRect)

      if (hitRectangle) {
        const boxItemCenter = new FastVector(rect.x + rect.width / 2, rect.y + rect.height / 2)
        const centerDelta = boxItemCenter.sub(selfCenter)

        const isHorizontalCheck = selfRect.x + selfRect.width <= rect.x || selfRect.x >= rect.x + rect.width

        if (isHorizontalCheck) {
          if (centerDelta.x > 0) {
            delta.x -= hitRectangle.width
          } else {
            delta.x += hitRectangle.width
          }
        } else {
          if (centerDelta.y > 0) {
            delta.y -= hitRectangle.height
          } else {
            delta.y += hitRectangle.height
          }
        }
      }
    })

    const newPosition = this.boxPosition.add(delta)
    const limits = {
      minx: this.width / 2,
      maxx: this._box.width - this.width / 2,
      miny: this.height / 2,
      maxy: this._box.height - this.height / 2
    }

    if (newPosition.x < limits.minx) {
      delta.x += Math.round(limits.minx - newPosition.x)
    }

    if (newPosition.x > limits.maxx) {
      delta.x -= Math.round(newPosition.x - limits.maxx)
    }

    if (newPosition.y < limits.miny) {
      delta.y += Math.round(limits.miny - newPosition.y)
    }

    if (newPosition.y > limits.maxy) {
      delta.y -= Math.round(newPosition.y - limits.maxy)
    }

    return delta
  }

  /**
   * @param delta {FastVector}
   */
  move(delta) {
    this.boxPosition = this.boxPosition.add(delta)
    this.emitChangeByTimeout()
    this.draw()
  }

  /**
   * @returns {FastVector}
   */
  getNewRotateCoordinates() {
    const newCoordinates = FastVector.zero
    const center = new FastVector(
      this._box.width / 2,
      this._box.height / 2
    )

    const current = new FastVector(
      this.boxPosition.x - center.x,
      this.boxPosition.y - center.y
    )

    const rotatedCenter = new FastVector(
      center.y,
      center.x
    )

    newCoordinates.x = Math.round(rotatedCenter.x - current.y)
    newCoordinates.y = Math.round(rotatedCenter.y + current.x)

    return newCoordinates
  }

  rotateInsideBox() {
    const newCoordinates = this.getNewRotateCoordinates()
    this.boxPosition.x = newCoordinates.x
    this.boxPosition.y = newCoordinates.y

    this.rotation += 90
    if (this.rotation >= 360) this.rotation = 0
  }

  rotate() {
    this.rotation += this.type === BoxItem.Types.Stairs ? 180 : 90
    if (this.rotation >= 360) this.rotation = this.rotation - 360
    this.emitChangeByTimeout()
    this.draw()
  }

  getCopyPosition() {
    const positions = [
      new FastVector(- this.width, 0),
      new FastVector(this.width, 0),
      new FastVector(0, this.height),
      new FastVector(0, - this.height),
    ]

    let findedPosition = null

    if (this.type !== BoxItem.Types.Stairs) {
      for (let l in positions) {
        const positionItem = positions[l]
        const position = new FastVector(this.position.x + positionItem.x, this.position.y + positionItem.y)
        const rect = new Rectangle(position.x - this.width / 2, position.y - this.height / 2, this.width, this.height)

        let someIntersects = false
        const boxIntersectionRect = this._box.rectangle.intersection(rect)

        if (boxIntersectionRect && boxIntersectionRect.width === rect.width && boxIntersectionRect.height === rect.height) {
          for (let i in BoxItem.BoxItemsList) {
            const boxItem = BoxItem.BoxItemsList[i]
            if (boxItem !== this && boxItem.rectangle.intersection(rect) !== null) {
              someIntersects = true
              break
            }
          }

          if (!someIntersects) {
            findedPosition = positionItem
            break
          }
        }
      }
    }

    return findedPosition
  }

  copy() {
    const copyPosition = this.getCopyPosition()

    if (copyPosition !== null) {
      const position = this.position.clone()
      position.x -= this._width

      const dictionaryData = JSON.parse(JSON.stringify(this.dictionaryData))
      const boxItem = BoxItem.Create(dictionaryData)

      boxItem.setBox(this._box)
      boxItem.boxPosition.x = this.boxPosition.x + copyPosition.x
      boxItem.boxPosition.y = this.boxPosition.y + copyPosition.y
      boxItem.rotation = this.rotation

      BoxItem.BoxItemsList.push(boxItem)

      boxItem.draw()

      this.emitChangeByTimeout()

      CreateJsBlocksEditor.Instance.setSelectedElement(boxItem)
    }
  }

  updatePosition() {
    if (this.boxPosition) {

      if (this.boxPosition.x < this.width / 2 || this.boxPosition.x + this.width / 2 > this._box.width) {
        this.destroy()
      }

      if (this.boxPosition.y < this.height / 2 || this.boxPosition.y + this.height / 2 > this._box.height) {
        this.destroy()
      }
    }
  }

  draw() {
    if (this.isSetuped) {
      this.updatePosition()
    }

    this.x = this.position.x
    this.y = this.position.y

    if (this._actionsView) this._actionsView.draw()

    super.draw()
  }


  destroy() {
    super.destroy();

    if (this._view) this._view.destroy()
    if (this._box) this._box.removeBoxItem(this)

    BoxItem.BoxItemsList = BoxItem.BoxItemsList.filter((boxItem) => boxItem !== this)

    Grid.Instance.removeBoxItem(this)
  }
}
