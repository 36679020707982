import {
  Camera,
  Color3,
  Color4,
  Engine,
  HemisphericLight, Matrix, MeshBuilder, PointerEventTypes,
  PointLight,
  Scene, Tools,
  Vector3
} from '@babylonjs/core';
import CameraController from '@/components/BlocksRenderer/Blocks3DRenderer/CameraController';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import Wall
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/WallsConstructor/Wall';
import { GLTF2Export } from '@babylonjs/serializers';

class Blocks3DRenderer {

  static Instance
  /**
   * @type {HTMLCanvasElement}
   * @private
   */
  _canvas
  /**
   * @type {Engine}
   * @private
   */
  _engine
  /**
   * @type {Scene}
   * @private
   */
  _scene

  /**
   * @type {CameraController}
   * @private
   */
  _cameraController

  /**
   * @type {BlocksConstructor}
   * @private
   */
  _blocksConstructor

  _lights = []

  _requestAnimationStarted = false

  _carcass
  _walls
  _floor
  _roof
  _pillars

  _wallsTransparent = false

  _hardwareScaling = 1

  /**
   * @type {boolean}
   * @private
   */
  _roofVisible = true

  /**
   * @type {{heught: number, width: number}}
   * @private
   */
  _canvasSize = {
    width: 0,
    height: 0
  }

  /**
   * @return {Camera}
   */
  static get camera() {
    return this.Instance._cameraController.camera
  }

  get hasRoof() {
    return this._blocksConstructor.hasRoof
  }

  constructor(canvas, {
    carcass,
    walls,
    floor,
    roof,
    pillars
  }) {
    this._canvas = canvas
    this._carcass = carcass
    this._walls = walls
    this._floor = floor
    this._roof = roof
    this._pillars = pillars

    Blocks3DRenderer.Instance = this

    this.initEngine()
  }

  initEngine() {
    this._engine = new Engine(this._canvas, true, {
      preserveDrawingBuffer: true
    })

    this._engine.setHardwareScalingLevel(this._hardwareScaling)
    this._scene = new Scene(this._engine)

    // this._scene.fogEnabled = true
    // // this._scene.fogStart = 1
    // this._scene.fogDensity = 0.001
    // this._scene.fogColor = Color3.White()
    // this._scene.fogMode = Scene.FOGMODE_EXP2

    this._scene.ambientColor = Color3.White()

    this._scene.createDefaultEnvironment({
      createSkybox: false,
      createGround: false,
    })

    this._scene.clearColor = new Color4(1,1,1,1)

    this._cameraController = new CameraController({
      scene: this._scene,
      canvas: this._canvas
    })

    // this._scene.enableDepthRenderer(this._cameraController.camera, false, true)

    this._blocksConstructor = new BlocksConstructor({
      scene: this._scene
    })

    this._scene.onPointerObservable.add((info) => {
      if (info.event.button === 2) {
        if (info.type === PointerEventTypes.POINTERDOWN) {
          eventBus.$emit(EventBusEvents.RIGHT_MOUSE_BUTTON_DOWN)
        } else if (info.type === PointerEventTypes.POINTERUP) {
          eventBus.$emit(EventBusEvents.RIGHT_MOUSE_BUTTON_UP)
        }
      }

      if (info.type === 1) {
        eventBus.$emit(EventBusEvents.MOUSE_DOWN_ON_3D_SCENE)
        this.navigateToPoint(null)
      }
    })


    const light = new HemisphericLight("hemiLight", new Vector3(-10000, -1000, 10000), this._scene)
    light.intensity = 2
    // light.radius = 20000
    light.diffuse = Color3.White()
    // light.specular = Color3.Red()
    this._lights.push(light)

    const light2 = new PointLight("hemiLight", new Vector3(10000, -1000, -10000), this._scene)
    light2.intensity = 2
    light2.diffuse = Color3.White()
    // light2.specular = Color3.Red()
    this._lights.push(light2)

    // const pointLight = new PointLight('pointLight', new Vector3(-1000, 500, -100), this._scene)
    // pointLight.diffuse = Color3.White()
    // pointLight.intensity = 10
    // pointLight.radius = 1000
    // this._lights.push(pointLight)
    //
    // const pointLight2 = new PointLight('pointLight', new Vector3(0, 500, -100), this._scene)
    // pointLight2.diffuse = Color3.White()
    // pointLight2.intensity = 10
    // pointLight2.radius = 1000
    // this._lights.push(pointLight2)
    //
    // const pointLight3 = new PointLight('pointLight', new Vector3(1000, 500, -100), this._scene)
    // pointLight3.diffuse = Color3.White()
    // pointLight3.intensity = 10
    // pointLight3.radius = 1000
    // this._lights.push(pointLight3)

    // const lightPosition = MeshBuilder.CreateBox('test', {
    //   size: 10
    // }, this._scene)
    // lightPosition.position = pointLight.position

    // this.startRequestAnimation()
    this._engine.runRenderLoop(() => {
      this._scene.render()
    })
  }

  whenSceneReady() {
    return this._scene.whenReadyAsync()
  }

  getImage() {
    return new Promise((resolve) => {
      const canvas = document.querySelector('canvas')

      setTimeout(() => {
        canvas.toBlob((blob) => {
          const fileReader = new FileReader()
          fileReader.onload = (e) => {
            resolve({
              dataURL: e.target.result
            })
          }

          fileReader.readAsDataURL(blob)
        })
      }, 100)
    })

    // return new Promise((resolve) => {
    //   const precision = 1
    //   Tools.CreateScreenshotUsingRenderTarget(this._engine, this._cameraController.camera, {precision, width: 1024 / precision, height: 768 / precision}, (data) => {
    //     resolve({
    //       dataURL: data
    //     })
    //   }, null, 2, true)
    // })
  }

  /**
   *
   * @param points {Vector3[]}
   * @return {Promise}
   * @private
   */
  _adjustRadius(points) {
    return new Promise((resolve, reject) => {
      const renderWidth = this._engine.getRenderWidth()
      const renderHeight = this._engine.getRenderHeight()

      let minY = renderHeight
      let minX = renderWidth

      const updateRadius = () => {
        points.forEach((point) => {
          const screenPoint = this.getScreenPoint(point)
          if (screenPoint.x < minX) {
            minX = screenPoint.x
          }

          if (renderWidth - screenPoint.x < minX) {
            minX = renderWidth - screenPoint.x
          }

          if (screenPoint.y < minY) {
            minY = screenPoint.y
          }

          if (renderHeight - screenPoint.y < minY) {
            minY = renderHeight - screenPoint.y
          }

        })

        if ((minX > 0 && minY > 0) && minY < renderHeight - 100 || minX < renderWidth - 100 ) {
          resolve()
        } else {
          if (minX < 0 || minY < 0) {
            this._cameraController.camera.radius = this._cameraController.camera.radius * 1.01
          } else {
            this._cameraController.camera.radius = this._cameraController.camera.radius * 0.97
          }

          window.requestAnimationFrame(() => {
            updateRadius()
          })
        }
      }

      updateRadius()
      // reject()
    })
  }

  /**
   *
   * @return {Promise<[]>}
   */
  createPdfScreenShots() {
    return new Promise((resolve) => {
      const savedSettings = {
        alpha: this._cameraController.camera.alpha,
        beta: this._cameraController.camera.beta,
        radius: this._cameraController.camera.radius,
        target: this._cameraController.camera.target.clone()
      }

      const imagesList = []
      const edgeConnectors = this._blocksConstructor.edgeConnectors


      const width = (edgeConnectors.tl.x - edgeConnectors.br.x)
      const depth = (edgeConnectors.tl.y - edgeConnectors.br.y)
      const height = (this._blocksConstructor.stagesCount * BlocksConstructor.StageHeight)
      const radius = Math.abs(Math.abs(width) > Math.abs(depth) ? width : depth) * BlocksConstructor.Scale * 1.8

      const x = (-edgeConnectors.tl.x + width / 2) * BlocksConstructor.Scale
      const z = (edgeConnectors.tl.y - depth / 2) * BlocksConstructor.Scale
      const y = (height / 2) * BlocksConstructor.Scale

      const center =  new Vector3(x, y , z)

      const scaledWidth = (width / 2) * BlocksConstructor.Scale
      const scaledHeight = (height / 2) * BlocksConstructor.Scale
      const scaledDepth = (depth / 2) * BlocksConstructor.Scale

      const edgePoints = [
        new Vector3(x - scaledWidth, y + scaledHeight * 2, z + scaledDepth), // leftTop1
        new Vector3(x - scaledWidth, y + scaledHeight * 2, z - scaledDepth), // leftTop2

        new Vector3(x - scaledWidth, 0, z + scaledDepth), // leftBottom1
        new Vector3(x - scaledWidth, 0, z - scaledDepth), // leftBottom2

        new Vector3(x + scaledWidth, y + scaledHeight * 2, z + scaledDepth), // rightTop1
        new Vector3(x + scaledWidth, y + scaledHeight * 2, z - scaledDepth), // rightTop2

        new Vector3(x + scaledWidth, 0, z + scaledDepth), // rightBottom1
        new Vector3(x + scaledWidth, 0, z - scaledDepth), // rightBottom2
      ]

      // edgePoints.forEach(point => {
      //   const box = MeshBuilder.CreateBox('test', {
      //     size: 1
      //   }, this._scene)
      //   box.position = point
      // })

      this._cameraController.camera.setTarget(center)
      // this._cameraController.camera.mode = Camera.ORTHOGRAPHIC_CAMERA
      // this._cameraController.camera.orthoTop = 10
      // this._cameraController.camera.orthoBottom = -10
      // this._cameraController.camera.orthoLeft = -25
      // this._cameraController.camera.orthoRight = 25

      const cameraSettings = [
        {
          alpha: Math.PI / 3,
          beta: Math.PI / 3,
          radius: radius,
          target: new Vector3(center.x, center.y, center.z)
        },
        {
          alpha: Math.PI / 2,
          beta: 0,
          radius: radius,
          target: center
        },
        {
          alpha: 0,
          beta: Math.PI / 2,
          radius: radius,
          target: center
        },
        {
          alpha: Math.PI + Math.PI / 2,
          beta: Math.PI / 2,
          radius: radius,
          target: center
        },
        {
          alpha: Math.PI / 2,
          beta: Math.PI / 2,
          radius: radius,
          target: center
        },
        {
          alpha: Math.PI,
          beta: Math.PI / 2,
          radius: radius,
          target: center
        }
      ]

      let settingsIndex = 0

      const savedSize = { width: this._canvasSize.width, height: this._canvasSize.height }
      this.setSize({
        width: 680 * 2,
        height: 380 * 2
      })

      const makeImage = () => {
        const settings = cameraSettings[settingsIndex]
        settingsIndex++

        this._cameraController.camera.setTarget(settings.target)
        this._cameraController.camera.alpha = settings.alpha
        this._cameraController.camera.beta = settings.beta
        this._cameraController.camera.radius = settings.radius

        const roofVisibility = this._roofVisible

        if (settingsIndex === 2) {
          this.setRoofVisibility(false)
        }

        setTimeout(() => {
          this._adjustRadius(edgePoints).then(() => {
            this.getImage().then((imageData) => {
              imagesList.push(imageData)
              if (settingsIndex < cameraSettings.length) {
                setTimeout(() => {
                  makeImage()
                }, settingsIndex === 1 ? 2000 : 1000)
              } else {
                this._cameraController.camera.setTarget(savedSettings.target)
                this._cameraController.camera.alpha = savedSettings.alpha
                this._cameraController.camera.beta = savedSettings.beta
                this._cameraController.camera.radius = savedSettings.radius
                resolve(imagesList)
                this.setSize(savedSize)
              }
              this.setRoofVisibility(roofVisibility)
            })
          })
        }, 300)
      }

      makeImage()
    })
  }

  setSize({width, height}) {
    this._canvasSize = arguments[0]
    this._canvas.style.width = width + 'px'
    this._canvas.style.height = height + 'px'

    setTimeout(() => {
      if (this._engine) this._engine.resize()
    }, 0)
  }

  startRequestAnimation() {
    this._requestAnimationStarted = true
    this.requestFrame()
  }

  /**
   *
   * @param position {Vector3}
   * @param cameraPosition {Vector3}
   */
  navigateToPoint(position, cameraPosition) {
    this._cameraController.setTarget(position, cameraPosition)
  }

  /**
   *
   * @param position {Vector3}
   * @return Vector3
   */
  getScreenPoint(position) {
    const point = Vector3.Project(position,
      Matrix.Identity(),
      this._scene.getTransformMatrix(),
      this._scene.activeCamera.viewport.toGlobal(this._engine.getRenderWidth(), this._engine.getRenderHeight()))
    point.x *= this._hardwareScaling
    point.y *= this._hardwareScaling

    const localMatrix = new Matrix()
    this._scene.activeCamera.getWorldMatrix().invertToRef(localMatrix)
    const localCameraPosition = Vector3.TransformCoordinates(position, localMatrix)

    return localCameraPosition.z > 0 ? point : {
      x: -1000,
      y: -1000
    }
  }

  setDataForRender(data) {
    this._blocksConstructor.clear()
    if (data) this._blocksConstructor.constructFromData(data, {
      carcass: this._carcass,
      walls: this._walls,
      floor: this._floor,
      roof: this._roof,
      pillars: this._pillars
    })

    // this._scene.meshes.forEach((mesh) => {
    //   console.log(mesh.name, mesh.isReady())
    // })

    // this.exportToGlb()
  }

  setCarcass(carcass) {
    this._carcass = carcass
    this._blocksConstructor.setCarcass(this._carcass)
  }

  setWalls(wallOptions) {
    this._walls = wallOptions
    this._blocksConstructor.setWallOptions(this._walls)
  }

  setRoof(roofOptions) {
    this._roof = roofOptions
    this._blocksConstructor.setRoofOptions(this._roof)
  }

  requestFrame() {
    requestAnimationFrame(() => {
      this._scene.render()
      if (this._requestAnimationStarted) this.requestFrame()
    })
  }

  setWallsTransparency(transparent) {
    this._wallsTransparent = transparent
    this._blocksConstructor.setWallsTransparency(this._wallsTransparent)
  }

  setRoofVisibility(visible) {
    this._roofVisible = visible
    this._blocksConstructor.setRoofVisibility(visible)
  }

  exportToGlb() {
    this._blocksConstructor.hideGround()
    GLTF2Export.GLBAsync(this._scene, "fileName").then((gltf) => {
      gltf.downloadFiles()
      this._blocksConstructor.showGround()
    })
  }

  dispose() {
    this._requestAnimationStarted = false
    this._blocksConstructor.dispose()
    this._cameraController.dispose()
    this._scene.dispose()
    this._engine.dispose()

    this._blocksConstructor = null
    this._cameraController = null
    this._scene = null
    this._engine = null
  }
}

export default Blocks3DRenderer
