<template lang="pug">
  .popup-content-wrapper.project-sended
    .popup-header Заявка успешно отправлена
    .popup-content
      .success-icon
      .description Заявка (PDF) и ссылка для ее редактирования отправлены на почту <span>{{this.email}}</span>
    .popup-actions
      kraus-button(:label="'Продолжить'",
        :colors="{label: '#FFFFFF', background: '#0558AB'}",
        :border="true",
        @click="onContinueClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import PopupsNames from '@/components/_common/ui/Popup/popupNames';
export default {
  name: 'ProjectSended',
  components: { KrausButton },
  data() {
    return {
      email: ''
    }
  },

  mounted() {
    this.email = this.$store.state.email
  },

  methods: {
    onContinueClick() {
      eventBus.$emit(EventBusEvents.ShowPopup, null)
    },

    onCancelClick() {
      eventBus.$emit(EventBusEvents.ShowPopup, null)
    }
  }
}
</script>
