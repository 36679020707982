import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';

class CursorController {

  /**
   * @type {HTMLDivElement}
   * @private
   */
  _cursorElement
  _rightButtonPressed = false

  constructor() {
    this._cursorElement = document.querySelector('#cursor')
    document.addEventListener('mousemove', this._onMouseMove)
    eventBus.$on(EventBusEvents.RIGHT_MOUSE_BUTTON_DOWN, this._onRightMouseButtonDown)
    eventBus.$on(EventBusEvents.RIGHT_MOUSE_BUTTON_UP, this._onRightMouseButtonUp)
  }

  _onMouseMove = (e) => {
    let cursor = this.getCursorStyle(e.target)

    this._cursorElement.style.top = e.pageY + 'px'
    this._cursorElement.style.left = e.pageX + 'px'

    if (cursor === 'none') {
      this._cursorElement.setAttribute('class', this._rightButtonPressed ? 'move' : 'default')
    } else {
      this._cursorElement.setAttribute('class', '')
    }
  }

  _onRightMouseButtonDown = (e) => {
    this._rightButtonPressed = true
    this._cursorElement.setAttribute('class', 'move')
  }

  _onRightMouseButtonUp = (e) => {
    this._rightButtonPressed = false
    this._cursorElement.setAttribute('class','default')
  }

  /**
   *
   * @param el
   * @return {String|null}
   */
  getCursorStyle(el) {
    let cursor = null
    const css = getComputedStyle(el, null)
    if (css.cursor.trim() !== '') {
      cursor = css.cursor
    } else if (el.parentElement) {
      cursor = this.getCursorStyle(el.parentElement)
    }

    return cursor
  }

  dispose() {
    document.removeEventListener('mousemove', this._onMouseMove)
    eventBus.$off(EventBusEvents.RIGHT_MOUSE_BUTTON_DOWN, this._onRightMouseButtonDown)
    eventBus.$off(EventBusEvents.RIGHT_MOUSE_BUTTON_UP, this._onRightMouseButtonUp)
  }
}

export default CursorController
