import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Shape } from '@createjs/easeljs';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import FastVector from 'fast-vector';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';

class WallItemView extends BaseEditorElement {

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _selectedShape

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _hitAreaShape

  /**
   * @type {WallItem}
   */
  _wallItem

  /**
   * @return {WallItem}
   */
  get wallItem() {
    return this._wallItem
  }

  /**
   * @param wallItem {WallItem}
   */
  constructor(wallItem) {
    super()

    this._wallItem = wallItem

    this._hitAreaShape = new Shape()
    this.container.hitArea = this._hitAreaShape
    //this.container.addChild(this._hitAreaShape)

    this._selectedShape = new Shape()
    this.container.addChild(this._selectedShape)

    setTimeout(() => {
      this._wallItem.addChild(this)
    })

    this.container.addEventListener('mousedown', this._onMouseDown)
    this.container.addEventListener('pressmove', this._onPressMove)
  }

  _onPressMove = (e) => {
    const delta = new FastVector(
      Math.round(e.nativeEvent.movementX * BaseEditorElement.PixelRatio / Grid.Instance.container.scale),
      Math.round(e.nativeEvent.movementY * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)
    )

    this._wallItem.move(delta)
  }

  _onMouseDown = (e) => {
    if (!this._wallItem.isSetuped) {
      this._wallItem.trySetup()
    } else {
      CreateJsBlocksEditor.Instance.setSelectedElement(this._wallItem)
    }
  }

  draw() {
    super.draw();

    const wall = this._wallItem.wall
    this.container.rotation = (wall ? (wall.vertical ? 90 : 0) : 0 ) + this._wallItem.commonRotation + this._wallItem.rotation

    const [x, y] = [-this._wallItem._width / 2, -this._wallItem._height / 2]
    const [width, height] = [this._wallItem._width, this._wallItem._height]

    this._hitAreaShape.graphics
      .clear()
      .beginFill('#000000')
      .drawRect(x - 2, y - 6, width + 4, height + 12)
      .endFill()

    if (this._wallItem.isSelected()) {
      this._selectedShape.graphics
        .clear()
        .setStrokeDash([2, 2], -(this._wallItem.x + this._wallItem.y))
        .beginStroke('#F47090')
        .setStrokeStyle(2)
        .drawRect(x - 8, y - 4, width + 16, height + 8)
        .endStroke()
    } else {
      this._selectedShape.graphics.clear()
    }
  }

  destroy() {
    this.container.removeEventListener('mousedown', this._onMouseDown)
    this.container.removeEventListener('pressmove', this._onPressMove)
    this._wallItem.removeChild(this)
    super.destroy()
  }
}

export default WallItemView
