<template lang="pug">
  .selected-item-options(:class="{visible: item !== null && item.isSetuped}")
    template(v-if="item && jsonItem")
      vue-custom-scrollbar
        selected-item-header(:item="item", :json="jsonItem")
        selected-item-content(:item="item", :json="jsonItem")
      .selected-item-footer
        kraus-button(:icon="crossIcon", :label="'Свернуть'", :border="true", @click="onHideClick")
</template>
<script>
import SelectedItemHeader from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemHeader';
import SelectedItemContent from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent';
import KrausButton from '@/components/_common/ui/KrausButton';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import { mapGetters } from 'vuex';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
export default {
  name: 'SelectedItemOptions',
  components: { KrausButton, SelectedItemContent, SelectedItemHeader, vueCustomScrollbar },
  props: {
    item: null
  },

  data() {
    return {
      crossIcon: require('@/assets/images/ui/icon-cross.svg'),
      jsonItem: null,
    }
  },

  watch: {
    item(val) {
      this.getActualItem()
    },

    stagesData: {
      deep: true,
      handler: function() {
        this.getActualItem()
      }
    }
  },

  computed: {
    ...mapGetters([
      'stagesData',
      'stage'
    ])
  },

  methods: {
    onHideClick() {
      CreateJsBlocksEditor.Instance.setSelectedElement(null)
    },

    getActualItem() {
      this.jsonItem = null

      if (this.item) {
        switch (this.item.elementType) {

          case BaseEditorElement.ELEMENT_TYPE_BOX:
            for (let stage in this.stagesData) {
              if (parseInt(stage) === this.stage) {
                const stageData = this.stagesData[stage]
                if (stageData) {
                  const filtered = stageData.boxes.filter(box => box.id === this.item.id)
                  if (filtered.length > 0) {
                    this.jsonItem = filtered[0]
                    return
                  }
                }
              }
            }
            break

          case BaseEditorElement.ELEMENT_TYPE_WALL_ITEM:
            for (let stage in this.stagesData) {
               if (parseInt(stage) === this.stage) {
                const stageData = this.stagesData[stage]
                if (stageData) {
                  const filtered = stageData.wallItems.filter(wallItem => wallItem.id === this.item.id)
                  if (filtered.length > 0) {
                    this.jsonItem = filtered[0]
                    return
                  }
                }
              }
            }

            break

          case BaseEditorElement.ELEMENT_TYPE_BOX_ITEM:
            for (let stage in this.stagesData) {
               if (parseInt(stage) === this.stage) {
                const stageData = this.stagesData[stage]
                if (stageData) {
                  const filtered = stageData.boxItems.filter(boxItem => boxItem.id === this.item.id)
                  if (filtered.length > 0) {
                    this.jsonItem = filtered[0]
                    return
                  }
                }
              }
            }
            break

          case BaseEditorElement.ELEMENT_TYPE_EXTERNAL_ITEM:
            for (let stage in this.stagesData) {
               if (parseInt(stage) === this.stage) {
                const stageData = this.stagesData[stage]
                if (stageData) {
                  const filtered = stageData.externalItems.filter(externalItem => externalItem.id === this.item.id)
                  if (filtered.length > 0) {
                    this.jsonItem = filtered[0]
                    return
                  }
                }
              }
            }
            break

          case BaseEditorElement.ELEMENT_TYPE_WALL:

            for (let stage in this.stagesData) {
              const stageData = this.stagesData[stage]
              if (stageData) {
                const filtered = stageData.walls.filter(wallItem => wallItem.id === this.item.id)
                if (filtered.length > 0) {
                  this.jsonItem = filtered[0]
                  return
                }
              }
            }

            break
        }
      }
    }
  }
}
</script>
