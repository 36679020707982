import WallsConstructor
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/WallsConstructor';
import Box from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/Box';
import ExternalItemsConstructor
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/ExternalItemsConstructor';
import BoxItemsConstructor
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/BoxItemsConstructor';
import GroundGrid from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/GroundGrid';
import { Texture } from '@babylonjs/core';
import Roof3D from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/Roof3D';
import ModelsCache
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/ModelsCache/ModelsCache';
import Wall
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/WallsConstructor/Wall';

import "@babylonjs/inspector"
import EditorDataUtil from '@/common/EditorDataUtil.class'
import PillarsConstructor
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/PillarsConstructor';

class BlocksConstructor {

  /**
   * @private {ModelsCache}
   */
  static _modelsCache

  static Scale = 0.01
  static StageHeight = 245

  _scene

  _wallsConstructors = []
  _boxes = []
  _externalItemsConstructor
  _boxItemsConstructor
  _pillarsConstructor

  /**
   * @type GroundGrid
   * @private
   */
  _groundGrid

  /**
   *
   * @type {Roof3D[]}
   * @private
   */
  _roofs = []

  /**
   * @type {{tl: {x:number, y:number}, br: {x:number, y:number}}}
   * @private
   */
  _edgeConnectors

  _stagesCount = 0

  /**
   * @return {{tl: {x:number, y:number}, br: {x:number, y:number}}}
   */
  get edgeConnectors() {
    return this._edgeConnectors
  }

  /**
   * @return {number}
   */
  get stagesCount() {
    return this._stagesCount
  }

  get hasRoof() {
    return this._roofs.length > 0
  }

  constructor({scene}) {
    this._scene = scene

    BlocksConstructor._modelsCache = new ModelsCache({scene})
    Wall._insideMaterialsCache = []
    Wall._outsideMaterial = null
    Wall._carcassMaterial = null

    Box._groundMaterial = null
    Box._coatingMaterials = []


    this._scene.onBeforeRenderObservable.add(() => {
      this._wallsConstructors.forEach(wallConstructor => {
        wallConstructor.updateTransparency()
      })
    })

    // this._scene.debugLayer.show({
    //   embedMode: true,
    // })
  }

  setCarcass(carcass) {
    this._wallsConstructors.forEach(wallConstructor => {
      wallConstructor.setCarcass(carcass)
    })
  }

  setWallOptions(options) {
    this._wallsConstructors.forEach(wallConstructor => {
      wallConstructor.setWallOptions(options)
    })
  }

  setRoofOptions(options) {
    this._roofs.forEach(roof => {
      roof.setOptions(options)
    })
  }

  setWallsTransparency(transparency) {
    this._wallsConstructors.forEach(wallConstructor => wallConstructor.setWallsTransparency(transparency))
    this._roofs.forEach(roofItem => roofItem.setTransparency(transparency))
  }

  setRoofVisibility(visible) {
    this._roofs.forEach(roofItem => roofItem.setVisibility(visible))
  }

  hideGround() {
    this._groundGrid.hide()
  }

  showGround() {
    this._groundGrid.show()
  }

  _getConnectorById(stageData, id) {
    const list = stageData.connectors.filter((connector) => connector.id === id)
    return list.length > 0 ? list[0] : null
  }

  _getWallItemById(stageData, id) {
    const list = stageData.wallItems.filter((wallItem) => wallItem.id === id)
    return list.length > 0 ? list[0] : null
  }

  _getWallById(stageData, id) {
    const list = stageData.walls.filter((wall) => wall.id === id)
    return list.length > 0 ? list[0] : null
  }

  _getBoxItem(stageData, id) {
    const list = stageData.boxItems.filter((boxItem) => boxItem.id === id)
    return list.length > 0 ? list[0] : null
  }


  constructFromData(stagesData, {carcass, walls, floor, roof, pillars}) {
    let lastStageData = null
    let lastStage = 0

    stagesData = JSON.parse(JSON.stringify(stagesData))

    for (let stage in stagesData) {
      const data = stagesData[stage]

      if (data) {
        data.boxes.forEach((box) => {
          box.connectors = box.connectors.map((connectorId) => this._getConnectorById(data, connectorId))
          box.boxItems = box.boxItems.map((boxItemId) => this._getBoxItem(data, boxItemId))
        })

        data.walls.forEach((wall) => {
          wall.connectors = wall.connectors.map((connectorId) => this._getConnectorById(data, connectorId))
        })
        data.tempWalls.forEach((wall) => {
          wall.connectors = wall.connectors.map((connectorId) => this._getConnectorById(data, connectorId))
          wall.wallItems = wall.wallItems.map((wallItemId) => this._getWallItemById(data, wallItemId))
        })
        data.insideWalls.forEach((wall) => {
          wall.connectors = wall.connectors.map((connectorId) => this._getConnectorById(data, connectorId))
          wall.wallItems = wall.wallItems.map((wallItemId) => this._getWallItemById(data, wallItemId))
          wall.intersectedWalls = wall.intersectedWalls.map((wallId) => this._getWallById(data, wallId))
        })

        lastStageData = data
        lastStage = parseInt(stage)

        const stageWallsConstructor = new WallsConstructor({
          scene: this._scene,
          data,
          carcass,
          walls,
          stage: stage * 1
        })

        this._wallsConstructors.push(stageWallsConstructor)

        const stairsSourceData = stage > 1 ? stagesData[stage - 1].boxItems.filter(item => item.dictionaryData.editorType === 'Stairs') : null
        const stairsData = []

        if (stairsSourceData) {
          const stageBoxes = stagesData[stage - 1].boxes

          stairsSourceData.forEach(stairSourceItem => {
            const stairItem = JSON.parse(JSON.stringify(stairSourceItem))
            const box = stageBoxes.filter(boxItem => boxItem.id === stairItem.boxId)[0]

            stairItem.position.x = box.x + stairItem.position.x
            stairItem.position.y = box.y + stairItem.position.y
            stairsData.push(stairItem)
          })
        }

        data.boxes.forEach(boxData => {
          const box = new Box({
            scene: this._scene,
            data: boxData,
            stageData: data,
            floor,
            stairsData,
            stage
          })

          this._boxes.push(box)
          stageWallsConstructor.walls.forEach((wallItem, index) => {
            if (box.isContainWallId(wallItem.id)) {
              wallItem.setBox(box)
            }
          })
        })
      }
    }

    this._stagesCount = lastStage

    this._pillarsConstructor = new PillarsConstructor({
      pillars,
      scene: this._scene
    })

    this._externalItemsConstructor = new ExternalItemsConstructor({
      scene: this._scene,
      stagesData: stagesData
    })

    this._boxItemsConstructor = new BoxItemsConstructor({
      scene: this._scene,
      stagesData: stagesData
    })

    this._groundGrid = new GroundGrid({scene: this._scene})

    const boxesPacks = EditorDataUtil.getConnectedPacks(lastStageData)

    boxesPacks.forEach(boxesPack => {
      if (boxesPack.length >= 4) {
        this._roofs.push(new Roof3D({
          stage: lastStage,
          connectedBoxes: boxesPack,
          scene: this._scene,
          options: roof
        }))
      }
    })

    this._updateEdgeConnectors(stagesData)

    // this._scene.freeActiveMeshes()
    // this._scene.autoClear = false // Color buffer
    // this._scene.autoClearDepthAndStencil = false // Depth and stencil, obviously
    // this._scene.blockMaterialDirtyMechanism = true
    // this._scene.setRenderingAutoClearDepthStencil(0, false, false, false)
  }

  _updateEdgeConnectors(stagesData) {
    let tl = null
    let br = null

    let connectors = []
    if (stagesData[1]) {
      connectors = stagesData[1].connectors
    }

    connectors.forEach(connector => {
      if (tl === null) {
        tl = connector
      } else {
        const vector = {
          x: connector.x - tl.x,
          y: connector.y - tl.y
        }

        if (vector.x <= 0 && vector.y <= 0) tl = connector
      }

      if (br === null) {
        br = connector
      } else {
        const vector = {
          x: connector.x - br.x,
          y: connector.y - br.y
        }

        if (vector.x >= 0 && vector.y >= 0) br = connector
      }
    })

    this._edgeConnectors = {
      tl,
      br
    }
  }

  clear() {
    if (this._wallsConstructors.length > 0) this._wallsConstructors.forEach((wallConstructor) => wallConstructor.dispose())
    if (this._externalItemsConstructor) this._externalItemsConstructor.dispose()
    if (this._boxItemsConstructor) this._boxItemsConstructor.dispose()
    if (this._groundGrid) this._groundGrid.dispose()

    this._roofs.forEach(roof => roof.dispose())
  }

  dispose() {
    this.clear()
  }
}

export default BlocksConstructor
