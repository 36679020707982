<template lang="pug">
  .container-info
    .container-info_row
      .container-info_label Кол-во блоков
      .container-info_value {{this.blocksCount}}

    .container-info_row
      .container-info_label Этажи
      .container-info_value {{stagesCount}}

    .container-info_row
      .container-info_label Площадь
      //.container-info_value {{this.width / 100}}м x {{this.height / 100}}м x {{(this.stagesCount * 245) / 100}}м
      .container-info_value {{square}} м²
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ContainerInfo',
  data() {
    return {
      stagesCount: 1,
      blocksCount: 0,
      width: 0,
      height: 0,
    }
  },

  created() {
    this.updateInfo()
  },

  computed: {
    ...mapGetters([
      'stagesData',
      'square',
    ]),
  },

  watch: {
    stagesData: {
      deep: true,
      handler: function () {
        this.updateInfo()
      }
    }
  },

  methods: {
    updateInfo() {
      if (this.stagesData) {
        this.blocksCount = 0
        this.width = 0
        this.height = 0

        const topLeftConnector = {
          x: null,
          y: null
        }

        const bottomRightConnector = {
          x: null,
          y: null
        }

        let connectorsFinded = false

        for (let i = 1; i <= 3; i++) {
          if (this.stagesData[i] && this.stagesData[i].boxes.length > 0) {
            this.stagesCount = i
          }

          const stageData = this.stagesData[i]
          if (stageData) {
            this.blocksCount += stageData.boxes.length
            stageData.connectors.forEach(c => {
              connectorsFinded = true

              if (topLeftConnector.x == null || topLeftConnector.x > c.x) {
                topLeftConnector.x = c.x
              }

              if (topLeftConnector.y == null || topLeftConnector.y > c.y) {
                topLeftConnector.y = c.y
              }

              if (bottomRightConnector.x == null || topLeftConnector.x < c.x) {
                bottomRightConnector.x = c.x
              }

              if (bottomRightConnector.y == null || bottomRightConnector.y < c.y) {
                bottomRightConnector.y = c.y
              }
            })
          }
        }

        if (connectorsFinded) {
          this.width = bottomRightConnector.x - topLeftConnector.x
          this.height = bottomRightConnector.y - topLeftConnector.y
        }
      }
    },
  }
}
</script>
