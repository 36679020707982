<template lang="pug">
  .comment-form.no-select
    .comment-form-add-comment-button(v-if="!comment && !editMode", @click="onAddCommentClick")
      .add-comment-button-icon
      .add-comment-button-text Добавить доп. указания

    .comment-form-editor(v-if="editMode")
      .comment-form-editor-text
        textarea(v-model="comment")
      .comment-form-editor-actions
        .action-button-save.no-select(@click="onSaveClick") Сохранить
        .action-button-cancel.no-select(@click="onCancelClick") Отмена

    .comment-form-viewer(v-if="comment && !editMode")
      .comment-form-viewer-label Доп. комментарий
      .comment-form-viewer-text {{comment}}
      .comment-form-viewer-actions
        .action-button-edit.no-select(@click="onEditClick")
          .action-button-icon
          .action-button-label Изменить
        .action-button-cancel.no-select(@click="onDeleteClick")
          .action-button-icon
          .action-button-label Удалить

</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CommentForm',
  data() {
    return {
      comment: '',
      editMode: false,
    }
  },

  mounted() {
    this.comment = this.projectComment
  },

  computed: {
    ...mapGetters([
        'projectComment'
    ])
  },

  watch: {
    projectComment(val) {
      this.comment = val
    }
  },

  methods: {
    ...mapActions([
        'setProjectComment'
    ]),

    onAddCommentClick() {
      this.editMode = true
    },

    onSaveClick() {
      this.comment = this.comment.trim()
      this.setProjectComment(this.comment)
      this.editMode = false
    },

    onCancelClick() {
      this.editMode = false
    },

    onEditClick() {
      this.editMode = true
    },

    onDeleteClick() {
      this.comment = ''
      this.setProjectComment('')
      this.editMode = false
    }
  }
}
</script>
