<template lang="pug">
  .ordering-equipment
    .image-wrapper(v-for="(image, index) in imagesList", :key="index")
      .order-image(:style="{backgroundImage: 'url('+ image +')'}")
      .stage-num(v-if="imagesList.length > 1") Этаж {{index + 1}}
    ordering-comment-form

    .equipment-wrapper
      .building-equipment
        container-items

</template>
<script>
import ContainerItems from '@/components/BlocksEditor/BuildingEquipment/ContainerItems';
import OrderingCommentForm from '@/components/Ordering/OrderingCommentForm';
import { mapGetters } from 'vuex';
export default {
  name: 'OrderingEquipment',
  components: { OrderingCommentForm, ContainerItems },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'orderingStageImages'
    ]),

    imagesList() {
      const imagesList = []

      for (let key in this.orderingStageImages) {
        if (this.orderingStageImages[key]) {
          imagesList[key - 1] = this.orderingStageImages[key].dataURL
        }
      }
      return imagesList
    }
  }
}
</script>
