<template lang="pug">
  .tooltip-icon(v-if="hintDataText", v-tooltip.left="hintDataText || text")
</template>
<script>
import HintsData from '@/common/HintsData';

export default {
  name: 'TooltipIcon',
  props: ['text', 'hint'],
  data() {
    return {}
  },

  computed: {
    hintDataText() {
      return this.hint ? HintsData.getHint(this.hint.section, this.hint.key) : null
    }
  }
}
</script>
