import { Color3, Mesh, MeshBuilder, StandardMaterial, Vector3 } from '@babylonjs/core';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';
import { GridMaterial } from '@babylonjs/materials';

class GroundGrid {
  _ground
  _scene

  constructor({scene}) {
    this._scene = scene
    this._createGrid()
  }

  _createGrid() {

    const ratio = 0.05
    const groundMaterial = new GridMaterial("groundMaterial", this._scene);
    groundMaterial.majorUnitFrequency = (100 / ratio) * BlocksConstructor.Scale;
    groundMaterial.minorUnitVisibility = 0.3;
    groundMaterial.gridRatio = ratio;
    groundMaterial.backFaceCulling = false;
    groundMaterial.mainColor = Color3.White()
    groundMaterial.lineColor = Color3.FromHexString('#889EBF')
    groundMaterial.opacity = 0.5;
    groundMaterial.wireframe = false;
    groundMaterial.fogEnabled = true

    this._ground = Mesh.CreateGround("ground", 1000, 1000, 1, this._scene, false);
    // this._ground = Mesh.CreateBox("ground", 100, this._scene);
    this._ground.position.y = -0.01
    this._ground.material = groundMaterial;
  }

  hide() {
    this._ground.scaling = Vector3.Zero()
  }

  show() {
    this._ground.scaling = Vector3.One()
  }

  dispose() {
    this._ground.dispose()
  }
}

export default GroundGrid
