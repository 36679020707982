<template lang="pug">
  .section-menu(v-if="selectedSection", :style="menuStyle")
    .section-menu-header
      .title {{selectedSection.title}}
      .close-button(@click="onCloseClick")
    component(v-bind:is="contentComponent")
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ItemsList from '@/components/BlocksEditor/LeftMenu/SectionMenu/ItemsList';
import MaterialsList from '@/components/BlocksEditor/LeftMenu/SectionMenu/MaterialsList';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';

export default {
  name: 'SectionMenu',
  components: {ItemsList, MaterialsList},
  data() {
    return {
      updatePositionInterval: null,
      rectOfMenuItemButton: null,
      rectOfSectionMenu: null
    }
  },

  mounted() {
    this.updatePositionInterval = setInterval(() => {
      this.updatePosition()
    }, 100)

    document.addEventListener('mouseup', this.onMouseUp)
    CreateJsBlocksEditor.Instance.addEventListener(Events.ELEMENT_SELECTED, this.onElementSelected)
  },

  destroyed() {
    clearInterval(this.updatePositionInterval)
    document.removeEventListener('mouseup', this.onMouseUp)
    CreateJsBlocksEditor.Instance.removeEventListener(Events.ELEMENT_SELECTED, this.onElementSelected)
  },

  computed: {
    ...mapGetters([
      'selectedSection'
    ]),

    contentComponent() {
      return this.selectedSection && this.selectedSection.id === 'decoration' ? 'MaterialsList' : 'ItemsList'
    },

    menuStyle() {
      if (this.rectOfMenuItemButton) {
        let topPosition = this.rectOfMenuItemButton.top + this.rectOfMenuItemButton.height / 2 - this.rectOfSectionMenu.height / 2 - 80
        let bottomPosition = topPosition + this.rectOfSectionMenu.height

        if (topPosition < 70) topPosition = 70
        if (bottomPosition + 80 > window.innerHeight) {
          const delta = bottomPosition - window.innerHeight + 80 + 10
          topPosition -= delta
        }
        return {
          top: topPosition + 'px'
        }
      }

      return {}
    },
  },

  methods: {
    ...mapActions([
      'selectSection'
    ]),

    onMouseUp(e) {
      if (e.target.closest('.section-menu') === null) {
        this.onCloseClick()
      }
    },

    onElementSelected() {
      this.onCloseClick()
    },

    onCloseClick() {
      this.selectSection(null)
    },

    updatePosition() {
      const selectedElement = document.querySelector('.left-menu-item.selected')
      if (selectedElement && this.$el) {
        this.rectOfMenuItemButton = selectedElement.getBoundingClientRect()
        this.rectOfSectionMenu = this.$el.getBoundingClientRect()
      }
    }
  }
}
</script>
