<template lang="pug">
  .door-options
    .door-type
      .select-label Тип
      .select-wrapper
        kraus-equipment-select(v-model="type.value", :values="type.items")

    .door-sizes(v-if="size.items.length > 1")
      .select-label Размер
      .select-wrapper
        kraus-equipment-select(v-model="size.value", :values="size.items")

    .door-sizes(v-if="json.dictionaryData.configurations")
      .select-label Нижняя и верхнии части
      .select-wrapper
        kraus-equipment-select(v-model="configuration.value", :values="configuration.items")

    template(v-if="false")
      .top-part()
        .select-label Верхняя часть
        .select-wrapper
          kraus-equipment-select(v-model="topPart.value", :values="topPart.items")

      .bottom-part
        .select-label Верхняя часть
        .select-wrapper
          kraus-equipment-select(v-model="bottomPart.value", :values="bottomPart.items")

    .door-color(v-if="this.color.items.length > 0")
      .select-label Цвет
      .select-wrapper
        kraus-equipment-select(v-model="color.value", :values="color.items")

    .divider

    .label-item Замки

    .door-locks
      .lock-item
        .small-switcher
          .small-switcher-info
            .text 1-й замок
            .cost.blue бесплатно
          .small-switcher-switcher
            kraus-switcher-small(v-model="locks.lock1")

      .lock-item
        .small-switcher
          .small-switcher-info
            .text 2-й замок
            .cost(:class="{blue: !locks.lock2}") {{costs.lock2| currency }}
          .small-switcher-switcher
            kraus-switcher-small(v-model="locks.lock2")

      .lock-item
        .small-switcher
          .small-switcher-info
            .text Внутр. засов
            .cost(:class="{blue: !locks.bolt}") {{costs.bolt| currency }}
          .small-switcher-switcher
            kraus-switcher-small(v-model="locks.bolt")

    template(v-if="json.dictionaryData.metalFinishing")
      .divider

      .label-item Внутренняя отделка

      .door-locks

        .lock-item
          .small-switcher
            .small-switcher-info
              .text Замена дермантина металом
              .cost(:class="{blue: !metalFinishing}") {{json.dictionaryData.metalFinishing.price| currency }}
            .small-switcher-switcher
              kraus-switcher-small(v-model="metalFinishing")

    .door-closer
      .small-switcher(v-if="json.dictionaryData.closer")
        .small-switcher-info
          .text Доводчик
          .cost(:class="{blue: !closerEnabled}") {{json.dictionaryData.closer.price | currency}}
        .small-switcher-switcher
          kraus-switcher-small(v-model="closerEnabled")

</template>
<script>
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import EquipmentCategories from '@/common/EquipmentCategories';
import LeftMenuData from '@/components/BlocksEditor/LeftMenu/leftMenuData';
import MapUtil from '@/utils/MapUtil';
export default {
  name: 'DoorOptions',
  components: { KrausSwitcherSmall, KrausEquipmentSelect },
  props: {
    item: null,
    json: null
  },

  data() {
    return {
      prevId: null,
      costs: {
        lock2: 0,
        bolt: 0
      },
      locks: {
        lock1: false,
        lock2: false,
        bolt: false
      },

      metalFinishing: false,

      type: {
        value: null,
        items: []
      },
      size: {
        value: null,
        items: [],
      },

      configuration: {
        value: null,
        items: []
      },

      color: {
        value: null,
        items: []
      },

      closerEnabled: false
    }
  },

  created() {
    this.init()
  },

  watch: {
    "item.id": function () {
      this.init()
    },

    'json': {
      deep: true,
      handler: function (val, oldVal) {
        if (this.prevId !== val.id || val.dictionaryData.id !== oldVal.dictionaryData.id) this.init()
        this.prevId = val.id
      }
    },

    locks: {
      deep: true,
      handler: function () {
        const dictionaryData = this.json.dictionaryData
        dictionaryData.lock1Enabled = this.locks.lock1
        dictionaryData.lock2Enabled = this.locks.lock2
        dictionaryData.boltEnabled = this.locks.bolt
        this.item.setDictionaryData(dictionaryData)
      }
    },

    closerEnabled(val) {
      const dictionaryData = this.json.dictionaryData
      dictionaryData.closerEnabled = val
      this.item.setDictionaryData(dictionaryData)
    },

    metalFinishing(val) {
      const dictionaryData = this.json.dictionaryData
      dictionaryData.metalFinishingEnabled = val
      this.item.setDictionaryData(dictionaryData)
    },

    'size.value': {
      handler: function (val) {
        if (val) {
          const dictionaryData = this.json.dictionaryData
          dictionaryData.size = val
          this.item.setDictionaryData(dictionaryData)
        }
      }
    },

    'color.value': {
      handler: function (val) {
        const dictionaryData = this.json.dictionaryData
        dictionaryData.color = val
        this.item.setDictionaryData(dictionaryData)
      }
    },

    'type.value': {
      handler: function (val, oldVal) {

        const dictionaryData = val

        this.configuration.value = null

        if (this.item.dictionaryData.configurations) {
          this.configuration.items = this.item.dictionaryData.configurations.map(item => {
            return {
              key: item.key,
              title: item.title,
              cost: item.cost,
              raw: item
            }
          })

          if (this.item.dictionaryData.configuration) {
            this.configuration.value = this.configuration.items.filter(item => item.key === this.item.dictionaryData.configuration.key)[0]
          } else {
            this.configuration.value = this.configuration.items[0]
          }
        }

        dictionaryData.configuration = this.configuration.value

        if (this.item.dictionaryData.sizes) {
          this.size.items = this.item.dictionaryData.sizes.map(item => {
            return {
              key: item.key,
              title: item.title,
              cost: item.price,
              width: item.width,
              height: item.height,
              raw: item,
            }
          })

          // this.size.value = this.size.items.filter(item => item.key === this.item.dictionaryData.size.key)[0]
          const filteredList = this.item.dictionaryData.size ? this.size.items.filter(item => item.key === this.item.dictionaryData.size.key) : []
          this.size.value = filteredList.length > 0 ? filteredList[0] : this.size.items[0]
        } else {
          this.size.items = []
          this.size.value = null
        }

        dictionaryData.size = this.size.value
        this.item.setDictionaryData(dictionaryData)

        if (oldVal && val.id !== oldVal.id) {
          this.init()
        }
      }
    },

    'configuration.value': {
      handler: function (val) {
        const dictionaryData = this.json.dictionaryData
        dictionaryData.configuration = val ? val.raw : null
        this.item.setDictionaryData(dictionaryData)
      }
    }
  },

  computed: {
    insideDoor () {
      return this.json.dictionaryData.inside
    }
  },

  methods: {
    init() {
      EquipmentCategories.getItems({
        categoryKey: 'dveri_vneshnie',
        optionKey: 'prochee'
      }).options.forEach(optionItem => {
        if (optionItem.key === 'vtotoy_zamok') {
          this.costs.lock2 = optionItem.price
        } else if (optionItem.key === 'zadvidka') {
          this.costs.bolt = optionItem.price
        }
      })

      this.locks.lock1 = this.item.dictionaryData.lock1Enabled
      this.locks.lock2 = this.item.dictionaryData.lock2Enabled
      this.locks.bolt = this.item.dictionaryData.boltEnabled
      this.closerEnabled = this.item.dictionaryData.closerEnabled

      this.metalFinishing = this.item.dictionaryData.metalFinishingEnabled

      this.type.items = []

      const otherDoorsKeys = ['vorota', 'rolstavni', 'luk']
      if (otherDoorsKeys.indexOf(this.item.dictionaryData.id) < 0) {
        if (this.item.dictionaryData.inside) {
          EquipmentCategories.getCategory('dveri_mezhkomnatnye')
            .options
            .forEach(item => {
              if (item.key !== 'prochee' && otherDoorsKeys.indexOf(item.key) < 0) {
                let mapped = MapUtil.doorInternal(item)
                if (mapped.id === this.json.dictionaryData.id) {
                  mapped = this.json.dictionaryData
                }
                this.type.items.push(mapped)
              }
            })
        } else {
          EquipmentCategories.getCategory('dveri_vneshnie')
            .options
            .forEach(item => {
              if (item.key !== 'prochee' && otherDoorsKeys.indexOf(item.key) < 0) {
                let mapped = MapUtil.doorExternal(item)
                if (mapped.id === this.json.dictionaryData.id) {
                  mapped = this.json.dictionaryData
                }
                this.type.items.push(mapped)
              }
            })
        }
      } else {
        EquipmentCategories.getCategory('dveri_vneshnie')
          .options
          .forEach(item => {
            if (item.key !== 'prochee' && otherDoorsKeys.indexOf(item.key) >= 0) {
              let mapped = MapUtil.doorOther(item)
              if (mapped.id === this.json.dictionaryData.id) {
                mapped = this.json.dictionaryData
              }
              this.type.items.push(mapped)
            }
          })
      }

      this.type.items.forEach(item => {
        item.icon = {
          dataURL: item.menuIcon
        }
      })

      this.type.value = this.type.items.filter(item => item.id === this.item.dictionaryData.id)[0]

      if (this.item.dictionaryData.sizes) {
        this.size.items = this.item.dictionaryData.sizes.map(item => {
          return {
            key: item.key,
            title: item.title,
            cost: item.price,
            width: item.width,
            height: item.height,
            raw: item,
          }
        })

        this.size.value = this.size.items.filter(item => item.key === this.item.dictionaryData.size.key)[0]
      } else {
        this.size.items = []
      }

      if (this.item.dictionaryData.colors) {
        this.color.items = this.item.dictionaryData.colors.map(item => {
          return {
            key: item.key,
            icon: {
              color: item.hex
            },
            title: item.title,
            cost: item.cost,
            raw: item
          }
        })

        this.color.value = this.color.items.filter(item => item.key === this.item.dictionaryData.color.key)[0]
      } else {
        this.color.items = []
      }

      this.configuration.items = []

      if (this.item.dictionaryData.configurations) {
        this.configuration.items = this.item.dictionaryData.configurations.map(item => {
          return {
            key: item.key,
            title: item.title,
            cost: item.cost,
            raw: item
          }
        })

        if (this.item.dictionaryData.configuration) {
          this.configuration.value = this.configuration.items.filter(item => item.key === this.item.dictionaryData.configuration.key)[0]
        } else {
          this.configuration.value = this.configuration.items[0]
        }
      }
    }
  }
}
</script>
