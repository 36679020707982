import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import FastVector from 'fast-vector';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall';

export default class WallConnector {
  static _ID = 0

  /**
   *
   * @type {WallConnector[]}
   */
  static WallConnectorsList = []

  /**
   *
   * @type {Wall[]}
   * @private
   */
  _walls = []

  _id = 0
  x = 0
  y = 0

  /**
   *
   * @param x {number}
   * @param y {number}
   * @constructor
   */
  static Create({x, y}) {
    WallConnector._ID ++
    const connector = new WallConnector()
    connector.setId(WallConnector._ID)
    connector.x = x
    connector.y = y

    WallConnector.WallConnectorsList.push(connector)

    return connector
  }

  static FromJSON(data) {
    if (data.id > WallConnector._ID) WallConnector._ID = data.id
    const connector = new WallConnector()
    connector.setId(data.id)
    connector.x = data.x
    connector.y = data.y

    WallConnector.WallConnectorsList.push(connector)
  }

  /**
   * @param id
   * @return {WallConnector | null}
   * @constructor
   */
  static GetById(id) {
    const list = WallConnector.WallConnectorsList.filter((connector) => connector.id === id)
    return list.length > 0 ? list[0] : null
  }

  constructor() {
    //
  }

  /**
   * @return {number}
   */
  get id() {
    return this._id
  }

  /**
   * @return {Wall[]}
   */
  get walls() {
    return this._walls
  }

  setId(id) {
    this._id = id
  }

  addWall(wall) {
    if (this._walls.indexOf(wall) < 0) this._walls.push(wall)
  }

  removeWall(wall) {
    this._walls = this._walls.filter(wallObject => wallObject !== wall)

    setTimeout(() => {
      if (this._walls.length === 0) this.destroy()
    }, 0)
  }

  toJson() {
    const data = {
      id: this.id,
      x: this.x,
      y: this.y,
      walls: this._walls.map(wall => wall.id)
    }

    return data
  }

  destroy() {
    WallConnector.WallConnectorsList = WallConnector.WallConnectorsList
      .filter((connector) => connector !== this)
  }
}
