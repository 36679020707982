import AbstractRoofMesh
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/Roof/AbstractRoofMesh'
import {
  CSG,
  Mesh,
  MeshBuilder,
  TransformNode,
} from '@babylonjs/core';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor'

class SingleRoofMesh extends AbstractRoofMesh {

  /**
   *
   * @type {{side: Mesh, top: Mesh, bottom: Mesh}}
   * @private
   */
  meshes = {
    bottom: null,
    top: null,
    side: null
  }


  constructor({...args}) {
    super({
      type: AbstractRoofMesh.Types.single,
      ...args
    })

    this.createMesh()
  }

  createMesh() {
    this._createBottomMesh()
    this._createSideMeshes()
    this._createTopMesh()
  }

  _createBottomMesh() {
    const width = ((this.vertical ? this.width : this.height) + 8) * BlocksConstructor.Scale
    const depth = ((this.vertical ? this.height : this.width) + 8) * BlocksConstructor.Scale

    const mesh = MeshBuilder.CreateBox('Single roof bottom mesh', {
      width,
      height: BlocksConstructor.Scale,
      depth,
    }, this.scene)

    mesh.position = this.center
    mesh.rotation.y = !this.vertical ? Math.PI / 2 : 0
    mesh.material = this.roofMaterial

    mesh.parent = this.rootMesh

    this.meshes.bottom = mesh
  }

  _createSideMeshes() {
    const sideRootMesh = new Mesh('Single roof root of side meshes', this.scene)
    sideRootMesh.parent = this.rootMesh

    const width = ((this.vertical ? this.width : this.height) + 8) * BlocksConstructor.Scale
    const depth = ((this.vertical ? this.height : this.width) + 8) * BlocksConstructor.Scale
    const height = (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale

    let frontMesh = MeshBuilder.CreateBox('Single roof front side mesh', {
      width: width,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    frontMesh = this.createEdges(frontMesh, width, BlocksConstructor.Scale * 4)

    frontMesh.parent = sideRootMesh
    frontMesh.position.y = height / 2
    frontMesh.position.z += depth / 2


    let backMesh = MeshBuilder.CreateBox('Single roof front side mesh', {
      width: width,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    backMesh = this.createEdges(backMesh, width, BlocksConstructor.Scale * 4)

    backMesh.parent = sideRootMesh
    backMesh.position.y = height / 2
    backMesh.position.z -= depth / 2
    backMesh.rotation.y = Math.PI

    sideRootMesh.position = this.center
    sideRootMesh.rotation.y = !this.vertical ? Math.PI / 2 : 0

    let leftMesh = MeshBuilder.CreateBox('Single roof left side mesh', {
      width: depth,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    leftMesh = this.createEdges(leftMesh, depth, BlocksConstructor.Scale * 4)

    leftMesh.parent = sideRootMesh
    leftMesh.position.y = height / 2
    leftMesh.position.x -= width / 2
    leftMesh.rotation.y = -Math.PI / 2

    let rightMesh = MeshBuilder.CreateBox('Single roof left side mesh', {
      width: depth,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    rightMesh = this.createEdges(rightMesh, depth, BlocksConstructor.Scale * 4)

    rightMesh.parent = sideRootMesh
    rightMesh.position.y = height / 2
    rightMesh.position.x = width / 2
    rightMesh.rotation.y = Math.PI / 2

    const slicer = MeshBuilder.CreateBox('slicer', {
      height: 3,
      width: width + 200 * BlocksConstructor.Scale,
      depth: depth + 200 * BlocksConstructor.Scale,
    }, this.scene)

    const slicerPivot = new TransformNode('pivot', this.scene)
    slicerPivot.parent = sideRootMesh

    slicer.parent = slicerPivot
    slicer.position.y += 3 / 2
    slicer.position.x = width / 2

    const angle = -Math.atan( height / (width + this._angleLedge * BlocksConstructor.Scale))
    slicerPivot.position.y = height
    slicerPivot.position.x = -width / 2
    slicerPivot.rotation.z = angle

    const sideMeshes = [frontMesh, backMesh, leftMesh, rightMesh]
    const slicerCsg = CSG.FromMesh(slicer)

    sideMeshes.forEach(meshItem => {

      const csgItem = CSG.FromMesh(meshItem)
      const csgResult = csgItem.subtract(slicerCsg)

      const subtractedMesh = csgResult.toMesh('side-mesh', this.roofMaterial, this.scene)
      subtractedMesh.parent = sideRootMesh

      meshItem.dispose()
    })

    slicer.dispose()

    sideRootMesh.position = this.center
    sideRootMesh.rotation.y = !this.vertical ? Math.PI / 2 : 0

    this.meshes.side = sideRootMesh
  }

  _createTopMesh() {
    const topRootMesh = new Mesh('Single roof top root mesh', this.scene)
    topRootMesh.parent = this.rootMesh

    const width = ((this.vertical ? this.width : this.height ) + 8) * BlocksConstructor.Scale
    const depth = ((this.vertical ? this.height : this.width) + 8) * BlocksConstructor.Scale
    const height = (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale

    const topWidth = Math.sqrt(Math.pow(width, 2) + Math.pow(height,2))
    const delta = topWidth - width

    let topMesh = MeshBuilder.CreateBox('Top mesh', {
      width: depth,
      depth: BlocksConstructor.Scale * 4,
      height: topWidth
    })

    topMesh = this.createEdges(topMesh, topWidth, depth)

    const pivot = new TransformNode('pivot', this.scene)
    pivot.parent = topRootMesh

    topMesh.material = this.roofMaterial

    topMesh.parent = pivot
    topMesh.position.x = 0
    topMesh.position.y = !this.vertical ? topWidth / 2 + 6 * BlocksConstructor.Scale : -topWidth / 2 - 6 * BlocksConstructor.Scale

    const angle = -Math.atan( height / (width + this._angleLedge * BlocksConstructor.Scale))
    pivot.rotation.x = !this.vertical ? angle - Math.PI / 2 : -Math.PI / 2 - angle
    pivot.position.y = height + 2 * BlocksConstructor.Scale
    pivot.position.z = !this.vertical ? topWidth / 2 : -topWidth / 2

    topRootMesh.position = this.center
    topRootMesh.rotation.y = this.vertical ? Math.PI / 2 : 0
  }

  dispose() {
    this.rootMesh.dispose()
  }
}

export default SingleRoofMesh
