<template lang="pug">
  .box-options
    .box-sizes
      .size-item
        .size-item-label Ширина
        .size-item-value 2,45 м
      .size-item
        .size-item-label Высота
        .size-item-value 2,45 м
      .size-item
        .size-item-label Длина
        .size-item-value {{this.json.length / 100}} м

    .box-color
      .select-label Цвет каркаса
      .select-wrapper
        kraus-equipment-select(v-model="carcassColorData.value", :values="carcassColorData.items")

    .info-item
      .info-item-icon
      .info-item-text Изменение цвета каркаса приминяется ко всему проекту

    .divider

    .label-item Внешняя отделка

    .box-walls
      .select-label Стены
      .select-wrapper
        kraus-equipment-select(v-model="wallMaterialData.value", :values="wallMaterialData.items")

    .box-coating
      .select-label Покрытие
      .select-wrapper
        kraus-equipment-select(v-model="colorData.value", :values="colorData.items")

    .divider

    .label-item Внутренняя отделка

    .box-walls
      .select-label Стены
      .select-wrapper
        kraus-equipment-select(v-model="insideWallMaterials.value", :values="insideWallMaterials.items")

    .info-item(v-if="!insideWallDifferentMaterial")
      .info-item-icon
      .info-item-text Изменение материала стен приминяется ко всему проекту

    .label-item Пол

    .box-walls
      .select-label Покрытие
      .select-wrapper
        kraus-equipment-select(v-model="floorCoatingData.value", :values="floorCoatingData.items")

    .info-item(v-if="!differentFloorCoating")
      .info-item-icon
      .info-item-text Изменение покрытия пола приминяется ко всему проекту
</template>
<script>
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';
import { mapActions, mapGetters } from 'vuex';
import EquipmentCategories from '@/common/EquipmentCategories';
import KrausSelect from '@/components/_common/ui/KrausSelect';
export default {
  name: 'BoxOptions',
  components: { KrausSelect, KrausEquipmentSelect },
  props: {
    item: null,
    json: null
  },

  data() {
    return {
      size: {
        width: 2.45,
        height: 2.75,
        length: 8
      },
      carcassColorData: {
        value: null,
        items: []
      },

      wallMaterialData: {
        value: null,
        items: []
      },

      colorData: {
        value: null,
        items: []
      },

      insideWallMaterials: {
        value: null,
        items: []
      },

      floorCoatingData: {
        value: null,
        items: []
      },
    }
  },

  created() {
    this.init()
  },

  watch: {
    json() {
      this.init()
    },

    'carcassColorData.value': {
      handler: function (val) {
        this.setCarcassColor(val)
      }
    },

    carcassColor(val) {
      this.carcassColorData.value = val
    },

    'wallMaterialData.value': {
      handler: function (val) {
        this.setWallMaterial(val)

        let colors
        val.raw.options.forEach(item => {
          if (item.key === 'cvet') colors = item.options
        })

        this.colorData.items = colors.map(item => {
          return {
            icon: {
              color: item.hex
            },
            title: item.title,
            raw: item
          }
        })
      }
    },

    wallMaterial(val) {
      this.wallMaterialData.value = val
    },

    'colorData.value': {
      handler: function (val) {
        this.setWallColor(val)
      }
    },

    wallColor(val) {
      this.colorData.value = val
    },

    'insideWallMaterials.value': {
      handler: function (material) {
        if (this.insideWallDifferentMaterial) {
          const dictionaryData = this.item.dictionaryData
          dictionaryData.wallMaterial = material
          this.item.setDictionaryData(dictionaryData)
        } else {
          this.setInsideWallMaterial(material)
        }
      }
    },

    'floorCoatingData.value': {
      handler: function (material) {
        if (this.differentFloorCoating) {
          const dictionaryData = this.item.dictionaryData
          dictionaryData.floorCoating = material
          this.item.setDictionaryData(dictionaryData)
        } else {
          this.setFloorCoating(material)
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'containerType',
      'carcassColor',
      'wallMaterial',
      'wallColor',
      'insideWallDifferentMaterial',
      'insideWallMaterial',
      'differentFloorCoating',
      'floorCoating'
    ])
  },

  methods: {
    ...mapActions([
      'setCarcassColor',
      'setWallMaterial',
      'setWallColor',
      'setInsideWallMaterial',
      'setFloorCoating'
    ]),

    init() {
      // Цвет каркаса
      this.carcassColorData.items = EquipmentCategories.getItems({
        categoryKey: 'karkas',
        optionKey: 'cveta_karkasa'
      }).options.map(item => {
        return {
          icon: {
            color: item.hex,
          },
          title: item.title,
          raw: item
        }
      })

      if (!this.carcassColor) this.setCarcassColor(this.carcassColorData.items[0])
      this.carcassColorData.value = this.carcassColor

      this.wallMaterialData.items = EquipmentCategories.getItems({
        categoryKey: 'vneshnyaya_otdelka',
        optionKey: 'steny'
      }).options.map((item) => {
        return {
          title: item.title,
          cost: item.cost,
          raw: item
        }
      })

      if (this.wallMaterial === null) this.setWallMaterial(this.wallMaterialData.items[0])
      this.wallMaterialData.value = this.wallMaterial

      let color
      this.wallMaterial.raw.options.forEach(item => {
        if (item.key === 'cvet') color = item.options[0]
      })

      if (this.wallColor === null) this.setWallColor({
        icon: {
          color: color.hex
        },
        title: color.title,
        raw: color
      })
      this.colorData.value = this.wallColor


      //Внутренняя отделка
      if (!this.containerType.improved) {
        this.insideWallMaterials.items = EquipmentCategories
          .getCategory('stena_vnutri')
          .options
          .map((item) => {
            return {
              title: item.title,
              raw: item
            }
          })
      } else {
        this.insideWallMaterials.items = EquipmentCategories
          .getCategory('stena_vnutri')
          .options[0].options
          .map((item) => {
            return {
              title: item.title,
              raw: item,
              icon: {
                color: item.hex
              }
            }
          })
      }

      if (this.insideWallDifferentMaterial) {
        if (this.item.dictionaryData.wallMaterial) {
          this.insideWallMaterials.value = this.item.dictionaryData.wallMaterial
        } else {
          this.insideWallMaterials.value = this.insideWallMaterial
        }
      } else {
        this.insideWallMaterials.value = this.insideWallMaterial
      }

      //Покрытие пола
      this.floorCoatingData.items = EquipmentCategories.getItems({
        categoryKey: 'chistovoy_pol',
        optionKey: 'linoleym_bitovoy'
      }).options.map((item) => {
        return {
          title: item.title,
          cost: item.price,
          raw: item
        }
      })

      if (this.differentFloorCoating) {
        if (this.item.dictionaryData.floorCoating) {
          this.floorCoatingData.value = this.item.dictionaryData.floorCoating
        } else {
          this.floorCoatingData.value = this.floorCoating
        }
      } else {
        this.floorCoatingData.value = this.floorCoating
      }
    }
  }
}
</script>
