class EditorDataUtil {

  static getConnectorById(connectors, connectorId) {
    if (connectorId.id) {
      return connectorId
    }

    const list = connectors.filter((connector) => connector.id === connectorId)

    return list.length > 0 ? list[0] : null
  }

  static getConnectedBoxes(box, stageData, connectedList = null, level = 0) {
    if (connectedList === null) connectedList = [box]

    const {tl, br} = EditorDataUtil.getEdgeConnectors(stageData, box)

    const newBoxes = []

    stageData.boxes.filter(item => item !== box && connectedList.indexOf(item) < 0).forEach(otherBox => {
      let isIntersect = false

      const otherBoxConnectors = otherBox.connectors.map(id => EditorDataUtil.getConnectorById(stageData.connectors, id))

      otherBoxConnectors.forEach((c) => {
        if (c.x >= tl.x && c.x <= br.x && c.y >= tl.y && c.y <= br.y) {
          isIntersect = true
        }
      })
      if (isIntersect) {
        newBoxes.push(otherBox)
      }
    })

    const addedBoxes = []
    newBoxes.forEach(box => {
      if (connectedList.indexOf(box) < 0) {
        connectedList.push(box)
        addedBoxes.push(box)
      }
    })

    addedBoxes.forEach(box => {
      const additionalList = EditorDataUtil.getConnectedBoxes(box, stageData, connectedList, level + 1)
      additionalList.forEach(boxItem => {
        if (connectedList.indexOf(boxItem) < 0) connectedList.push(boxItem)
      })
    })

    return connectedList
  }

  static getConnectedPacks(stageData) {
    const boxesPacks = []

    const isInSomePack = (box) => {
      let inPack = false
      boxesPacks.forEach(pack => {
        if (pack.indexOf(box) >= 0) inPack = true
      })

      return inPack
    }

    stageData.boxes.forEach(box => {
      if (!isInSomePack(box)) {
        const pack = EditorDataUtil.getConnectedBoxes(box, stageData)
        boxesPacks.push(pack)
      }
    })

    return boxesPacks
  }

  /**
   * @return {{tl: null, br: null}}
   */
  static getEdgeConnectors(stageData, box) {
    let tl = null
    let br = null

    const boxConnectors = box.connectors.map(id => EditorDataUtil.getConnectorById(stageData.connectors, id))

    boxConnectors.forEach(connector => {
      if (tl === null) {
        tl = connector
      } else {
        const vector = {
          x: connector.x - tl.x,
          y: connector.y - tl.y
        }

        if (vector.x < 0 && vector.y < 0) tl = connector
      }

      if (br === null) {
        br = connector
      } else {
        const vector = {
          x: connector.x - br.x,
          y: connector.y - br.y
        }

        if (vector.x > 0 && vector.y > 0) br = connector
      }
    })

    return {
      tl,
      br
    }
  }

  static roofAvailable(boxesList) {
    let available = false

    if (boxesList.length >= 4) {
      available = EditorDataUtil.getBoxesSquare(boxesList) === EditorDataUtil.getEdgeConnectorsSquare(boxesList)
    }

    return available
  }

  static getBoxesSquare(boxesList, additional = 0) {
    let totalSquare = 0
    boxesList.forEach(box => {
      totalSquare += (box.width + additional) * (box.height + additional)
    })

    return totalSquare
  }

  static getBoxesConnectors(boxesList) {
    const connectorsList = []

    boxesList.forEach(box => {
      box.connectors.forEach(connector => {

        const existsList = connectorsList.filter(item => item.id === connector.id)
        if (existsList.length === 0) {
          connectorsList.push(connector)
        }
      })
    })

    return connectorsList
  }

  static getEdgeConnectorsSquare(boxesList) {
    const {tl, br} = this.getBoxesEdgeConnectors(boxesList)
    const width = Math.abs(br.x - tl.x)
    const height = Math.abs(br.y - tl.y)

    return width * height
  }

  /**
   * @return {{tl: null, br: null}}
   */
  static getBoxesEdgeConnectors(boxesList) {
    let tl = null
    let br = null

    EditorDataUtil.getBoxesConnectors(boxesList).forEach(connector => {
      if (tl === null) {
        tl = connector
      } else {
        const vector = {
          x: connector.x - tl.x,
          y: connector.y - tl.y
        }

        if (vector.x <= 0 && vector.y <= 0) tl = connector
      }

      if (br === null) {
        br = connector
      } else {
        const vector = {
          x: connector.x - br.x,
          y: connector.y - br.y
        }

        if (vector.x >= 0 && vector.y >= 0) br = connector
      }
    })

    return {
      tl,
      br
    }
  }
}

export default EditorDataUtil
