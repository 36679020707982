<template lang="pug">
  .container-type
    .container-type_title
      span {{title}}
      tooltip-icon(:text="hint")
    .container-type_desription {{description}}
</template>
<script>
import { mapGetters } from 'vuex';
import TooltipIcon from '../../_common/ui/TooltipIcon';
import EquipmentCategories from '../../../common/EquipmentCategories';

export default {
  name: 'ContainerType',
  components: { TooltipIcon },
  computed: {
    ...mapGetters([
      'containerType'
    ]),

    title() {
      return this.containerType ? (this.containerType.improved ? 'Улучшенный' : 'Стандарт') : ''
    },

    hint() {
      return EquipmentCategories.getCategory('container').hint
    },

    description() {
      return this.containerType ? (this.containerType.improved ? 'Стены из сэндвич-панелей' : 'Стены с деревянной обрешёткой') : ''
    }
  }
}
</script>
