<template lang="pug">
  .selected-item-content
    vue-custom-scrollbar(class="selected-item-content-scrollbar")
      template(v-if="contentComponent")
        component(:is="contentComponent", v-bind="{item: item, json: json}")
</template>
<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import BoxOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/BoxOptions';
import BoilerOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/BoilerOptions';
import WashStandOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/WashStandOptions';
import WallOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/WallOptions';
import DoorOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/DoorOptions';
import WindowOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/WindowOptions';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
export default {
  name: 'SelectedItemContent',
  components: { vueCustomScrollbar },
  props: {
    item: null,
    json: null
  },
  data() {
    return {

    }
  },

  mounted() {
    window.addEventListener('resize', this.onWindowResize)
    this.onWindowResize()
  },

  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },

  computed: {
    contentComponent() {
      let component

      switch (this.item.elementType) {
        case BaseEditorElement.ELEMENT_TYPE_BOX:
            return BoxOptions
          break

        case BaseEditorElement.ELEMENT_TYPE_WALL_ITEM:

          switch (this.item.type) {
            case WallItem.Types.Window:
              component = WindowOptions
              break

            case WallItem.Types.Door:
              component = DoorOptions
              break

            case WallItem.Types.Additional:
              component = null
              break
          }

          return  component

          break

        case BaseEditorElement.ELEMENT_TYPE_BOX_ITEM:
        case BaseEditorElement.ELEMENT_TYPE_EXTERNAL_ITEM:
          const washStands = [BoxItem.Types.WashStandBig, BoxItem.Types.WashStandSmall]

          switch (true) {
            case washStands.indexOf(this.item.dictionaryData.editorType) >= 0:
              component = WashStandOptions
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.Boiler:
              component = BoilerOptions
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.ToiletBowl:
              component = null
              break
          }

          return component

          break

        case BaseEditorElement.ELEMENT_TYPE_WALL:
          return WallOptions
          break

        return null
      }
    }
  },

  methods: {
    onWindowResize() {
      const header = document.querySelector('.selected-item-header')
      const footer = document.querySelector('.selected-item-footer')
      const siteHeader = document.querySelector('.site-header')
      const scroll = document.querySelector('.selected-item-content-scrollbar')

      if (header && footer) {
        let elementsHeight = header.getBoundingClientRect().height +
          footer.getBoundingClientRect().height +
          siteHeader.getBoundingClientRect().height

        scroll.style.height = (window.innerHeight - elementsHeight) + 'px'
      }
    }
  }
}
</script>
