<template lang="pug">
  .step.edit-modes
    .step-description Перейдите в 2D режим для удобного редактирования проекта. В режиме 3D хорошо виден весь проект.
    .step-image(:style="{backgroundImage: 'url(' + this.image + ')'}")
</template>
<script>
export default {
  name: 'EditModes',
  data() {
    return {
      image: require('@/assets/images/first-steps/step-1-image.svg')
    }
  }
}
</script>
