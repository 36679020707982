const leftMenuModule = {
  state: () => ({
    selectedSection: null
  }),

  mutations: {
    selectSection(state, section) {
      state.selectedSection = section
    }
  },
  actions: {
    selectSection({commit}, section) {
      commit('selectSection', section)
    }
  },
  getters: {
    selectedSection: state => state.selectedSection
  }
}

export default leftMenuModule
