<template lang="pug">
  .content.content-plumbing
    .plumbing-list(v-if="items.length > 0")
      .plumbing-item(v-for="(item, index) in items", :key="index", @click="onPlumbingItemClick(item)")
        .plumbing-item-icon(:style="{backgroundImage: 'url(' + item.icon + ')'}")
        .plumbing-item-content
          .plumbing-item-title {{item.title}}
          .plumbing-item-info
            .plumbing-item-info-options {{item.options}}
            .plumbing-item-info-cost {{item.cost| currency }}
    template(v-else)
      .content-header
        .content-option-name Нет добавленных элементов

</template>
<script>
import { mapGetters } from 'vuex';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';

export default {
  name: 'Plumbing',
  data() {
    return {
      items: [
        // {
        //   icon: '/pics/right-menu/window.svg',
        //   title: 'Окошечко',
        //   options: '100x100, поворотное',
        //   cost: '10000'
        // },
      ]
    }
  },

  created() {
    this.updateItems()
  },

  watch: {
    stagesData: {
      deep: true,
      handler: function () {
        this.updateItems()
      }
    }
  },

  computed: {
    ...mapGetters([
      'stagesData'
    ])
  },

  methods: {
    updateItems() {
      const types = [
        BoxItem.Types.WashStandSmall,
        BoxItem.Types.WashStandBig,
        BoxItem.Types.Shower,
        BoxItem.Types.ToiletBowl,
        BoxItem.Types.Boiler,
        BoxItem.Types.Conditioner
      ]

      this.items = []

      for (const stage in this.stagesData) {
        const stageData = this.stagesData[stage]
        if (stageData) {
          stageData.boxItems.forEach(item => {
            if (types.indexOf(item.type) >= 0) {
              let cost = item.dictionaryData.option ? item.dictionaryData.option.raw.price : item.dictionaryData.data.raw.price
              this.items.push({
                id: item.id,
                icon: item.dictionaryData.menuIcon,
                title: item.dictionaryData.data.raw.title,
                options: item.dictionaryData.option ? item.dictionaryData.option.title : 'Стандарт',
                cost,
                stage: parseInt(stage)
              })

              // if (item.type === BoxItem.Types.Boiler) console.log(item)
            }
          })
        }
      }
    },

    onPlumbingItemClick(item) {
      eventBus.$emit(EventBusEvents.SelectItemInEditor, {
        type: BaseEditorElement.ELEMENT_TYPE_BOX_ITEM,
        id: item.id,
        stage: item.stage
      })
    }
  }
}
</script>
