<template lang="pug">
  .left-menu-item(:class="cssClass", @click="onClick")
    .icon(:style="itemStyle")
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MenuItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },

  mounted() {
  },

  computed: {
    ...mapGetters([
      'selectedSection',
      'editorType'
    ]),

    cssClass() {
      const classObj = {
        selected: this.isSelected,
        disabled: this.disabled
      }

      classObj[this.item.id] = true

      return classObj
    },

    disabled() {
      return this.editorType === '2D' ? false : this.item.id !== 'decoration'
    },

    isSelected() {
      return this.selectedSection && this.selectedSection.id === this.item.id
    },

    itemStyle() {
      return {
        backgroundImage: 'url(' + require('@/assets/images/left-menu/icon-' + this.item.id + '.svg') + ')'
      }
    }
  },

  methods: {
    ...mapActions([
      'selectSection'
    ]),

    onClick() {
      if (!this.disabled) this.selectSection(this.isSelected ? null : this.item)
    }
  }
}
</script>
