import Wall from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/WallsConstructor/Wall';

class WallsConstructor {
  _scene
  _data
  _stage
  _carcass

  _walls = []

  _wallsOptions

  /**
   * @returns {Wall[]}
   */
  get walls () {
    return this._walls
  }

  constructor({scene, data, stage, carcass, walls}) {
    this._scene = scene
    this._data = data
    this._stage = stage
    this._carcass = carcass
    this._wallsOptions = walls

    this._createWalls()
  }

  setWallsTransparency(transparency) {
    this._walls.forEach(wall => wall.setTransparency(transparency))
  }

  updateTransparency() {
    this._walls.forEach(wall => wall.updateTransparency())
    // console.log('--------')
  }

  _createWalls() {
    if (this._data) {
      this._data.tempWalls.forEach((wallData) => {
        if (!wallData.tempDashed) {

          this._walls.push(new Wall({
            data: this._data,
            scene: this._scene,
            stage: this._stage,
            wallData: wallData,
            carcass: this._carcass,
            wallsOptions: this._wallsOptions
          }))
        }
      })

      this._data.insideWalls.forEach((wallData) => {
        wallData.insideBox = true
        this._walls.push(new Wall({
          data: this._data,
          scene: this._scene,
          stage: this._stage,
          wallData: wallData,
          carcass: this._carcass,
          wallsOptions: this._wallsOptions
        }))
      })
    }
  }

  setCarcass(carcass) {
    this._carcass = carcass
    this._walls.forEach(wall => {
      wall.setCarcass(carcass)
    })
  }

  setWallOptions(options) {
    this._wallsOptions = options
    this._walls.forEach(wall => {
      wall.setWallOptions(options)
    })
  }

  _getTempWalls(wallData) {
    const tempWalls = []

    this._data.walls
      .filter(data => data.temp)
      .forEach((tempWallData) => {
      const intersectWall = tempWallData.intersectedWalls.filter(itemData => itemData.id === wallData.id).length > 0
      if (intersectWall) tempWalls.push(tempWallData)
    })

    return tempWalls
  }

  dispose() {

  }
}

export default WallsConstructor
