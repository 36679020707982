<template lang="pug">
  .content.content-external-finishing
    .content-header
      .content-option-name Стены
      .content-option-cost
    .content-actions
      kraus-equipment-select(:values="wallMaterialData.items", v-model="wallMaterialData.value")

    .content-header
      .content-option-name Покрытие
      .content-option-cost
    .content-actions
      kraus-equipment-select(:values="colorData.items", v-model="colorData.value")
</template>
<script>
import KrausSelect from '@/components/_common/ui/KrausSelect';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';

export default {
  name: 'ExternalFinishing',
  components: {
    KrausEquipmentSelect,
    KrausSelect
  },
  data() {
    return {
      wallMaterialData: {
        value: null,
        items: []
      },
      colorData: {
        value: null,
        items: []
      }
    }
  },

  created() {
    this.init()
  },

  watch: {
    'wallMaterialData.value': {
      handler: function (val) {
        this.setWallMaterial(val)

        let colors
        val.raw.options.forEach(item => {
          if (item.key === 'cvet') colors = item.options
        })

        this.colorData.items = colors.map(item => {
          return {
            icon: {
              color: item.hex
            },
            title: item.title,
            raw: item
          }
        })

        let color
        this.wallMaterial.raw.options.forEach(item => {
          if (item.key === 'cvet') color = item.options[0]
        })

        this.setWallColor({
          icon: {
            color: color.hex
          },
          title: color.title,
          raw: color
        })
      }
    },

    wallMaterial(val) {
      this.wallMaterialData.value = val
    },

    'colorData.value': {
      handler: function (val) {
        this.setWallColor(val)
      }
    },

    wallColor(val) {
      this.colorData.value = val
    }
  },

  computed: {
    ...mapGetters([
      'wallMaterial',
      'wallColor',
      'containerType'
    ])
  },

  methods: {
    ...mapActions([
      'setWallMaterial',
      'setWallColor',
    ]),

    init() {
      this.wallMaterialData.items = EquipmentCategories.getItems({
        categoryKey: 'vneshnyaya_otdelka',
        optionKey: 'steny'
      }).options.map((item) => {
        return {
          title: item.title,
          cost: item.cost,
          raw: item
        }
      })

      if (this.wallMaterial === null) {
        if (this.containerType.improved) {
          this.setWallMaterial(this.wallMaterialData.items.filter(item => item.raw.key === 'metal_045')[0])
        } else {
          this.setWallMaterial(this.wallMaterialData.items.filter(item => item.raw.key === 'prooflist_s8')[0])
        }
      }

      this.wallMaterialData.value = this.wallMaterial

      let color
      this.wallMaterial.raw.options.forEach(item => {
        if (item.key === 'cvet') color = item.options[0]
      })

      if (this.wallColor === null) this.setWallColor({
        icon: {
          color: color.hex
        },
        title: color.title,
        raw: color
      })

      this.colorData.value = this.wallColor
    }
  }
}
</script>
