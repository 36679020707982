<template lang="pug">
  #app
    template(v-if="applicationReady")
      router-view(v-if="!isMobile")
      popup
      kraus-tooltip(v-show="tooltipsVisible")
      kraus-small-tooltip
      download-scheme
      //first-steps(@close="onFirstStepsClosed", v-if="false")
      kraus-equipment-select-options
      yandex-map
    #cursor
    .creating-images-proccess(v-show="creatingStageImages")

</template>
<script>
import Popup from '@/components/_common/ui/Popup';
import { eventBus } from '@/main';
import PopupsNames from '@/components/_common/ui/Popup/popupNames';
import EventBusEvents from '@/common/EventBusEvents';
import FirstSteps from '@/components/FirstSteps';
import DownloadScheme from '@/components/DownloadScheme';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
import CostCalculator from '@/common/CostCalculator';
import api from '@/common/http/api';
import CursorController from '@/common/CursorController';
import KrausTooltip from '@/components/_common/ui/Tooltip';
import KrausSmallTooltip from '@/components/_common/ui/KrausSmallTooltip';
import KrausEquipmentSelectOptions from '@/components/_common/ui/KrausEquipmentSelectOptions';
import YandexMap from '@/components/Ordering/YandexMap';
import { isMobile } from 'mobile-device-detect';
import HintsData from '@/common/HintsData';

export default {
  components: { YandexMap, KrausEquipmentSelectOptions, KrausSmallTooltip, KrausTooltip, DownloadScheme, FirstSteps, Popup },
  data() {
    return {
      applicationReady: false,
      calculateTimeout: null,
      json: null,
      isMobile: isMobile,
      creatingStageImages: false
    }
  },

  created() {
    this.setProduction(['kraus-blocks.ru', 'www.kraus-blocks.ru'].indexOf(window.location.host) >= 0)
    eventBus.$on(EventBusEvents.StageImagesReady, this.onStagesImagesReady)
    eventBus.$on(EventBusEvents.CreateStageImages, this.onCreateStageImages)
  },

  mounted() {
    const promiseList = [
      EquipmentCategories.loadData(),
    ]

    Promise.all(promiseList).then(() => {
      HintsData.setData({
        obreshotka: EquipmentCategories.rawData.obreshotka.filter(item => item.key === 'hints')[0].options,
        sandwich: EquipmentCategories.rawData.sandwich.filter(item => item.key === 'hints')[0].options,
      })
      this.applicationReady = true
      this.$nextTick(() => {
        if (isMobile) {
          eventBus.$emit(EventBusEvents.ShowPopup, PopupsNames.MobileDevice)
        } else {
          eventBus.$emit(EventBusEvents.ShowPopup, PopupsNames.SelectContainerType)
        }
      })
    })

    eventBus.$on(EventBusEvents.ClearProject, this.onClearProject)

    this.cursorController = new CursorController()
  },

  destroyed() {
    this.cursorController.dispose()
  },

  watch: {
    restored(val) {
      if (val) {
        this.setRestored(false)
      }
    },

    // imagesList() {
    //   if (this.pdfRequested) {
    //     if (this.imagesTimeout) {
    //       clearTimeout(this.imagesTimeout)
    //     }
    //
    //     this.imagesTimeout = setTimeout(() => {
    //
    //     }, 100)
    //   }
    // },

    calculateCostParameters: {
      deep: true,
      handler: function (params) {
        if (this.containerType) {
          if (this.calculateTimeout) clearTimeout(this.calculateTimeout)

          this.calculateTimeout = setTimeout(() => {
            const calculation = new CostCalculator(params)

            this.setTotalCost(calculation.total)
            this.setContainersCost(calculation.containers)
            this.setCarcassCost(calculation.carcass)
            this.setExternalFinishingCost(calculation.externalFinishing)
            this.setInternalFinishingCost(calculation.internalFinishing)
            this.setRoofCost(calculation.roof)
            this.setWindowsCost(calculation.windows)
            this.setDoorsCost(calculation.doors)
            this.setInsideWallsCost(calculation.insideWalls)
            this.setFloorCost(calculation.floor)
            this.setPlumbingCost(calculation.plumbing)
            this.setWarmingCost(calculation.warming)
            this.setWaterProofCost(calculation.waterProof)
            this.setElectricCost(calculation.electric)
            this.setAdditionalCost(calculation.additional)
            this.setMountingCost(calculation.mounting)

            this.json = params
            this.saveProject()

          }, 500)
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'screenshotsType',
      'images3d',

      'production',
      'boxesCount',
      'pdfRequested',
      'restored',
      'requestId',
      'projectId',
      'stagesData',
      'containerType',
      'cornerPosts',
      'carcassColor',
      'carcassStrengthening',
      'wallMaterial',
      'wallColor',
      'insideWallMaterial',
      'insideWallDifferentMaterial',
      'partitionWallMaterial',
      'roofType',
      'roofColor',
      'roofMaterial',
      'roofMetalTrusses',
      'additionalFloor',
      'additionalLags',
      'metalLags',
      'metalLagsCount',
      'mainFloor',
      'bottomFloor',
      'additionalMainFloor',
      'floorCoating',
      'differentFloorCoating',
      'warming',
      'waterproofing',
      'electricPacket',
      'electricSockets',
      'electricLights',
      'mounting',

      'totalCost',
      'containersCost',
      'carcassCost',
      'externalFinishingCost',
      'internalFinishingCost',
      'roofCost',
      'windowsCost',
      'doorsCost',
      'insideWallsCost',
      'floorCost',
      'plumbingCost',
      'warmingCost',
      'waterProofCost',
      'electricCost',
      'additionalCost',
      'mountingCost',

      'orderingStageImages',

      'square'
    ]),


    imagesList() {
      const imagesList = []

      for (let key in this.orderingStageImages) {
        if (this.orderingStageImages[key]) {
          imagesList.push({
            stage: key,
            data: this.orderingStageImages[key].dataURL
          })
        }
      }

      return imagesList
    },

    calculateCostParameters() {
      return {
        boxesCount: this.boxesCount,
        fullJson: this.stagesData,
        dictionary: EquipmentCategories.rawData,
        equipment: {
          containerType: this.containerType,
          cornerPosts: this.cornerPosts,
          carcassColor: this.carcassColor,
          carcassStrengthening: this.carcassStrengthening,
          wallMaterial: this.wallMaterial,
          wallColor: this.wallColor,
          insideWallMaterial: this.insideWallMaterial,
          insideWallDifferentMaterial: this.insideWallDifferentMaterial,
          partitionWallMaterial: this.partitionWallMaterial,

          roofType: this.roofType,
          roofColor: this.roofColor,
          roofMaterial: this.roofMaterial,
          roofMetalTrusses: this.roofMetalTrusses,

          additionalFloor: this.additionalFloor,
          additionalLags: this.additionalLags,
          metalLags: this.metalLags,
          metalLagsCount: this.metalLagsCount,
          mainFloor: this.mainFloor,
          bottomFloor: this.bottomFloor,
          additionalMainFloor: this.additionalMainFloor,
          floorCoating: this.floorCoating,
          differentFloorCoating: this.differentFloorCoating,

          warming: this.warming,

          waterproofing: this.waterproofing,

          electricPacket: this.electricPacket,
          electricSockets: this.electricSockets,
          electricLights: this.electricLights,

          mounting: this.mounting
        }
      }
    },

    costs() {
      return {
        totalCost: this.totalCost,
        containersCost: this.containersCost,
        carcassCost: this.carcassCost,
        externalFinishingCost: this.externalFinishingCost,
        internalFinishingCost: this.internalFinishingCost,
        roofCost: this.roofCost,
        windowsCost: this.windowsCost,
        doorsCost: this.doorsCost,
        insideWallsCost: this.insideWallsCost,
        floorCost: this.floorCost,
        plumbingCost: this.plumbingCost,
        warmingCost: this.warmingCost,
        waterProofCost: this.waterProofCost,
        electricCost: this.electricCost,
        additionalCost: this.additionalCost,
        mountingCost: this.mountingCost
      }
    },

    tooltipsVisible() {
      return this.$route.fullPath === '/'
    }
  },

  methods: {
    ...mapActions([
      'setScreenshotsType',
      'setProduction',

      'setRestored',
      'setProjectId',
      'setTotalCost',
      'setContainersCost',
      'setCarcassCost',
      'setExternalFinishingCost',
      'setInternalFinishingCost',
      'setRoofCost',
      'setWindowsCost',
      'setDoorsCost',
      'setInsideWallsCost',
      'setFloorCost',
      'setPlumbingCost',
      'setWarmingCost',
      'setWaterProofCost',
      'setElectricCost',
      'setAdditionalCost',
      'setMountingCost',
      'setRequestId',
      'setContainerType',
    ]),

    onClearProject() {
      this.setContainerType(null)
      EquipmentCategories.setContainerType(null)
      this.$nextTick(() => {
        eventBus.$emit(EventBusEvents.ShowPopup, PopupsNames.SelectContainerType)
      })
    },

    onCreateStageImages() {
      this.creatingStageImages = true
    },

    onStagesImagesReady() {
      if (this.screenshotsType === 'for-pdf') {
        this.saveProject(true).then(() => {
          if (this.production) {
            setTimeout(() => {
              window.location = '/project/pdf/' + this.projectId
            })
          } else {
            const a = document.createElement('a')
            a.href = this.production ? '/project/pdf/' + this.projectId : '//dev.kraus-blocks.ru/project/pdf/' + this.projectId
            a.target = '_self'
            a.click()
            setTimeout(() => {
              a.remove()
            })
          }

          this.creatingStageImages = false
        })
      } else if (this.screenshotsType === 'for-ordering') {
        this.$router.push('/ordering')
        this.creatingStageImages = false
      }

      this.setScreenshotsType(null)
    },

    onFirstStepsClosed() {
    },

    saveProject(forPdf = false) {
      this.json.costs = this.costs
      this.json.stagesImages = forPdf ? this.imagesList : []
      this.json.images3d = forPdf ? this.images3d.map(item => item.dataURL) : []
      this.json.info = {
        square: this.square
      }

      return api.post('project/save', {
        id: this.projectId,
        pid: this.requestId,
        data: this.json
      }, {
        headers: {
          'Content-Encoding': 'deflate, gzip'
        }
      }).then(response => {
        this.setProjectId(response.data._id)
        this.setRequestId(response.data.pid)
      })
    }
  }
}
</script>
