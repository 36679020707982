<template lang="pug">
  .selected-item-header
    .selected-item-icon(:style="{backgroundImage: 'url('+ icon +')'}")
    .selected-item-info
      .selected-item-title {{title}}
      .selected-item-cost {{cost| currency }}
    .selected-item-close-button(@click="onCloseClick")
</template>
<script>
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import WindowOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/WindowOptions';
import DoorOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/DoorOptions';
import BoilerOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/BoilerOptions';
import WallOptions from '@/components/BlocksEditor/SelectedItemOptions/SelectedItemContent/WallOptions';
import ContainerUtil from '@/utils/ContainerUtil';
import { mapGetters } from 'vuex';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';

export default {
  name: 'SelectedItemHeader',

  props: {
    item: null,
    json: null
  },

  data() {
    return {
      cost: 0
    }
  },

  created() {
    this.update()
  },

  watch: {
    stagesData: {
      deep: true,
      handler: function () {
        this.update()
      }
    },
    item() {
      this.update()
    },
    insideWallsCost: {
      deep: true,
      handler: function() {
        this.update()
      }
    },
    doorsCost: {
      deep: true,
      handler: function() {
        this.update()
      }
    },
    windowsCost: {
      deep: true,
      handler: function() {
        this.update()
      }
    }
  },

  computed: {
    ...mapGetters([
      'containerType',
      'stagesData',
      'insideWallsCost',
      'doorsCost',
      'windowsCost'
    ]),

    title() {
      switch (this.item.elementType) {
        case BaseEditorElement.ELEMENT_TYPE_BOX:
          return 'Контейнер ' + this.item.id
          break

        case BaseEditorElement.ELEMENT_TYPE_WALL_ITEM:
          switch(this.item.type) {
            case WallItem.Types.Window:
              title = 'Окно'
              break
            case WallItem.Types.Door:
              title = 'Дверь'
              break
            case WallItem.Types.Additional:
              title = 'Вытяжка'
              break
          }

          return title
          break

        case BaseEditorElement.ELEMENT_TYPE_BOX_ITEM:
        case BaseEditorElement.ELEMENT_TYPE_EXTERNAL_ITEM:
          const washStands = [BoxItem.Types.WashStandBig, BoxItem.Types.WashStandSmall]
          let title

          switch (true) {
            case washStands.indexOf(this.item.dictionaryData.editorType) >= 0:
              title = 'Раковина'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.Boiler:
              title = 'Бойлер'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.ToiletBowl:
              title = 'Туалет'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.Shower:
              title = 'Душ'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.Conditioner:
              title = 'Сушилка для рук'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.ElectricHeating:
              title = 'Обогреватель'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.Stairs:
              title = 'Лестница'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.Ventilation:
              title = 'Вытяжка'
              break
            case this.item.dictionaryData.editorType === BoxItem.Types.WaterHeating:
              title = 'Батарея'
              break
          }
          return title + ' ' + this.item.id
          break

        case BaseEditorElement.ELEMENT_TYPE_WALL:
          return 'Перегородка'
          break
      }
    },

    icon() {
      switch (this.item.elementType) {
        case BaseEditorElement.ELEMENT_TYPE_BOX:
          return require('@/assets/images/editor/selected-item-options/icon-box.svg')
          break

        case BaseEditorElement.ELEMENT_TYPE_WALL:
          return require('@/assets/images/editor/selected-item-options/icon-wall.svg')
          break

        default:
          return this.json.dictionaryData.menuIcon
          break
      }
    }
  },

  methods: {
    onCloseClick() {
      CreateJsBlocksEditor.Instance.setSelectedElement(null)
    },

    update() {
      this.cost = 0

      switch (this.item.elementType) {
        case BaseEditorElement.ELEMENT_TYPE_BOX:
          // if (ContainerUtil.isStandard(this.json, this.containerType.improved)) {
          //   this.cost = this.containerType.raw.price
          // } else {
          //   this.cost = 0
          //   this.containerType.options.forEach(sizeItem => {
          //     if (this.json.length >= sizeItem.min && this.json.length <= sizeItem.max) {
          //       this.cost = sizeItem.cost
          //     }
          //   })
          // }

          this.cost = 0
          this.containerType.options.forEach(sizeItem => {
            if (this.json.length >= sizeItem.min && this.json.length <= sizeItem.max) {
              this.cost = sizeItem.price
            }
          })

          break

        case BaseEditorElement.ELEMENT_TYPE_WALL_ITEM:
          if (this.json.dictionaryData.size) {
            this.cost = this.json.dictionaryData.size.raw ? this.json.dictionaryData.size.raw.price : this.json.dictionaryData.data.raw.price
          } else {
            this.cost = this.json.dictionaryData.data.raw.price
          }

          switch(this.item.type) {
            case WallItem.Types.Window:
              const windowCostItems = this.windowsCost.items.filter(item => item.id === this.item.id)
              this.cost = windowCostItems.length > 0 ? windowCostItems[0].price : 0
              break
            case WallItem.Types.Door:
              const doorCostItems = this.doorsCost.items.filter(item => item.id === this.item.id)
              this.cost = doorCostItems.length > 0 ? doorCostItems[0].price : 0
              break
          }

          break

        case BaseEditorElement.ELEMENT_TYPE_BOX_ITEM:
        case BaseEditorElement.ELEMENT_TYPE_EXTERNAL_ITEM:
          if (this.item.dictionaryData.editorType === BoxItem.Types.Boiler) {
            this.cost = this.item.dictionaryData.option.raw.price
          } else {
            this.cost = this.item.dictionaryData.data.raw.price
          }
          break

        case BaseEditorElement.ELEMENT_TYPE_WALL:
          if (this.item.insideBoxWall) {
            const costItems = this.insideWallsCost.items.filter(item => item.id === this.item.id)
            this.cost = costItems.length > 0 ? costItems[0].price : 0
          } else {
            this.cost = 0
          }
          break
      }
    }
  }
}
</script>
