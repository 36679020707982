import { detect } from 'detect-browser';

const constants = {
  browser: detect(),
  regEx: {
    email: /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
    phone: /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/m
  },
  modelsList: ['/models/additional/vytyazhka.glb',
    '/models/box-items/batareya_otopleniya.glb',
    '/models/box-items/lestnica_vnutrennyaya.glb',
    '/models/box-items/sushilka_dlya_ruk.glb',
    '/models/box-items/boyler.glb',
    '/models/box-items/obogrevatel_elektricheskiy.glb',
    '/models/box-items/tualet.glb',
    '/models/box-items/dush.glb',
    '/models/box-items/podvesnaya_40.glb',
    '/models/box-items/tulpan_60.glb',

    '/models/door/external/dver_metall_100x200.glb',
    '/models/door/external/dver_orgalit_80x200.glb',
    '/models/door/external/dver_pvh_steklo_steklo_80x200.glb',
    '/models/door/external/dver_metall_120x200.glb',
    '/models/door/external/dver_orgalit_90x200.glb',
    '/models/door/external/luk_40x40.glb',
    '/models/door/external/dver_metall_80x200.glb',
    '/models/door/external/dver_pvh_sandwish_sandwish_80x200.glb',
    '/models/door/external/rolstavni_205x205.glb',
    '/models/door/external/dver_metall_90x200.glb',
    '/models/door/external/dver_pvh_sandwish_steklo_80x200.glb',
    '/models/door/external/vorota_205x205.glb',
    '/models/door/external/dver_orgalit_100x200.glb',
    '/models/door/external/dver_pvh_steklo_sandwish_80x200.glb',

    '/models/door/internal/dver_filenchataya_100x200.glb',
    '/models/door/internal/dver_iz_orgalita_80x200.glb',
    '/models/door/internal/dver_filenchataya_80x200.glb',
    '/models/door/internal/dver_iz_orgalita_90x200.glb',
    '/models/door/internal/dver_filenchataya_90x200.glb',
    '/models/door/internal/dver_iz_pvh_sandwish_sandwish_80x200.glb',
    '/models/door/internal/dver_iz_mdf_100x200.glb',
    '/models/door/internal/dver_iz_pvh_sandwish_steklo_80x200.glb',
    '/models/door/internal/dver_iz_mdf_80x200.glb',
    '/models/door/internal/dver_iz_pvh_steklo_sandwish_80x200.glb',
    '/models/door/internal/dver_iz_mdf_90x200.glb',
    '/models/door/internal/dver_iz_pvh_steklo_steklo_80x200.glb',
    '/models/door/internal/dver_iz_orgalita_100x200.glb',

    '/models/external-items/lestnica_vneshnyaya_ulichnaya.glb',
    '/models/external-items/lestnica_vneshnyaya_ulichnaya_mirrored.glb',

    '/models/window/plastic/100h100_gluhoe.glb',
    '/models/window/plastic/100h100_povorotno_otkidnoe.glb',
    '/models/window/plastic/120h100_gluhoe.glb',
    '/models/window/plastic/120h100_obe_poloviny_povorotno_otkidnye.glb',
    '/models/window/plastic/120h100_s_fortochkoy_50h50_v_nizhnem_pravom_uglu.glb',
    '/models/window/plastic/120h100_s_okoshkom_fortochkoy_50h50_v_nizhney_chasti.glb',
    '/models/window/plastic/150h100_gluhoe.glb',
    '/models/window/plastic/150h100_obe_poloviny_povorotno_otkidnye.glb',
    '/models/window/plastic/150h100_s_dvumya_fortochkami_50h50_po_krayam_nizhney_chasti.glb',
    '/models/window/plastic/150h100_s_fortochkoy_50h50_v_nizhney_chasti_seredina_nizhney_chasti_.glb',
    '/models/window/plastic/150h100_s_odnoy_povorotno_otkidnoy_polovinoy.glb',
    '/models/window/plastic/180h100_gluhoe.glb',
    '/models/window/plastic/180h100_s_1_iz_3_povorotno_otkidnoy_centralnoy_chastyu.glb',
    '/models/window/plastic/180h100_s_2_iz_3_povorotno_otkidnymi_levoy_i_pravoy_chastyami.glb',
    '/models/window/plastic/180h100_s_dvumya_fortochkami_50h50_po_krayam_nizhney_chasti.glb',
    '/models/window/plastic/180h100_s_fortochkoy_50h50_v_nizhney_chasti_v_centre_nizhney_chasti_.glb',
    '/models/window/plastic/200h100_dvukamernoe_s_fortochkoy_v_seredine_nizhney_chasti.glb',
    '/models/window/plastic/50h100_gluhoe.glb',
    '/models/window/plastic/50h100_povorotnoe.glb',
    '/models/window/plastic/50h50_povorotnoe.glb',
    '/models/window/plastic/75h85_povorotnoe.glb',
    '/models/window/plastic/80h100_gluhoe.glb',
    '/models/window/plastic/80h100_povorotno_otkidnoe.glb',

    '/models/window/wood/60h40.glb',
    '/models/window/wood/75h85.glb']
}

export default constants
