<template lang="pug">
  .container-type-item(@click="onClick")
    .container-type-item-icon(:style="{backgroundImage: 'url(' + data.icon+ ')'}")
    .container-type-item-info
      .container-type-item-info-title {{data.title}}
      .container-type-item-info-size {{data.size}}
      .container-type-item-info-description {{data.description}}
      .container-type-item-info-space
      .container-type-item-info-cost {{data.cost| currency_wo_plus }}
</template>
<script>
import { mapActions } from 'vuex';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import EquipmentCategories from '@/common/EquipmentCategories';

export default {
  name: "ContainerTypeItem",
  props: {
    data: null
  },
  data() {
    return {
    }
  },

  methods: {
    ...mapActions([
      'setContainerType'
    ]),

    onClick() {
      this.setContainerType(this.data)
      EquipmentCategories.setContainerType(this.data.key)
      eventBus.$emit(EventBusEvents.HidePopup,'')
    }
  }
}
</script>
