<template lang="pug">
  .kraus-switcher-small.no-select(ref="switcher")
    .values-wrapper(:class="{disabled: !this.selectedValue}", @click="onItemClick()")
      .value-item(v-for="(item, index) in items", :key="index",)
    .highlighter(:style="highlighterStyle", :class="{disabled: !this.selectedValue}", @click="onItemClick()")
</template>
<script>
export default {
  name: 'KrausSwitcherSmall',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default () {
        return [true, false]
      }
    }
  },

  data() {
    return {
      selectedValue: null,
      elements: []
    }
  },

  created() {
    this.selectedValue = this.value
  },

  mounted() {
    this.elements = Array.from(this.$refs.switcher.querySelectorAll('.value-item'))
  },

  computed: {
    highlighterStyle () {
      let left = 0
      let width = 0

      left = this.selectedValue ? 16 : 0

      return {
        left: left + 'px',
      }
    }
  },

  watch: {
    value() {
      this.selectedValue = this.value
    }
  },

  methods: {
    isItemEnabled(item) {
      return item === this.selectedValue
    },

    onItemClick() {
      this.selectedValue = !this.selectedValue
      this.$emit('input', this.selectedValue)
    }
  }
}
</script>
