<template lang="pug">
  .content.content-waterproofing
    template(v-if="!containerType.improved")
      .included-in-price
        .included-in-price-info
          .included-in-price-info-text
            span Плёнка ПВХ 40мкр
            tooltip-icon(:hint="{section: 'waterproofing', key: 'pvc-film-40'}")
          .included-in-price-info-cost входит в стоимость
        .included-in-price-checkbox

      .small-switcher
        .small-switcher-info
          .text
            span Гидропроф внешний, А
            tooltip-icon(:hint="{section: 'waterproofing', key: 'hydroprof-external-A'}")
          .cost(v-if="waterproofingData.external") {{waterProofCost.external| currency }}
        .small-switcher-switcher
          kraus-switcher-small(v-model="waterproofingData.external")

      .small-switcher
        .small-switcher-info
          .text
            span Гидропроф внутренний, Б
            tooltip-icon(:hint="{section: 'waterproofing', key: 'hydroprof-internal-B'}")
          .cost(v-if="waterproofingData.internal") {{waterProofCost.internal| currency }}
        .small-switcher-switcher
          kraus-switcher-small(v-model="waterproofingData.internal")

    template(v-else)
      .included-in-price
        .included-in-price-info
          .included-in-price-info-text
            span Гидропроф внутренний, Б
            tooltip-icon(:hint="{section: 'waterproofing', key: 'hydroprof-internal-B'}")
          .included-in-price-info-cost входит в стоимость
        .included-in-price-checkbox

      .small-switcher
        .small-switcher-info
          .text
            span Гидропроф внешний, А
            tooltip-icon(:hint="{section: 'waterproofing', key: 'hydroprof-external-A'}")
          .cost(v-if="waterproofingData.external") {{waterProofCost.external| currency }}
        .small-switcher-switcher
          kraus-switcher-small(v-model="waterproofingData.external")
</template>
<script>
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import { mapActions, mapGetters } from 'vuex';
import TooltipIcon from '../../../../_common/ui/TooltipIcon';
export default {
  name: 'Waterproofing',
  components: { TooltipIcon, KrausSwitcherSmall },
  data() {
    return {
      waterproofingData: {
        external: null,
        internal: null
      }
    }
  },

  created() {
    this.init()
  },

  watch: {
    waterproofingData(val) {
      this.setWaterproofing(val)
    }
  },

  computed: {
    ...mapGetters([
      'waterproofing',
      'waterProofCost',
      'containerType'
    ])
  },

  methods: {
    ...mapActions([
      'setWaterproofing'
    ]),

    init() {
      if (this.waterproofing === null) {
        this.setWaterproofing({
          external: false,
          internal: false
        })
      }

      this.waterproofingData = this.waterproofing
    }
  }
}
</script>
