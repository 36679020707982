<template lang="pug">
  .yandex-map(v-if="visible")
    .background
    .map-wrapper
      .query-form
        .input-field
          kraus-input(ref="input", type="text", :inputData="query", :placeholder="'Введите адрес и нажмите \"Enter\"'", @keyDown="onKeyDown")
        kraus-button(:label="'Найти'",
          :colors="{label: '#FFFFFF', background: '#0558AB'}",
          :border="true",
          @click="onFindClick")

        .close-button(@click="hide()")
      yandex-map(ref="map", :coords="coords", :zoom="10", :controls="controls")
        ymap-marker(:markerId="'place'", :coords="coords", :options="{draggable: true}", @dragend="onMarkerDragEnd")

      .description *Для более точного определения, маркер на карте можно перемещать
      .actions
        kraus-button(:label="'Применить'",
          :colors="{label: '#FFFFFF', background: '#0558AB'}",
          :border="true",
          @click="onApplyClick")
        kraus-button(:label="'Отмена'",
          :colors="{label: '#0558AB', background: '#FFFFFF'}",
          :border="true",
          @click="onCancelClick")
        .close-button(@click="hide()")
</template>
<script>
import api from '@/common/http/api';
import KrausInput from '@/components/_common/ui/KrausInput';
import KrausButton from '@/components/_common/ui/KrausButton';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import { mapActions, mapGetters } from 'vuex';
import EquipmentCategories from '@/common/EquipmentCategories';

export default {
  components: { KrausButton, KrausInput },
  // components: {
  //   LMap,
  //   LTileLayer,
  //   LMarker
  // },
  data () {
    return {
      visible: false,
      query: {
        value: ''
      },
      coords: [55.75399399999374,37.62209300000001],
      controls: ['zoomControl'],
      timeout: null,
      delivery: {
        query: '',
        mapPoint: null,
        distance: 0,
        cost: 0
      }
    }
  },

  mounted() {
    eventBus.$on(EventBusEvents.ShowDeliveryMap, this.show)
  },

  destroyed() {
    eventBus.$off(EventBusEvents.ShowDeliveryMap, this.show)
  },

  computed: {
    ...mapGetters([
      'customerContacts',
      'containerType'
    ]),

    kmCost() {
      return this.containerType ? EquipmentCategories.getCategory('dostavka')
        .options
        .filter(item => item.key === '1_km')[0].price : 0
    }
  },

  watch: {
    coords(val) {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }

      api.get('/project/distance', {
        params: {
          to: [val[1], val[0]].join(',')
        }
      }).then(response => {
        this.delivery = {
          query: this.query.value,
          mapPoint: this.coords,
          distance: Math.round(response.data.distance / 1000),
          cost: 0
        }
      })
    }
  },

  methods: {
    ...mapActions([
      'setCustomerContacts',
      'setDelivery'
    ]),

    show() {
      this.visible = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },

    hide() {
      this.visible = false
    },

    onMarkerDragEnd(e) {
      this.coords = e.get('target').geometry.getCoordinates()
    },

    onKeyDown(e) {
      if (e.keyCode === 13) {
        this.onFindClick()
      }
    },

    onFindClick() {
      if (this.query.value.trim().length >= 3) {
        api.get('project/geo', {
          params: {
            search: this.query.value.trim()
          }
        }).then(response => {
          const features = response.data.features
          const geometry = features.length > 0 ? features[0].geometry : null

          if (geometry && geometry.type === 'Point') {
            const point = geometry.coordinates.reverse()
            this.coords = point
          }
        })
      }
    },

    onApplyClick() {
      if (this.delivery.distance > 0) {
        const data = this.customerContacts
        data.address = this.query.value
        this.setCustomerContacts(data)
        this.setDelivery(this.delivery)
        this.hide()
      }
    },

    onCancelClick() {
      this.hide()
    }
  }
}
</script>
