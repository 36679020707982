<template lang="pug">
  .ordering-result(:style="{marginTop: marginTop}")
    .project-name Проект «Стандарт»
    .project-num № {{requestId}}
    template(v-if="containerType")
      .project-description(v-if="!containerType.improved") Контейнеры с деревянной обрешёткой
      .project-description(v-else) Контейнеры сэндвичные

    .divider

    .info-items
      .info-item
        .label Этажи
        .value {{stagesCount}}

      .info-item
        .label Блоки
        .value {{boxesCount}}

      .info-item
        .label Площадь
        .value {{square}} м²

      .info-item
        .label Срок изготовления
        .value 7 - 14 дней

    .divider

    .cost-wrapper
      .cost
        .label Стоимость
        .value {{resultCost | currency_wo_plus}}

      .actions
        kraus-button(:label="final ? 'Оформить' : 'Продолжить'",
          :colors="{label: '#FFFFFF', background: '#0558AB'}",
          :border="true",
          @click="onContinueClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { mapGetters } from 'vuex';
export default {
  name: 'OrderingResult',
  components: { KrausButton },
  props: {
    final: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      width: 0,
      height: 0,
      marginTop: '0px'
    }
  },

  created() {
    this.calcSquare()
  },

  mounted() {
    this.updateMarginTop()
    this.wrapperEl = document.querySelector('.ordering-wrapper')
    this.wrapperEl.addEventListener('scroll', this.updateMarginTop)
  },

  beforeDestroy() {
    this.wrapperEl.removeEventListener('scroll', this.updateMarginTop)
  },

  computed: {
    ...mapGetters([
      'stagesData',
      'stagesCount',
      'boxesCount',
      'totalCost',
      'requestId',
      'mountingCost',
      'containerType',
      'square',
      'delivery'
    ]),

    resultCost() {
      let cost = parseFloat(this.totalCost)
      cost += parseFloat(this.mountingCost.total)

      if (this.delivery && this.delivery.distance) {
        cost += parseFloat(this.delivery.cost)
      }

      return cost
    }
  },

  methods: {
    updateMarginTop() {
      this.marginTop = document.querySelector('.ordering-wrapper').scrollTop + 'px'
    },

    onContinueClick() {
      this.$emit('continue')
    },

    calcSquare() {
      if (this.stagesData) {
        this.blocksCount = 0
        this.width = 0
        this.height = 0

        const topLeftConnector = {
          x: null,
          y: null
        }

        const bottomRightConnector = {
          x: null,
          y: null
        }

        let connectorsFinded = false

        for (let i = 1; i <= 3; i++) {
          const stageData = this.stagesData[i]
          if (stageData) {
            this.blocksCount += stageData.boxes.length
            stageData.connectors.forEach(c => {
              connectorsFinded = true

              if (topLeftConnector.x == null || topLeftConnector.x > c.x) {
                topLeftConnector.x = c.x
              }

              if (topLeftConnector.y == null || topLeftConnector.y > c.y) {
                topLeftConnector.y = c.y
              }

              if (bottomRightConnector.x == null || topLeftConnector.x < c.x) {
                bottomRightConnector.x = c.x
              }

              if (bottomRightConnector.y == null || bottomRightConnector.y < c.y) {
                bottomRightConnector.y = c.y
              }
            })
          }
        }

        if (connectorsFinded) {
          this.width = bottomRightConnector.x - topLeftConnector.x
          this.height = bottomRightConnector.y - topLeftConnector.y
        }
      }
    }
  }
}
</script>
