<template lang="pug">
  .tooltip-controller
    .dots
      .dot(v-for="(item, index) in dotsList", :key="index", :class="{selected: index === highlightedStep}")
    .space-filler
    .actions
      .action-button.skip.no-select(@click="onSkipClick") Пропустить все
      .action-button.next.no-select(@click="onNextClick") Далее
</template>
<script>
export default {
  name: 'TooltipController',
  props: {
    stepsCount: {
      type: Number,
      default: 0
    },
    currentStep: {
      type: Number,
      default: 0
    },
  },

  data() {
    return {

    }
  },

  computed: {
    highlightedStep() {
      return this.currentStep >= 4 ? 4 : this.currentStep
    },

    dotsList() {
      let count = this.stepsCount > 5 ? 5 : this.stepsCount
      return new Array(count)
    },
  },

  methods: {
    onSkipClick() {
      this.$emit('skipAll')
    },

    onNextClick() {
      this.$emit('next')
    }
  }
}
</script>
