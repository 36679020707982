import Vue from 'vue';

Vue.filter('currency', function(val) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 0
  })

  let prefix = ''
  if (val > 0) {
    prefix = '+ '
  } else if (val < 0) {
    prefix = '- '
  }

  return prefix + formatter.format(Math.abs(val)).replaceAll(',', ' ') + ' ₽'
})

Vue.filter('currency_wo_plus', function(val) {
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    minimumFractionDigits: 0
  })

  let prefix = ''
  if (val > 0) {
    prefix = ''
  } else if (val < 0) {
    prefix = '- '
  }

  return prefix + formatter.format(Math.abs(val)).replaceAll(',', ' ') + ' ₽'
})
