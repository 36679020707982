import { Shape } from '@createjs/easeljs';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import FastVector from 'fast-vector';
import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';

export default class BoxView {
  static Colors = {
    background : {
      normal: '#FFFFFF',
      wrong: '#FFBFBF'
    }
  }

  /**
   * @type {Box}
   * @private
   */
  _box

  /**
   * @type {easeljs.Container}
   * @private
   */
  _container

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _bgShape

  /**
   * @type {FastVector}
   * @private
   */
  _mousePositionDelta = FastVector.zero

  /**
   *
   * @param box {Box}
   */
  constructor(box) {
    this._box = box
    this._container = box.container

    this._bgShape = new Shape()
    this._bgShape.alpha = 0.8

    this._container.addChild(this._bgShape)

    this._addMouseEvents()
  }

  draw() {

    this._container.x = this._box.x
    this._container.y = this._box.y

    this._container.alpha = this._box.isSetuped ? 1 : 0.8

    const setupAvailable = this._box.isSetuped || this._box.checkIsSetupAvailable()

    this._bgShape.graphics
      .clear()
      .beginFill( setupAvailable ? BoxView.Colors.background.normal : BoxView.Colors.background.wrong)
      .drawRect(0, 0, this._box.width, this._box.height)
      .endFill()
  }

  _addMouseEvents() {
    this._bgShape.addEventListener('mousedown', this._onMouseDown)
    this._bgShape.addEventListener('pressmove', this._onPressMove)
    this._bgShape.addEventListener('click', this._onClick)
  }

  _removeMouseEvents() {
    this._bgShape.removeEventListener('mousedown', this._onMouseDown)
    this._bgShape.removeEventListener('pressmove', this._onPressMove)
    this._bgShape.removeEventListener('click', this._onClick)
  }

  /**
   * @param e {MouseEvent}
   * @private
   */
  _onMouseDown = (e) => {
    this._mousePositionDelta = CreateJsBlocksEditor.Instance.mousePosition.clone()

    if (!this._box.isSetuped) {
      this._box.trySetup()
    }
  }

  _onPressMove = (e) => {
    if (this._box.isSetuped && this._box.isSelected()) {
      const mousePosition = CreateJsBlocksEditor.Instance.mousePosition
      const delta = new FastVector(
        Math.round(e.nativeEvent.movementX * BaseEditorElement.PixelRatio / Grid.Instance.container.scale),
        Math.round(e.nativeEvent.movementY * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)
      )

      const movement = this._box.getUpdatedMoveDelta(delta)
      this._box.move(movement)
    }
  }

  _onClick = (e) => {
    if (this._box.isSetuped) {
      const distance = this._mousePositionDelta.distance(CreateJsBlocksEditor.Instance.mousePosition)
      if (distance < 1) {
        CreateJsBlocksEditor.Instance.setSelectedElement(this._box)
      }
    }
  }

  destroy() {
    this._removeMouseEvents()
  }
}
