<template lang="pug">
  .boiler-options
    .boiler-volume
      .select-label Объём
      .select-wrapper
        kraus-equipment-select(v-model="volume.value", :values="volume.items")
</template>
<script>
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';
export default {
  name: 'BoilerOptions',
  components: { KrausEquipmentSelect },
  props: {
    item: null,
    json: null
  },
  data() {
    return {
      volume: {
        value: null,
        items: []
      },
    }
  },

  created() {
    this.init()
  },

  watch: {
    'item.id': function () {
      this.init()
    },

    'volume.value': {
      handler: function(val) {
        this.item.dictionaryData.option = val
        this.item.setDictionaryData(this.item.dictionaryData)
      }
    }
  },

  methods: {
    init() {
      this.volume.items = this.item.dictionaryData.data.raw.options.map(item => {
        return {
          key: item.key,
          title: item.title,
          cost: item.price,
          raw: item
        }
      })

      let value

      this.volume.items.forEach(item => {
        if (item.key === this.item.dictionaryData.option.key) {
          value = item
        }
      })

      this.volume.value = value
    }
  }
}
</script>
