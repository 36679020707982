import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Bitmap, Container, Rectangle, Shadow, Shape } from '@createjs/easeljs';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';

class CloneButtonItem extends BaseEditorElement {
  static SIDE_LEFT = 'left'
  static SIDE_RIGHT = 'right'
  static SIDE_TOP = 'top'
  static SIDE_BOTTOM = 'bottom'
  /**
   *
   * @type {easeljs.Shape}
   * @private
   */
  _shape

  /**
   *
   * @private {String}
   */
  _side

  /**
   *
   * @param side {String}
   */
  constructor(side) {
    super()
    this._side = side

    this._shape = new Shape()
    this._shape.graphics
      .beginFill('#ffffff')
      .beginStroke('#efefef')
      .drawCircle(0,0, 32)
      .endFill()

    const bitmap = new Bitmap(require('@/assets/images/editor/actions/plus.png'))
    bitmap.mouseEnabled = false

    this.container.addChild(this._shape)
    this.container.addChild(bitmap)

    bitmap.scale = 0.75

    bitmap.x = - 18 * bitmap.scale
    bitmap.y = - 18 * bitmap.scale

    this.container.addEventListener('click', this._onClick)
    this._shape.addEventListener('rollover', this._onMouseOver)
    this._shape.addEventListener('rollout', this._onMouseOut)
  }

  _onClick = () => {
    this.dispatchEvent(new CustomEvent('CloneButtonClicked', {detail: this}))
  }

  _onMouseOver = () => {
    this.dispatchEvent(new CustomEvent('CloneButtonOvered', {detail: this}))
  }

  _onMouseOut = () => {
    this.dispatchEvent(new CustomEvent('CloneButtonOuted', {detail: this}))
  }

  /**
   * @returns {String}
   */
  get side() {
    return this._side
  }
}

export default class CloneBoxActionsView extends BaseEditorElement {

  /**
   * @type {CloneBoxActions[]}
   */
  static CloneBoxActionsList = []

  /**
   * @type {Box}
   * @private
   */
  _box

  _buttons = []

  /**
   *
   * @type {easeljs.Container}
   * @private
   */
  _indicatorContainer

  _visible = true

  /**
   * @param box {Box}
   */
  constructor(box) {
    super()

    this._box = box

    this._indicatorContainer = new Container()
    this._indicatorContainer.alpha = 0.5
    this.container.addChild(this._indicatorContainer)

    this._createButtons()

    CloneBoxActionsView.CloneBoxActionsList.push(this)
    CreateJsBlocksEditor.Instance.addEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
    Grid.Instance.addEventListener(Events.GRID_MOVED, this._onGridMoved)

    Grid.Instance.actionsContainer.addChild(this)
    Grid.Instance.addEventListener(Events.GRID_MOVED, this._onGridMoved)
    this._box.addEventListener(Events.BOX_MOVED, this._onBoxMoved)
  }

  _createButtons() {
    this._buttons.push(
      new CloneButtonItem(CloneButtonItem.SIDE_LEFT),
      new CloneButtonItem(CloneButtonItem.SIDE_RIGHT),
      new CloneButtonItem(CloneButtonItem.SIDE_TOP),
      new CloneButtonItem(CloneButtonItem.SIDE_BOTTOM),
    )
    this._buttons.forEach(buttonItem => {
      this.addChild(buttonItem)
      buttonItem.addEventListener('CloneButtonClicked', this._onCloneButtonClick)
      buttonItem.addEventListener('CloneButtonOvered', this._onCloneButtonOver)
      buttonItem.addEventListener('CloneButtonOuted', this._onCloneButtonOut)
    })
  }

  _onCloneButtonOver = (e) => {
    const buttonItem = e.detail
    this._indicatorContainer.scale = 1
    const tempRectangle = this._box.rectangle.clone()

    switch (buttonItem.side) {
      case CloneButtonItem.SIDE_LEFT:
        tempRectangle.x = - this._box.rectangle.width - this._box.rectangle.width / 2
        tempRectangle.y = - this._box.rectangle.height / 2
        break
      case CloneButtonItem.SIDE_RIGHT:
        tempRectangle.x = this._box.rectangle.width / 2
        tempRectangle.y = - this._box.rectangle.height / 2
        break
      case CloneButtonItem.SIDE_TOP:
        tempRectangle.x = - this._box.rectangle.width / 2
        tempRectangle.y = - this._box.rectangle.height / 2 - this._box.rectangle.height
        break
      case CloneButtonItem.SIDE_BOTTOM:
        tempRectangle.x = - this._box.rectangle.width / 2
        tempRectangle.y = this._box.rectangle.height / 2
        break
    }

    this._indicatorContainer.removeAllChildren()

    const shape = new Shape()
    shape.graphics
      .setStrokeDash([10, 10], 0)
      .setStrokeStyle(4, 'square')
      .beginStroke('#0558AB')
      .drawRect(tempRectangle.x, tempRectangle.y, tempRectangle.width, tempRectangle.height)
      .endStroke()

    this._indicatorContainer.addChild(shape)
  }

  _onCloneButtonOut = (e) => {
    this._indicatorContainer.scale = 0
  }

  _onCloneButtonClick = (e) => {
    const buttonItem = e.detail
    const tempRectangle = this._box.rectangle.clone()

    switch (buttonItem.side) {
      case CloneButtonItem.SIDE_LEFT:
        tempRectangle.x = this._box.rectangle.x - this._box.rectangle.width
        break
      case CloneButtonItem.SIDE_RIGHT:
        tempRectangle.x = this._box.rectangle.x + this._box.rectangle.width
        break
      case CloneButtonItem.SIDE_TOP:
        tempRectangle.y = this._box.rectangle.y - this._box.rectangle.height
        break
      case CloneButtonItem.SIDE_BOTTOM:
        tempRectangle.y = this._box.rectangle.y + this._box.rectangle.height
        break
    }

    const newBox = CreateJsBlocksEditor.Instance.createBox({
      width: tempRectangle.width,
      height: tempRectangle.height,
      x: Math.round(tempRectangle.x + this._box.width / 2),
      y: Math.round(tempRectangle.y + this._box.height / 2)
    })

    setTimeout(() => {
      newBox.walls.forEach((wall) => {
        wall.intersectedWalls.forEach((intersectedWall) => {
          if (intersectedWall.wallItems.length > 0 && intersectedWall.tempDashed) {
            intersectedWall.removeWallItems()
          }
        })
      })
    }, 10)

    Pillar.UpdatePillars()
    CloneBoxActionsView.CloneBoxActionsList.forEach(item => item.updateButtonsVisibility())
  }

  _onBoxMoved = () => {
    this.updatePosition()
  }

  _onElementSelected = (e) => {
    this._visible = e.detail.element === null
    this.draw()
  }

  _onGridMoved = () => {
    this.draw()
  }

  draw() {
    if (this._visible) {
      this.container.scale = 1
      this.updatePosition()
    } else {
      this.container.scale = 0
    }
  }

  updatePosition() {
    const center = this._box.center
    this.x = center.x
    this.y = center.y

    this._buttons.forEach(buttonItem => {
      this._updateButtonVisibility(buttonItem)
      this._updateButtonPosition(buttonItem)
    })
  }

  _updateButtonPosition(buttonItem) {
    const scale = buttonItem.container.scale

    switch (buttonItem.side) {
      case CloneButtonItem.SIDE_LEFT:
        buttonItem.x = -this._box.width / 2 - 110 * scale
        break
      case CloneButtonItem.SIDE_RIGHT:
        buttonItem.x = this._box.width / 2 + 110 * scale
        break
      case CloneButtonItem.SIDE_TOP:
        buttonItem.y = -this._box.height / 2 - 110 * scale
        break
      case CloneButtonItem.SIDE_BOTTOM:
        buttonItem.y = this._box.height / 2 + 110 * scale
        break
    }
  }

  updateButtonsVisibility() {
    this._buttons.forEach(button => this._updateButtonVisibility(button))
  }

  _updateButtonVisibility (buttonItem) {
    if (this._box.rectangle) {
      const tempRectangle = this._box.rectangle.clone()

      switch (buttonItem.side) {
        case CloneButtonItem.SIDE_LEFT:
          tempRectangle.x = this._box.rectangle.x - this._box.rectangle.width
          break
        case CloneButtonItem.SIDE_RIGHT:
          tempRectangle.x = this._box.rectangle.x + this._box.rectangle.width
          break
        case CloneButtonItem.SIDE_TOP:
          tempRectangle.y = this._box.rectangle.y - this._box.rectangle.height
          break
        case CloneButtonItem.SIDE_BOTTOM:
          tempRectangle.y = this._box.rectangle.y + this._box.rectangle.height
          break
      }

      let visible = true

      Box.BoxesList.forEach(boxItem => {
        if (boxItem !== this._box && visible) {
          const rect = tempRectangle.intersection(boxItem.rectangle)
          visible = rect === null
        }
      })

      if (visible) {
        ExternalItem.ExternalItemsList.forEach((item) => {
          if (item.rectangle.intersects(tempRectangle)) {
            visible = false
          }
        })
      }

      buttonItem.container.scale = visible ? 1 / Grid.Instance.container.scale : 0
    }
  }

  destroy() {
    Grid.Instance.actionsContainer.removeChild(this)

    this._buttons.forEach(buttonItem => {
      this.addChild(buttonItem)
      buttonItem.removeEventListener('CloneButtonClicked', this._onCloneButtonClick)
      buttonItem.removeEventListener('CloneButtonOvered', this._onCloneButtonOver)
      buttonItem.removeEventListener('CloneButtonOuted', this._onCloneButtonOut)
    })
    CloneBoxActionsView.CloneBoxActionsList = CloneBoxActionsView.CloneBoxActionsList.filter(item => item !== this)
    CreateJsBlocksEditor.Instance.removeEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
    super.destroy()
  }
}
