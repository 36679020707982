import { Bitmap, Container, Stage } from '@createjs/easeljs';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import FastVector from 'fast-vector';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';
import constants from '@/common/contants';

class CreateJsBlocksEditor extends BaseEditorElement {

  static SelectedElement
  /**
   * @type {CreateJsBlocksEditor}
   */
  static Instance

  /**
   * @type {easeljs.Stage}
   * @private
   */
  _stage

  /**
   * @type HTMLCanvasElement
   * @private
   */
  _canvas

  /**
   * @type {number}
   * @private
   */
  _scaleLimit = {
    min: 0.27,
    max: 3
  }
  _scale = 1
  _toScale = 1.6
  // _toScale = 1

  /**
   * @type Grid
   * @private
   */
  _grid

  /**
   *
   * @type {boolean}
   * @private
   */
  _animationStarted = false

  /**
   * @type {FastVector}
   * @private
   */
  _mousePosition = new FastVector()

  /**
   * @return {FastVector}
   */
  get mousePosition() {
    return this._mousePosition
  }

  containerType = null

  static EmitChangedEvent() {
    const data = this.Instance.getJSON()
    this.Instance.dispatchEvent(new CustomEvent(Events.ELEMENTS_CHANGED, {
      detail: data
    }))
  }

  /**
   *
   * @param id {Number}
   * @constructor
   */
  static SelectWallItem(id) {
    const list = WallItem.WallItemsList.filter(item => item.id === id)
    if (list.length > 0) this.Instance.setSelectedElement(list[0])
  }

  /**
   *
   * @param id {Number}
   * @constructor
   */
  static SelectBoxItem(id) {
    const list = BoxItem.BoxItemsList.filter(item => item.id === id)
    if (list.length > 0) this.Instance.setSelectedElement(list[0])
  }

  /**
   *
   * @param id {Number}
   * @constructor
   */
  static SelectBox(id) {
    const list = Box.BoxesList.filter(item => item.id === id)
    if (list.length > 0) this.Instance.setSelectedElement(list[0])
  }

  /**
   *
   * @param id {Number}
   * @constructor
   */
  static SelectExternalItem(id) {
    const list = ExternalItem.ExternalItemsList.filter(item => item.id === id)
    if (list.length > 0) this.Instance.setSelectedElement(list[0])
  }

  /**
   *
   * @param id {Number}
   * @constructor
   */
  static SelectWall(id) {
    const list = Wall.WallsList.filter(item => item.id === id)
    if (list.length > 0) this.Instance.setSelectedElement(list[0])
  }


  /**
   *
   * @returns {{width: number, height: number}}
   * @private
   */
  get _defaultContainerSize() {
    return this.containerType.key === 'obreshotka' ? {
      width: 585,
      height: 245
    } : {
      width: 600,
      height: 245
    }
  }

  /**
   *
   * @param canvas {HTMLCanvasElement}
   */
  constructor(canvas) {
    super()
    CreateJsBlocksEditor.Instance = this
    this._canvas = canvas
    this._updateCanvasSize()

    this._stage = new Stage(this._canvas)
    this._stage.addChild(this.container)
    this._stage.enableMouseOver(10)

    this._grid = new Grid(this._canvas)
    this._grid.x = this._canvas.width  / 2
    this._grid.y = this._canvas.height  / 2
    this._grid.container.scale = this._scale

    this.addChild(this._grid)
    this.draw()

    this.startAnimation()

    this._canvas.addEventListener('mousewheel', (e) => {
      const delta = -e.deltaY / 200
      this.zoom(delta)
    })

    this._scale = this._toScale
    this._grid.container.scale = this._scale

    window.addEventListener('resize', this._onWindowResize)
    document.addEventListener('keydown', this._onKeyDown)
  }

  _onWindowResize = () => {
    this._updateCanvasSize  ()
  }

  _onKeyDown = (e) => {
    if (e.code === 'Escape') {
      this.setSelectedElement(null)
    }
  }

  setBackgroundImage(data) {
    this._grid.setBackgroundImage(data)
  }

  selectElementByType({type, id}) {
    switch (type) {
      case BaseEditorElement.ELEMENT_TYPE_WALL_ITEM:
        CreateJsBlocksEditor.SelectWallItem(id)
        break
      case BaseEditorElement.ELEMENT_TYPE_EXTERNAL_ITEM:
        CreateJsBlocksEditor.SelectExternalItem(id)
        break
      case BaseEditorElement.ELEMENT_TYPE_WALL:
        CreateJsBlocksEditor.SelectWall(id)
        break
      case BaseEditorElement.ELEMENT_TYPE_BOX_ITEM:
        CreateJsBlocksEditor.SelectBoxItem(id)
        break
      case BaseEditorElement.ELEMENT_TYPE_BOX:
        CreateJsBlocksEditor.SelectBox(id)
        break
    }
  }

  /**
   *
   * @param element {BaseEditorElement}
   */
  setSelectedElement(element) {
    if (CreateJsBlocksEditor.SelectedElement && CreateJsBlocksEditor.SelectedElement.isSetuped === false) {
      CreateJsBlocksEditor.SelectedElement.destroy()
      CreateJsBlocksEditor.SelectedElement = null
    } else if (CreateJsBlocksEditor.SelectedElement !== element) {
      CreateJsBlocksEditor.SelectedElement = element
    }

    this.dispatchEvent(
      new CustomEvent(
        Events.ELEMENT_SELECTED, {
          detail: {
            element: element
          }
        }
      )
    )
  }

  setMousePosition(x, y) {
    const ratio = constants.browser.name === 'chrome' ? 2 : CreateJsBlocksEditor.PixelRatio

    this._mousePosition.x = (x * ratio - Grid.Instance.x) / Grid.Instance.container.scale
    this._mousePosition.y = (y * ratio - Grid.Instance.y) / Grid.Instance.container.scale

    const element = CreateJsBlocksEditor.SelectedElement

    if (element && element.isSetuped === false) {
      element.setPosition(
        Math.round(this._mousePosition.x),
        Math.round(this._mousePosition.y)
      )
    }
  }

  _updateCanvasSize() {
    const ratio = constants.browser.name === 'chrome' ? 2 : CreateJsBlocksEditor.PixelRatio
    const rect = this._canvas.getBoundingClientRect()
    this._canvas.width = rect.width * ratio
    this._canvas.height = rect.height * ratio
  }

  startAnimation () {
    this._animationStarted = true
    this.requestFrame()
  }

  stopAnimation () {
    this._animationStarted = false
  }

  /**
   * @param boxParams
   * @return {Box}
   */
  createBox(boxParams) {
    const box = Box.Create(!boxParams ? {
      width: this._defaultContainerSize.width,
      height: this._defaultContainerSize.height
    } : {
      width: boxParams.width,
      height: boxParams.height
    })

    if (boxParams) {
      box.setPosition(boxParams.x , boxParams.y)
    } else {
      box.isSetuped = false
      box.setPosition(this._mousePosition.x , this._mousePosition.y)
    }


    if (!boxParams) this.setSelectedElement(box)

    return box
  }

  createWallItem({width, height, type, dictionaryData, enableSelect = true}) {
    const wallItem = WallItem.Create({width, height, type, dictionaryData})
    wallItem.setPosition(this._mousePosition.x , this._mousePosition.y)

    if (enableSelect) this.setSelectedElement(wallItem)

    return wallItem
  }

  createBoxItem({dictionaryData}) {
    const boxItem = BoxItem.Create(dictionaryData)
    boxItem.setPosition(this._mousePosition.x, this._mousePosition.y)
    this.setSelectedElement(boxItem)
  }

  /**
   * @param material
   * @return {Wall}
   */
  createWall(material) {
    const wall = Wall.CreateInsideBoxWall()
    wall.material = material
    wall.setPosition(this._mousePosition.x, this._mousePosition.y)
    this.setSelectedElement(wall)
    return wall
  }

  createExternalItem(dictionaryData) {
    const externalItem = ExternalItem.Create(dictionaryData)
    externalItem.setPosition(this._mousePosition.x, this._mousePosition.y)
    this.setSelectedElement(externalItem)
  }

  setContainerType(containerType) {
    this.containerType = containerType
  }

  createDefault(containerType) {
    this.setContainerType(containerType)
    Pillar.WallDistantionDelta = containerType.improved ? 300 : 240

    for (let i = 0; i < 1; i++) {
      //let box = new Box()
      const box = Box.Create({
        width: this._defaultContainerSize.width, //Math.round(100 + Math.random() * 300) ,
        height: this._defaultContainerSize.height //Math.round(100 + Math.random() * 300)
      })

      box.setPosition(-203, -80)
      box.createDefaultWallItems()
    }

    Pillar.UpdatePillars()
    // let box = new Box(this._grid)
    // box.initBox({
    //   width: 200,
    //   height: 100
    // })
    //
    // let box2 = new Box(this._grid)
    // box2.initBox({
    //   width: 50,
    //   height: 50
    // })
    // box2.setPosition(400, 0)
    //
    // let box3 = new Box(this._grid)
    // box3.initBox({
    //   width: 100,
    //   height: 200
    // })
    // box3.y = 300
    // box3.setPosition(0, 300)
    //
    // this._grid.addBox(box)
    // this._grid.addBox(box2)
    // this._grid.addBox(box3)
  }

  requestFrame () {
    requestAnimationFrame(() => {
      const delta = (this._toScale - this._scale) / 4
      if (Math.abs(this._toScale - this._scale) > 0.01) {
        this._grid.dispatchEvent(new Event(Events.GRID_MOVED))
        this._scale += delta
        this._grid.container.scale = this._scale
        this._grid.draw()
      }

      this._stage.update()

      if (this._animationStarted) {
        this.requestFrame()
      }
    })
  }

  zoom(delta) {
    this._toScale += delta / 10

    if (this._toScale < this._scaleLimit.min) {
      this._toScale = this._scaleLimit.min
    }

    if (this._toScale > this._scaleLimit.max) {
      this._toScale = this._scaleLimit.max
    }
  }

  clean() {
    this._grid.clean()
    this.emitChangeByTimeout()
  }

  getJSON() {
    return this._grid.toJson()
  }

  restoreFromJson(data) {
    this._grid.restoreFromJson(data)
    Pillar.UpdatePillars()
  }

  getImageData(options = {}) {
    return this._grid.getImageData(this._canvas, options)
  }

  setBackgroundImage(imageDataURL) {
    this._grid.setBackgroundImage(imageDataURL)
  }

  destroy() {
    this._animationStarted = false
    if (!this.destroyed) {
      this.clean()
      super.destroy()
      window.removeEventListener('rezise', this._onWindowResize)
      document.removeEventListener('keydown', this._onKeyDown)
    }
  }
}

export default CreateJsBlocksEditor
