<template lang="pug">
  .content.content-roof
    .content-header
      .content-option-name
        span Тип кровли
        tooltip-icon(:hint="{section: 'roof', key: 'type'}")
      .content-option-cost
    .content-actions
      kraus-equipment-select(:values="roof.type.items", v-model="roof.type.value")

    template(v-if="!isRoofFlat")
      .content-header
        .content-option-name Цвет кровли
        .content-option-cost
      .content-actions
        kraus-equipment-select(:values="roof.color.items", v-model="roof.color.value")


    .content-header
      .content-option-name Материал
      .content-option-cost
    .content-actions
      kraus-equipment-select(:values="roof.material.items", v-model="roof.material.value")

    .small-switcher(v-if="boxesCount >= 4")
      .small-switcher-info
        .text
          span Металлические фермы
          tooltip-icon(:hint="{section: 'roof', key: 'metal-trusses'}")
        .cost(v-if="roof.metalTrusses") {{roofCost.metalTrusses| currency }}
      .small-switcher-switcher
        kraus-switcher-small(v-model="roof.metalTrusses")
</template>
<script>
import KrausSelect from '@/components/_common/ui/KrausSelect';
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import KrausEquipmentSelect
  from '@/components/_common/ui/KrausEquipmentSelect';
import { mapActions, mapGetters } from 'vuex';
import EquipmentCategories from '@/common/EquipmentCategories';
import TooltipIcon from '../../../../_common/ui/TooltipIcon';
export default {
  name: 'Roof',
  components: { TooltipIcon, KrausEquipmentSelect, KrausSwitcherSmall, KrausSelect },
  data() {
    return {
      roofTypes: null,
      roof: {
        type: {
          value: null,
          items: [
            {
              title: 'Плоская 1',
              icon: {
                type: 'image',
                dataURL: require('@/assets/images/editor/building-equipment/roof/type-flat.svg')
              }
            },
            {
              title: 'Плоская 2',
              icon: {
                type: 'image',
                dataURL: require('@/assets/images/editor/building-equipment/roof/type-flat.svg')
              }
            },
            {
              title: 'Плоская 3',
              icon: {
                type: 'image',
                dataURL: require('@/assets/images/editor/building-equipment/roof/type-flat.svg')
              }
            }
          ]
        },
        color: {
          value: null,
          items: [
            {
              title: 'Белый',
              cost: 10000,
              icon: {
                type: 'color',
                color: '#ffffff'
              }
            },
            {
              title: 'Синий',
              cost: null,
              icon: {
                type: 'color',
                color: '#0000ff'
              }
            },
            {
              title: 'Желтый',
              cost: 10000,
              icon: {
                type: 'color',
                color: '#ffff00'
              }
            }
          ]
        },
        material: {
          value: null,
          items: [
            {
              title: 'Плоская 3',
              icon: {
                type: 'image',
                dataURL: require('@/assets/images/editor/building-equipment/roof/roof-material-s8.svg')
              }
            },
            {
              title: 'Плоская 33',
              icon: {
                type: 'image',
                dataURL: require('@/assets/images/editor/building-equipment/roof/roof-material-s8.svg')
              }
            },
            {
              title: 'Плоская 11',
              icon: {
                type: 'image',
                dataURL: require('@/assets/images/editor/building-equipment/roof/roof-material-s8.svg')
              }
            }
          ]
        },
        metalTrusses: false
      }
    }
  },

  created() {
    this.init()
  },

  watch: {
    'roof.type.value': {
      handler: function (val) {
        if (this.roofType !== val) {
          this.setRoofType(val)

          this.roof.material.items = this.roofType.raw.options.map(item => {
            return {
              title: item.title,
              raw: item
            }
          })

          this.setRoofMaterial(this.roof.material.items[0])
          this.roof.material.value = this.roofMaterial
        }
      }
    },

    'roof.material.value': {
      handler: function (val) {
        this.setRoofMaterial(val)
      }
    },

    'roof.color.value': {
      handler: function (val) {
        this.setRoofColor(val)
      }
    },

    'roof.metalTrusses': {
      handler: function (val) {
        this.setRoofMetalTrusses(val)
      }
    },

    boxesCount(count) {
      const prevLength = this.roof.type.items.length
      this.roof.type.items = this.getRoofTypesItems()

      if (prevLength !== this.roof.type.items.length) {
        if (count >= 4) {
          if (this.roof.type.value.key === 'ploskaya') {
            this.roof.type.value = this.roof.type.items[0]
          }
        } else {
          if (this.roof.type.value.key !== 'ploskaya') {
            this.roof.type.value = this.roof.type.items[0]
          }
        }
      }

      if (count < 5 && this.roofMetalTrusses) {
        this.setRoofMetalTrusses(false)
      }
    }
  },

  computed: {
    ...mapGetters([
      'roofCost',
      'roofType',
      'roofColor',
      'roofMaterial',
      'roofMetalTrusses',
      'containerType',
      'boxesCount'
    ]),

    isRoofFlat() {
      return this.roofType?.key === 'ploskaya'
    }
  },

  methods: {
    ...mapActions([
      'setRoofType',
      'setRoofColor',
      'setRoofMaterial',
      'setRoofMetalTrusses'
    ]),

    init() {
      this.roofTypes = EquipmentCategories.getItems({
        categoryKey: 'krovlya',
        optionKey: 'tip_krovli'
      }).options.map((item) => {
        return {
          key: item.key,
          title: item.title,
          raw: item
        }
      })

      this.roof.type.items = this.getRoofTypesItems()

      if (this.roofType === null) this.setRoofType(this.roof.type.items[0])
      this.roof.type.value = this.roofType

      this.roof.color.items = EquipmentCategories.getItems({
        categoryKey: 'krovlya',
        optionKey: 'cvet_kryshi'
      }).options.map((item) => {
        return {
          icon: {
            color: item.hex
          },
          title: item.title,
          cost: item.price,
          raw: item
        }
      })

      if (this.roofMaterial === null) this.setRoofMaterial({
        title: this.roofType.raw.options[0].title,
        raw: this.roofType.raw.options[0],
      })

      //console.log(this.roofMaterial.title)

      this.roof.material.value = this.roofMaterial
      this.roof.material.items = this.roofType.raw.options.map(item => {
        return {
          title: item.title,
          raw: item
        }
      })

      if (this.roofColor === null) {
        if (this.containerType.improved) {
          this.setRoofColor(this.roof.color.items.filter(item => item.raw.key === 'white')[0])
        } else {
          this.setRoofColor(this.roof.color.items[0])
        }
      }
      this.roof.color.value = this.roofColor


      if (this.roofMetalTrusses === null) this.setRoofMetalTrusses(false)
      this.roof.metalTrusses = this.roofMetalTrusses
    },

    getRoofTypesItems() {
      let items
      if (this.boxesCount >= 4) {
        items = this.roofTypes.filter(item => item.key !== 'ploskaya')
      } else {
        items = this.roofTypes.filter(item => item.key === 'ploskaya')
      }
      return items
    }
  }
}
</script>
