<template lang="pug">
  .electric-item
    .electric-item-icon(:style="{backgroundImage: 'url(' + data.icon + ')'}")
    .electric-item-info
      .electric-item-info-title
        span {{data.title}}
        tooltip-icon(:hint="hint")
      .electric-item-info-cost-wrapper
        .electric-item-info-cost(:class="{total: count > 0}") {{cost}}
        .electric-item-info-buttons
          .electric-action-button.minus(@click="onMinusClick")
          .electric-counter {{count}}
          .electric-action-button.plus(@click="onPlusClick")
</template>
<script>
import { mapGetters } from 'vuex';
import TooltipIcon from '../../../../../_common/ui/TooltipIcon';

export default {
  name: 'ElectricItem',
  components: { TooltipIcon },
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      count: 0
    }
  },

  created() {
    this.count = this.data.count
  },

  computed: {
    ...mapGetters([
      'boxesCount'
    ]),

    freeCount() {
      return ['rozetka_sdvoennaya', 'plafon_tabletka'].indexOf(this.data.key) >= 0? this.boxesCount * 2 : 0
    },

    hint() {
      switch (this.data.key) {
        case 'rozetka_sdvoennaya':
          return {section: 'electrician', key: 'double-socket'}
          break

        case 'rozetka_ulichnaya':
          return {section: 'electrician', key: 'street-socket'}
          break

        case 'plafon_tabletka':
          return {section: 'electrician', key: 'plafond-tablet'}
          break

        case 'led-light':
          return {section: 'electrician', key: 'svetilnik_led'}
          break

        case 'street-spotlight':
          return {section: 'electrician', key: 'ulichnyy_prozhektor'}
          break
      }
    },

    cost() {
      let totalCost = this.count * this.data.cost - this.freeCount * this.data.cost
      if (totalCost < 0) {
        totalCost = 0
      }
      return this.count > 0 ? '+' + totalCost + ' ₽' : this.data.cost + ' ₽ за шт.'
    }
  },

  methods: {
    onMinusClick() {
      if (this.count > 0) this.count--
      this.data.count = this.count
    },

    onPlusClick() {
      this.count++
      this.data.count = this.count
    }
  }
}
</script>
