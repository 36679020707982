import { Container } from '@createjs/easeljs';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import constants from '@/common/contants';

class BaseEditorElement extends EventTarget {
  static ELEMENT_TYPE_BOX = 'BOX'
  static ELEMENT_TYPE_BOX_ITEM = 'BOX_ITEM'
  static ELEMENT_TYPE_EXTERNAL_ITEM = 'EXTERNAL_ITEM'
  static ELEMENT_TYPE_WALL = 'WALL'
  static ELEMENT_TYPE_WALL_ITEM = 'WALL_ITEM'

  static PixelRatio = constants.browser.name === 'chrome' ? 2 : 2

  static _CHANGE_TIMEOUT

  elementType = null

  /**
   * @type {easeljs.Container}
   */
  container

  _x = 0
  _y = 0

  /**
   *
   * @type {BaseEditorElement[]}
   * @private
   */
  _childs = []

  destroyed = false

  constructor() {
    super()
    this.container = new Container()
    this.container.addEventListener('added', () => {
      this.draw()
    })
  }

  get x() {
    return this.container.x
  }

  get y() {
    return this.container.y
  }

  set x(x) {
    this.container.x = x
  }

  set y(y) {
    this.container.y = y
  }

  _drawingTO = null

  /**
   * @returns {boolean}
   */
  isSelected () {
    return CreateJsBlocksEditor.SelectedElement === this
  }

  addChild(baseEditorElement) {
    if (this._childs.indexOf(baseEditorElement) < 0) {
      this._childs.push(baseEditorElement)
      this.container.addChild(baseEditorElement.container)
    }
  }

  removeChild(baseEditorElement) {
    if (this._childs.indexOf(baseEditorElement) >= 0) {
      this._childs = this._childs.filter((item) => item != baseEditorElement)
      this.container.removeChild(baseEditorElement.container)
      this.recursiveCleanContainer(baseEditorElement.container)
    }
  }

  /**
   *
   * @param elem {BaseEditorElement}
   */
  recursiveCleanElem(elem) {
    elem._childs.forEach(item => {
      this.recursiveCleanElem(item)
    })

    elem.removeAllChilds()
    this.recursiveCleanContainer(elem.container)
  }
  /**
   *
   * @param childrens {DisplayObject}
   */
  recursiveCleanContainer(container) {
    if (container.children && container.children.length > 0 ) container.children.forEach(item => {
      this.recursiveCleanContainer(item)
    })
    if (container.removeAllChilds) container.removeAllChilds()
    container.removeAllEventListeners()
  }

  removeAllChilds() {
    this._childs.forEach((childItem) => {
      this.removeChild(childItem)
    })
  }

  draw() {
    if (this._drawingTO) {
      return
    }

    this._drawingTO = setTimeout(() => {
      this._childs.forEach((childItem) => {
        childItem.draw()
      })
      this._drawingTO = null
    }, 0)
  }

  destroy() {
    if (!this.destroyed) {
      this.destroyed = true
      this.recursiveCleanElem(this)
    }
  }

  emitChangeEvent() {
    CreateJsBlocksEditor.EmitChangedEvent()
  }

  emitChangeByTimeout() {
    if (BaseEditorElement._CHANGE_TIMEOUT) clearTimeout(BaseEditorElement._CHANGE_TIMEOUT)

    BaseEditorElement._CHANGE_TIMEOUT = setTimeout(() => {
      this.emitChangeEvent()
    }, 100)
  }
}

export default BaseEditorElement
