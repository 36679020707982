<template lang="pug">
  .tooltip-point.no-select(:style="style")
    .circle(:class="{hidden: visible}")
    .circle.selected(:style="{opacity: visible ? 1 : 0}", @click="onClick")
    .text(v-if="textVisible", :class="{visible: visible}", v-html="text", @transitionend="onTransitionEnd")
</template>
<script>
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';

export default {
  name: 'TooltipPoint',
  props: [
    'data',
    'blocksRenderer'
  ],

  data() {
    return {
      renderStarted: false,
      screenPoint: null,
      visible: false,
      textVisible: false
    }
  },

  mounted() {
    eventBus.$on(EventBusEvents.TooltipPoint, this._onTooltipPointData)
    eventBus.$on(EventBusEvents.MOUSE_DOWN_ON_3D_SCENE, this._onSceneMouseDown)
    this.startRender()
  },

  beforeDestroy() {
    this.renderStarted = false
  },

  destroyed() {
    eventBus.$off(EventBusEvents.TooltipPoint, this._onTooltipPointData)
    eventBus.$off(EventBusEvents.MOUSE_DOWN_ON_3D_SCENE, this._onSceneMouseDown)
  },

  computed: {
    text() {
      return this.data.text
    },
    point() {
      return this.data.position
    },
    cameraPosition() {
      return this.data.cameraPosition
    },
    style() {
      return this.screenPoint ? {
        top: Math.round(this.screenPoint.y) + 'px',
        left: Math.round(this.screenPoint.x) + 'px'
      } : {
        top: 0,
        left: 0
      }
    }
  },

  methods: {
    startRender() {
      this.renderStarted = true
      this.requestAnimationFrame()
    },

    requestAnimationFrame() {
      window.requestAnimationFrame(() => {
        this.updatePosition()
        if (this.renderStarted) this.requestAnimationFrame()
      })
    },

    updatePosition() {
      if (this.renderStarted) this.screenPoint = this.blocksRenderer.getScreenPoint(this.point)
    },

    onClick() {
      !this.visible ? this.show() : this.hide()
    },

    show() {
      this.textVisible = true
      setTimeout(() => {
        this.visible = true
      }, 0)

      this.blocksRenderer.navigateToPoint(this.point, this.cameraPosition)
      eventBus.$emit(EventBusEvents.TooltipPoint, this.data)
    },

    hide() {
      this.visible = false
    },

    onTransitionEnd() {
      if (!this.visible) {
        this.textVisible = false
      }
    },

    _onTooltipPointData(data) {
      if (data !== this.data) this.hide()
    },

    _onSceneMouseDown() {
      this.hide()
    }
  }
}
</script>
