<template lang="pug">
  .content.content-doors
    template(v-if="items.length > 0")
      //kraus-container-select(:items="items", v-model="selectedStage")
      .stage-items-wrapper(v-for="(data, index) in items", :key="index")
        .stage-selector(:class="{opened: data.opened}", @click="onArrowClick(data)")
          .label {{data.label}}
          .arrow
        .doors-list(v-if="data.opened")
          .door-item(v-for="(item, index) in data.items", :key="index", @click="onDoorItemClick(item)")
            .door-item-icon(:style="{backgroundImage: 'url(' + item.icon + ')'}")
            .door-item-content
              .door-item-title {{item.title}}
              .door-item-info
                .door-item-info-options {{item.options}}
                .door-item-info-cost(v-if="item.cost > 0") {{item.cost | currency }}
    template(v-else)
      .content-header
        .content-option-name Нет добавленных дверей
</template>
<script>
import KrausContainerSelect
  from '@/components/_common/ui/KrausContainerSelect';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import { mapGetters } from 'vuex';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
export default {
  name: 'Doors',
  components: { KrausContainerSelect },
  data() {
    return {
      selectedStage: null,
      items: [
        // {
        //   stage: 1,
        //   containerId: 1,
        //   label: 'Этаж 1 / Контейнер 1',
        //   items: [
        //     {
        //       icon: '/pics/right-menu/window.svg',
        //       title: 'Окошечко',
        //       options: '100x100, поворотное',
        //       cost: '10000'
        //     },
        //   ]
        // },
      ]
    }
  },

  mounted() {
    this.updateItems()
  },

  computed: {
    ...mapGetters([
      'stage',
      'stagesData',
      'doorsCost'
    ]),
  },

  watch: {
    doorsCost: {
      deep: true,
      handler: function () {
        this.updateItems()
      }
    }
  },

  methods: {
    onArrowClick(data) {
      data.opened = !data.opened
    },

    updateItems() {
      const list = []

      const getStageData = (stage) => {
        const filtered = list.filter(item => item.stage === stage)
        let data = null

        if (filtered.length > 0) {
          data = filtered[0]
        } else {
          data = {
            label: 'Этаж ' + stage,
            opened: stage === this.stage,
            stage: stage,
            items: []
          }

          list.push(data)
        }

        return data
      }

      if (this.doorsCost.items) {
        this.doorsCost.items.forEach(doorItem => {
          const data = getStageData(parseInt(doorItem.stage))
          data.items.push({
            icon: doorItem.dictionaryData.menuIcon,
            title: 'Дверь ' + doorItem.id,
            options: doorItem.dictionaryData.title,
            cost: doorItem.price,
            id: doorItem.id,
            stage: doorItem.stage
          })
        })
      }

      this.items = list
    },

    onDoorItemClick(doorItem) {
      eventBus.$emit(EventBusEvents.SelectItemInEditor, {
        type: BaseEditorElement.ELEMENT_TYPE_WALL_ITEM,
        id: doorItem.id,
        stage: doorItem.stage
      })
    }
  }
}
</script>
