<template lang="pug">
  .editor-left-menu(:style="{left: visible ? '0px' : '-1000px'}")
    .menu-items(ref="menuElement", :style="style")
      menu-item(v-for="(item, index) in items" :key="index", :item="item")
    section-menu
</template>
<script>
import MenuItem from '@/components/BlocksEditor/LeftMenu/MenuItem';
import SectionMenu from '@/components/BlocksEditor/LeftMenu/SectionMenu';
import EquipmentCategories from '@/common/EquipmentCategories';
import LeftMenuData from '@/components/BlocksEditor/LeftMenu/leftMenuData';
import MapUtil from '@/utils/MapUtil';
import { mapGetters } from 'vuex';
export default {
  name: 'LeftMenu',
  components: { SectionMenu, MenuItem },
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      items: [
        {
          id: 'box',
          title: 'Конструкции',
          data: {
            type: 'menu-item',
          }
        },
        {
          id: 'window',
          title: 'Окна',
          data: {
            type: 'menu-item',
          }
        },
        {
          id: 'door',
          title: 'Двери',
          data: {
            type: 'menu-item',
          }
        },
        {
          id: 'bathroom',
          title: 'Сантехника',
          data: {
            type: 'menu-item',
          }
        },
        {
          id: 'stairs',
          title: 'Второй этаж',
          data: {
            type: 'menu-item',
          }
        },
        {
          id: 'additional',
          title: 'Доп. элементы',
          data: {
            type: 'menu-item',
          }
        },
        {
          id: 'decoration',
          title: 'Отделка',
          data: {
            type: 'menu-item',
          }
        },
      ],
      editorBoundingRect: new DOMRect(),
      menuBoundingRect: new DOMRect()
    }
  },

  created() {
    this.createLeftMenuData()
  },

  mounted() {
    window.addEventListener('resize', this.onWindowResize)
    this.updateComponent()
  },

  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },

  watch: {
    containerType() {
      this.updateLeftMenuBoxItems()
    }
  },

  computed: {
    ...mapGetters([
      'containerType'
    ]),
    style () {
      return {
        top: (this.editorBoundingRect.height - this.menuBoundingRect.height ) / 2 + 'px',
        left: 20 + 'px'
      }
    }
  },

  methods: {
    onWindowResize() {
      this.updateComponent()
    },

    updateComponent() {
      const element = document.querySelector('.blocks-editor')??document.querySelector('.blocks-renderer')
      this.$nextTick(() => {
        this.editorBoundingRect = element.getBoundingClientRect()
        this.menuBoundingRect = this.$refs.menuElement.getBoundingClientRect()
      })
    },

    updateLeftMenuBoxItems() {
      if (this.containerType) {
        LeftMenuData.box.items = [
          {
            id: 1,
            type: 'box-container',
            title: 'Блок-контейнер',
            menuIcon: require('@/assets/images/left-menu/items/contructions/box.svg'),
            data: {
              cost: this.containerType.cost
            }
          },
          {
            id: 2,
            type: 'box-wall',
            title: 'Перегородка',
            menuIcon: require('@/assets/images/left-menu/items/contructions/inside-wall.svg'),
            data: {
              cost: null
            }
          }
        ]
      }
    },

    createLeftMenuData() {

      this.updateLeftMenuBoxItems()

      LeftMenuData.window.items = []

      const woodWindows = EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'derevyannye'
      })
      if (woodWindows?.options) {
        woodWindows.options.forEach(item => {
          LeftMenuData.window.items.push(MapUtil.windowWood(item))
        })
      }

      const plasticWindows = EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'plastikovye'
      })
      if (plasticWindows.options) {
        plasticWindows.options.forEach(item => {
          LeftMenuData.window.items.push(MapUtil.windowPlastic(item))
        })
      }

      LeftMenuData.door.items = []
      const otherDoorsKeys = ['vorota', 'rolstavni', 'luk']
      EquipmentCategories.getCategory('dveri_vneshnie')
        .options.forEach(item => {
          if (item.key !== 'prochee' && otherDoorsKeys.indexOf(item.key) < 0) {
            LeftMenuData.door.items.push(MapUtil.doorExternal(item))
          }
        })

      EquipmentCategories.getCategory('dveri_mezhkomnatnye')
        .options.forEach(item => {
          if (item.key !== 'prochee') {
            LeftMenuData.door.items.push(MapUtil.doorInternal(item))
          }
        })

      EquipmentCategories.getCategory('dveri_vneshnie')
        .options.forEach(item => {
        if (item.key !== 'prochee' && otherDoorsKeys.indexOf(item.key) >= 0) {
          LeftMenuData.door.items.push(MapUtil.doorOther(item))
        }
      })

      LeftMenuData.bathroom.items = []

      EquipmentCategories.getItems({
        categoryKey: 'santehnika',
        optionKey: 'rakoviny'
      }).options.forEach(item => {
        LeftMenuData.bathroom.items.push(MapUtil.washStand(item))
      })

      const toilet = EquipmentCategories.getItems({
        categoryKey: 'santehnika',
        optionKey: 'tualet'
      })
      LeftMenuData.bathroom.items.push(MapUtil.toilet(toilet))

      const shower = EquipmentCategories.getItems({
        categoryKey: 'santehnika',
        optionKey: 'dush'
      })
      LeftMenuData.bathroom.items.push(MapUtil.shower(shower))

      const boiler = EquipmentCategories.getItems({
        categoryKey: 'santehnika',
        optionKey: 'boyler'
      })
      LeftMenuData.bathroom.items.push(MapUtil.additionalItem(boiler))

      const handDryer = EquipmentCategories.getItems({
        categoryKey: 'santehnika',
        optionKey: 'sushilka_dlya_ruk'
      })
      LeftMenuData.bathroom.items.push(MapUtil.additionalItem(handDryer))


      LeftMenuData.stairs.items = []

      EquipmentCategories.getItems({
        categoryKey: 'dop_elementy',
        optionKey: 'lestnicy'
      }).options.forEach(item => {
        if (item.key === 'lestnica_vnutrennyaya') {
          LeftMenuData.stairs.items.push(MapUtil.internalStair(item))
        } else {
          LeftMenuData.stairs.items.push(MapUtil.externalStair(item))
          LeftMenuData.stairs.items.push(MapUtil.externalStair(item, true))
        }
      })

      // const stairPlatform = EquipmentCategories.getItems({
      //   categoryKey: 'dop_elementy',
      //   optionKey: 'ploschadka_2_y_etazh'
      // })
      // LeftMenuData.stairs.items.push(MapUtil.stairPlatform(stairPlatform))

      LeftMenuData.additional.items = []

      const additionalItemsIgnoreKeys = ['lestnicy', 'ploschadka_2_y_etazh', 'boyler', 'sushilka_dlya_ruk']

      EquipmentCategories.getCategory('dop_elementy').options.forEach(item => {
        if (additionalItemsIgnoreKeys.indexOf(item.key) < 0) {
          LeftMenuData.additional.items.push(MapUtil.additionalItem(item))
        }
      })
    }
  }
}
</script>
