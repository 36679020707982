import { Shape } from '@createjs/easeljs';
import WallItemView from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem/WallItemView';
import { DoorHandle } from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';

class DoorView extends WallItemView {

  /**
   *
   * @type {easeljs.Shape}
   * @private
   */
  _doorShape

  constructor(wallItem) {
    super(wallItem)

    this._doorShape = new Shape()
    this.container.addChild(this._doorShape)

    this.draw()
  }

  get isGate() {
    return this.wallItem.gate
  }

  draw() {
    super.draw()

    const [x, y] = [-this.wallItem._width / 2, -this.wallItem._height / 2]
    if (this.isGate) {
      if (this.wallItem.dictionaryData.id === 'vorota') {
        this._doorShape.graphics
          .clear()
          .setStrokeStyle(6)
          .beginStroke('#ffffff')
          .moveTo(x, y + this.wallItem._height / 2)
          .lineTo(x + this.wallItem._width, y + this.wallItem._height / 2)

          .setStrokeStyle(4)
          .beginStroke('#0558AB')
          .moveTo(x,y)
          .lineTo(x, y + this.wallItem._height)

          .moveTo(x + this.wallItem._width,y)
          .lineTo(x +this.wallItem._width, y + this.wallItem._height)

          .moveTo(x, y + this.wallItem._height / 2)
          .lineTo(x + this.wallItem._width / 2.1, y + this.wallItem._height + this.wallItem._height)

          .moveTo(x + this.wallItem._width, y + this.wallItem._height / 2)
          .lineTo(x + this.wallItem._width - this.wallItem._width / 2.1, y + this.wallItem._height + this.wallItem._height)
      } else if (this.wallItem.dictionaryData.id === 'rolstavni') {
        const yDelta = 4

        this._doorShape.graphics
          .clear()
          .setStrokeStyle(6)
          .beginStroke('#ffffff')
          .moveTo(x, y + this.wallItem._height / 2 + yDelta)
          .lineTo(x + this.wallItem._width, y + this.wallItem._height / 2 + yDelta)

          .setStrokeStyle(4)
          .beginStroke('#0558AB')
          .moveTo(x, y - 2 + yDelta)
          .lineTo(x, y + this.wallItem._height + 2 + yDelta)

          .moveTo(x + this.wallItem._width, y - 2 + yDelta)
          .lineTo(x + this.wallItem._width, y + this.wallItem._height + 2 + yDelta)
          .endStroke()

        this._doorShape.graphics
          .setStrokeStyle(5)
          .setStrokeDash([])
          .beginStroke('#ffffff')
          .moveTo(x, y + this.wallItem._height / 2 - 4 + yDelta)
          .lineTo(x + this.wallItem._width, y + this.wallItem._height / 2 - 4 + yDelta)
          .endStroke()

        this._doorShape.graphics
          .setStrokeStyle(4)
          .setStrokeDash([4, 4])
          .beginStroke('#0558AB')
          .moveTo(x, y + this.wallItem._height / 2 - 4 + yDelta)
          .lineTo(x + this.wallItem._width, y + this.wallItem._height / 2 - 4 + yDelta)
          .endStroke()

        this._doorShape.graphics
          .setStrokeDash([])
          .setStrokeStyle(4)
          .beginStroke('#0558AB')
          .moveTo(x, y + this.wallItem._height / 2 + 4 + yDelta)
          .lineTo(x + 6, y + this.wallItem._height / 2 + 4 + yDelta)
          .endStroke()

        this._doorShape.graphics
          .setStrokeDash([])
          .setStrokeStyle(4)
          .beginStroke('#0558AB')
          .moveTo(x + this.wallItem._width, y + this.wallItem._height / 2 + 4 + yDelta)
          .lineTo(x + this.wallItem._width - 6, y + this.wallItem._height / 2 + 4 + yDelta)
          .endStroke()

      } else {
        this._doorShape.graphics
          .clear()
          .setStrokeStyle(6)
          .beginStroke('#aaaaaa')
          .moveTo(x, y + this.wallItem._height / 2)
          .lineTo(x + this.wallItem._width, y + this.wallItem._height / 2)

          .setStrokeStyle(4)
          .beginStroke('#0558AB')
          .moveTo(x, y)
          .lineTo(x, y + this.wallItem._height)

          .moveTo(x + this.wallItem._width, y)
          .lineTo(x + this.wallItem._width, y + this.wallItem._height)

          .setStrokeStyle(4)
          .moveTo(x + this.wallItem._width * 0.2, y + 2)
          .lineTo(x + this.wallItem._width * 0.2 + 8, y + 2)
      }
    } else {
      this._doorShape.graphics
        .clear()
        .setStrokeStyle(6)
        .beginStroke('#ffffff')
        .moveTo(x, y + this.wallItem._height / 2)
        .lineTo(x + this.wallItem._width, y + this.wallItem._height / 2)

        .setStrokeStyle(4)
        .beginStroke('#0558AB')
        .moveTo(x,y)
        .lineTo(x, y + this.wallItem._height)

        .moveTo(x + this.wallItem._width,y)
        .lineTo(x +this.wallItem._width, y + this.wallItem._height)

      if (this.wallItem.handle === DoorHandle.Right) {
        this._doorShape.graphics
          .moveTo(x, y + this.wallItem._height / 2)
          .lineTo(x + this.wallItem._width - this.wallItem._width / 5, y + this.wallItem._height + this.wallItem._height)
      } else {
        this._doorShape.graphics
          .moveTo(x + this.wallItem._width,y + this.wallItem._height / 2)
          .lineTo(x + this.wallItem._width / 5, y + this.wallItem._height + this.wallItem._height)
      }
    }
  }
}

export default DoorView
