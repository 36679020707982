import { Mesh, SceneLoader, TransformNode } from '@babylonjs/core';

class ModelCacheItem {
  /**
   * @type {string}
   */
  key

  /**
   * @type {Mesh}
   */
  mesh

  constructor({key, mesh}) {
    this.key = key
    this.mesh = mesh
  }

  getMesh() {
    const newMesh = this.mesh.clone()
    return newMesh
  }
}

class ModelsCache {

  /**
   *
   * @type {ModelCacheItem[]}
   * @private
   */
  _cachedMeshes = []

  /**
   * @type {Scene}
   * @private
   */
  _scene

  constructor({scene}) {
    this._scene = scene
  }

  loadModel(modelUrl) {
    return new Promise((resolve) => {
      const key = modelUrl.path + modelUrl.fileName
      const cacheItem = this.getFromCache(key)

      if (cacheItem) {
        resolve(cacheItem.getMesh())
      } else {

        SceneLoader.LoadAssetContainer(modelUrl.path, modelUrl.fileName, this._scene, (container) => {
          const cacheItem = this.getFromCache(key)

          if (cacheItem) {
            container.dispose()
            resolve(cacheItem.getMesh())
          } else {
            const rootMesh = new TransformNode('cache_root', this._scene)
            container.addAllToScene()

            container.meshes.forEach(meshItem => {
              if (!meshItem.parent) meshItem.parent = rootMesh
            })

            if (modelUrl.path.indexOf('door') < 0) {

              rootMesh.getChildMeshes(mesh => {
                if (mesh.material) mesh.material.freeze()
              })

              this._cachedMeshes.push(new ModelCacheItem({
                key,
                mesh: rootMesh
              }))
            }

            resolve(rootMesh)
          }
        })
      }
    })
  }

  /**
   *
   * @param key {string}
   * @return {ModelCacheItem|null}
   */
  getFromCache(key) {
    const list = this._cachedMeshes.filter(item => item.key === key)
    return list.length > 0 ? list[0] : null
  }
}

export default ModelsCache
