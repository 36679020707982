<template lang="pug">
  .history-buttons
    kraus-button(:icon="iconUndo", :disabled="undoButtonDisabled", @click="onUndoClick")
    .divider
    kraus-button(:icon="iconRedo", :disabled="redoButtonDisabled", @click="onRedoClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { mapActions, mapGetters } from 'vuex';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
export default {
  name: 'HistoryButtons',
  components: { KrausButton },
  data() {
    return {
      iconUndo: require('@/assets/images/editor/history-buttons/icon-undo.svg'),
      iconRedo: require('@/assets/images/editor/history-buttons/icon-redo.svg'),
      history: [],
      index: 0,
      blockTimeout: null,
      blocked: false,
      backButtonClicked: false
    }
  },

  mounted() {
    this.history = Array.from(this.historyList)
    this.index = this.historyIndex ? this.historyIndex : this.history.length - 1
  },

  destroyed() {
    this.setHistory({
      data: this.history,
      index: this.index
    })
  },

  computed: {
    ...mapGetters([
      'historyList',
      'historyIndex'
    ]),

    redoButtonDisabled() {
      return this.blocked || this.index === this.history.length - 1
    },
    undoButtonDisabled() {
      return this.blocked || this.index === 0
    }
  },

  methods: {
    ...mapActions([
      'setHistory'
    ]),

    /**
     * @param historyList {[]}
     * @param index {number}
     */
    replaceHistory({historyList, index}) {
      this.history = historyList
      this.index = index

      this.setHistory({
        data: historyList,
        index: index
      })
    },

    addHistory(data) {
      if (!this.backButtonClicked) {
        this.history.splice(this.index + 1)

        const lastData = this.history.length > 0 ? this.history[this.history.length - 1] : null
        let canBeAdded = true

        if (lastData && JSON.stringify(lastData) === JSON.stringify(data)) canBeAdded = false

        if (canBeAdded) {
          this.history.push(data)
          this.index = this.history.length - 1

          this.setHistory({
            data: this.history,
            index: this.index
          })
        }
      }

      this.backButtonClicked = false
    },

    onUndoClick() {
      this.backButtonClicked = true
      this.moveIndex(-1)
    },

    onRedoClick () {
      this.backButtonClicked = true
      this.moveIndex(1)
    },

    moveIndex(delta) {
      if (!this.blocked) {
        this.index += delta

        if (this.index < 0) {
          this.index = 0
        } else if (this.index >= this.history.length) {
          this.index = this.history.length - 1
        }

        const data = this.history[this.index]

        this.$emit('restore', data)

        this.blocked = true
        setTimeout(() => {
          this.blocked = false
        }, 100)
      }
    }
  }
}
</script>
