import {
  Color3, CSG,
  Mesh, MeshBuilder,
  PBRMetallicRoughnessMaterial,
  TransformNode,
  Vector3
} from '@babylonjs/core';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';
import EquipmentCategories from '@/common/EquipmentCategories';

class AbstractRoofMesh {
  static Types = {
    single: 'single',
    double: 'double',
    flat: 'flat'
  }

  type
  scene
  stage

  /**
   * @type {{tl: null, br: null}}
   */
  edgeConnectors

  /**
   * @type {Mesh}
   */
  rootMesh

  _width = null
  _height = null

  _ledge = 20
  _angleLedge = 25

  options

  /**
   * @type {PBRMetallicRoughnessMaterial}
   */
  roofMaterial

  constructor({type, edgeConnectors, scene, stage, options}) {
    this.stage = stage
    this.type = type
    this.scene = scene
    this.options = options
    this.edgeConnectors = edgeConnectors

    this.createMaterial()

    this.rootMesh = new Mesh('Root roof mesh', this.scene)
  }

  setVisibility(visible) {
    this.rootMesh.scaling = visible ? Vector3.One() : Vector3.Zero()
  }

  setTransparency(transparent) {
    this.roofMaterial.alpha = transparent ? 0.2 : 1
  }

  setOptions(options) {
    this.options = options

    this.createMaterial()
  }

  createMaterial() {
    if (!this.roofMaterial) this.roofMaterial = new PBRMetallicRoughnessMaterial('roof material', this.scene)

    if (this.options.color.data.hex) {
      this.roofMaterial.baseColor = Color3.FromHexString(this.options.color.data.hex)
    } else {
      this.roofMaterial.baseColor = Color3.White()
    }

    if (this.options.color.data.key === 'white') {
      this.roofMaterial.emissiveColor = Color3.FromHexString(this.options.color.data.hex)
    } else {
      this.roofMaterial.emissiveColor = Color3.Black()
    }

    this.roofMaterial.metallic = this.options.color.metallic
    this.roofMaterial.roughness = this.options.color.roughness
  }

  get vertical() {
    return this.height > this.width
  }

  get width() {
    if (!this._width) {
      this._width = this.edgeConnectors.br.x - this.edgeConnectors.tl.x
    }
    return this._width
  }

  get height() {
    if (this._height === null) this._height = this.edgeConnectors.br.y - this.edgeConnectors.tl.y
    return this._height
  }

  get center() {
    return new Vector3(
      -(this.edgeConnectors.tl.x + this.width / 2) * BlocksConstructor.Scale,
      (this.stage * BlocksConstructor.StageHeight) * BlocksConstructor.Scale,
      (this.edgeConnectors.tl.y + this.height / 2) * BlocksConstructor.Scale,
    )
  }

  createMesh() {}

  /**
   * @param mesh
   * @param width
   * @param depth
   * @return {Mesh}
   */
  createEdges(mesh, width, depth) {
    const parentMesh = new TransformNode('edges_root', this.scene)

    const edgeMeshes = []
    const edgeDistance = EquipmentCategories.containerType === 'obreshotka' ? 10 : 15

    const createByWidth = width > depth
    const length = createByWidth ? width : depth

    for (let i = -length; i < length; i += edgeDistance * 2 * BlocksConstructor.Scale) {
      const edgeBox = MeshBuilder.CreateBox('edge_box', {
        width: edgeDistance * BlocksConstructor.Scale,
        height: (BlocksConstructor.StageHeight) * BlocksConstructor.Scale * 10,
        depth: 3 * BlocksConstructor.Scale
      }, this._scene)

      edgeBox.parent = parentMesh
      edgeBox.position.z = 2 * BlocksConstructor.Scale
      edgeBox.position.x = (i + edgeDistance * 2 * BlocksConstructor.Scale)
      edgeBox.position.y = 0

      edgeMeshes.push(edgeBox)
    }

    const edgesMesh = Mesh.MergeMeshes(edgeMeshes, true)

    const csgBox = CSG.FromMesh(mesh)
    const csgEdges = CSG.FromMesh(edgesMesh)

    let newMesh = csgBox.subtract(csgEdges).toMesh(mesh.name + '_csg', this.roofMaterial, this.scene)

    mesh.dispose()
    edgesMesh.dispose()

    return newMesh
  }

  dispose() {

  }
}

export default AbstractRoofMesh
