import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import { Container, Shape } from '@createjs/easeljs';
import FastVector from 'fast-vector';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';

export default class ResizeWallActionsView extends BaseEditorElement {
  /**
   * @type {Wall}
   * @private
   */
  _wall

  /**
   * @type {easeljs.Container}
   * @private
   */
  _resizeItemsContainer

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _leftResizeItem
  /**
   * @type {easeljs.Shape}
   * @private
   */
  _rightResizeItem

  constructor(wall) {
    super()
    this._wall = wall

    this._createResizeItems()

    Grid.Instance.actionsContainer.addChild(this)
  }

  _createResizeItems() {
    this._resizeItemsContainer = new Container()
    this.container.addChild(this._resizeItemsContainer)

    this._leftResizeItem = new Shape()
    this._rightResizeItem = new Shape()
    this._resizeItemsContainer.addChild(this._leftResizeItem)
    this._resizeItemsContainer.addChild(this._rightResizeItem)

    this._leftResizeItem.addEventListener('pressmove', this._onResizePressMove)
    this._rightResizeItem.addEventListener('pressmove', this._onResizePressMove)

    this._leftResizeItem.addEventListener('pressup', this._onResizePressUp)
    this._rightResizeItem.addEventListener('pressup', this._onResizePressUp)

    this._leftResizeItem.graphics
      .setStrokeStyle(2)
      .beginStroke('#F47090')
      .beginFill('#ffffff')
      .drawRect(-4, -4, 8, 8)
      .endFill()

    this._rightResizeItem.graphics
      .setStrokeStyle(2)
      .beginStroke('#F47090')
      .beginFill('#ffffff')
      .drawRect(-4, -4, 8, 8)
      .endFill()
  }

  _onResizePressMove = (e) => {
    const mouseVector = new FastVector(
      Math.round(e.nativeEvent.movementX * BaseEditorElement.PixelRatio / Grid.Instance.container.scale),
      Math.round(e.nativeEvent.movementY * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)
    )

    while (true) {
      const c1 = {
        x: this._wall.firstConnector.x,
        y: this._wall.firstConnector.y
      }

      const c2 = {
        x: this._wall.lastConnector.x,
        y: this._wall.lastConnector.y
      }

      if (e.target === this._leftResizeItem) {
        if (!this._wall.vertical) {
          c1.x += mouseVector.x
        } else {
          c1.y += mouseVector.y
        }
      } else {
        if (!this._wall.vertical) {
          c2.x += mouseVector.x
        } else {
          c2.y += mouseVector.y
        }
      }

      const length = this._wall.vertical ? c2.y - c1.y : c2.x - c1.x
      const movementAvailable = this._wall.someMovementsAvailable([c1, c2])

      if (movementAvailable && length >= 10) {
        this._wall.firstConnector.x = c1.x
        this._wall.firstConnector.y = c1.y

        this._wall.lastConnector.x = c2.x
        this._wall.lastConnector.y = c2.y
        this._wall.draw()

        Pillar.UpdatePillars()
      }

      if (mouseVector.x !== 0) {
        mouseVector.x += mouseVector.x > 0 ? -1 : 1
      }

      if (mouseVector.x === 0 && mouseVector.y !== 0) {
        mouseVector.y += mouseVector.y > 0 ? -1 : 1
      }

      if (length < 10 || movementAvailable || mouseVector.y === 0) break
    }

    this.emitChangeByTimeout()
  }

  _onResizePressUp = (e) => {
    //
  }

  draw() {
    super.draw()

    const [connector1, connector2] = [this._wall.firstConnector, this._wall.lastConnector]

    if (this._resizeItemsContainer) {
      this._resizeItemsContainer.scale = this._wall.isSelected() ? 1 : 0
      if (!this._wall.vertical) {
        this._leftResizeItem.x = connector1.x - 14
        this._leftResizeItem.y = connector1.y

        this._rightResizeItem.x = connector2.x + 14
        this._rightResizeItem.y = connector2.y
      } else {
        this._leftResizeItem.x = connector1.x
        this._leftResizeItem.y = connector1.y - 14

        this._rightResizeItem.x = connector2.x
        this._rightResizeItem.y = connector2.y + 14
      }
    }
  }

  destroy() {

    this._leftResizeItem.removeEventListener('pressmove', this._onResizePressMove)
    this._rightResizeItem.removeEventListener('pressmove', this._onResizePressMove)

    this._leftResizeItem.removeEventListener('pressup', this._onResizePressUp)
    this._rightResizeItem.removeEventListener('pressup', this._onResizePressUp)

    Grid.Instance.actionsContainer.removeChild(this)
    super.destroy();
  }
}
