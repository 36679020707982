<template lang="pug">
  .popup-content-wrapper.select-container-type
    .popup-header Выберите тип контейнера
    .popup-content
      .description Перед началом  оформления заявки необходимо выбрать тип базового контейнера. Внимание! Изменить тип контейнера после создания проекта нельзя!
      container-type-item(v-for="(data, index) in types", :key="index", :data="data")
</template>
<script>
import ContainerTypeItem from '@/components/_common/ui/Popup/SelectContainerType/ContainerTypeItem';
import EquipmentCategories from '@/common/EquipmentCategories';
export default {
  name: 'SelectContainerType',
  components: { ContainerTypeItem },
  data() {
    return {
      types: [
        // {
        //   icon: require('@/assets/images/ui/popup/select-container-type/type-1.svg'),
        //   title: 'Стандарт',
        //   size: '5,85м × 2,45м × 2,45м',
        //   description: 'Стены с деревянной обрешёткой',
        //   cost: 89000
        // },
        //
        // {
        //   icon: require('@/assets/images/ui/popup/select-container-type/type-1.svg'),
        //   title: 'Улучшенный',
        //   size: '6м × 2,45м × 2,45м',
        //   description: 'Стены из сэндвич-панелей',
        //   cost: 2125000
        // }
      ]
    }
  },

  created() {
    this.updateItems()
  },

  methods: {
    updateItems() {
      this.types = []

      const simpleOptions = EquipmentCategories.getItems({
        categoryKey: 'container',
        optionKey: 'obreshotochniy',
        containerType: 'obreshotka'
      }).options
      const item1 = simpleOptions.filter(item => item.min >= 500 && item.max <= 585)[0]

      const improvedOptions = EquipmentCategories.getItems({
        categoryKey: 'container',
        optionKey: 'sandwich',
        containerType: 'sandwich'
      }).options
      const item2 = improvedOptions.filter(item => item.min >= 491 && item.max <= 600)[0]

      this.types.push({
        key: 'obreshotka',
        icon: require('@/assets/images/ui/popup/select-container-type/type-1.svg'),
        title: 'Стандарт',
        size: '5,85м × 2,45м × 2,45м',
        description: 'Стены с деревянной обрешёткой',
        cost: item1.price,
        raw: item1,
        improved: false,
        options: simpleOptions
      })

      this.types.push({
        key: 'sandwich',
        icon: require('@/assets/images/ui/popup/select-container-type/type-2.svg'),
        title: 'Улучшенный',
        size: '6м × 2,45м × 2,45м',
        description: 'Стены из сэндвич-панелей',
        cost: item2.price,
        raw: item2,
        improved: true,
        options: improvedOptions
      })
    }
  }
}
</script>
