import EquipmentCategories from '@/common/EquipmentCategories';
import Vue from 'vue';

const equipmentModule = {
  state: () => ({
    containerType: null,
    cornerPosts: null,
    carcassColor: null,
    carcassStrengthening: null,
    wallMaterial: null,
    wallColor: null,
    insideWallMaterial: null,
    insideWallDifferentMaterial: null,
    partitionWallMaterial: null,

    roofType: null,
    roofMaterial: null,
    roofColor: null,
    roofMetalTrusses: null,

    bottomFloor: null,
    additionalFloor: null,
    additionalLags: null,
    metalLags: null,
    metalLagsCount: 0,
    mainFloor: null,
    additionalMainFloor: null,
    floorCoating: null,
    differentFloorCoating: null,

    warming: null,

    waterproofing: null,

    electricPacket: null,
    electricSockets: null,
    electricLights: null,

    mounting: null,
  }),

  mutations: {
    setContainerType(state, value) {
      state.containerType = value
      state.cornerPosts = null
      state.carcassColor = null
      state.carcassStrengthening = null
      state.wallMaterial = null
      state.wallColor = null
      state.insideWallMaterial = null
      state.insideWallDifferentMaterial = null
      state.partitionWallMaterial = null

      state.roofType = null
      state.roofMaterial = null
      state.roofColor = null
      state.roofMetalTrusses = null

      state.bottomFloor = null
      state.additionalFloor = null
      state.additionalLags = null
      state.metalLags = null
      state.metalLagsCount = 0
      state.mainFloor = null
      state.additionalMainFloor = null
      state.floorCoating = null
      state.differentFloorCoating = null

      state.warming = null

      state.waterproofing = null

      state.electricPacket = null
      state.electricSockets = null
      state.electricLights = null
      state.mounting = null
    },

    setMounting(state, value) {
      state.mounting = value
    },

    setElectricLights(state, value) {
      state.electricLights = value
    },

    setElectricSockets(state, value) {
      state.electricSockets = value
    },

    setElectricPacket(state, value) {
      state.electricPacket = value
    },

    setWaterproofing(state, value) {
      state.waterproofing = value
    },

    setWarming(state, value) {
      let lathing = 100
      const splitted = value.thickness.raw.title.split('_')
      let thickness = !parseInt(splitted[1])? parseInt(splitted[0]) : parseInt(splitted[1])
      lathing = thickness < 100 ? 100 : thickness

      Vue.set(value.thickness, 'lathing', lathing + ' x 40 мм')

      state.warming = value
    },

    setFloorCoating(state, value) {
      state.floorCoating = value
    },

    setDifferentFloorCoating(state, value) {
      state.differentFloorCoating = value
    },

    setAdditionalMainFloor(state, value) {
      state.additionalMainFloor = value
    },

    setBottomFloor(state, value) {
      state.bottomFloor = value
    },

    setMainFloor(state, value) {
      state.mainFloor = value
    },

    setMetalLags(state, value) {
      state.metalLags = value
    },

    setMetalLagsCount(state, value) {
      state.metalLagsCount = value
    },

    setAdditionalLags(state, value) {
      state.additionalLags = value
    },

    setAdditionalFloor(state, value) {
      state.additionalFloor = value
    },

    setInsideWallDifferentMaterial(state, value) {
      state.insideWallDifferentMaterial = value
    },

    setInsideWallMaterial(state, value) {
      state.insideWallMaterial = value
    },

    setPartitionWallMaterial(state, value) {
      state.partitionWallMaterial = value
    },

    setRoofMetalTrusses(state, value) {
      state.roofMetalTrusses = value
    },

    setRoofColor(state, value) {
      state.roofColor = value
    },

    setRoofType(state, value) {
      state.roofType = value
    },

    setRoofMaterial(state, value) {
      state.roofMaterial = value
    },

    setCornerPosts(state, value) {
      state.cornerPosts = value
    },

    setCarcassColor(state, value) {
      state.carcassColor = value
    },

    setCarcassStrengthening(state, value) {
      state.carcassStrengthening = value
    },

    setWallMaterial(state, value) {
      state.wallMaterial = value
    },

    setWallColor(state, value) {
      state.wallColor = value
    },

    restoreEquipmentStore(state, value) {
      for (const key in value) {
        state[key] = value[key]
      }

    }
  },
  actions: {
    restoreEquipmentStore({commit}, value) {
      commit('restoreEquipmentStore', value)
    },

    setContainerType({commit}, value) {
      commit('setContainerType', value)
    },

    setMounting({commit}, data) {
      commit('setMounting', data)
    },

    setElectricLights({commit}, value) {
      commit('setElectricLights', value)
    },

    setElectricSockets({commit}, value) {
      commit('setElectricSockets', value)
    },

    setElectricPacket({commit}, value) {
      commit('setElectricPacket', value)
    },

    setWaterproofing({commit}, value) {
      commit('setWaterproofing', value)
    },

    setWarming({commit}, value) {
      commit('setWarming', value)
    },

    setFloorCoating({commit}, value) {
      commit('setFloorCoating', value)
    },

    setDifferentFloorCoating({commit}, value) {
      commit('setDifferentFloorCoating', value)
    },

    setAdditionalMainFloor({commit}, value) {
      commit('setAdditionalMainFloor', value)
    },

    setBottomFloor({commit}, value) {
      commit('setBottomFloor', value)
    },

    setMainFloor({commit}, value) {
      commit('setMainFloor', value)
    },

    setMetalLags({commit}, value) {
      commit('setMetalLags', value)
    },

    setMetalLagsCount({commit}, value) {
      commit('setMetalLagsCount', parseFloat(value))
    },

    setAdditionalLags({commit}, value) {
      commit('setAdditionalLags', value)
    },

    setAdditionalFloor({commit}, value) {
      commit('setAdditionalFloor', value)
    },

    setInsideWallDifferentMaterial({commit}, value) {
      commit('setInsideWallDifferentMaterial', value)
    },

    setInsideWallMaterial({commit}, value) {
      commit('setInsideWallMaterial', value)
    },

    setPartitionWallMaterial({commit}, value) {
      commit('setPartitionWallMaterial', value)
    },

    setRoofMetalTrusses({commit}, value) {
      commit('setRoofMetalTrusses', value)
    },

    setRoofColor({commit}, value) {
      commit('setRoofColor', value)
    },

    setRoofMaterial({commit}, value) {
      commit('setRoofMaterial', value)
    },

    setRoofType({commit}, value) {
      commit('setRoofType', value)
    },

    setCornerPosts({commit}, value) {
      commit('setCornerPosts', value)
    },

    setCarcassColor({commit}, value) {
      commit('setCarcassColor', value)
    },

    setCarcassStrengthening({commit}, value) {
      commit('setCarcassStrengthening', value)
    },

    setWallMaterial({commit}, value) {
      commit('setWallMaterial', value)
    },

    setWallColor({commit}, value) {
      commit('setWallColor', value)
    },
  },
  getters: {
    partitionWallMaterial: state => state.partitionWallMaterial,
    containerType: state => state.containerType,
    cornerPosts: state => state.cornerPosts,
    carcassColor: state => state.carcassColor,
    carcassStrengthening: state => state.carcassStrengthening,
    wallMaterial: state => state.wallMaterial,
    wallColor: state => state.wallColor,
    insideWallMaterial: state => state.insideWallMaterial,
    insideWallDifferentMaterial: state => state.insideWallDifferentMaterial,

    roofType: state => state.roofType,
    roofColor: state => state.roofColor,
    roofMaterial: state => state.roofMaterial,
    roofMetalTrusses: state => state.roofMetalTrusses,

    bottomFloor: state => state.bottomFloor,
    additionalFloor: state => state.additionalFloor,
    additionalLags: state => state.additionalLags,
    metalLags: state => state.metalLags,
    metalLagsCount: state => state.metalLagsCount,
    mainFloor: state => state.mainFloor,
    additionalMainFloor: state => state.additionalMainFloor,
    floorCoating: state => state.floorCoating,
    differentFloorCoating: state => state.differentFloorCoating,

    warming: state => state.warming,

    waterproofing: state => state.waterproofing,

    electricPacket: state => state.electricPacket,
    electricSockets: state => state.electricSockets,
    electricLights: state => state.electricLights,

    mounting: state => state.mounting
  }
}

export default equipmentModule
