import BoxItemView from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/BoxItemView';
import { Bitmap, Container } from '@createjs/easeljs';

class StairsView extends BoxItemView {

  /**
   * @type {Bitmap}
   * @private
   */
  _image

  /**
   * @param boxItem {BoxItem}
   */
  constructor(boxItem) {
    super(boxItem)

    this._image = new Bitmap(require('@/assets/images/editor/boxItems/stairs-1.png'))
    const imageContainer = new Container()
    imageContainer.addChild(this._image)

    this.container.addChild(imageContainer)

    imageContainer.scale = 0.25

    imageContainer.x = - (840 * imageContainer.scale) / 2
    imageContainer.y = - (1144 * imageContainer.scale) / 2
  }
}

export default StairsView
