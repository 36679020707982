<template lang="pug">
  div.building-equipment(:style="styles", :class="{visible: visible}")
    vue-custom-scrollbar
      container-type
      container-info
      container-items
</template>
<script>
import ContainerType from '@/components/BlocksEditor/BuildingEquipment/ContainerType';
import ContainerInfo from '@/components/BlocksEditor/BuildingEquipment/ContainerInfo';
import ContainerItems from '@/components/BlocksEditor/BuildingEquipment/ContainerItems';
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  name: "BuildingEquipment",
  components: { ContainerItems, ContainerInfo, ContainerType, vueCustomScrollbar },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
    }
  },

  computed: {
    styles () {
      return {
      }
    }
  },

  methods: {
  }
}
</script>
