<template lang="pug">
  .wash-stand-options
    .wash-stand-type
      .select-label Тип
      .select-wrapper
        kraus-equipment-select(v-model="type.value", :values="type.items")

    .info-item
      .info-item-icon
      .info-item-text В комплект уже входит смеситель
</template>
<script>
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';
import EquipmentCategories from '@/common/EquipmentCategories';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import MapUtil from '@/utils/MapUtil';
export default {
  name: 'BoilerOptions',
  components: { KrausEquipmentSelect },

  props: {
    item: null,
    json: null
  },

  data() {
    return {
      type: {
        value: null,
        items: []
      },
    }
  },

  created() {
    this.init()
  },

  watch: {
    'type.value': {
      handler: function(val) {
        if (this.item.dictionaryData.id !== val.id) {
          this.item.setDictionaryData(val)
        }
      }
    }
  },

  methods: {
    init() {
      this.type.items = []

      EquipmentCategories.getItems({
        categoryKey: 'santehnika',
        optionKey: 'rakoviny'
      }).options.forEach(item => {
        this.type.items.push(MapUtil.washStand(item))
      })

      this.type.items.forEach(item => {
        item.icon = {
          dataURL: item.menuIcon
        }
      })

      this.type.value = this.type.items.filter(item => item.id === this.item.dictionaryData.id)[0]
    }
  }
}
</script>
