import Vue from 'vue';
import Vuex from 'vuex';
import leftMenuModule from '@/store/leftMenu';
import equipmentModule from '@/store/equipment';
import costsModule from '@/store/costs';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    leftMenuModule,
    equipmentModule,
    costsModule
  },

  state: {
    screenshotsType: null,
    rendererTooltipsEnabled: true,
    production: false,
    email: null,
    restored: false,
    clearProject: false,
    customerContacts: {},
    delivery: {
      query: '',
      mapPoint: null,
      distance: 0,
      cost: 0
    },
    customerCraneUnloading: false,
    projectComment: null,
    requestId: null,
    projectId: null,
    editorType: '2D',
    jsonData: null,
    tooltipsShowedList: [],
    stagesData: {
      1: null,
      2: null,
      3: null
    },
    stageImages: {
      1: null,
      2: null,
      3: null
    },
    orderingStageImages: {
      1: null,
      2: null,
      3: null
    },
    images3d: [],
    historyList: [],
    historyIndex: null,
    stage: 1
  },
  mutations: {
    setScreenshotsType(state, value) {
      state.screenshotsType = value
    },
    setCustomerCraneUnloading(state, value) {
      state.customerCraneUnloading = value
    },
    setProduction(state, value) {
      state.production = value
    },
    setClearProject(state, value) {
      state.clearProject = value
      state.rendererTooltipsEnabled = true
    },
    setProjectComment(state, value) {
      state.projectComment = value
    },
    setCustomerContacts(state, value) {
      state.customerContacts = value
    },
    setDelivery(state, value) {
      state.delivery = Object.assign({}, value)
    },
    setProjectId(state, value) {
      state.projectId = value
    },
    setRequestId(state, requestId) {
      state.requestId = requestId
    },
    setJson(state, value) {
      state.jsonData = value
      state.stagesData[state.stage] = value
    },
    setStageJson(state, {stage, data}) {
      state.stagesData[stage] = data
    },
    setHistory(state, {data, index}) {
      state.historyList = data
      state.historyIndex = index
    },
    setEditorType(state, value) {
      state.editorType = value
    },
    setStage(state, stage) {
      const currentStage = state.stage
      state.stagesData[currentStage] = state.jsonData
      state.stage = stage
      state.jsonData = state.stagesData[stage] ? state.stagesData[stage] : null
    },
    setStageImage(state, {stage, data}) {
      state.stageImages[stage] = data
    },
    setOrderingStageImage(state, {stage, data}) {
      state.orderingStageImages[stage] = data
    },
    setImages3d(state, data) {
      state.images3d = data
    },
    addShowedTooltips(state, type) {
      if (state.tooltipsShowedList.indexOf(type) < 0) state.tooltipsShowedList.push(type)
    },

    cleanProject(state) {
      state.mounting = null
      state.requestId = null
      state.projectId = null
      state.editorType = '2D'
      state.stage = 1

      state.stagesData = {
        1: null,
        2: null,
        3: null
      }
      state.stageImages = {
        1: null,
        2: null,
        3: null
      }
      state.orderingStageImages = {
        1: null,
        2: null,
        3: null
      }
    },
    setRestored(state, value) {
      state.restored = value
    }
  },
  actions: {
    setScreenshotsType({commit}, data) {
      commit('setScreenshotsType', data)
    },
    setCustomerCraneUnloading({commit}, data) {
      commit('setCustomerCraneUnloading', data)
    },
    disableRendererTooltips({state}) {
      state.rendererTooltipsEnabled = false
    },
    setProduction({commit}, value) {
      commit('setProduction', value)
    },

    setRestored({ commit }, value) {
      commit('setRestored', value)
    },

    setClearProject({commit}, data) {
      commit('setClearProject', data)
    },
    setProjectComment({commit}, data) {
      commit('setProjectComment', data)
    },
    setCustomerContacts({commit}, data) {
      commit('setCustomerContacts', data)
    },
    setDelivery({commit}, data) {
      commit('setDelivery', data)
    },
    setProjectId({commit}, id) {
      commit('setProjectId', id)
    },
    setRequestId({commit}, requestId) {
      commit('setRequestId', requestId)
    },
    setEditorType({commit}, editorType) {
      commit('setEditorType', editorType)
    },
    setJsonData({commit}, data) {
      commit('setJson', data)
    },
    setStageJsonData({commit}, {stage, data}) {
      commit('setStageJson', {stage, data})
    },
    setHistory({commit}, data) {
      commit('setHistory', data)
    },
    setStage({commit}, stage) {
      commit('setStage', stage)
    },
    setStageImage({commit}, {stage, data}) {
      commit('setStageImage', {stage, data})
    },
    setOrderingStageImage({commit}, {stage, data}) {
      commit('setOrderingStageImage', {stage, data})
    },
    setImages3d({commit}, data) {
      commit('setImages3d', data)
    },
    cleanProject({commit}) {
      commit('cleanProject')
    },

    addTooltipsToShowedList({commit}, type) {
      commit('addShowedTooltips', type)
    },

    tooltipsShowed({state}, type) {
      return state.tooltipsShowedList.indexOf(type) >= 0
    }
  },
  getters: {
    screenshotsType: state => state.screenshotsType,
    customerCraneUnloading: state => state.customerCraneUnloading,
    rendererTooltipsEnabled: state => state.rendererTooltipsEnabled,
    production: state => state.production,
    restored: state => state.restored,
    clearProject: state => state.clearProject,
    projectComment: state => state.projectComment,
    projectId: state => state.projectId,
    editorType: state => state.editorType,
    jsonData: state => state.jsonData,
    stagesData: state => state.stagesData,
    historyList: state => state.historyList,
    historyIndex: state => state.historyIndex,
    stage: state => state.stage,
    stageImages: state => state.stageImages,
    images3d: state => state.images3d,
    orderingStageImages: state => state.orderingStageImages,
    customerContacts: state => state.customerContacts,
    delivery: state => state.delivery,
    requestId: state => state.requestId,
    stagesCount: state => {
      let count = 1
      for (let i = 1; i <= 3; i++) {
        if (state.stagesData[i]) count = i
      }

      return count
    },

    boxesCount: state => {
      let count = 0
      for (const stage in state.stagesData) {
        const stageData = state.stagesData[stage]
        if (stageData) {
          count += stageData.boxes.length
        }
      }

      return count
    },

    square: state => {
      let square = 0

      if (state.stagesData) {
        for (let i = 1; i <= 3; i++) {
          const stageData = state.stagesData[i]
          if (stageData) {
            stageData.boxes.forEach(box => {
              square += (box.width / 100) * (box.height / 100)
            })
          }
        }
      }

      return square.toFixed(2)
    }
  }
})
