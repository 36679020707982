<template lang="pug">
  .materials-tab
    .tab-item(v-for="item in items", :key="item.id", :class="{active: isSelected(item)}", @click="onTabClick(item)")
      .tab-title {{item.title}}
      .tab-indicator
</template>
<script>
export default {
  name: 'MaterialsTab',
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedValue: null,
      items: [
        {
          id: 'carcass',
          title: 'Каркас'
        },
        {
          id: 'external',
          title: 'Снаружи'
        },
        {
          id: 'roof',
          title: 'Крыша'
        },
      ]
    }
  },

  mounted() {
    if (!this.value) {
      this.selectedValue = this.items[0]
    } else {
      this.selectedValue = this.value
    }
  },

  watch: {
    selectedValue(val) {
      if (this.value !== val) this.$emit('input', this.selectedValue)
    }
  },

  methods: {
    isSelected(item) {
      return this.selectedValue === item
    },

    onTabClick(item) {
      this.selectedValue = item
    }
  }
}
</script>
