const Events = {
  ELEMENTS_CHANGED: 'ELEMENTS_CHANGED',
  ELEMENT_SELECTED: 'ELEMENT_SELECTED',

  GRID_MOVED: 'GRID_MOVED',
  GRID_FINDED_NEAR_CONNECTOR: 'GRID_FINDED_NEAR_CONNECTOR',

  BOX_MOVED: 'BOX_MOVED',

  WALL_MOVED: 'WALL_MOVED',

  WALL_ITEM_UPDATED: 'WALL_ITEM_UPDATED'

}

export default Events
