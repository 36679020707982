<template lang="pug">
  .ordering-contacts-form
    .form-label Заказчик
    .form-input
      kraus-input(:inputData="form.organization",
        :placeholder="'Название организации'")
    .form-input
      kraus-input(:inputData="form.inn",
        :placeholder="'ИНН'")
    .form-input
      kraus-input(:inputData="form.contactName",
        :placeholder="'Контактное лицо'")
    .form-input
      kraus-input(:inputData="form.phone",
        :placeholder="'Телефон'",
        :icon="icons.phone",
        :mask="'+7 (###) ###-##-##'",
        :validator="regEx.phone"
      )
    .form-input
      kraus-input(:inputData="form.email",
        :placeholder="'E-mail'",
        :icon="icons.email",
        :validator="regEx.email")

    .form-label.margined Доставка
      .form-input
        kraus-input(:inputData="form.address",
          :placeholder="'Адреса доставки'",
          :icon="icons.map", @focus="onDeliveryInputFocus")

      .delivery-info(v-if="delivery.distance > 0")
        .delivery-info-label Стоимость доставки:
        .delivery-info-cost {{delivery.cost | currency}}
        .delivery-info-space
        .delivery-info-distance ~ {{Math.round(delivery.distance)}} км

      .customer-crane-unloading(v-if="craneSwitcherVisible")
        .label Разгрузка краном покупателя
        kraus-switcher-small(:label="'Разгрузка краном покупателя'", v-model="customerCraneUnloading")

      //.delivery-columns
      .form-input
        kraus-input(:inputData="form.date",
          :placeholder="'Желаемая дата'",
          :icon="icons.calendar",
          calendar="date"
        )

        //.form-input
        //  kraus-input(:inputData="form.time",
        //    :placeholder="'Желаемое время'",
        //    :icon="icons.clock",
        //    calendar="time"
        //  )

      //.form-input
      //  kraus-input(:inputData="form.receiverName",
      //    :placeholder="'ФИО принимающего'")
      //.form-input
      //  kraus-input(:inputData="form.receiverPhone",
      //    :placeholder="'Телефон принимающего'",
      //    :icon="icons.phone",
      //    :mask="'+7(###)###-##-##'",
      //    :validator="regEx.phone"
      //  )

    .form-label.margined(v-show="boxesCount > 1") Монтаж

    .mounting-form(v-show="boxesCount > 1")
      .mounting-item(v-for="(item, index) in mountingItems",
        :key="index",
        :class="{selected: isMountingItemSelected(item)}",
        @click="onMountingItemClick(item)")
        .checkbox
          .dot
        .info
          .label
            span {{item.title}}
            tooltip-icon(:hint="{section: 'montazh', key: item.key}")
          .cost(v-if="item.price > 0 && totalCostWithoutRoof") {{Math.ceil((item.price/100) * totalCostWithoutRoof) | currency}}
          .cost(v-else) бесплатно

</template>
<script>
import KrausInput from '@/components/_common/ui/KrausInput'
import { mapActions, mapGetters } from 'vuex'
import EquipmentCategories from '@/common/EquipmentCategories'
import constants from '@/common/contants'
import { eventBus } from '@/main'
import EventBusEvents from '@/common/EventBusEvents'
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall'
import TooltipIcon from '@/components/_common/ui/TooltipIcon';

export default {
  name: 'OrderingContactsForm',
  components: { TooltipIcon, KrausSwitcherSmall, KrausInput },
  data() {
    return {
      regEx: constants.regEx,
      mountingItem: null,
      customerCraneUnloading: false,
      form: {
        organization: { value: '', error: '' },
        inn: { value: '', error: '' },
        contactName: { value: '', error: '' },
        phone: { value: '', error: '' },
        email: { value: '', error: '' },

        address: { value: '', error: '' },
        date: { value: '', error: '' },
        // time: { value: '', error: '' },
        // receiverName: { value: '', error: '' },
        // receiverPhone: { value: '', error: '' }
      },
      icons: {
        phone: require('@/assets/images/ui/icon-phone.svg'),
        email: require('@/assets/images/ui/icon-email.svg'),
        map: require('@/assets/images/ui/icon-map-marker.svg'),
        calendar: require('@/assets/images/ui/icon-calendar.svg'),
        clock: require('@/assets/images/ui/icon-clock.svg'),
      }
    }
  },

  created() {
    if (this.customerContacts) {
      for (let prop in this.customerContacts) {
        if (this.customerContacts[prop]) this.form[prop].value = this.customerContacts[prop]
      }
    }

    if (!this.mounting) this.setMounting(this.mountingItems[0])
    if (this.delivery) {
      this.updateDeliveryCost()
    }
  },

  watch: {
    'form': {
      deep: true,
      handler(form) {
        const formData = {}
        for (let prop in form) {
          formData[prop] = form[prop].value
        }

        this.setCustomerContacts(formData)
      }
    },

    'customerContacts.address': {
      handler: function (val) {
        this.form.address.value = val
      }
    },

    craneSwitcherVisible(val) {
      if (!val) this.customerCraneUnloading = false
    },

    customerCraneUnloading(val) {
      this.setCustomerCraneUnloading(val)
      this.delivery.cost = this.getCostByDistance(this.delivery.distance)
      this.setDelivery(this.delivery)
    },

    delivery() {
      this.updateDeliveryCost()
    }
  },

  computed: {
    ...mapGetters([
      'boxesCount',
      'totalCost',
      'additionalCost',
      'roofCost',
      'customerContacts',
      'mounting',
      'delivery'
    ]),

    totalCostWithoutRoof() {
      return this.totalCost - this.roofCost.total
    },

    mountingItems() {
      return EquipmentCategories.getCategory('montazh').options.map(item => {
        return {
          key: item.key,
          title: item.title,
          price: item.price
        }
      })
    },

    craneSwitcherVisible() {
      return this.delivery.distance <= 49 ? true : false
    },

    mkadCost() {
      return EquipmentCategories.getCategory('dostavka')
        .options
        .filter(item => item.key === (this.customerCraneUnloading ? 'no_unload' : 'basic'))[0].price
    },

    kmCost() {
      return EquipmentCategories.getCategory('dostavka')
        .options
        .filter(item => item.key === '1_km')[0].price
    }
  },

  methods: {
    ...mapActions([
      'setCustomerContacts',
      'setMounting',
      'setCustomerCraneUnloading',
      'setDelivery'
    ]),

    updateDeliveryCost() {
      const cost = this.getCostByDistance(this.delivery.distance)
      if (cost !== this.delivery.cost) {
        this.delivery.cost = cost
        this.setDelivery(this.delivery)
      }
    },

    getCostByDistance(distance) {
      let cost = 0
      const km = distance

      const boxesCount = !this.customerCraneUnloading ? this.boxesCount : this.boxesCount / 2

      if (km <= 49) {
        cost = this.mkadCost * boxesCount
      } else {
        cost = Math.ceil(this.mkadCost + Math.round((km - 49) * this.kmCost) * boxesCount)
      }

      return Math.ceil(cost)
    },

    onDeliveryInputFocus() {
      eventBus.$emit(EventBusEvents.ShowDeliveryMap)
    },

    isMountingItemSelected(item) {
      return this.mounting.key === item.key
    },

    onMountingItemClick(item) {
      this.setMounting(item)
    },

    hasErrors() {
      let hasErrors = false

      for (let prop in this.form) {
        let field = this.form[prop]
        if (field.value === '' || field.error !== '') {
          hasErrors = true
          field.error = 'Поле необходимо заполнить'
        }
      }

      return hasErrors
    }
  }
}
</script>
