<template lang="pug">
  .section-menu-content
    .materials-list
      materials-tab(v-model="surface")
      vue-custom-scrollbar(class="materials-list-items-wrapper")
        .search-form
          .icon
          .input
            input(type="text", placeholder="Введите номер RAL", v-model="searchRalNumber")

        .materials-list-items
          .materials-list-item(v-for="(item, index) in colorsList",
            :class="{selected: isSelected(item)}",
            :key="index",
            @click="onItemClick(item)")
            .color(:style="{backgroundColor: item.icon.color}")
            .info
              .title {{item.title}}
              .cost {{item.cost * boxesCount | currency}}
</template>
<script>
import MaterialsTab from '@/components/BlocksEditor/LeftMenu/SectionMenu/MaterialsList/MaterialsTab';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'MaterialsList',
  components: { MaterialsTab, vueCustomScrollbar },
  data() {
    return {
      surface: null,
      searchRalNumber: ''
    }
  },

  computed: {
    ...mapGetters([
      'roofColor',
      'carcassColor',
      'wallMaterial',
      'wallColor',
      'boxesCount'
    ]),

    colorsList() {
      let list = []
      if (this.surface) {
        switch (this.surface.id) {
          case 'carcass':

            list = EquipmentCategories.getItems({
              categoryKey: 'karkas',
              optionKey: 'cveta_karkasa'
            }).options.map(item => {
              return {
                icon: {
                  color: item.hex,
                },
                title: item.title,
                cost: item.price,
                raw: item
              }
            })

            break

          case 'external':
            list = this.wallMaterial.raw.options.filter(item => item.key === 'cvet')[0]
              .options.map(item => {
              return {
                icon: {
                  color: item.hex,
                },
                title: item.title,
                cost: item.price,
                raw: item
              }
            })
            break

          case 'roof':
            list = EquipmentCategories.getItems({
              categoryKey: 'krovlya',
              optionKey: 'cvet_kryshi'
            }).options.map((item) => {
              return {
                icon: {
                  color: item.hex
                },
                title: item.title,
                cost: item.price,
                raw: item
              }
            })
            break
        }
      }

      return list
    }
  },

  methods: {
    ...mapActions([
      'setCarcassColor',
      'setWallColor',
      'setRoofColor'
    ]),

    isSelected(item) {
      switch (this.surface.id) {
        case 'carcass':
          return this.carcassColor.raw.key === item.raw.key
          break
        case 'external':
          return  this.wallColor.raw.key === item.raw.key
          break
        case 'roof':
          return  this.roofColor.raw.key === item.raw.key
          break
      }
    },

    onItemClick(item) {
      switch (this.surface.id) {
        case 'carcass':
          this.setCarcassColor(item)
          break

        case 'external':
          this.setWallColor(item)
          break

        case 'roof':
          this.setRoofColor(item)
          break
      }
    }
  }
}
</script>
