<template lang="pug">
  .popup-wrapper(v-show="visible")
    .popup-background(v-if="backgroundEnabled")
    .popup(:style="style", ref="popup")
      template(v-if="visible && contentComponent !== null")
        component(:is="contentComponent")
      .popup-close-button(@click="onCloseButtonClick", v-if="closeButtonVisible")
</template>
<script>
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import PopupsNames from '@/components/_common/ui/Popup/popupNames';
import SelectContainerType from '@/components/_common/ui/Popup/SelectContainerType';
import ClearProject from '@/components/_common/ui/Popup/ClearProject';
import SendProject from '@/components/_common/ui/Popup/SendProject';
import ProjectSended from '@/components/_common/ui/Popup/ProjectSended';
import MobileDevice from '@/components/_common/ui/Popup/MobileDevice';

export default {
  name: 'Popup',
  data() {
    return {
      popupName: null,
      contentComponent: null,
      visible: false,
      updatePositionInterval: null,
      backgroundEnabled: false,
      position: {
        top: 0,
        left: 0,
      }
    }
  },

  created() {
    eventBus.$on(EventBusEvents.ShowPopup, this.onShowPopupEvent)
    eventBus.$on(EventBusEvents.HidePopup, this.onHidePopupEvent)
  },

  destroyed() {
    eventBus.$off(EventBusEvents.ShowPopup, this.onShowPopupEvent)
    eventBus.$off(EventBusEvents.HidePopup, this.onHidePopupEvent)
  },

  computed: {
    closeButtonVisible() {
      return [PopupsNames.SelectContainerType, PopupsNames.MobileDevice].indexOf(this.popupName) < 0
    },

    style() {
      return {
        top: this.position.top + 'px',
        left: this.position.left + 'px'
      }
    }
  },

  methods: {
    onCloseButtonClick() {
      this.hide()
    },

    onShowPopupEvent(popupName) {
      this.popupName = popupName

      if (popupName) {
        this.show()
        this.contentComponent = null
        this.backgroundEnabled = false

        switch (popupName) {
          case PopupsNames.MobileDevice:
            this.contentComponent = MobileDevice
            this.backgroundEnabled = true
            break;

          case PopupsNames.SelectContainerType:
            this.contentComponent = SelectContainerType
            this.backgroundEnabled = true
            break;

          case PopupsNames.ClearProject:
            this.contentComponent = ClearProject
            this.backgroundEnabled = true
            break;

          case PopupsNames.SendProject:
            this.contentComponent = SendProject
            this.backgroundEnabled = true
            break;

          case PopupsNames.ProjectSended:
            this.contentComponent = ProjectSended
            this.backgroundEnabled = true
            break;
        }

        this.$nextTick(() => {
          this.updatePosition()
        })
      } else {
        this.hide()
      }
    },

    onHidePopupEvent() {
      this.hide()
    },

    show() {
      if (!this.visible) {
        this.visible = true
        this.updatePositionInterval = setInterval(() => {
          this.updatePosition()
        }, 100)
      }
    },

    hide() {
      if (this.visible) {
        this.visible = false
        clearInterval(this.updatePositionInterval)
      }
    },

    updatePosition() {
      if (this.$refs.popup) {
        const rect = this.$refs.popup.getBoundingClientRect()
        this.position.left = (window.innerWidth - rect.width) / 2
        this.position.top = (window.innerHeight - rect.height) / 2
      }
    }
  }
}
</script>
