import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Bitmap, Shape } from '@createjs/easeljs';
import Container from '@createjs/easeljs/src/display/Container';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import WallConnector from '@/components/BlocksEditor/CreateJsBlocksEditor/WallConnector';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';

class Grid extends BaseEditorElement {

  /**
   * @type {Grid}
   */
  static Instance;
  /**
   * @type {HTMLCanvasElement}
   * @private
   */
  _canvas

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _rectShape

  /**
   *
   * @type {boolean}
   * @private
   */
  _mouseCaptured = false

  _offset = {
    x: 0,
    y: 0
  }

  _pixelDelta = 50
  _linesCount = 200

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _externalItemsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _boxItemsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _wallItemsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _wallsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _tempWallsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _insideWallsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _boxesContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _pillarsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  _tempWallsPillarsContainer;

  /**
   * @type {BaseEditorElement}
   * @private
   */
  actionsContainer

  /**
   *
   * @type {easeljs.Container}
   * @private
   */
  _backgroundImageContainer

  /**
   *
   * @param canvas {HTMLCanvasElement}
   */
  constructor(canvas) {
    super()
    Grid.Instance = this

    this._canvas = canvas

    this._rectShape = new Shape()
    this._rectShape.addEventListener('mousedown', () => {
      const selectedElement = CreateJsBlocksEditor.SelectedElement
      if (selectedElement && selectedElement.isSetuped) {
        CreateJsBlocksEditor.Instance.setSelectedElement(null)
      }
    })

    this.container.addChild(this._rectShape)

    this._backgroundImageContainer = new Container()
    this._backgroundImageContainer.alpha = 0.5
    this.container.addChild(this._backgroundImageContainer)

    this._boxesContainer = new BaseEditorElement()
    this._pillarsContainer = new BaseEditorElement()
    this._tempWallsPillarsContainer = new BaseEditorElement()
    this._wallsContainer = new BaseEditorElement()
    this._tempWallsContainer = new BaseEditorElement()
    this._insideWallsContainer = new BaseEditorElement()
    this._wallItemsContainer = new BaseEditorElement()
    this._boxItemsContainer = new BaseEditorElement()
    this._externalItemsContainer = new BaseEditorElement()
    this.actionsContainer = new BaseEditorElement()

    this.addChild(this._pillarsContainer)
    this.addChild(this._boxesContainer)
    this.addChild(this._wallsContainer)
    this.addChild(this._tempWallsContainer)
    this.addChild(this._insideWallsContainer)
    this.addChild(this._tempWallsPillarsContainer)
    this.addChild(this._wallItemsContainer)
    this.addChild(this._boxItemsContainer)
    this.addChild(this._externalItemsContainer)

    this.addChild(this.actionsContainer)

    this._rectShape.addEventListener('pressmove', (e) => {
      this.onPressMove(e)
    })
  }

  /**
   *
   * @param canvas {HTMLCanvasElement}
   * @returns {{dataURL: *, x: *, y: *}}
   */
  getImageData(canvas, { removeBackground, padding } ) {
    let minX, minY, maxX, maxY

    WallConnector.WallConnectorsList.forEach((conntector) => {
      if (!minX || conntector.x < minX) {
        minX = conntector.x - 4
      }

      if (!minY || conntector.y < minY) {
        minY = conntector.y - 4
      }

      if (!maxX || conntector.x > maxX) {
        maxX = conntector.x + 4
      }

      if (!maxY || conntector.y > maxY) {
        maxY = conntector.y + 4
      }
    })

    ExternalItem.ExternalItemsList.forEach((externalItem) => {
      const extMinX = externalItem.x - 500
      const extMinY = externalItem.y - 500

      const extMaxX = externalItem.x + 500
      const extMaxY = externalItem.y + 500

      if (minX > extMinX) minX = extMinX
      if (maxX < extMaxX) maxX = extMaxX

      if (minY > extMinY) minY = extMinY
      if (maxY < extMaxY) maxY = extMaxY
    })

    if (padding) {
      minX -= padding
      maxX += padding
      minY -= padding
      maxY += padding
    }

    const width = maxX - minX
    const height = maxY - minY

    const prevWidth = canvas.width
    const prevHeight = canvas.height

    canvas.width = width
    canvas.height = height

    const prevX = this.x
    const prevY = this.y

    this.x = -minX
    this.y = -minY

    const prevScale = this.container.scale
    this.container.scale = 1

    if (removeBackground === true || removeBackground === undefined) this._rectShape.alpha = 0

    CreateJsBlocksEditor.Instance.setSelectedElement({})

    canvas.style.opacity = 0

    let promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        const data = {
          x: minX,
          y: minY,
          dataURL: canvas.toDataURL()
        }

        resolve(data)

        this.container.scale = prevScale
        this._rectShape.alpha = 1
        canvas.width = prevWidth
        canvas.height = prevHeight
        canvas.style.opacity = 1

        this.x = prevX
        this.y = prevY
        CreateJsBlocksEditor.Instance.setSelectedElement(null)
      }, 500)
    })

    return promise
  }

  setBackgroundImage(data) {
    this._backgroundImageContainer.removeAllChildren()
    if (data) {
      const bitmap = new Bitmap(data.dataURL)
      bitmap.x = data.x
      bitmap.y = data.y
      this._backgroundImageContainer.addChild(bitmap)
    }
  }

  /**
   * @param box {Box}
   */
  addBox(box) {
    this._boxesContainer.addChild(box)
  }

  /**
   *
   * @param box {Box}
   */
  removeBox(box) {
    this._boxesContainer.removeChild(box)
  }

  /**
   * @param pillar {Pillar}
   */
  addPillar(pillar) {
    if (pillar.onTempWall) {
      this._tempWallsPillarsContainer.addChild(pillar)
    } else {
      this._pillarsContainer.addChild(pillar)
    }
  }

  /**
   *
   * @param pillar {Pillar}
   */
  removePillar(pillar) {
    if (pillar.onTempWall) {
      this._tempWallsPillarsContainer.removeChild(pillar)
    } else {
      this._pillarsContainer.removeChild(pillar)
    }
  }

  /**
   *
   * @param wall {Wall}
   */
  addWall(wall) {
    wall.temp ? this._tempWallsContainer.addChild(wall) : this._wallsContainer.addChild(wall)
  }

  /**
   *
   * @param wall {Wall}
   */
  removeWall(wall) {
    if (wall.insideBoxWall) {
      this._insideWallsContainer.removeChild(wall)
    } else {
      wall.temp ? this._tempWallsContainer.removeChild(wall) : this._wallsContainer.removeChild(wall)
    }
  }

  /**
   *
   * @param wall {Wall}
   */
  addInsideWall(wall) {
    this._insideWallsContainer.addChild(wall)
  }

  /**
   *
   * @param wall {Wall}
   */
  removeInsideWall(wall) {
    this._insideWallsContainer.removeChild(wall)
  }

  /**
   * @param wallItem {WallItem}
   */
  addWallItem(wallItem) {
    this._wallItemsContainer.addChild(wallItem)
  }

  /**
   * @param wallItem {WallItem}
   */
  removeWallItem(wallItem) {
    this._wallItemsContainer.removeChild(wallItem)
  }

  /**
   * @param boxItem
   */
  addBoxItem(boxItem) {
    this._boxItemsContainer.addChild(boxItem)
  }

  /**
   * @param boxItem
   */
  removeBoxItem(boxItem) {
    this._boxItemsContainer.removeChild(boxItem)
  }

  /**
   * @param boxItem
   */
  addExternalItem(externalItem) {
    this._externalItemsContainer.addChild(externalItem)
  }

  /**
   * @param boxItem
   */
  removeExternalItem(externalItem) {
    this._externalItemsContainer.removeChild(externalItem)
  }

  draw() {
    const length = this._linesCount * this._pixelDelta
    if (this.x > length * this.container.scale) {
      this.x = length * this.container.scale
    }
    if (this.y > length * this.container.scale) {
      this.y = length * this.container.scale
    }
    if (this.x < -length * this.container.scale + window.innerWidth * 2) {
      this.x = -length * this.container.scale + window.innerWidth * 2
    }
    if (this.y < -length * this.container.scale + window.innerHeight * 2) {
      this.y = -length * this.container.scale + window.innerHeight * 2
    }

    this._rectShape.alpha = 0.8
    this._rectShape.graphics
      .clear()
      .beginFill('#FAFBFB')
      .drawRect(
        -this._linesCount * this._pixelDelta, -this._linesCount * this._pixelDelta,
        this._linesCount * this._pixelDelta * 2, this._linesCount * this._pixelDelta * 2
      )
      .endFill()

    this.drawHorizontalLines()
    this.drawVerticalLines()
  }

  drawHorizontalLines() {
    for (let i = -this._linesCount; i < this._linesCount; i++) {
      const x = (i * this._pixelDelta)
      const color = i % 2 == 0 ? '#889EBF' : '#889EBF'
      const thickness = (i % 2 == 0 ? 0.5 : 0.25) / this.container.scale
      this._rectShape.graphics
        .setStrokeStyle(thickness, 'square')
        .beginStroke(color)
        .moveTo(x, -this._linesCount * this._pixelDelta)
        .lineTo(x, this._linesCount * this._pixelDelta)
    }
  }

  drawVerticalLines() {
    for (let i = -this._linesCount; i < this._linesCount; i++) {
      const y = (i * this._pixelDelta)
      const color = i % 2 == 0 ? '#889EBF' : '#889EBF'
      const thickness = (i % 2 == 0 ? 0.5 : 0.25) / this.container.scale

      this._rectShape.graphics
        .setStrokeStyle(thickness, 'square')
        .beginStroke(color)
        .moveTo(-this._linesCount * this._pixelDelta, y)
        .lineTo(this._linesCount * this._pixelDelta, y)
    }
  }

  onPressMove(e) {
    const nativeEvent = e.nativeEvent
    this.x += nativeEvent.movementX * Grid.PixelRatio
    this.y += nativeEvent.movementY * Grid.PixelRatio

    this.dispatchEvent(new Event(Events.GRID_MOVED))

    this.draw()
  }

  destroy() {
    super.destroy()
    this._rectShape.removeAllEventListeners()
  }

  clean() {
    BoxItem.BoxItemsList.forEach((boxItem) => boxItem.destroy())
    WallItem.WallItemsList.forEach((wallItem) => wallItem.destroy())
    Wall.InsideWallsList.forEach((wall) => wall.destroy())

    Box.BoxesList.forEach((box) => {
      box.destroy()
    })

    Wall.TempWallsList.forEach((wall) => wall.destroy())

    ExternalItem.ExternalItemsList.forEach(externalItem => {
      externalItem.destroy()
    })

    WallConnector._ID = 0
    WallConnector.WallConnectorsList = []

    Wall._ID = 0
    Wall.WallsList = []
    Wall.TempWallsList = []
    Wall.InsideWallsList = []

    Box._ID = 0
    Box.BoxesList = []

    WallItem.WallItemsList = []
    BoxItem.BoxItemsList = []
    ExternalItem.ExternalItemsList = []

    this.draw()
  }

  restoreFromJson(data) {
    this.clean()

    if (data) {
      /*** Walls and Boxes ***/
      data.connectors.forEach((connectorData) => {
        WallConnector.FromJSON(connectorData)
      })

      data.walls.forEach((wallData) => {
        Wall.FromJSON(wallData)
      })
      data.tempWalls.forEach((wallData) => {
        Wall.FromJSON(wallData)
      })

      data.walls.forEach((wallData) => {
        const wall = Wall.GetById(wallData.id)
        wall.intersectedWalls = wallData.intersectedWalls.map((wallId) => Wall.GetById(wallId))
      })

      data.tempWalls.forEach((wallData) => {
        const wall = Wall.GetById(wallData.id)
        wall.intersectedWalls = wallData.intersectedWalls.map((wallId) => Wall.GetById(wallId))
      })

      data.boxes.forEach((boxData) => {
        Box.FromJSON(boxData)
      })

      data.walls.forEach((wallData) => {
        const wall = Wall.GetById(wallData.id)
        wall.setBox(Box.GetById(wallData.boxId))
      })

      Box.BoxesList.forEach((box) => {
        box.draw()
      })

      Wall.UpdateWalls()

      /*** Walls and Boxes end ***/

      data.insideWalls.forEach((wallData) => {
        const wall = Wall.FromJSONInsideWall(wallData)
        wall.setBox(Box.GetById(wallData.boxId))
      })

      data.boxItems.forEach((boxItemData) => {
        BoxItem.FromJSON(boxItemData)
      })

      data.wallItems.forEach((wallItemData) => {
        WallItem.FromJSON(wallItemData)
      })

      data.externalItems.forEach((externalItemData) => {
        ExternalItem.FromJSON(externalItemData)
      })

    }
  }

  toJson() {
    const data = {
      connectors: WallConnector.WallConnectorsList.map((connector) => connector.toJson()),
      boxes: Box.BoxesList.map((box) => box.toJson()),
      walls: Wall.WallsList.map((wall) => wall.toJson()),
      tempWalls: Wall.TempWallsList.map((wall) => wall.toJson()),
      insideWalls: Wall.InsideWallsList.map((wall) => wall.toJson()),
      boxItems: BoxItem.BoxItemsList.map((boxItem) => boxItem.toJson()),
      wallItems: WallItem.WallItemsList.map((wallItem) => wallItem.toJson()),
      externalItems: ExternalItem.ExternalItemsList.map((externalItem) => externalItem.toJson())
    }

    return data
  }

}

export default Grid
