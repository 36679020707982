<template lang="pug">
  .content.content-windows
    template(v-if="items.length > 0")
      //kraus-container-select(:items="items", v-model="selectedStage")
      .stage-items-wrapper(v-for="(data, index) in items", :key="index")
        .stage-selector(:class="{opened: data.opened}", @click="onArrowClick(data)")
          .label {{data.label}}
          .arrow
        .windows-list(v-if="data.opened")
          .window-item(v-for="(item, index) in data.items", :key="index", @click="onWindowItemClick(item)")
            .window-item-icon(:style="{backgroundImage: 'url(' + item.icon + ')'}")
            .window-item-content
              .window-item-title {{item.title}}
              .window-item-info
                .window-item-info-options {{item.options}}
                .window-item-info-cost(v-if="item.cost > 0") {{item.cost | currency }}
    template(v-else)
      .content-header
        .content-option-name Нет добавленных окон
</template>
<script>
import KrausContainerSelect
  from '@/components/_common/ui/KrausContainerSelect';
import { mapGetters } from 'vuex';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
export default {
  name: 'Windows',
  components: { KrausContainerSelect },
  data() {
    return {
      selectedStage: null,
      items: [
        // {
        //   stage: 1,
        //   containerId: 1,
        //   label: 'Этаж 1 / Контейнер 2',
        //   items: [
        //     {
        //       icon: '/pics/right-menu/window.svg',
        //       title: 'Окошечко',
        //       options: '100x100, поворотное',
        //       cost: '10000'
        //     },
        //   ]
        // },
      ]
    }
  },

  mounted() {
    this.updateItems()
  },

  computed: {
    ...mapGetters([
      'stage',
      'stagesData',
      'windowsCost'
    ]),

    stageItems() {
      let list = []

      if (this.selectedStage) {
        list = this.selectedStage.items
      }

      return list
    },
  },

  watch: {
    windowsCost: {
      deep: true,
      handler: function () {
        this.updateItems()
      }
    }
  },

  methods: {
    onArrowClick(data) {
      data.opened = !data.opened
    },

    updateItems() {
      const list = []

      const getStageData = (stage) => {
        const filtered = list.filter(item => item.stage === stage)
        let data = null

        if (filtered.length > 0) {
          data = filtered[0]
        } else {
          data = {
            label: 'Этаж ' + stage,
            opened: stage === this.stage,
            stage: stage,
            items: []
          }

          list.push(data)
        }

        return data
      }

      if (this.windowsCost.items) {
        this.windowsCost.items.forEach(windowItem => {
          const data = getStageData(parseInt(windowItem.stage))
          data.items.push({
            icon: windowItem.dictionaryData.menuIcon,
            title: 'Окно ' + windowItem.id,
            options: windowItem.dictionaryData.title,
            cost: windowItem.price,
            id: windowItem.id,
            stage: windowItem.stage
          })
        })
      }

      this.items = list
    },

    // updateItems() {
    //   const stagesData = this.stagesData
    //   let items = []
    //
    //   for (const stage in stagesData) {
    //     const stageData = stagesData[stage]
    //
    //     if (stageData) {
    //       const windowsList = stageData.wallItems.filter(item => item.type === WallItem.Types.Window)
    //       const boxWindows = {}
    //
    //       windowsList.forEach(windowItem => {
    //         if (!boxWindows[windowItem.boxId]) boxWindows[windowItem.boxId] = []
    //         boxWindows[windowItem.boxId].push(windowItem)
    //       })
    //
    //       for (const boxId in boxWindows) {
    //         const item = {
    //           stage,
    //           containerId: boxId,
    //           label: 'Этаж ' + stage + ' / Контейнер ' + boxId,
    //           opened: true,
    //           items: []
    //         }
    //
    //         items.push(item)
    //
    //         let freeWindowsCount = 1
    //
    //         boxWindows[boxId].forEach(windowItem => {
    //           let cost = windowItem.dictionaryData.data.cost
    //           if (!this.containerType.improved) {
    //             if (freeWindowsCount > 0 && windowItem.dictionaryData.data.raw.key === '75h85') {
    //               freeWindowsCount --
    //               cost = 0
    //             }
    //           } else {
    //             if (freeWindowsCount > 0 && windowItem.dictionaryData.data.raw.key === '75h85_povorotnoe') {
    //               freeWindowsCount --
    //               cost = 0
    //             }
    //           }
    //
    //           item.items.push({
    //             icon: windowItem.dictionaryData.menuIcon,
    //             title: 'Окно ' + windowItem.id,
    //             options: windowItem.dictionaryData.title,
    //             cost: cost,
    //             id: windowItem.id
    //           })
    //         })
    //       }
    //     }
    //   }
    //
    //   this.items = items
    //
    //   if (this.items.length > 0) {
    //     this.selectedStage = this.items[0]
    //   }
    // },

    onWindowItemClick(windowItem) {
      eventBus.$emit(EventBusEvents.SelectItemInEditor, {
        type: BaseEditorElement.ELEMENT_TYPE_WALL_ITEM,
        id: windowItem.id,
        stage: windowItem.stage
      })
      //CreateJsBlocksEditor.SelectWallItem(windowItem.id)
    }
  }
}
</script>
