<template lang="pug">
  .popup-content-wrapper
    .popup-header Конфигуратор Kraus-blocks.ru
    .popup-content
      .description Для удобного использования конфигуратора, пожалуйста, откройте его на настольном компьютере или ноутбуке.
    .popup-actions
      kraus-button(:label="'Перейти на основной сайт kraust.ru'",
        :colors="{label: '#FFFFFF', background: '#0558AB'}",
        :border="true",
        @click="onGotoClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
export default {
  name: 'MobileDevice',
  components: { KrausButton },
  data() {
    return {}
  },

  methods: {
    onGotoClick() {
      window.location = 'https://kraust.ru/'
    },
  }
}
</script>
