import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import BoxView from '@/components/BlocksEditor/CreateJsBlocksEditor/Box/BoxView';
import WallConnector from '@/components/BlocksEditor/CreateJsBlocksEditor/WallConnector';
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall';
import { Rectangle } from '@createjs/easeljs';
import FastVector from 'fast-vector';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import BoxActionsView from '@/components/BlocksEditor/CreateJsBlocksEditor/Box/BoxActionsView';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import CloneBoxActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/Box/CloneBoxActionsView';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';
import ResizeBoxActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/Box/ResizeBoxActionsView';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';
import EquipmentCategories from '@/common/EquipmentCategories';
import MapUtil from '@/utils/MapUtil';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';

export default class Box extends BaseEditorElement {
  /**
   * @type {Box}
   * @private
   */
  static LAST_SELECTED_BOX
  static _ID = 0

  static Side = {
    Left: 'left',
    Right: 'right',
    Top: 'top',
    Bottom: 'bottom'
  }

  /**
   * @type {Box[]}
   */
  static BoxesList = []

  /**
   * @type {string}
   */
  elementType

  /**
   *
   * @type {Wall[]}
   * @private
   */
  _walls = []

  /**
   *
   * @type {WallConnector[]}
   * @private
   */
  _connectors = []
  _id = 0

  isSetuped = true

  width = 0
  height = 0

  _rotation = 0

  /**
   * @type {BoxView}
   * @private
   */
  _boxView

  /**
   * @type {BoxActionsView}
   * @private
   */
  _boxActionsView

  /**
   * @type {CloneBoxActionsView}
   * @private
   */
  _cloneBoxActionsView

  /**
   * @type {ResizeBoxActionsView}
   * @private
   */
  _resizeBoxActionsView

  /**
   * @type {easeljs.Rectangle}
   * @private
   */
  _rectangle = new Rectangle(0,0,0,0)

  /**
   * @type {BoxItem[]}
   * @private
   */
  _boxItems = []

  /**
   * @type {Wall[]}
   * @private
   */
  _insideWalls = []

  /**
   * @type {Object}
   */
  dictionaryData = {}

  _stickingDisabled = false
  _movingDisabled = false

  /**
   * @param width {number}
   * @param height {number}
   * @return {Box}
   * @constructor
   */
  static Create({width, height}) {
    Box._ID ++

    const box = new Box()
    box.setId(Box._ID)
    box.width = width
    box.height = height
    box.init()

    this.BoxesList.push(box)

    return box
  }

  static FromJSON(data) {
    if (data.id > Box._ID) Box._ID = data.id

    const box = new Box()
    box.setId(data.id)
    box.x = data.x
    box.y = data.y
    box.width = data.width
    box.height = data.height
    box.setConnectors(data.connectors.map((connectorId) => WallConnector.GetById(connectorId)))
    box.setWalls(data.walls.map((wallId) => Wall.GetById(wallId)))
    box.updateWallCachePositions()
    box.setDictionaryData(data.dictionaryData)

    Box.BoxesList.push(box)
  }

  /**
   * @param id
   * @return {Box | null}
   * @constructor
   */
  static GetById(id) {
    const list = Box.BoxesList.filter((box) => box.id === id)
    return list.length > 0 ? list[0] : null
  }

  constructor() {
    super();
    this.elementType = BaseEditorElement.ELEMENT_TYPE_BOX
    this._boxView = new BoxView(this)
    this._boxActionsView = new BoxActionsView(this)
    this._cloneBoxActionsView = new CloneBoxActionsView(this)

    CreateJsBlocksEditor.Instance.addEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)

    Grid.Instance.addBox(this)
  }

  toJson() {
    const data = {
      id: this.id,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      length: this.length,
      connectors: this._connectors.map((connector) => connector.id),
      walls: this._walls.map((wall) => wall.id),
      boxItems: this.boxItems.map((boxItem) => boxItem.id),
      dictionaryData: this.dictionaryData
    }
    return data
  }

  /**
   * @return {number}
   */
  get id() {
    return this._id
  }

  /**
   * @return {easeljs.Rectangle}
   */
  get rectangle() {
    this._rectangle.x = this.x
    this._rectangle.y = this.y

    this._rectangle.width = this.width
    this._rectangle.height = this.height

    return this._rectangle
  }

  /**
   * @return {WallConnector[]}
   */
  get connectors() {
    return this._connectors
  }

  /**
   * @return {number}
   */
  get length() {
    return this.width > this.height ? this.width : this.height
  }

  /**
   * @returns {FastVector}
   */
  get center() {
    return new FastVector(this.x + this.width / 2, this.y + this.height / 2)
  }

  /**
   * @return {BoxItem[]}
   */
  get boxItems() {
    return this._boxItems
  }

  /**
   * @return {Wall[]}
   */
  get insideWalls() {
    return this._insideWalls
  }

  /**
   * @return {Wall[]}
   */
  get walls() {
    return this._walls
  }

  /**
   * @param id {number}
   */
  setId(id) {
    this._id = id
  }

  init() {
    this._createWallConnectors()
    this._createWalls()
  }

  createDefaultWallItems() {
    if (!CreateJsBlocksEditor.Instance.containerType.improved) {
      const windowData = EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'derevyannye'
      }).options.filter(item => item.key === '75h85')[0]

      const windowDictionary = MapUtil.windowWood(windowData)

      const windowItem = CreateJsBlocksEditor.Instance.createWallItem({
        width: windowDictionary.size.width,
        height: 12,
        type: WallItem.Types.Window,
        dictionaryData: windowDictionary,
        enableSelect: false
      })

      windowItem.setWall(this.walls[2])
      windowItem.pointOnWall = 120
      windowItem._updatePosition()


      // categoryKey: 'dveri_vneshnie',
      //   optionKey: 'dvery_orgalit'
      const doorData = EquipmentCategories
        .getCategory('dveri_vneshnie')
        .options.filter(item => item.key === 'dver_orgalit')[0]

      const doorDictionary = MapUtil.doorExternal(doorData)

      const color = doorDictionary.color
      doorDictionary.color = {
        key: color.key,
        icon: {
          color: color.hex
        },
        title: color.title,
        cost: color.cost,
        raw: color
      }

      const doorItem = CreateJsBlocksEditor.Instance.createWallItem({
        width: doorDictionary.size ? doorDictionary.size.width : 60,
        height: 14,
        type: WallItem.Types.Door,
        dictionaryData: doorDictionary,
        enableSelect: false
      })

      doorItem.setWall(this.walls[2])
      doorItem.pointOnWall = 470
      doorItem._updatePosition()
    } else {
      const windowData = EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'plastikovye'
      }).options.filter(item => item.key === '80h100_povorotno_otkidnoe')[0]

      const windowDictionary = MapUtil.windowPlastic(windowData)

      const windowItem = CreateJsBlocksEditor.Instance.createWallItem({
        width: windowDictionary.size.width,
        height: 12,
        type: WallItem.Types.Window,
        dictionaryData: windowDictionary,
        enableSelect: false
      })

      windowItem.setWall(this.walls[2])
      windowItem.pointOnWall = 120
      windowItem._updatePosition()

      const doorData = EquipmentCategories
        .getCategory('dveri_vneshnie')
        .options.filter(item => item.key === 'dver_metall')[0]

      const doorDictionary = MapUtil.doorExternal(doorData)

      const color = doorDictionary.color
      doorDictionary.color = {
        key: color.key,
        icon: {
          color: color.hex
        },
        title: color.title,
        cost: color.cost,
        raw: color
      }

      const doorItem = CreateJsBlocksEditor.Instance.createWallItem({
        width: doorDictionary.size ? doorDictionary.size.width : 60,
        height: 14,
        type: WallItem.Types.Door,
        dictionaryData: doorDictionary,
        enableSelect: false
      })

      doorItem.setWall(this.walls[2])
      doorItem.pointOnWall = 470
      doorItem._updatePosition()
    }
  }

  /**
   * @param dictionaryData {Object}
   */
  setDictionaryData(dictionaryData) {
    this.dictionaryData = dictionaryData
    this.emitChangeByTimeout()
  }

  /**
   * @param connectors {WallConnector[]}
   */
  setConnectors(connectors) {
    this._connectors = connectors
    this._resizeBoxActionsView = new ResizeBoxActionsView(this)
  }

  /**
   * @param walls {Wall[]}
   */
  setWalls(walls) {
    this._walls = walls
    this._updateWallsSides()
  }

  /**
   * @param boxItem {BoxItem}
   */
  addBoxItem(boxItem) {
    if (this._boxItems.indexOf(boxItem) < 0) this._boxItems.push(boxItem)
  }

  /**
   * @param boxItem {BoxItem}
   */
  removeBoxItem(boxItem) {
    this._boxItems = this._boxItems.filter((item) => item !== boxItem)
  }

  /**
   * @param insideWall {Wall}
   */
  addInsideWall(insideWall) {
    if (this._insideWalls.indexOf(insideWall) < 0) this._insideWalls.push(insideWall)
  }

  /**
   * @param insideWall {Wall}
   */
  removeInsideWall(insideWall) {
    this._insideWalls = this._insideWalls.filter((item) => item !== insideWall)
  }

  draw() {
    super.draw()

    this._boxView.draw()
    this._boxActionsView.draw()
    this._cloneBoxActionsView.draw()

    if (this._resizeBoxActionsView) this._resizeBoxActionsView.draw()

    this._walls.forEach((wall) => {
      wall.draw()
    })

    this._insideWalls.forEach((wall) => {
      wall.draw()
    })

    this._boxItems.forEach((boxItem) => {
      boxItem.draw()
    })
  }

  /**
   * Только для новых контейнеров созданных из меню
   * @param x
   * @param y
   */
  setPosition(x, y) {
    const [c1, c2, c3, c4] = this._connectors

    x -= Math.round(this.width / 2)
    y -= Math.round(this.height / 2)

    c1.x = x
    c1.y = y

    c2.x = x + this.width
    c2.y = y

    c3.x = x + this.width
    c3.y = y + this.height

    c4.x = x
    c4.y = y + this.height

    this.x = x
    this.y = y

    if (this.isSetuped) {
      this.updateWalls()
    }

    this.draw()
  }

  trySetup() {
    if (!this.isSetuped && this.checkIsSetupAvailable()) {
      this.isSetuped = true
      this.draw()
      this.updateWalls()
    }
  }

  checkIsSetupAvailable() {
    let available = true

    for (const box of Box.BoxesList) {
      if (box !== this && this.rectangle.intersection(box.rectangle) !== null) {
        available = false
        break
      }
    }

    const testRectangle = this.rectangle.clone()
    // if (available) {
    //   ExternalItem.ExternalItemsList.forEach(item => {
    //     if (item.rectangle.intersects(testRectangle)) {
    //       available = false
    //     }
    //   })
    // }

    return available
  }

  /**
   * @param delta {FastVector}
   * @return {FastVector}
   */
  getUpdatedMoveDelta(delta) {
    const selfCenter = this.center

    Box.BoxesList.forEach((box) => {
      if (box !== this) {

        const testRect = this.rectangle.clone()
        testRect.x = this.x + delta.x
        testRect.y = this.y + delta.y

        const hitRectangle = testRect.intersection(box.rectangle)

        if (hitRectangle) {
          const boxCenter = box.center
          const centerDelta = boxCenter.sub(selfCenter)

          const isHorizontalCheck = this.x + this.width <= box.x || this.x >= box.x + box.width

          if (isHorizontalCheck) {
            if (centerDelta.x > 0) {
              delta.x -= hitRectangle.width
            } else {
              delta.x += hitRectangle.width
            }
          } else {
            if (centerDelta.y > 0) {
              delta.y -= hitRectangle.height
            } else {
              delta.y += hitRectangle.height
            }
          }
        }
      }
    })

    ExternalItem.ExternalItemsList.forEach((externalItem) => {
      const testRect = this.rectangle.clone()
      testRect.x = this.x + delta.x
      testRect.y = this.y + delta.y

      const externtalItemRect = externalItem.rectangle
      const hitRectangle = testRect.intersection(externalItem.rectangle)

      if (hitRectangle) {
        const externalItemCenter = new FastVector(externalItem.x, externalItem.y)
        const centerDelta = externalItemCenter.sub(selfCenter)

        const isHorizontalCheck = this.x + this.width <= externtalItemRect.x || this.x >= externtalItemRect.x + externtalItemRect.width

        if (isHorizontalCheck) {
          if (centerDelta.x > 0) {
            delta.x -= hitRectangle.width
          } else {
            delta.x += hitRectangle.width
          }
        } else {
          if (centerDelta.y > 0) {
            delta.y -= hitRectangle.height
          } else {
            delta.y += hitRectangle.height
          }
        }
      }
    })

    return delta
  }

  /**
   *
   * @param delta {FastVector}
   */
  move(delta, findNearConnectors = true) {
    if (!this._movingDisabled) {
      this.x += delta.x
      this.y += delta.y

      this._connectors.forEach((connector) => {
        connector.x += delta.x
        connector.y += delta.y
      })

      this._insideWalls.forEach((wall) => {
        wall.move(delta, false)
      })

      if (findNearConnectors) this._findNearConnectors()

      this.updateWalls()
      this.draw()
      Pillar.UpdatePillars()
    }
  }

  disableSticking() {
    this._stickingDisabled = true
  }

  enableSticking() {
    this._stickingDisabled = false
  }

  disableMoving() {
    this._movingDisabled = true
    setTimeout(() => {
      this._movingDisabled = false
    }, 250)
  }

  _findNearConnectors() {
    let findedDelta = null

    if (!this._stickingDisabled) {
      this.getOtherBoxesConnectors().forEach((connector) => {
        const connectorVector = new FastVector(connector.x, connector.y)

        this.connectors.forEach((boxConnector) => {
          const boxConnectorVector = new FastVector(boxConnector.x, boxConnector.y)
          if (boxConnectorVector.distance(connectorVector) < 20) {
            findedDelta = connectorVector.sub(boxConnectorVector)
          }
        })
      })

      if (findedDelta) {
        // this.x += findedDelta.x
        // this.y += findedDelta.y
        //
        // this.connectors.forEach((c) => {
        //   c.x += findedDelta.x
        //   c.y += findedDelta.y
        // })

        this.move(findedDelta, false)

        this.disableSticking()
        this.disableMoving()
      }
    } else {
      let minDistance = null

      this.getOtherBoxesConnectors().forEach((connector) => {
        const connectorVector = new FastVector(connector.x, connector.y)

        this._connectors.forEach((boxConnector) => {
          const boxConnectorVector = new FastVector(boxConnector.x, boxConnector.y)
          const distance = boxConnectorVector.distance(connectorVector)

          if (minDistance === null || minDistance > distance) {
            minDistance = distance
          }
        })
      })

      if (minDistance > 30) {
        this.enableSticking()
      }
    }
  }

  /**
   * @return {WallConnector[]}
   */
  getOtherBoxesConnectors() {
    const connectorsList = []
    Box.BoxesList.forEach((box) => {
      if (box !== this) {
        box.connectors.forEach((c) => connectorsList.push(c))
      }
    })

    return connectorsList
  }

  /**
   * @return {FastVector[]}
   */
  getNewRotateCoordinates() {
    /**
     * @type {FastVector[]}
     */
    let newCoordinates = []
    const center = this.center

    this._connectors.forEach((c, index) => {
      let currentX = c.x - center.x
      let currentY = c.y - center.y

      newCoordinates[index] = new FastVector(center.x - currentY, center.y + currentX)
    })

    return newCoordinates
  }

  /**
   * @param newCoordinates {FastVector[]}
   * @returns {boolean}
   */
  isRotationAvailable(newCoordinates = null) {
    if (!newCoordinates) newCoordinates = this.getNewRotateCoordinates()
    let isRotationAvailable = true

    Box.BoxesList.forEach(boxItem => {
      if (boxItem.rectangle) {
        const hitTestRect = boxItem.rectangle.clone()
        // hitTestRect.x += 1
        // hitTestRect.y += 1
        // hitTestRect.width -= 2
        // hitTestRect.height -= 2

        newCoordinates.forEach(position => {
          if (hitTestRect.contains(position.x, position.y)) {
            isRotationAvailable = false
          }
        })
      }
    })

    return isRotationAvailable
  }

  rotate() {
    const newCoordinates = this.getNewRotateCoordinates()

    if (this.isRotationAvailable(newCoordinates)) {
      this._rotation += 90
      if (this._rotation >= 360) this._rotation = 0

      this._boxItems.forEach((boxItem) => {
        boxItem.rotateInsideBox()
      })


      this.insideWalls.forEach((wallItem) => {
        wallItem.rotateInsideBox()
      })

      const oldWidth = this.width
      const oldHeight = this.height

      this.width = oldHeight
      this.height = oldWidth

      this.x -= (this.width - oldWidth) / 2
      this.y -= (this.height - oldHeight) / 2

      this._connectors.forEach((c, index) => {
        c.x = Math.round(newCoordinates[index].x)
        c.y = Math.round(newCoordinates[index].y)
      })

      this._updateWallsSides()
      this.updateWalls()
      this.draw()

      Pillar.UpdatePillars()
    }
  }

  updateWallCachePositions() {
    this._walls.forEach((wall) => {
      wall.updateCachePosition()
    })
  }

  updateWalls() {
    this.updateWallCachePositions()
    Wall.UpdateWalls()

    this.emitChangeByTimeout()
  }

  destroy() {
    super.destroy()

    CreateJsBlocksEditor.Instance.removeEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)

    this._walls.forEach((wall) => {
      wall.destroy()
    })
    this._walls = []

    this._insideWalls.forEach((wall) => {
      wall.destroy()
    })
    this._insideWalls = []

    this._boxItems.forEach((boxItem) => {
      boxItem.destroy()
    })
    this._boxItems = []

    Box.BoxesList = Box.BoxesList.filter((box) => this !== box)

    this._boxView.destroy()
    this._boxActionsView.destroy()
    this._cloneBoxActionsView.destroy()
    if (this._resizeBoxActionsView) this._resizeBoxActionsView.destroy()

    Grid.Instance.removeBox(this)

    this.updateWalls()

    this.emitChangeByTimeout()
  }

  _onElementSelected = (e) => {
    if (this.isSelected()) {
      Box.LAST_SELECTED_BOX = this
    }
    this.draw()
  }

  _createWallConnectors() {
    const c1 = WallConnector.Create({
      x: this.x,
      y: this.y
    })

    const c2 = WallConnector.Create({
      x: this.x + this.width,
      y: this.y
    })

    const c3 = WallConnector.Create({
      x: this.x + this.width,
      y: this.y + this.height
    })

    const c4 = WallConnector.Create({
      x: this.x,
      y: this.y + this.height
    })

    this.setConnectors([c1, c2, c3, c4])
  }

  _createWalls() {
    const [c1, c2, c3, c4] = this._connectors

    const w1 = new Wall.Create([c1, c2])

    const w2 = new Wall.Create([c2, c3])

    const w3 = new Wall.Create([c3, c4])

    const w4 = new Wall.Create([c4, c1])

    this._walls = [w1, w2, w3, w4]

    this._walls.forEach((wallItem) => {
      wallItem.setBox(this)
    })

    this._updateWallsSides()
  }

  _updateWallsSides() {
    const center = this.center
    this._walls.forEach((wall) => {
      if (wall.vertical) {
        wall.setSide(wall.firstConnector.x < center.x ? Box.Side.Left : Box.Side.Right)
      } else {
        wall.setSide(wall.firstConnector.y < center.y ? Box.Side.Top : Box.Side.Bottom)
      }
    })
  }
}
