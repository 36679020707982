import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import WallView from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall/WallView';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import WallConnector from '@/components/BlocksEditor/CreateJsBlocksEditor/WallConnector';
import { Rectangle } from '@createjs/easeljs';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';
import FastVector from 'fast-vector';
import InsideBoxWallActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall/InsideBoxWallActionsView';
import ResizeWallActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall/ResizeWallActionsView';
import DashedWallActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall/DashedWallActionsView';
import WallLengthLabelView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall/WallLengthLabelView';

export default class Wall extends BaseEditorElement {
  static _ID = 0
  static _POSITIONS = {
    vertical: {},
    horizontal: {}
  }

  _id = 0

  /**
   * @type {string}
   */
  elementType

  /**
   * @type {Wall[]}
   */
  static WallsList = []

  /**
   * @type {Wall[]}
   */
  static TempWallsList = []

  /**
   * @type {Wall[]}
   */
  static InsideWallsList = []

  /**
   * @type {easeljs.Container}
   * @private
   */
  _container

  /**
   * @type {WallView}
   * @private
   */
  _wallView

  /**
   * @type {WallConnector[]}
   * @private
   */
  _connectors

  /**
   * @type {easeljs.Rectangle}
   * @private
   */
  _rectangle = new Rectangle()

  /**
   * @type {Box}
   * @private
   */
  _box = null

  temp = false
  hidden = false
  insideBoxWall = false

  _rotation = 0

  initLength = 100
  initVertical = true

  /**
   * @type {Wall[]}
   */
  intersectedWalls = []

  /**
   * @type {WallItem[]}
   * @private
   */
  _wallItems = []

  /**
   * @type {String}
   * @private
   */
  _boxSide

  /**
   * @type {InsideBoxWallActionsView}
   * @private
   */
  _insideBoxWallActionsView

  /**
   * @type {ResizeWallActionsView}
   * @private
   */
  _resizeWallActionsView

  /**
   * @type {DashedWallActionsView}
   * @private
   */
  _dashedWallActionsView

  /**
   * @type {WallLengthLabelView}
   * @private
   */
  _wallLengthLabelView

  material = null

  /**
   * @param connectors {WallConnector[]}
   * @param temp {boolean}
   * @return {Wall}
   */
  static Create(connectors, temp = false) {
    Wall._ID ++

    const wall = new Wall()
    wall.setId(Wall._ID)
    wall.setConnectors(connectors)
    wall.temp = temp

    wall.createActionsViews()

    if (!temp) {
      Wall.WallsList.push(wall)
    }

    wall.emitChangeByTimeout()

    Grid.Instance.addWall(wall)

    return wall
  }

  /**
   * @param connectors {WallConnector[]}
   * @return {Wall}
   */
  static  CreateInsideBoxWall() {
    const c1 = WallConnector.Create({x: 0, y: 0})
    const c2 = WallConnector.Create({x: 100, y: 0})

    const wall = Wall.Create([c1, c2])
    wall.insideBoxWall = true
    wall.temp = true
    wall.createActionsViews()

    Grid.Instance.addInsideWall(wall)

    return wall
  }

  /**
   * @param connectors {WallConnector[]}
   * @param box {Box}
   * @return {Wall}
   * @constructor
   */
  static CreateInsideBoxWallWithConnectors(connectors, box) {
    const [c1, c2] = connectors

    const wall = Wall.Create([c1, c2])
    wall.insideBoxWall = true
    wall.temp = true
    wall.createActionsViews()
    wall.setBox(box)

    Wall.InsideWallsList.push(wall)

    Grid.Instance.addInsideWall(wall)

    Pillar.UpdatePillars()

    return wall
  }

  /**
   * @param data {Object}
   * @param temp {boolean}
   * @constructor
   */
  static FromJSON(data) {
    if (data.id > Wall._ID) Wall._ID = data.id

    const connectors = data.connectors.map((connectorId) => WallConnector.GetById(connectorId))
    const wall = new Wall()
    wall.setId(data.id)
    wall.setConnectors(connectors)
    wall.temp = data.temp

    wall.temp ? Wall.TempWallsList.push(wall) : Wall.WallsList.push(wall)


    Grid.Instance.addWall(wall)
  }

  /**
   * @param data {Object}
   * @param temp {boolean}
   * @constructor
   */
  static FromJSONInsideWall(data) {
    if (data.id > Wall._ID) Wall._ID = data.id

    const connectors = data.connectors.map((connectorId) => WallConnector.GetById(connectorId))
    const wall = new Wall()
    wall.setId(data.id)
    wall.setConnectors(connectors)
    wall.temp = data.temp
    wall.insideBoxWall = true
    wall.material = data.material
    wall.createActionsViews()

    Wall.InsideWallsList.push(wall)

    Grid.Instance.addInsideWall(wall)

    return wall
  }

  /**
   * @param id
   * @return {Wall | null}
   * @constructor
   */
  static GetById(id) {

    const allList = Wall.WallsList.concat(Wall.TempWallsList).concat(Wall.InsideWallsList)
    const list = allList.filter((wall) => wall.id === id)

    if (list.length > 0) {
      return list[0]
    }

    return  null
  }

  /**
   *
   */
  constructor() {
    super();
    this.elementType = Wall.ELEMENT_TYPE_WALL
    this._wallView = new WallView(this)
  }

  toJson() {
    const data = {
      id: this.id,
      boxId: this._box ? this._box.id : null,
      connectors: this._connectors.map((connector) => connector.id),
      length: this.wallLength,
      temp: this.temp,
      tempDashed: this.tempDashed,
      intersectedWalls: this.intersectedWalls.map((wall) => wall.id),
      material: this.material,
      wallItems: this._wallItems.map((wallItem) => wallItem.id)
    }

    return data
  }

  /**
   * @return {number}
   */
  get id() {
    return this._id
  }

  /**
   * @return {boolean}
   */
  get tempDashed() {
    return !this.insideBoxWall && this.intersectedWalls.length > 1
  }

  /**
   * @return {Box}
   */
  get box() {
    return this.temp && !this.insideBoxWall && this.intersectedWalls.length > 0 ? this.intersectedWalls[0].box : this._box
  }

  get isSetuped() {
    return this._box !== null
  }

  /**
   * @return {WallItem[]}
   */
  get wallItems() {
    return this._wallItems
  }

  get center() {
    const vector = new FastVector(0,0)
    vector.x = this.firstConnector.x + (this.vertical ? 0 : this.wallLength / 2)
    vector.y = this.firstConnector.y + (this.vertical ? this.wallLength / 2 : 0)

    return vector
  }

  /**
   * @return {WallConnector[]}
   */
  get connectors() {
    return this._connectors
  }

  /**
   * @return {WallConnector}
   */
  get firstConnector() {
    const [c1, c2] = this._connectors
    return this.vertical ? (c1.y < c2.y ? c1 : c2) : (c1.x < c2.x ? c1 : c2)
  }

  /**
   * @return {WallConnector}
   */
  get lastConnector() {
    const [c1, c2] = this._connectors
    return this.vertical ? (c1.y > c2.y ? c1 : c2) : (c1.x > c2.x ? c1 : c2)
  }

  /**
   * @return {number}
   */
  get wallLength() {
    const [c1, c2] = this.connectors
    return Math.round(this.vertical ? Math.abs(c1.y - c2.y) : Math.abs(c1.x - c2.x))
  }

  /**
   * @returns {easeljs.Rectangle}
   */
  get rectangle() {
    this._rectangle.x = this.firstConnector.x - 2
    this._rectangle.y = this.firstConnector.y - 2
    this._rectangle.width = this.vertical ? 4 : this.wallLength + 4
    this._rectangle.height = !this.vertical ? 4 : this.wallLength + 4

    return this._rectangle
  }

  get rotationAvailable() {
    const newCoordinates = this.getNewRotateCoordinates()
    return this.someMovementsAvailable(newCoordinates)
  }

  /**
   * @return {String}
   */
  get boxSide() {
    return this.temp ? this.intersectedWalls[0].boxSide : this._boxSide
  }

  /**
   * @return {boolean}
   */
  get vertical() {
    const [c1, c2] = this._connectors
    return c1.x === c2.x
  }

  get canBeSetuped() {
    let can = false
    let selfRect = this.rectangle.clone()

    Box.BoxesList.forEach((boxItem) => {

      const boxRect = boxItem.rectangle.clone()
      boxRect.x -= 2
      boxRect.y -= 2
      boxRect.width += 4
      boxRect.height += 4
      const rect = boxRect.intersection(selfRect)
      if (rect && rect.width === selfRect.width && rect.height === selfRect.height) {
        can = true
      }
    })

    if (can) {
      Wall.InsideWallsList.forEach((wall) => {
        if (wall !== this && wall.rectangle.intersects(selfRect)) {
          can = false
        }
      })

      BoxItem.BoxItemsList.forEach((boxItem) => {
        if (boxItem.rectangle.intersects(selfRect)) {
          can = false
        }
      })
    }

    return can
  }

  copy() {
    const wall = CreateJsBlocksEditor.Instance.createWall(this.material)
    wall.initLength = this.wallLength
    wall.initVertical = this.vertical

    wall.setPosition(this.center.x, this.center.y)
    wall.draw()

    this.emitChangeByTimeout()
  }

  createActionsViews() {
    if (!this._wallLengthLabelView) {
      this._wallLengthLabelView = new WallLengthLabelView(this)
    }

    if (this.insideBoxWall && !this._insideBoxWallActionsView) {
      this._insideBoxWallActionsView = new InsideBoxWallActionsView(this)
    }

    if (this.insideBoxWall && !this._resizeWallActionsView) {
      this._resizeWallActionsView = new ResizeWallActionsView(this)
    }

    if (this.tempDashed && !this._dashedWallActionsView) {
      this._dashedWallActionsView = new DashedWallActionsView(this)
    }
  }

  trySetup() {
    if (this.insideBoxWall) {
      if (!this.isSetuped && this.canBeSetuped) {
        let can = false
        let selfRect = this.rectangle.clone()

        let findedBox = null

        Box.BoxesList.forEach((box) => {
          const boxRect = box.rectangle.clone()
          boxRect.x -= 2
          boxRect.y -= 2
          boxRect.width += 4
          boxRect.height += 4
          const rect = boxRect.intersection(selfRect)
          if (rect && rect.width === selfRect.width && rect.height === selfRect.height) {
            findedBox = box
          }
        })

        if (findedBox) {
          this.setBox(findedBox)
          Wall.InsideWallsList.push(this)
          Pillar.UpdatePillars()
        }

        CreateJsBlocksEditor.Instance.setSelectedElement(null)
      }

      if (this.isSetuped) {
        CreateJsBlocksEditor.Instance.setSelectedElement(this)
        this.emitChangeByTimeout()
      }
    }
    this.draw()
  }

  setPosition(x, y) {
    if (this.insideBoxWall) {
      if (!this.isSetuped) {

        const length = this.initLength
        const connectors = [{
            x: this.firstConnector.x,
            y: this.firstConnector.y
          },
          {
            x: this.lastConnector.x,
            y: this.lastConnector.y
          }
        ]

        const [c1, c2] = connectors

        if (this.initVertical) {
          c1.x = x
          c1.y = Math.round(y - length / 2)

          c2.x = x
          c2.y = Math.round(y + length / 2)
        } else {
          c1.y = y
          c1.x = Math.round(x - length / 2)

          c2.y = y
          c2.x = Math.round(x + length / 2)
        }

        this.connectors.forEach((c, index) => {
          c.x = connectors[index].x
          c.y = connectors[index].y
        })
        this.draw()
      }
    }
  }

  /**
   * @param vector {FastVector}
   * @param checkIntersections
   */
  move(vector, checkIntersections = true) {
    if (this.insideBoxWall) {
      if (checkIntersections) {

        for (let x = 0; x < Math.abs(vector.x); x++) {
          let delta = new FastVector(vector.x > 0 ? 1 : -1, 0)
          delta = this.checkIntersections(delta)

          this._connectors.forEach(c => {
            c.x += delta.x
          })
        }

        for (let y = 0; y < Math.abs(vector.y); y++) {
          let delta = new FastVector(0, vector.y > 0 ? 1 : -1)
          delta = this.checkIntersections(delta)

          this._connectors.forEach(c => {
            c.y += delta.y
          })
        }

        this._updateInsideWall()
        this.emitChangeByTimeout()

      } else {
        this.connectors.forEach(c => {
          c.x = Math.round(c.x + vector.x)
          c.y = Math.round(c.y + vector.y)
        })
      }

      this.draw()
    }
  }

  checkIntersections(delta) {
    if (this.isSetuped) {
      /**
       * @type {easeljs.Rectangle[]}
       */
      const checkRects = []

      this._box.walls.forEach((wall) => {
        if (wall !== this) {
          wall.intersectedWalls.forEach((wall) => {
            if (!wall.tempDashed) {
              checkRects.push(wall.rectangle)
            }
          })
        }
      })

      // this._box.insideWalls.forEach((wall) => {
      //   if (wall !== this) {
      //     checkRects.push(wall.rectangle)
      //   }
      // })

      this._box.boxItems.forEach((boxItem) => {
        checkRects.push(boxItem.rectangle)
      })

      const selfRect = this.rectangle.clone()
      selfRect.x += delta.x
      selfRect.y += delta.y

      const selfCenter = new FastVector(
        selfRect.x + selfRect.width / 2,
        selfRect.y + selfRect.height / 2,
      )

      const boxRect = this._box.rectangle.clone()
      boxRect.x -= 2
      boxRect.y -= 2
      boxRect.width += 4
      boxRect.height += 4

      if (selfRect.x < boxRect.x) {
        delta.x += (boxRect.x - selfRect.x)
      }

      if (selfRect.x + selfRect.width > boxRect.x + boxRect.width ) {
        delta.x += (boxRect.x + boxRect.width) - (selfRect.x + selfRect.width)
      }

      if (selfRect.y < boxRect.y) {
        delta.y += (boxRect.y - selfRect.y)
      }

      if (selfRect.y + selfRect.height > boxRect.y + boxRect.height ) {
        delta.y += (boxRect.y + boxRect.height) - (selfRect.y + selfRect.height)
      }

      checkRects.forEach((checkRectangle) => {
        const checkReactangleCenter = new FastVector(
          checkRectangle.x + checkRectangle.width / 2,
          checkRectangle.y + checkRectangle.height / 2,
        )

        const centerDelta = checkReactangleCenter.sub(selfCenter)

        const hitRectangle = checkRectangle.intersection(selfRect)

        if (hitRectangle) {
          const horizontalCheck = this.rectangle.x + this.rectangle.width <= checkRectangle.x || this.rectangle.x >= checkRectangle.x + checkRectangle.width

          if (horizontalCheck) {
            if (centerDelta.x > 0) {
                delta.x -= hitRectangle.width
            } else {
                delta.x += hitRectangle.width
            }
          } else {
            if (centerDelta.y > 0) {
              delta.y -= hitRectangle.height
            } else {
              delta.y += hitRectangle.height
            }
          }
        }
      })
    }

    return delta
  }

  _updateInsideWall() {
    if (this.insideBoxWall && this.isSetuped) {
      /**
       * @type {Wall[]}
       */
      const dashedWalls = []

      this._box.walls.forEach((wall) => {
        wall.intersectedWalls.forEach((tempWall) => {
          if (tempWall.tempDashed) dashedWalls.push(tempWall)
        })
      })

      const selfRect = this.rectangle
      let someTouched = false
      dashedWalls.forEach((dashedWall) => {
        const dashedRect = dashedWall.rectangle
        const hitRectangle = dashedRect.intersection(selfRect)

        if (hitRectangle && hitRectangle.width === selfRect.width && hitRectangle.height === selfRect.height) {
          this.intersectedWalls = dashedWall.intersectedWalls
          someTouched = true
        }
      })

      if (!someTouched) {
        this.intersectedWalls = []
      }

      Pillar.UpdatePillars()
    }
  }

  /**
   * @param side {String}
   */
  setSide(side) {
    if (this._boxSide !== side) {
      const prevSide = this._boxSide
      this._boxSide = side

      if (prevSide === Box.Side.Left || prevSide === Box.Side.Right) {
        this.intersectedWalls.forEach((wall) => {
          wall.wallItems.forEach((wallItem) => {
            wallItem.flipPositionOnWall()
          })
        })
      }
    }
  }

  someMovementsAvailable(coordinatesList) {
    let available = false

    if (this.insideBoxWall && this.isSetuped) {
      available = true

      const rectFromCoordinates = this.getRectFromCoordinates(coordinatesList)
      const testRect = this._box.rectangle.clone()

      if (rectFromCoordinates.width > rectFromCoordinates.height) {
        testRect.x += 2
        testRect.width -= 4

        testRect.y -= 2
        testRect.height += 4
      } else {
        testRect.y += 2
        testRect.height -= 4

        testRect.y -= 2
        testRect.height += 4
      }

      const boxRect = testRect.intersection(rectFromCoordinates)

      if (boxRect === null || boxRect.width !== rectFromCoordinates.width || boxRect.height !== rectFromCoordinates.height) {
        available = false
      }

      if (available) {
        /**
         * @type {easeljs.Rectangle[]}
         */
        const rectsList = []

        this._box.walls.forEach((wall) => {
          if (wall !== this) {
            wall.intersectedWalls.forEach((wall) => {
              if (!wall.tempDashed) {
                rectsList.push(wall.rectangle)
              }
            })
          }
        })

        // Wall.InsideWallsList.forEach((wall) => {
        //   const rect = wall.rectangle.clone()
        //   rect.width += 4
        //   rect.height += 4
        //   rect.x -= 2
        //   rect.y -= 2
        //   if (wall !== this) rectsList.push(rect)
        // })

        this._box.boxItems.forEach((boxItem) => {
          rectsList.push(boxItem.rectangle)
        })

        rectsList.forEach((testRectangle) => {
          if (available) {
            const intersectionRect = testRectangle.intersection(rectFromCoordinates)
            if (intersectionRect) {
              available = false
            }
          }
        })
      }
    }

    return available
  }

  /**
   * @param coordinates {FastVector[]}
   * @returns {easeljs.Rectangle}
   */
  getRectFromCoordinates(coordinates) {
    let rect = new Rectangle(0,0,0,0)

    if (coordinates.length >= 2) {
      const [c1, c2] = coordinates
      const first = c1.x <= c2.x && c1.y <= c2.y ? c1 : c2
      const last = first === c1 ? c2 : c1
      const width = Math.abs(c1.x - c2.x)
      const height = Math.abs(c1.y - c2.y)

      rect.x = first.x - 1
      rect.y = first.y - 1
      rect.width = width + 2
      rect.height = height + 2
    }

    return rect
  }

  /**
   * @return {{x:number, y:number}}
   */
  getNewRotateCoordinatesInsideBox() {
    let newCoordinates = []
    const center = this._box.center

    this._connectors.forEach((c, index) => {
      let currentX = c.x - center.x
      let currentY = c.y - center.y

      newCoordinates[index] = {
        x: center.x - currentY,
        y: center.y + currentX
      }
    })

    return newCoordinates
  }

  rotateInsideBox() {
    if (this.insideBoxWall) {
      const newCoordinates = this.getNewRotateCoordinatesInsideBox()
      this.connectors.forEach((c, index) => {
        c.x = newCoordinates[index].x
        c.y = newCoordinates[index].y
      })

      this._rotation += 90
      if (this._rotation === 90 || this._rotation === 270) this.wallItems.forEach(item => item.flipPositionOnWall())
      if (this._rotation >= 360) {
        this._rotation = 0
      }

      this.draw()
    }
  }

  rotate() {
    if (this.insideBoxWall) {
      const newCoordinates = this.getNewRotateCoordinates()
      this.connectors.forEach((c, index) => {
        c.x = newCoordinates[index].x
        c.y = newCoordinates[index].y
      })

      this._rotation += 90
      if (this._rotation === 180 || this._rotation === 360) this._wallItems.forEach(item => item.flipPositionOnWall())
      if (this._rotation >= 360) {
        this._rotation = 0
      }

      this.emitChangeByTimeout()

      this.draw()
    }
  }

  /**
   * @return {FastVector[]}
   */
  getNewRotateCoordinates() {
    let newCoordinates = []
    const center = this.center

    this._connectors.forEach((c, index) => {
      let currentX = c.x - center.x
      let currentY = c.y - center.y

      newCoordinates[index] = new FastVector(
        center.x - currentY,
        center.y + currentX
      )
    })

    return newCoordinates
  }

  draw() {
    super.draw();

    if (this.tempDashed) {
      let insideWalls = []
      this.intersectedWalls.forEach((wall) => {
        insideWalls = insideWalls.concat(wall.box.insideWalls)
      })

      // insideWalls.forEach((insideWall) => {
      //   const hitRect = this.rectangle.intersection(insideWall.rectangle)
      //   if (hitRect) {
      //     if (this.vertical === insideWall.vertical) {
      //       if (hitRect) {
      //         if (this.vertical && hitRect.height < insideWall.rectangle.height) {
      //           insideWall.destroy()
      //         } else if (!this.vertical && hitRect.width < insideWall.rectangle.width) {
      //           insideWall.destroy()
      //         }
      //       }
      //     }
      //   }
      // })
    }

    if (!this.temp) this.intersectedWalls.forEach((tempWall) => {
      tempWall.draw()
    })

    this._wallItems.forEach((wallItem) => {
      wallItem.draw()
    })

    if (this._insideBoxWallActionsView) this._insideBoxWallActionsView.draw()
    if (this._resizeWallActionsView) this._resizeWallActionsView.draw()
    if (this._dashedWallActionsView) this._dashedWallActionsView.draw()
    if (this._wallLengthLabelView) this._wallLengthLabelView.draw()

    this._wallView.draw()
  }

  /**
   * @param id {number}
   */
  setId(id) {
    this._id = id
  }

  /**
   * @param wallItem {WallItem}
   */
  addWallItem(wallItem) {
    this._wallItems.push(wallItem)
  }

  /**
   * @param wallItem {WallItem}
   */
  removeWallItem(wallItem) {
    this._wallItems = this._wallItems.filter(item => item !== wallItem)
  }

  removeWallItems() {
    const itemsList = this._wallItems
    itemsList.forEach(wallItem => wallItem.destroy())
  }

  /**
   * @param box {Box}
   */
  setBox(box) {
    this._box = box
    if (this.insideBoxWall) {
      this._box.addInsideWall(this)
      this._updateInsideWall()
    }
  }

  /**
   * @type {WallConnector[]}
   * @param connectors
   */
  setConnectors(connectors) {
    this._connectors = connectors

    connectors.forEach((connector) => {
      connector.addWall(this)
    })
  }

  isSelected() {
    let selected = false

    if (this.insideBoxWall) {
      selected = super.isSelected()
    } else if (this.temp) {
      this.intersectedWalls.forEach(wallItem => {
        if (wallItem.box && wallItem.box.isSelected()) {
          selected = true
        }
      })
    } else {
      selected = this._box ? this._box.isSelected() : super.isSelected()
    }

    return selected
  }

  updateCachePosition() {
    this.removeFromCache()

    if (this.vertical) {
      const key = this.firstConnector.x.toString()
      if (!Wall._POSITIONS.vertical[key]) Wall._POSITIONS.vertical[key] = []
      Wall._POSITIONS.vertical[key].push(this)
    } else {
      const key = this.firstConnector.y.toString()
      if (!Wall._POSITIONS.horizontal[key]) Wall._POSITIONS.horizontal[key] = []
      Wall._POSITIONS.horizontal[key].push(this)
    }
  }

  removeFromCache() {
    const vKeys = Object.keys(Wall._POSITIONS.vertical)
    const hKeys = Object.keys(Wall._POSITIONS.horizontal)

    vKeys.forEach((key) => {
      const list = Wall._POSITIONS.vertical[key]
      Wall._POSITIONS.vertical[key] = list.filter((wall) => wall !== this)
      if (Wall._POSITIONS.vertical[key].length === 0) {
        delete Wall._POSITIONS.vertical[key]
      }
    })

    hKeys.forEach((key) => {
      const list = Wall._POSITIONS.horizontal[key]
      Wall._POSITIONS.horizontal[key] = list.filter((wall) => wall !== this)
      if (Wall._POSITIONS.horizontal[key].length === 0) {
        delete Wall._POSITIONS.horizontal[key]
      }
    })
  }

  /**
   *
   * @param connectors {WallConnector[]}
   * @return {boolean}
   */
  containsConnectorsCoordinates(connectors) {
    const selfConnectors = [this.firstConnector, this.lastConnector]
    return selfConnectors[0].x === connectors[0].x && selfConnectors[0].y == connectors[0].y &&
    selfConnectors[1].x === connectors[1].x && selfConnectors[1].y === connectors[1].y
  }

  clearTempDashed() {
    if (this.tempDashed) {
      // /**
      //  * @type {Wall[]}
      //  */
      // let insideWalls = []
      // this.intersectedWalls.forEach((wall) => {
      //   insideWalls = insideWalls.concat(wall.box.insideWalls)
      // })
      //
      // insideWalls = insideWalls.filter((insideWall) => {
      //   return this.intersectedWalls.filter((wall) => {
      //     return wall.rectangle.intersects(insideWall.rectangle)
      //   }).length > 0
      // })
      //
      // insideWalls.forEach((wall) => wall.destroy())
    }
  }

  destroy() {
    // if (this.insideBoxWall) {
    //   try {
    //     throw new Error()
    //   } catch (e) {
    //     console.log(e.stack)
    //   }
    // }

    if (this.tempDashed) {
      this.clearTempDashed()
    }

    this._connectors.forEach((connector) => {
      connector.removeWall(this)
    })

    if (!this.temp) {
      this.removeFromCache()
    }

    this._wallItems.forEach((wallItem) => {
      wallItem.destroy()
    })

    if (this._box) this._box.removeInsideWall(this)

    if (this._insideBoxWallActionsView) this._insideBoxWallActionsView.destroy()
    if (this._resizeWallActionsView) this._resizeWallActionsView.destroy()
    if (this._dashedWallActionsView) this._dashedWallActionsView.destroy()
    if (this._wallLengthLabelView) this._wallLengthLabelView.destroy()

    Grid.Instance.removeWall(this)

    Wall.WallsList = Wall.WallsList.filter(wall => wall !== this)
    Wall.TempWallsList = Wall.TempWallsList.filter(wall => wall !== this)
    Wall.InsideWallsList = Wall.InsideWallsList.filter(wall => wall !== this)

    this._wallView.destroy()

    this.emitChangeByTimeout()

    super.destroy()
  }

  static UpdateWalls() {

    let newTempWalls = Wall.CreateTempWalls()

    const destroyList = []

    Wall.TempWallsList.forEach((tempWall) => {
      if (newTempWalls.indexOf(tempWall) < 0) {
        destroyList.push(tempWall)
      }
    })

    destroyList.forEach(wall => wall.destroy())

    Wall.InsideWallsList.forEach((insideWall) => {

      Wall.TempWallsList.forEach((tempWall) => {
        const selfRect = insideWall.rectangle
        const intersectionRect = tempWall.rectangle.intersection(selfRect)

        let destroyed = false

        if (intersectionRect && intersectionRect.width === selfRect.width && intersectionRect.height === selfRect.height) {
          if (!tempWall.tempDashed) {
            insideWall.destroy()
            destroyed = true
          }
        }

        if (!destroyed && !tempWall.tempDashed && intersectionRect && (intersectionRect.width !== selfRect.width || intersectionRect.height !== selfRect.height)) {
          if (tempWall.box === insideWall.box && tempWall.vertical !== insideWall.vertical) {
            const wallCenter = insideWall.center
            const boxCenter = insideWall.box.center

            if (insideWall.vertical) {
              if (wallCenter.y > boxCenter.y) {
                insideWall.lastConnector.y -= 1
              } else {
                insideWall.firstConnector.y += 1
              }
            } else {
              if (wallCenter.x > boxCenter.x) {
                insideWall.lastConnector.x -= 1
              } else {
                insideWall.firstConnector.x += 1
              }
            }

            insideWall.draw()
          } else if (tempWall.vertical === insideWall.vertical) {
            insideWall.destroy()
          }
        }
      })
    })

    Wall.TempWallsList = newTempWalls
  }

  static CreateTempWalls() {
    const newTempWalls = []

    for (let i = 0; i < 2; i++) {
      const vertical = i === 0
      const keys = Object.keys(vertical ? Wall._POSITIONS.vertical : Wall._POSITIONS.horizontal)

      keys.forEach((key) => {
        /**
         * @type {WallConnector[]}
         */
        const connectors = []

        /**
         * @type {Wall[]}
         */
        const wallsList = vertical ? Wall._POSITIONS.vertical[key] : Wall._POSITIONS.horizontal[key]

        wallsList.forEach((wall) => {
          wall.hidden = false
          wall.intersectedWalls = []
          wall.connectors.forEach(c => connectors.push(c))
        })

        connectors.sort((c1, c2) => {
          return vertical ? (c1.y > c2.y ? 1 : -1) : (c1.x > c2.x ? 1 : -1)
        })

        /**
         * @type {[WallConnector[]]}
         */
        const pairs = []

        for (let i = 1; i < connectors.length; i++) {
          pairs.push([
            connectors[i - 1],
            connectors[i]
          ])
        }

        pairs.forEach((pair) => {
          const [c1, c2] = pair
          const intersectedWalls = []

          wallsList.forEach(wall => {
            if (vertical) {
              if (c1.y !== c2.y && c1.y >= wall.firstConnector.y && c2.y <= wall.lastConnector.y) {
                intersectedWalls.push(wall )
              }
            } else {
              if (c1.x !== c2.x && c1.x >= wall.firstConnector.x && c2.x <= wall.lastConnector.x) {
                intersectedWalls.push(wall)
              }
            }

            if (intersectedWalls.length > 0) {
              wall.hidden = true
            }

            wall.draw()
          })

          if (intersectedWalls.length > 0) {
            newTempWalls.push(Wall.CreateTempWall(pair, intersectedWalls))
          }
        })
      })
    }

    return newTempWalls
  }

  /**
   * @param connectors {WallConnector[]}
   * @param intersectedWalls {Wall[]}
   */
  static CreateTempWall(connectors, intersectedWalls) {
    const tempWall = Wall.GetTempWall(connectors)

    if (tempWall.intersectedWalls.length !== intersectedWalls.length) {
      if (tempWall.tempDashed) tempWall.clearTempDashed()
      tempWall.removeWallItems()
    }
    tempWall.intersectedWalls = intersectedWalls

    intersectedWalls.forEach((wall) => {
      wall.intersectedWalls.push(tempWall)
    })

    tempWall.createActionsViews()
    tempWall.draw()

    return tempWall
  }

  /**
   *
   * @param connectors {WallConnector[]}
   * @return {Wall}
   */
  static GetTempWall(connectors) {
    /**
     * @type {Wall}
     */
    let tempWall = null

    Wall.TempWallsList.forEach((wall) => {
      if (wall.containsConnectorsCoordinates(connectors)) tempWall = wall
    })

    if (tempWall === null) {
      tempWall = Wall.Create(connectors, true)
    }

    return tempWall
  }

  /**
   * @param connectors {WallConnector[]}
   * @private
   */
  _deleteTempWall(connectors) {
    /**
     * @type {Wall}
     */
    let tempWall = null
    Wall.TempWallsList.forEach((wall) => {
      if (wall.containsConnectorsCoordinates(connectors)) tempWall = wall
    })

    if (tempWall) tempWall.destroy()
  }
}
