import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/components/Index.vue';
import Admin from '@/components/Admin.vue';
import Settings from '@/components/Admin/Settings.vue';
import Ordering from '@/components/Ordering';
import Project from '@/components/Project';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/ordering',
    name: 'Ordering',
    component: Ordering,
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: 'settings',
        component: Settings
      }
    ]
  },
  {
    path: '/project/:id',
    component: Project
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
