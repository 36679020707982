<template lang="pug">
  .kraus-switcher.no-select(ref="switcher")
    .highlighter(:style="highlighterStyle")
    .values-wrapper
      .value-item(v-for="(item, index) in items", :key="index", :class="{enabled: isItemEnabled(item)}", @click="onItemClick(anotherItem)") {{item.label}}
</template>
<script>
export default {
  name: 'KrausSwitcher',
  props: {
    value: null,
    items: {
      type: Array,
      default () {
        return [
          {
            label: '3D'
          },
          {
            label: '2D'
          }
        ]
      }
    }
  },

  data() {
    return {
      selectedValue: null,
      elements: []
    }
  },

  mounted() {
    this.elements = Array.from(this.$refs.switcher.querySelectorAll('.value-item'))
    if (!this.value) {
      this.selectedValue = this.items[0]
    } else {
      this.selectedValue = this.value
    }
  },

  watch: {
    value() {
      this.selectedValue = this.value
    }
  },

  computed: {
    anotherItem() {
      let another = null
      if (this.items.length > 0) {
        another = this.selectedValue == this.items[0] ? this.items[1] : this.items[0]
      }
      return another
    },
    highlighterStyle () {
      let left = 0
      let width = 0

      if (this.elements.length > 0 && this.selectedValue) {
        const index = this.items.indexOf(this.selectedValue)
        const selectedElement = this.elements[index]

        width = selectedElement.getBoundingClientRect().width
        left = index > 0 ? this.elements[0].getBoundingClientRect().width : 0
      }

      return {
        left: left + 'px',
        width: width + 'px'
      }
    }
  },

  methods: {
    isItemEnabled(item) {
      return item === this.selectedValue
    },

    onItemClick(item) {
      this.selectedValue = item
      this.$emit('input', item)
    }
  }
}
</script>
