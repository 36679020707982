import { Color3, Mesh, Vector3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';
import ModelsCache
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/ModelsCache/ModelsCache';

class WallItem {
  _scene
  _data
  _wallData
  _wallConnectors
  _stage

  /**
   *
   * @param scene
   * @param wall {Wall}
   * @param data {Object}
   */

  /**
   * @type {Mesh}
   * @private
   */
  _mesh

  _position

  constructor({scene, mesh, position, data, wallData, wallConnectors,  stage}) {
    this._scene = scene
    this._data = data
    this._wallData = wallData
    this._wallConnectors = wallConnectors
    this._stage = stage
    this._position = position

    this._mesh = new Mesh('wall_item_root', this._scene)
    this._mesh.parent = mesh
    this._mesh.position = this._position

    if (!WallItem._cache) WallItem._cache = new ModelsCache({scene})

    this._loadModel()
  }

  get holeSize() {
    const size = {
      width: 0,
      height: 0
    }

    switch (this._data.type) {
      case 'Window':
        size.width = 75
        size.height = 85
        break
    }

    return size
  }

  get modelUrl() {
    let url = {
      path: '',
      fileName: ''
    }
    switch (this._data.type) {
      case 'Additional':
        url = {
          path: '/models/additional/',
          fileName: this._data.dictionaryData.data.raw.key + '.glb'
        }
        break
      case 'Window':
        url = {
          path: '/models/window/' + this._data.dictionaryData.material + '/',
          fileName: this._data.dictionaryData.data.raw.key + '.glb'
        }
        break

      case 'Door':
        const isGate = ['vorota', 'rolstavni'].indexOf(this._data.dictionaryData.id) >= 0
        const isLuk = this._data.dictionaryData.id === 'luk'
        const size = isGate ? {width: 205, height: 205} : isLuk ? {width: this._data.dictionaryData.data.raw.width, height: this._data.dictionaryData.data.raw.height} : this._data.dictionaryData.size

        const path = this._data.dictionaryData.external ? 'external' : 'internal'
        let sizePostfix = '_' + size.width + 'x' + size.height

        if (this._data.dictionaryData.configuration) {
          sizePostfix = '_' + this._data.dictionaryData.configuration.key + sizePostfix
        }

        url = {
          path: '/models/door/' + path + '/',
          fileName: this._data.dictionaryData.data.raw.key + sizePostfix + '.glb'
        }
        break
    }

    return url
  }

  _loadModel() {
    BlocksConstructor._modelsCache.loadModel(this.modelUrl).then(mesh => {
      mesh.parent = this._mesh
      this._onModelLoaded()
    })
    // SceneLoader.LoadAssetContainer(this.modelUrl.path, this.modelUrl.fileName, this._scene, (container) => {
    //   container.meshes.forEach(meshItem => {
    //     if (!meshItem.parent) meshItem.parent = this._mesh
    //   })
    //
    //   container.addAllToScene()
    //   this._onModelLoaded()
    // })
  }

  get _rotation () {
    let rotation = 0
    const radian = Math.PI / 180

    switch (this._data.type) {
      case 'Window':
        rotation = Math.PI
        break
      case 'Door':
      case 'Additional':
        const angle = this._wallConnectors[0].x === this._wallConnectors[1].x ? 90 : 0
        if (this._data.dictionaryData.id === 'luk') {
          rotation = (this._data.rotation + this._data.commonRotation + angle) * radian - this._mesh.parent.rotation.y + Math.PI
        } else {
          rotation = (this._data.rotation + this._data.commonRotation + angle) * radian - this._mesh.parent.rotation.y
        }
        break
    }

    return rotation
  }

  _onModelLoaded = () => {
    this._mesh.scaling = new Vector3(100 * BlocksConstructor.Scale, 100 * BlocksConstructor.Scale, 100 * BlocksConstructor.Scale)
    this._mesh.rotation.y = this._rotation

    if (this._data.type === 'Door' || this._data.type === 'Additional') {
      const isGate = ['vorota', 'rolstavni'].indexOf(this._data.dictionaryData.id) >= 0
      const isLuk = this._data.dictionaryData.id === 'luk'
      const isVentilation = this._data.dictionaryData.id === 'vytyazhka'

      let size
      if (isGate) {
        size = {width: 205, height: 205}
      } else if (isVentilation) {
        size = {width: 10, height: 10}
      } else {
        size = this._data.dictionaryData.size || {width: this._data.dictionaryData.data.raw.width, height: this._data.dictionaryData.data.raw.height}
      }

      // let size = isGate
      //   ? {width: 205, height: 205} : isLuk ? {width: this._data.dictionaryData.data.raw.width, height: this._data.dictionaryData.data.raw.height} : this._data.dictionaryData.size

      this._mesh.position.y -= (size.height / 2) * BlocksConstructor.Scale
    }

    this._trySetColor()
  }

  _trySetColor() {
    if (this._data.type === 'Door') {
      const color = this._data.dictionaryData.color
      if (color && ['no_color', 'nocolor'].indexOf(color.key) < 0) this._mesh.getChildMeshes().forEach(mesh => {
        if (mesh.material && mesh.material.name.indexOf('_Colored') >= 0) {
          mesh.material.albedoColor = Color3.FromHexString(color.raw.hex)
          mesh.material.emissiveColor = Color3.FromHexString(color.raw.hex)
        }
      })
    } else if (this._data.type === 'Window') {
      this._mesh.getChildMeshes().forEach(mesh => {
        if (mesh.material && mesh.material.name.indexOf('_Plastic') >= 0) {
          mesh.material.emissiveColor = Color3.White()
          mesh.material.albedoColor = Color3.White()
        }
      })
    }
  }
}

export default WallItem
