import EquipmentCategories from '@/common/EquipmentCategories'
import EditorDataUtil from '@/common/EditorDataUtil.class'

class RoofCalculator {
  _connectedBoxes
  _stageConnectors

  constructor({stageConnectors, connectedBoxes}) {
    this._connectedBoxes = connectedBoxes
    this._stageConnectors = stageConnectors
  }

  /**
   * Возможно ли отображение крыши
   * @return {boolean}
   */
  isRoofAvailable() {
    let available = false

    if (this._connectedBoxes.length >= 4) {
      available = this.getBoxesSquare() === this.getEdgeConnectorsSquare()
    }

    return available
  }

  getConnectorById(connectorId) {
    const list = this._stageConnectors.filter((connector) => connector.id === connectorId)
    return list.length > 0 ? list[0] : null
  }

  getConnectors() {
    const connectorsList = []

    let allConnectors = []
    this._connectedBoxes.forEach(box => {
      allConnectors = allConnectors.concat(box.connectors.map((connectorId) => this.getConnectorById(connectorId)))
    })

    allConnectors.forEach(connector => {
      const existsList = connectorsList.filter(item => item.id === connector.id)
      if (existsList.length === 0) {
        connectorsList.push(connector)
      }
    })

    return connectorsList
  }

  getBoxesSquare() {
    let totalSquare = 0
    this._connectedBoxes.forEach(box => {
      totalSquare += box.width * box.height
    })

    return totalSquare
  }

  getEdgeConnectorsSquare() {
    const edges = {
      minx: null,
      miny: null,
      maxx: null,
      maxy: null
    }

    this.getConnectors().forEach((connector) => {
      if (edges.minx === null || edges.minx > connector.x) {
        edges.minx = connector.x
      }
      if (edges.maxx === null || edges.maxx < connector.x) {
        edges.maxx = connector.x
      }
      if (edges.miny === null || edges.miny > connector.y) {
        edges.miny = connector.y
      }
      if (edges.maxy === null || edges.maxy < connector.y) {
        edges.maxy = connector.y
      }
    })

    const [width, height] = [edges.maxx - edges.minx, edges.maxy - edges.miny]

    return width * height
  }

  /**
   * @return {{width: number, height: number}}
   */
  getSize() {
    const {tl, br} = this.getEdgeConnectors()
    const width = Math.abs(br.x - tl.x)
    const height = Math.abs(br.y - tl.y)
    return {width, height}
  }

  /**
   * @return {{tl: null, br: null}}
   */
  getEdgeConnectors() {
    let tl = null
    let br = null

    this.getConnectors().forEach(connector => {
      if (tl === null) {
        tl = connector
      } else {
        const vector = {
          x: connector.x - tl.x,
          y: connector.y - tl.y
        }

        if (vector.x <= 0 && vector.y <= 0) tl = connector
      }

      if (br === null) {
        br = connector
      } else {
        const vector = {
          x: connector.x - br.x,
          y: connector.y - br.y
        }

        if (vector.x >= 0 && vector.y >= 0) br = connector
      }
    })

    return {
      tl,
      br
    }
  }

  dispose() {
    if (this._roofMesh) {
      this._roofMesh.dispose()
    }
  }
}


class CostCalculator {

  _params

  total = 0

  containers = {
    total: 0,
    size: {
      width: 0,
      depth: 0,
      height: 0
    }
  }

  carcass = {
    total: 0,
    cornerPosts: 0,
    color: 0,
    strengthening: 0
  }

  externalFinishing = {
    material: {
      data: null,
      price: 0,
    },
    color: {
      data: null,
      price: 0,
    },
    total: 0
  }

  internalFinishing = {
    items: [],
    total: 0
  }

  roof = {
    total: 0,
    metalTrusses: 0,
    material: 0,
    color: 0,
    type: 0,
  }

  windows = {
    total: 0,
    items: []
  }

  doors = {
    total: 0,
    items: []
  }

  insideWalls = {
    total: 0,
    items: []
  }

  floor = {
    total: 0,
    additionalFloor: 0,
    additionalLags: 0,
    metalLags: 0,
    metalLagsCustomCount: 0,
    mainFloor: 0,
    bottomFloor: 0,
    additionalMainFloor: 0,
    mainFloorCoating: 0,
    items: []
  }

  plumbing = {
    total: 0,
    items: []
  }

  warming = {
    walls: 0,
    floor: 0,
    roof: 0,
    total: 0
  }

  waterProof = {
    total: 0,
    internal: 0,
    external: 0
  }

  electric = {
    total: 0,
    packet: 0,
    sockets: 0,
    lights: 0
  }

  additional = {
    total: 0,
    items: []
  }

  mounting = {
    total: 0
  }

  get _equipment() {
    return this._params.equipment
  }

  get _boxesCount() {
    return this._params.boxesCount
  }

  constructor(params) {
    this._params = params
    console.log('params', params)
    // console.log(JSON.stringify(params.fullJson))

    this.calculateContainers()
    this.calculateCarcass()
    this.calculateExternalFinishing()
    this.calculateInternalFinishing()
    this.calculateRoof()
    this.calculateWindows()
    this.calculateDoors()
    this.calculateInsideWalls()
    this.calculateFloor()
    this.calculatePlumbing()
    this.calculateWarming()
    this.calculateWaterProof()
    this.calculateElectric()
    this.calculateAdditional()

    this.calculateTotal()

    this.calculateMounting()
  }

  calculateTotal() {
    this.total = Math.ceil(
      this.containers.total +
      this.carcass.total +
      this.externalFinishing.total +
      this.internalFinishing.total +
      this.roof.total +
      this.windows.total +
      this.doors.total +
      this.insideWalls.total +
      this.floor.total +
      this.plumbing.total +
      this.warming.total +
      this.waterProof.total +
      this.electric.total +
      this.additional.total
    )

    // console.log('this.containers', this.containers)
    // console.log('this.carcass', this.carcass)
    // console.log('this.externalFinishing', this.externalFinishing)
    // console.log('this.internalFinishing', this.internalFinishing)
    // console.log('this.roof', this.roof)
    // console.log('this.windows', this.windows)
    // console.log('this.doors', this.doors)
    // console.log('this.insideWalls', this.insideWalls)
    // console.log('this.floor', this.floor)
    // console.log('this.plumbing', this.plumbing)
    // console.log('this.warming', this.warming)
    // console.log('this.electric', this.electric)
    // console.log('this.additional', this.additional)

  }

  _getSizeItem(length, sizeItems) {
    let founded = null
    for (let i in sizeItems) {
      const sizeItem = sizeItems[i]
      if (length >= sizeItem.min && length <= sizeItem.max) {
        founded = sizeItem
        break
      }
    }

    return founded
  }

  calculateContainers() {
    let minX = null
    let maxX = null
    let minY = null
    let maxY = null
    let stagesCount = 0

    if (this._equipment.containerType) {
      for (const stage in this._params.fullJson) {
        const stageJson = this._params.fullJson[stage]
        if (stageJson) {

          stagesCount = stage

          stageJson.connectors.forEach((connector) => {
            if (minX === null || minX > connector.x) {
              minX = connector.x
            }
            if (maxX === null || maxX < connector.x) {
              maxX = connector.x
            }
            if (minY === null || minY > connector.y) {
              minY = connector.y
            }
            if (maxY === null || maxY < connector.y) {
              maxY = connector.y
            }
          })

          stageJson.boxes.forEach(boxItem => {
            const sizeItem = this._getSizeItem(boxItem.length, this._equipment.containerType.options)
            if (sizeItem) this.containers.total += parseFloat(sizeItem.price)

            // if (ContainerUtil.isStandard(boxItem, this._equipment.containerType.improved)) {
            //   this.containers.total += parseFloat(this._equipment.containerType.raw.price)
            // } else {
            //   const sizeItem = this._getSizeItem(boxItem.length, this._equipment.containerType.options)
            //   if (sizeItem) this.containers.total += parseFloat(sizeItem.price)
            // }
          })
        }
      }


      this.containers.size.width = ((maxX - minX) / 100).toFixed(2)
      this.containers.size.depth = ((maxY - minY) / 100).toFixed(2)
      this.containers.size.height = ((stagesCount * 245) / 100).toFixed(2)
    }
  }

  calculateCarcass() {
    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        if (this._equipment.cornerPosts) {
          this.carcass.cornerPosts += parseFloat(stageJson.boxes.length * this._equipment.cornerPosts.raw.price)
        }

        this.carcass.color += parseFloat(stageJson.boxes.length * this._equipment.carcassColor.raw.price)

        if (this._equipment.carcassStrengthening) {
          const strengtheningOptions = EquipmentCategories.getItems({
            categoryKey: 'karkas',
            optionKey: 'usilenie_karkasa'
          }).options

          stageJson.boxes.forEach(boxItem => {
            const sizeItem = this._getSizeItem(boxItem.length, strengtheningOptions)
            if (sizeItem) this.carcass.strengthening += parseFloat(sizeItem.price)
          })
        }
      }
    }

    this.carcass.total += this.carcass.cornerPosts + this.carcass.color + this.carcass.strengthening
  }

  calculateExternalFinishing() {
    const wallMaterial = this._equipment.wallMaterial
    const wallColor = this._equipment.wallColor

    this.externalFinishing.color.data = wallColor.raw
    this.externalFinishing.material.data = wallMaterial.raw

    let materialBoxLengthsCosts
    wallMaterial.raw.options.forEach(optionItem => {
      if (optionItem.key === 'razmery') {
        materialBoxLengthsCosts = optionItem.options
      }
    })


    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) stageJson.boxes.forEach(boxItem => {
        const sizeItem = this._getSizeItem(boxItem.length, materialBoxLengthsCosts)
        if (sizeItem) this.externalFinishing.material.price += parseFloat(sizeItem.price)

        this.externalFinishing.color.price += wallColor.raw.price
      })
    }

    this.externalFinishing.total = this.externalFinishing.color.price + this.externalFinishing.material.price
  }

  calculateInternalFinishing() {
    const singleMaterial = this._equipment.insideWallMaterial
    const differentMaterials = this._equipment.insideWallDifferentMaterial

    const wallsMaterials = this._equipment.containerType.improved
      ? EquipmentCategories.getCategory('stena_vnutri').options[0].options
      : EquipmentCategories.getCategory('stena_vnutri').options

    const insideWallsMaterials = this._equipment.containerType.improved
      ? EquipmentCategories.getCategory('stena_vnutri').options[0].options
      : EquipmentCategories.getCategory('peregoronka_vnutri').options

    let wallMaterial = wallsMaterials.filter(item => singleMaterial.raw.key === item.key)[0]
    let insideWallMaterial = insideWallsMaterials.filter(item => singleMaterial.raw.key === item.key)[0]

    let wallMaterialSizes = wallMaterial.options

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        stageJson.boxes.forEach(box => {

          if (differentMaterials && box.dictionaryData.wallMaterial) {
            wallMaterial = box.dictionaryData.wallMaterial.raw
            wallMaterialSizes = wallMaterial.options
          }

          let price = 0

          if (this._equipment.containerType.improved) {
            price = parseFloat(wallMaterial.price)
          } else {
            const sizeItem = this._getSizeItem(box.length, wallMaterialSizes)
            if (sizeItem) price += parseFloat(sizeItem.price)
          }

          this.internalFinishing.items.push({
            id: box.id,
            wallMaterial: wallMaterial,
            dictionaryData: {
              wallMaterial
            },
            stage,
            price
          })

          this.internalFinishing.total += price
        })
      }
    }
  }

  calculateInsideWalls() {
    const singleMaterial = this._equipment.insideWallMaterial
    const differentMaterials = this._equipment.insideWallDifferentMaterial
    const partitionWallMaterial = this._equipment.partitionWallMaterial

    const wallsMaterials = EquipmentCategories.getCategory('peregoronka_vnutri').options

    let wallMaterial = this._equipment.containerType.improved ?
      partitionWallMaterial.raw :
      wallsMaterials.filter(item => singleMaterial.raw.key === item.key)[0]

    let insideWallMaterial = this._equipment.containerType.improved
      ? wallsMaterials.filter(item => 'sandwich_panel' === item.key)[0]
      : wallsMaterials.filter(item => singleMaterial.raw.key === item.key)[0]

    let freeWallsLength = this._boxesCount === 1 ? 2.5 : this._boxesCount * 6
    if (this._equipment.containerType.improved && partitionWallMaterial.raw.key === 'sandwich_panel') {
      freeWallsLength = 0
    }

    const getBoxById = (stageData, id) => {
      const list = stageData.boxes.filter(box => box.id === id)
      return list.length > 0 ? list[0] : null
    }

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson && stageJson.insideWalls) {
        stageJson.insideWalls.forEach((wall) => {

          const box = getBoxById(stageJson, wall.boxId)

          if (differentMaterials && box.dictionaryData.wallMaterial) {
            wallMaterial = box.dictionaryData.wallMaterial.raw

            if (!this._equipment.containerType.improved) {
              insideWallMaterial = wallsMaterials.filter(item => box.dictionaryData.wallMaterial.raw.key === item.key)[0]
            }
          }

          let wallLength = ((wall.length + 8) / 100)
          let price = 0

          if (freeWallsLength > 0) {
            if (freeWallsLength < wallLength) {
              wallLength -= freeWallsLength
              freeWallsLength = 0
              price = Math.ceil(parseFloat(wallMaterial.options[0].price) * wallLength)
            } else {
              freeWallsLength -= wallLength
            }
          } else {
            price = Math.ceil(parseFloat(wallMaterial.options[0].price) * wallLength)
          }

          // const price = this._equipment.containerType.improved ? Math.ceil(parseFloat(wallMaterial.options[0].price) * wallLength) : 0
          this.insideWalls.total += price

          this.insideWalls.items.push({
            id: wall.id,
            boxId: wall.boxId,
            stage: parseInt(stage),
            material: wallMaterial,
            price,
          })
        })

        // stageJson.boxes.forEach(box => {
        //
        //   if (differentMaterials && box.dictionaryData.wallMaterial) {
        //     wallMaterial = box.dictionaryData.wallMaterial.raw
        //
        //     if (!this._equipment.containerType.improved) {
        //       insideWallMaterial = wallsMaterials.filter(item => box.dictionaryData.wallMaterial.raw.key === item.key)[0]
        //     }
        //   }
        //
        //   box.insideWalls.forEach(wall => {
        //     let wallLength = ((wall.length + 8) / 100)
        //     let price = 0
        //
        //     if (freeWallsLength > 0) {
        //       if (freeWallsLength < wallLength) {
        //         wallLength -= freeWallsLength
        //         freeWallsLength = 0
        //         price = Math.ceil(parseFloat(wallMaterial.options[0].price) * wallLength)
        //       } else {
        //         freeWallsLength -= wallLength
        //       }
        //     } else {
        //       price = Math.ceil(parseFloat(wallMaterial.options[0].price) * wallLength)
        //     }
        //
        //     // const price = this._equipment.containerType.improved ? Math.ceil(parseFloat(wallMaterial.options[0].price) * wallLength) : 0
        //     this.insideWalls.total += price
        //
        //     this.insideWalls.items.push({
        //       id: wall.id,
        //       boxId: wall.boxId,
        //       stage: parseInt(stage),
        //       material: wallMaterial,
        //       price,
        //     })
        //   })
        // })
      }
    }
  }

  calculateRoof() {
    // const roofMaterialBoxesLengths = this._equipment.roofMaterial.raw.options
    const roofTypeBoxesLengths = this._equipment.roofType.raw.options
    const metalTrussesPrice = EquipmentCategories.getItems({
      categoryKey: 'krovlya',
      optionKey: 'metal_fermy'
    }).options[0].price

    let lastStageData

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]
      if (stageJson) lastStageData = stageJson
    }

    if (this._equipment.roofType.key !== 'ploskaya') {
      const materialKey = this._equipment.roofMaterial.raw.key
      const material = this._equipment.roofType.raw.options.filter(item => item.key === materialKey)[0]
      const connectedBoxesPacks = EditorDataUtil.getConnectedPacks(lastStageData)
      const rectangularBoxesPacks = []

      connectedBoxesPacks.forEach((boxesPack) => {
        const roof = new RoofCalculator({
          connectedBoxes: boxesPack,
          stageConnectors: lastStageData.connectors
        })

        if (roof.isRoofAvailable()) {
          const {width, height} = roof.getSize()
          const square = (width + 60) * (height + 60) * 1.1 / (100 * 100)
          this.roof.color += square * parseFloat(this._equipment.roofColor.raw.price)
          this.roof.material += square * parseFloat(material.options[0].price)
          rectangularBoxesPacks.push(boxesPack)
        }
      })

      let notInPackBoxesSquare = 0

      lastStageData.boxes.forEach((box) => {
        let isInSomePack = false
        rectangularBoxesPacks.forEach((boxesPack) => {
          if (boxesPack.indexOf(box) >= 0) isInSomePack = true
        })

        if (!isInSomePack) {
          notInPackBoxesSquare += box.width * box.height
        }
      })

      notInPackBoxesSquare /= (100 * 100)

      this.roof.color += notInPackBoxesSquare * parseFloat(this._equipment.roofColor.raw.price) * 1.1
      this.roof.material += notInPackBoxesSquare * parseFloat(material.options[1].price) * 1.1
    } else {
      this.roof.color = 0
      this.roof.material = 0
      this.roof.metalTrusses = 0
    }

    let totalSquare = 0
    lastStageData.boxes.forEach((box) => {
      totalSquare += box.width * box.height
    })
    totalSquare /= (100 * 100)

    if (this._equipment.roofMetalTrusses) this.roof.metalTrusses = totalSquare * parseFloat(metalTrussesPrice)

    this.roof.total = Math.ceil(this.roof.color + this.roof.material + this.roof.metalTrusses)
  }

  calculateWindows() {
    let freeWindowsCount = this._boxesCount

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        const windows = stageJson.wallItems.filter(item => item.type === 'Window')
        const boxWindows = {}

        windows.forEach(windowItem => {
          if (!boxWindows[windowItem.boxId]) boxWindows[windowItem.boxId] = []
          boxWindows[windowItem.boxId].push(windowItem)
        })

        for (const boxId in boxWindows) {

          boxWindows[boxId].forEach(windowItem => {
            let price = parseFloat(windowItem.dictionaryData.data.raw.price)

            if (!this._equipment.containerType.improved) {
              if (freeWindowsCount > 0 && windowItem.dictionaryData.data.raw.key === '75h85') {
                freeWindowsCount --
                price = 0
              }
            } else {
              if (freeWindowsCount > 0 && windowItem.dictionaryData.data.raw.key === '80h100_povorotno_otkidnoe') {
                freeWindowsCount --
                price = 0
              }
            }

            if (windowItem.dictionaryData.latticeEnabled) price += parseFloat(windowItem.dictionaryData.lattice.price)
            if (windowItem.dictionaryData.rollerShuttersEnabled) price += parseFloat(windowItem.dictionaryData.rollerShutters.price)

            this.windows.items.push({
              id: windowItem.id,
              dictionaryData: windowItem.dictionaryData,
              stage: parseInt(stage),
              boxId: windowItem.boxId,
              price
            })

            this.windows.total += price
          })
        }
      }
    }
  }

  calculateDoors() {
    const costs = {
      lock2: 0,
      bolt: 0
    }

    EquipmentCategories.getItems({
      categoryKey: 'dveri_vneshnie',
      optionKey: 'prochee'
    }).options.forEach(optionItem => {
      if (optionItem.key === 'vtotoy_zamok') {
        costs.lock2 = parseFloat(optionItem.price)
      } else if (optionItem.key === 'zadvidka') {
        costs.bolt = parseFloat(optionItem.price)
      }
    })

    let freeExternalDoorsCount = this._boxesCount
    let freeInternalDoorsCount = this._boxesCount

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        const doors = stageJson.wallItems.filter(item => item.type === 'Door')

        const boxDoors = {}

        doors.forEach(doorItem => {
          if (!boxDoors[doorItem.boxId]) {
            boxDoors[doorItem.boxId] = []
          }
          boxDoors[doorItem.boxId].push(doorItem)
        })

        for (const boxId in boxDoors) {

          boxDoors[boxId].forEach(doorItem => {
            let doorCost = parseFloat(doorItem.dictionaryData.size && doorItem.dictionaryData.size.raw ? doorItem.dictionaryData.size.raw.price : doorItem.dictionaryData.data.raw.price)

            if (this._equipment.containerType.improved) {
              if (freeExternalDoorsCount > 0 && doorItem.dictionaryData.id === 'dver_metall' && doorItem.dictionaryData.size.key === "80_h_200_sm") {
                freeExternalDoorsCount--
                doorCost = 0
              }
            } else {
              if (freeExternalDoorsCount > 0 && doorItem.dictionaryData.id === 'dver_orgalit' && doorItem.dictionaryData.size.key === "80_h_200_sm") {
                freeExternalDoorsCount--
                doorCost = 0
              }
            }

            if (freeInternalDoorsCount > 0 && (
                (doorItem.dictionaryData.id === 'dver_iz_orgalita' && doorItem.dictionaryData.size.key === "80_h_200_sm") ||
                (doorItem.dictionaryData.id === 'dver_iz_mdf' && doorItem.dictionaryData.size.key === "80_h_200_sm")
              )) {
              freeInternalDoorsCount--
              doorCost = 0
            }

            if (doorItem.dictionaryData.closerEnabled) this.doors.total += parseFloat(doorItem.dictionaryData.closer.price)
            if (doorItem.dictionaryData.lock2Enabled) doorCost += parseFloat(costs.lock2)
            if (doorItem.dictionaryData.boltEnabled) doorCost += parseFloat(costs.bolt)
            if (doorItem.dictionaryData.metalFinishingEnabled) doorCost += parseFloat(doorItem.dictionaryData.metalFinishing.price)
            if (doorItem.dictionaryData.configuration) doorCost += parseFloat(doorItem.dictionaryData.configuration.price)
            if (doorItem.dictionaryData.colors) doorCost += parseFloat(doorItem.dictionaryData.color.raw.price)

            this.doors.items.push({
              id: doorItem.id,
              dictionaryData: doorItem.dictionaryData,
              stage: parseInt(stage),
              boxId,
              price: doorCost,
            })
            this.doors.total += doorCost

          })
        }
      }
    }
  }

  calculateFloor() {
    const additionalLags = this._equipment.additionalLags
    const metalLags = this._equipment.metalLags

    if (metalLags) {
      const oneLagPrice = parseFloat(EquipmentCategories.getItems({
        categoryKey: this._equipment.containerType.improved ? 'dno' : 'chernovoy_pol',
        optionKey: 'ysilenie_pola'
      }).options.filter(item => item.key === 'one_metal_lag')[0].price)

      this.floor.metalLagsCustomCount = this._params.boxesCount * (this._equipment.metalLagsCount * oneLagPrice)
    }

    const mainFloor = this._equipment.mainFloor
    const additionalMainFloor = this._equipment.additionalMainFloor
    const mainFloorCoating = this._equipment.floorCoating

    let additionalLagsSizes, metalLagsSizes

    const lagsOptions = EquipmentCategories.getItems({
      categoryKey: this._equipment.containerType.improved ? 'dno' : 'chernovoy_pol',
      optionKey: 'ysilenie_pola'
    })

    if (lagsOptions.options) {
      lagsOptions.options.forEach(optionItem => {
        if (optionItem.key === 'lagi_derevo') {
          additionalLagsSizes = optionItem.options
        } else if (optionItem.key === 'ysilenie_pola') {
          metalLagsSizes = optionItem.options
        }
      })
    }

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        stageJson.boxes.forEach(boxItem => {
          if (this._equipment.additionalFloor) {
            const sizeItem = this._getSizeItem(boxItem.length, this._equipment.additionalFloor.raw.options)
            if (sizeItem) this.floor.additionalFloor += parseFloat(sizeItem.price)
          }

          if (additionalLags) {
            const sizeItem = this._getSizeItem(boxItem.length, additionalLagsSizes)
            if (sizeItem) this.floor.additionalLags += parseFloat(sizeItem.price)
          }

          if (metalLags) {
            const sizeItem = this._getSizeItem(boxItem.length, metalLagsSizes)
            if (sizeItem) this.floor.metalLags += parseFloat(sizeItem.price)
          }

          if (mainFloor.raw.options) {
            const sizeItem = this._getSizeItem(boxItem.length, mainFloor.raw.options)
            if (sizeItem) this.floor.mainFloor += parseFloat(sizeItem.price)
          }

          if (additionalMainFloor.raw.options) {
            const sizeItem = this._getSizeItem(boxItem.length, additionalMainFloor.raw.options)
            if (sizeItem) this.floor.additionalMainFloor += parseFloat(sizeItem.price)
          }

          if (this._equipment.bottomFloor) {
            const sizeItem = this._getSizeItem(boxItem.length, this._equipment.bottomFloor.raw.options)
            if (sizeItem) this.floor.bottomFloor += parseFloat(sizeItem.price)
          }

          if (boxItem.dictionaryData.floorCoating) {
            if (boxItem.dictionaryData.floorCoating.raw.options) {
              const sizeItem = this._getSizeItem(boxItem.length, boxItem.dictionaryData.floorCoating.raw.options)
              if (sizeItem) {
                this.floor.mainFloorCoating += parseFloat(sizeItem.price)

                let item = {
                  boxId: boxItem.id,
                  stage: parseInt(stage),
                  price: sizeItem.price,
                  cost: sizeItem.cost,
                  coating: boxItem.dictionaryData.floorCoating.raw
                }
                // delete item.coating.options

                this.floor.items.push(item)
              }
            }
          } else {
            if (mainFloorCoating.raw.options) {
              const sizeItem = this._getSizeItem(boxItem.length, mainFloorCoating.raw.options)
              if (sizeItem) {
                this.floor.mainFloorCoating += parseFloat(sizeItem.price)
                let item = {
                  boxId: boxItem.id,
                  stage: parseInt(stage),
                  price: sizeItem.price,
                  cost: sizeItem.cost,
                  coating: mainFloorCoating.raw
                }
                // delete item.coating.options

                this.floor.items.push(item)
              }
            }
          }
        })
      }
    }

    this.floor.total = this.floor.additionalFloor +
      this.floor.additionalLags +
      this.floor.metalLags +
      this.floor.metalLagsCustomCount +
      this.floor.mainFloor +
      this.floor.bottomFloor +
      this.floor.additionalMainFloor +
      this.floor.mainFloorCoating
  }

  calculatePlumbing() {
    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      const types = [
        'WashStandSmall',
        'WashStangBig',
        'Shower',
        'ToiletBowl',
        'Boiler',
        'Conditioner'
      ]

      if (stageJson) {
        stageJson.boxItems.forEach(item => {
          if (types.indexOf(item.type) >= 0) {
            const price = item.dictionaryData.option ? parseFloat(item.dictionaryData.option.cost) : parseFloat(item.dictionaryData.data.raw.price)
            this.plumbing.total += price

            this.plumbing.items.push({
              id: item.id,
              boxId: item.boxId,
              dictionaryData: item.dictionaryData,
              stage,
              price
            })
          }
        })
      }
    }
  }

  calculateWarming() {
    const sizesList = this._equipment.warming.thickness.raw.options

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        if (sizesList) {
          stageJson.boxes.forEach(boxItem => {
            const sizeItem = this._getSizeItem(boxItem.length, sizesList)
            if (sizeItem) this.warming.total += parseFloat(sizeItem.price)
          })
        } else {
          this.warming.total += stageJson.boxes.length * parseFloat(this._equipment.warming.thickness.raw.price)
        }
      }
    }
  }

  calculateWaterProof() {
    const internalSizesList = EquipmentCategories.getItems({
      categoryKey: 'gidroizolyaciya',
      optionKey: 'plenka_B'
    }).options

    const externalSizesList = EquipmentCategories.getItems({
      categoryKey: 'gidroizolyaciya',
      optionKey: 'plenka_A'
    }).options

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {

        stageJson.boxes.forEach(boxItem => {
          if (this._equipment.waterproofing.internal) {
            const sizeItem = this._getSizeItem(boxItem.length, internalSizesList)
            if (sizeItem) this.waterProof.internal += parseFloat(sizeItem.price)
          }

          if (this._equipment.waterproofing.external) {
            const sizeItem = this._getSizeItem(boxItem.length, externalSizesList)
            if (sizeItem) this.waterProof.external += parseFloat(sizeItem.price)
          }
        })
      }
    }

    this.waterProof.total = this.waterProof.internal + this.waterProof.external
  }

  calculateElectric() {
    const packet = this._equipment.electricPacket
    let freeSockets = this._boxesCount * 2
    let freeLights = this._boxesCount * 2

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        this.electric.packet += stageJson.boxes.length * parseFloat(packet.price)
      }
    }

    this._equipment.electricSockets.forEach(socket => {
      if (socket.key === 'rozetka_sdvoennaya' && freeSockets > 0) {
        freeSockets -= socket.count
        const delta = -freeSockets
        if (delta > 0) {
          this.electric.sockets += delta * parseFloat(socket.raw.price)
        }
      } else {
        this.electric.sockets += socket.count * parseFloat(socket.raw.price)
      }
    })

    this._equipment.electricLights.forEach(light => {
      if (light.key === 'plafon_tabletka' && freeLights > 0) {
        freeLights -= light.count
        const delta = -freeLights
        if (delta > 0) {
          this.electric.lights += delta * parseFloat(light.raw.price)
        }
      } else {
        this.electric.lights += light.count * parseFloat(light.raw.price)
      }
    })

    this.electric.total = this.electric.packet + this.electric.sockets + this.electric.lights
  }

  calculateAdditional() {
    const additionalKeys = []
    EquipmentCategories.getCategory('dop_elementy').options.forEach(item => {
      if (item.key === 'lestnicy') {
        item.options.forEach(stairItem => additionalKeys.push(stairItem.key))
      } else {
        additionalKeys.push(item.key)
      }
    })

    const plumbingTypes = [
      'WashStandSmall',
      'WashStangBig',
      'Shower',
      'ToiletBowl',
      'Boiler',
      'Conditioner'
    ]

    for (const stage in this._params.fullJson) {
      const stageJson = this._params.fullJson[stage]

      if (stageJson) {
        stageJson.boxItems.forEach(item => {
          if (plumbingTypes.indexOf(item.type) < 0) {
            let price = 0
            if (item.dictionaryData.option) {
              price = parseFloat(item.dictionaryData.option.raw.price)
            } else {
              price = parseFloat(item.dictionaryData.data.raw.price)
            }

            this.additional.total += price

            this.additional.items.push({
              id: item.id,
              boxId: item.box,
              dictionaryData: item.dictionaryData,
              price
            })
          }
        })

        stageJson.wallItems.forEach(item => {
          if (item.type === 'Additional' ) {
            let price = 0
            if (item.dictionaryData.option) {
              price = parseFloat(item.dictionaryData.option.raw.price)
            } else {
              price = parseFloat(item.dictionaryData.data.raw.price)
            }

            this.additional.total += price

            this.additional.items.push({
              id: item.id,
              boxId: item.box,
              dictionaryData: item.dictionaryData,
              price
            })
          }
        })

        stageJson.externalItems.forEach(item => {
          const price = parseFloat(item.dictionaryData.data.raw.price)
          this.additional.total += price
          this.additional.items.push({
            id: item.id,
            boxId: item.box,
            dictionaryData: item.dictionaryData,
            price
          })
        })
      }
    }
  }

  calculateMounting() {
    if (this._boxesCount > 1) {
      const totalCostWithoutRoof = this.total - this.roof.total
      this.mounting.total = Math.ceil( (this._equipment.mounting.price / 100) * totalCostWithoutRoof)
    } else {
      this.mounting.total = 0
    }
  }
}

export default CostCalculator
