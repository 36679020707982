import BoxItemView from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem/BoxItemView';
import { Bitmap, Container } from '@createjs/easeljs';

class ToiletView extends BoxItemView {

  /**
   * @type {Bitmap}
   * @private
   */
  _image

  /**
   * @param boxItem {BoxItem}
   */
  constructor(boxItem) {
    super(boxItem)

    this._image = new Bitmap(require('@/assets/images/editor/boxItems/toilet.png'))
    const imageContainer = new Container()
    imageContainer.addChild(this._image)

    this.container.addChild(imageContainer)

    imageContainer.scale = 0.28

    imageContainer.x = - (320 * imageContainer.scale) / 2
    imageContainer.y = - (479 * imageContainer.scale) / 2 + 5
  }
}

export default ToiletView
