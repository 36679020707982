import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement'
import { Bitmap, Shape } from '@createjs/easeljs'
import Container from '@createjs/easeljs/src/display/Container'
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall'
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor'
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid'
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events'
import WallConnector from '@/components/BlocksEditor/CreateJsBlocksEditor/WallConnector';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';

class DashedWallActionsView extends BaseEditorElement {

  /**
   * @type {easeljs.Container}
   * @private
   */
  _addButton = new Container()
  /**
   * @type {easeljs.Container}
   * @private
   */
  _deleteButton = new Container()

  /**
   * @type {Wall}
   * @private
   */
  _wall

  /**
   * @param wall {Wall}
   */
  constructor(wall) {
    super()

    this._wall = wall

    this._createButtons()

    this._addButton.addEventListener('click', this._onAddClick)
    this._deleteButton.addEventListener('click', this._onDeleteClick)

    this.updateButtonsStates()

    this.draw()

    CreateJsBlocksEditor.Instance.addEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
    Grid.Instance.addEventListener(Events.GRID_MOVED, this._onGridMoved)

    Grid.Instance.actionsContainer.addChild(this)
  }

  get isActivated() {
    return this._wall._intersectionWallActivated
  }

  _onElementSelected = () => {
    this.draw()
  }

  _onGridMoved = () => {
    this.draw()
  }

  _onAddClick = () => {
    this.toggleLine()
  }

  _onDeleteClick = () => {
    this.toggleLine()
  }

  draw() {
    if (this._wall && this._wall.isSelected()) {
      this.container.scale = 1 / Grid.Instance.container.scale
      this.updatePosition()
    } else {
      this.container.scale = 0
    }
  }

  _createButtons() {
    const addButtonShape = new Shape()
    const addButtonBitmap = new Bitmap(require('@/assets/images/editor/actions/plus-small-red.png'))
    addButtonShape.graphics
      .clear()
      .beginFill('#ffffff')
      .beginStroke('#efefef')
      .drawCircle(0,0, 24)
      .endFill()

    addButtonBitmap.scale = 0.5
    addButtonBitmap.x = -16 * addButtonBitmap.scale
    addButtonBitmap.y = -16 * addButtonBitmap.scale

    this._addButton.addChild(addButtonShape)
    this._addButton.addChild(addButtonBitmap)

    this.container.addChild(this._addButton)

    const deleteButtonShape = new Shape()
    const deleteButtonBitmap = new Bitmap(require('@/assets/images/editor/actions/delete.png'))
    deleteButtonShape.graphics
      .clear()
      .beginFill('#ffffff')
      .beginStroke('#efefef')
      .drawCircle(0,0, 24)
      .endFill()

    deleteButtonBitmap.scale = 0.3
    deleteButtonBitmap.x = -30 * deleteButtonBitmap.scale
    deleteButtonBitmap.y = -30 * deleteButtonBitmap.scale

    this._deleteButton.addChild(deleteButtonShape)
    this._deleteButton.addChild(deleteButtonBitmap)

    this.container.addChild(this._deleteButton)
  }

  toggleLine() {
    const box = Box.LAST_SELECTED_BOX

    /**
     * @type {WallConnector[]}
     */
    const newConnectors = []
    const originalConnectors = [this._wall.firstConnector, this._wall.lastConnector]

    originalConnectors.forEach((c) => {
      newConnectors.push(WallConnector.Create(c))
    })

    const [c1, c2] = newConnectors
    if (this._wall.vertical) {
      c1.y += 4
      c2.y -= 4
    } else {
      c1.x += 4
      c2.x -= 4
    }

    const wall = Wall.CreateInsideBoxWallWithConnectors(newConnectors, box)

    CreateJsBlocksEditor.Instance.setSelectedElement(wall)

    this.updateButtonsStates()

    this.draw()
  }

  updateButtonsStates() {
    let someWallIntersected = false

    Wall.InsideWallsList.forEach((wallItem) => {
      const rect = wallItem.rectangle.intersection(this._wall.rectangle)
      if (this._wall.isVertical === wallItem.isVertical && rect) {
        someWallIntersected = true
      }
    })

    if (someWallIntersected) {
      this._deleteButton.scale = 0
      this._addButton.scale = 0
    } else {
      this._deleteButton.scale = 0
      this._addButton.scale = 1
    }
  }

  updatePosition() {
    const center = this._wall.center
    this.x = center.x
    this.y = center.y

    this.x += !this._wall.vertical ? 0 : -30
    this.y += !this._wall.vertical ? -30 : 0

    this.updateButtonsStates()
  }

  destroy() {
    if (!this.destroyed) {
      super.destroy()
      Grid.Instance.actionsContainer.removeChild(this)
      Grid.Instance.removeEventListener(Events.GRID_MOVED, this._onGridMoved)
      CreateJsBlocksEditor.Instance.removeEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
    }
  }
}

export default DashedWallActionsView
