<template lang="pug">
  .result-information(:style="elStyle")
    .cost
      .label Стоимость
      .value {{resultCost | currency_wo_plus }}
    .time
      .label Время изготовления
      .value {{time}}
    .button-wrapper
      kraus-button(:label="'Заказать'", :colors="{background: '#0558AB', label: '#FFFFFF'}", @click="onOrderClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { mapActions, mapGetters } from 'vuex';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
export default {
  name: 'ResultInformation',
  components: { KrausButton },
  data() {
    return {
      cost: '0 ₽',
      time: '7 - 14 дней',
      width: 0,
      windowWidth: 0,
    }
  },

  mounted() {
    this.updateSizes()
    window.addEventListener('resize', this.updateSizes)
  },

  destroyed() {
    window.removeEventListener('resize', this.updateSizes)
  },

  computed: {
    ...mapGetters([
      'totalCost',
      'mountingCost',
      'editorType'
    ]),

    elStyle() {
      return {
        left: (this.windowWidth - this.width) / 2 + 'px'
      }
    },

    resultCost() {
      return this.totalCost + this.mountingCost.total
    }
  },

  methods: {
    ...mapActions([
      'setEditorType'
    ]),

    onOrderClick() {
      if (this.editorType !== '2D') {
        this.setEditorType('2D')
        this.$nextTick(() => {
          setTimeout(() => {
            eventBus.$emit(EventBusEvents.CreateStageImages, 'for-ordering')
          }, 500)
        })
      } else {
        eventBus.$emit(EventBusEvents.CreateStageImages, 'for-ordering')
      }
    },

    updateSizes() {
      const rightMenuEl = document.querySelector('.building-equipment')
      const rightMenuWidth = rightMenuEl ? rightMenuEl.getBoundingClientRect().width : 0

      this.width = this.$el.getBoundingClientRect().width
      this.windowWidth = window.innerWidth - rightMenuWidth //324 ширина размер правогого меню
    }
  }
}
</script>
