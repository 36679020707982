import { ArcRotateCamera, Vector3 } from '@babylonjs/core';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';

class CameraController {
  /**
   * @type {Scene}
   * @private
   */
   _scene

  /**
   * @type {HTMLCanvasElement}
   * @private
   */
  _canvas

  /**
   * @type {ArcRotateCamera}
   * @private
   */
  _camera

  /**
   *
   * @private {Vector3}
   */
  _targetPosition = null
  /**
   *
   * @private {Vector3}
   */
  _cameraPosition

  /**
   * @type {Vector3}
   * @private
   */
  _cameraTarget = new Vector3(250 * BlocksConstructor.Scale, 1, 0)

  _mouseCaptured = false


  constructor({scene, canvas}) {
    this._scene = scene
    this._canvas = canvas
    this.initCamera()

    this._scene.onBeforeRenderObservable.add(() => {
      this.update()
    })
  }

  get camera() {
    return this._camera
  }

  initCamera() {
    this._camera = new ArcRotateCamera("Camera", +Math.PI / 3.2, Math.PI / 2.5, 10, this._cameraTarget, this._scene)

    this._camera.lowerRadiusLimit = 0.1
    this._camera.upperRadiusLimit = 100

    this._camera.maxZ = 10000
    this._camera.minZ = 0
    this._camera.wheelPrecision = 200
    this._camera.attachControl(this._canvas, true)
  }

  update() {
    if (this._targetPosition) {
      // const toRadius = 5
      // const toAlpha = Math.PI / 2 - Math.PI / 10
      // const toBeta = Math.PI / 2 - Math.PI / 10

      const toPosition = this._cameraPosition ? this._cameraPosition : new Vector3(5.8,4, 7)
      const toTarget = new Vector3(2,2,0) //this._targetPosition.clone()
      toTarget.y -= 1
      // console.log(this._cameraTarget)

      this._camera.target = Vector3.Lerp(this._camera.target, toTarget, 0.03)
      this._camera.position = Vector3.Lerp(this._camera.position, toPosition, 0.03)

      const targetDistance = Vector3.Distance(this._targetPosition, this._camera.target)
      const positionDistance = Vector3.Distance(toPosition, this._camera.position)

      if (targetDistance < 0.05 && positionDistance < 0.05) {
        this._targetPosition = null
      }

      // if (distance > 0.1) {
      //   this._camera.setTarget(newTarget)
      // } else {
      //   this._camera.setTarget(this._targetPosition.clone())
      // }

      // // this._camera.target = newTarget
      // this._camera.radius += (toRadius - this._camera.radius) / 50
      // this._camera.alpha += (toAlpha - this._camera.alpha) / 50
      // this._camera.beta += (toBeta - this._camera.beta) / 50
      //
      // const deltaRadius = Math.abs(toRadius - this._camera.radius)
      // const deltaAlpha = Math.abs(toAlpha - this._camera.alpha)
      // const deltaBeta = Math.abs(toBeta - this._camera.beta)

      // if (distance < 0.1 && deltaRadius < 0.1 && deltaAlpha < 0.1 && deltaBeta < 0.1) {
      //   this._targetPosition = null
      // }

    }
  }

  /**
   *
   * @param position {Vector3}
   * @param position {Vector3}
   */
  setTarget(position, cameraPosition) {
    this._targetPosition = position
    this._cameraPosition = cameraPosition
  }

  dispose() {
    this._camera.dispose()
  }
}

export default CameraController
