<template lang="pug">
  .loader
</template>
<script>
import api from '@/common/http/api';
import { mapActions } from 'vuex';
import EquipmentCategories from '@/common/EquipmentCategories';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';

export default {
  name: 'Project',
  data() {
    return {
      id: null
    }
  },
  created() {
    this.id = this.$route.params.id
    if (this.id) {
      this.loadProject().finally(() => {
        this.$router.replace('/')
      })
    } else {
      this.$router.replace('/')
    }
  },

  methods: {
    ...mapActions([
      'setStage',
      'setJsonData',
      'setStageJsonData',
      'restoreEquipmentStore',
      'setRestored',
      'addTooltipsToShowedList',
      'disableRendererTooltips'
    ]),

    async loadProject()  {
      const response = await api.get('/project/' + this.id)
      const {fullJson, dictionary, equipment} = response.data

      this.restoreEquipmentStore(equipment)
      EquipmentCategories.setRawData(dictionary)
      EquipmentCategories.setContainerType(equipment.containerType.key)

      if (fullJson) {
        this.disableRendererTooltips()
        for (const stage in fullJson) {
          this.setStageJsonData({
            stage,
            data: fullJson[stage]
          })
        }

        this.setStage(1)
        this.setJsonData(fullJson[1])

        this.setRestored(true)
        this.addTooltipsToShowedList('2D')
        this.addTooltipsToShowedList('3D')
        eventBus.$emit(EventBusEvents.HidePopup)
      }

      return response.data
    }
  }
}
</script>
