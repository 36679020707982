<template lang="pug">
  .mounting-form
    mounting-item(v-for="(item, index) in mountingItems",
      :key="index",
      :data="item",
      :selected="mounting && item.key === mounting.key"
    )
</template>
<script>
import EquipmentCategories from '@/common/EquipmentCategories';
import MountingItem
  from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Mounting/MountingItem';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Mounting',
  components: { MountingItem },
  data() {
    return {}
  },

  computed: {
    ...mapGetters([
      'mounting',
    ]),

    mountingItems() {
      return EquipmentCategories.getCategory('montazh').options.map(item => {
        return {
          key: item.key,
          title: item.title,
          price: item.price
        }
      })
    },
  },


  created() {
    if (!this.mounting) {
      this.setMounting(this.mountingItems[0])
    }
  },

  methods: {
    ...mapActions([
      'setMounting',
    ]),
  }
}
</script>
