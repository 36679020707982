<template lang="pug">
  .container-item(:class="{opened: opened}", v-show="visible")
    .container-item_info(@click="onItemClick")
      .container-item_arrow(v-if="arrowOnLeftSide")
      .container-item_info_wrapper
        .container-item_title {{title}}
        .container-item_cost(v-show="cost !== 0") {{cost | currency }}
      .container-item_arrow.right(v-if="!arrowOnLeftSide")
    .container-item_content(:class="{opened: this.opened}", :style="{height: (opened ? contentWrapperHeight : 0) + 'px', opacity: opened ? 1 : 0}")
      .container-item_content_wrapper(ref="contentWrapper")
        component(v-bind:is="component")
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ContainerItem',
  props: {
    type: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      opened: false,
      contentWrapperHeight: 0,
      isAnimated: true
    }
  },

  mounted() {
    this.draw()
  },

  computed: {
    ...mapGetters([
      'insideWallsCost',
      'doorsCost',
      'windowsCost',
      'roofCost',
      'carcassCost',
      'externalFinishingCost',
      'internalFinishingCost',
      'floorCost',
      'plumbingCost',
      'warmingCost',
      'waterProofCost',
      'electricCost',
      'additionalCost',
      'mountingCost'
    ]),

    visible() {
      return this.type.id === 'Mounting' ? this.cost > 0 : true
    },

    cost() {
      let cost = 0

      switch (this.type.id) {
        case 'Carcass':
          cost = this.carcassCost.total
          break
        case 'ExternalFinishing':
          cost = this.externalFinishingCost.total
          break
        case 'Roof':
          cost = this.roofCost.total
          break
        case 'Windows':
          cost = this.windowsCost.total
          break
        case 'Doors':
          cost = this.doorsCost.total
          break
        case 'InternalFinishing':
          cost = this.insideWallsCost.total + this.internalFinishingCost.total
          break
        case 'Floor':
          cost = this.floorCost.total
          break
        case 'Plumbing':
          cost = this.plumbingCost.total
          break
        case 'Waterproofing':
          cost = this.waterProofCost.total
          break
        case 'Warming':
          cost = this.warmingCost.total
          break
        case 'Electric':
          cost = this.electricCost.total
          break
        case 'Additional':
          cost = this.additionalCost.total
          break
        case 'Mounting':
          cost = this.mountingCost.total
          break
      }

      return cost
    },

    title() {
      return this.type.title
    },

    component() {
      return this.type.component
    },

    arrowOnLeftSide() {
      return this.$route.name !== 'Ordering'
    }
  },

  beforeDestroy() {
    this.isAnimated = false
  },

  methods: {
    getContentWrapperHeight() {
      return this.$refs.contentWrapper ? this.$refs.contentWrapper.getBoundingClientRect().height : 0
    },

    draw() {
      if (this.isAnimated) requestAnimationFrame(() => {
        this.draw()
      })
      this.contentWrapperHeight = this.getContentWrapperHeight()
    },

    onItemClick() {
      this.toggleOpenedState()
    },

    toggleOpenedState() {
      this.opened = !this.opened
    }
  }
}
</script>
