import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';

class MapUtil {
  static container(options, {improved}) {
    const data = options[0]
    return {
      id: data.key,
      type: 'box-container',
      title: 'Блок-контейнер',
      icon: '/pics/menu-items/box.png',
      size: '5,85м × 2,45м × 2,45м',
      popupIcon: require('@/assets/images/ui/popup/select-container-type/type-1.svg'),
      options: options,
      improved: improved,
      data: {
        raw: data,
        cost: data.price
      }
    }
  }

  static windowWood(data) {
    let lattice = data.reshotka ? data.reshotka : null
    let rollerShutters = data.rolstavni ? data.rolstavni : null

    return {
      id: data.key,
      type: 'window',
      title: data.title,
      section: 'Деревянные окна',
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/windows/wood/' + data.key + '.svg'),
      latticeEnabled: false,
      rollerShuttersEnabled: false,
      lattice,
      rollerShutters,
      material: 'wood',
      size: {
        width: data.width,
        height: data.height
      },
      data: {
        raw: data,
        cost: data.price
      }
    }
  }

  static windowPlastic(data) {
    let lattice = data.reshotka ? data.reshotka : null
    let rollerShutters = data.rolstavni ? data.rolstavni : null

    return {
      id: data.key,
      type: 'window',
      title: data.title,
      section: 'Пластиковые',
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/windows/plastic/' + data.key + '.svg'),
      latticeEnabled: false,
      rollerShuttersEnabled: false,
      lattice,
      rollerShutters,
      material: 'plastic',
      size: {
        width: data.width,
        height: data.height
      },
      data: {
        raw: data,
        cost: data.price
      }
    }
  }

  static doorExternal(data) {
    let sizes = null
    let colors = null
    let configurations = null
    let metalFinishing = null
    let closer = null
    if (data.options) data.options.forEach(optionItem => {
      if (optionItem.key === 'razmer') sizes = optionItem.options
      if (optionItem.key === 'cvet_dveri') colors = optionItem.options
      if (optionItem.key === 'konfiguraciya') configurations = optionItem.options
      if (optionItem.key === 'otdelka_dveri_iznutri_metallom_vmesto_dermantina') metalFinishing = optionItem
      if (optionItem.key === 'closer') metalFinishing = optionItem
      if (optionItem.key === 'dovodchik_na_metallicheskuyu_dver') closer = optionItem
    })

    if (colors) colors.forEach(item => {
      item.raw = {
        cost: item.cost,
        hex: item.hex,
        key: item.key,
        price: item.price,
        title: item.title,
      }
    })

    if (sizes) sizes.forEach(item => {
      item.raw = {
        cost: item.cost,
        width: item.width,
        height: item.height,
        key: item.key,
        price: item.price,
        title: item.title
      }
    })

    return {
      id: data.key,
      type: 'door',
      title: data.title,
      section: 'Внешние двери',
      icon: '/pics/menu-items/box.png',
      size: sizes ? sizes[0] : {width: data.width, height: data.height},
      menuIcon: require('@/assets/images/left-menu/items/doors/external/' + data.key + '.svg'),
      sizes,
      lock1Enabled: true,
      lock2Enabled: false,
      boltEnabled: false,
      color: colors ? colors[0] : null,
      colors,
      configuration: configurations ? configurations[0] : null,
      configurations,
      inside: false,
      metalFinishingEnabled: false,
      metalFinishing,
      closerEnabled: false,
      closer,
      external: true,
      data: {
        cost: sizes ? sizes[0].price : 0,
        raw: data,
        sizes: sizes
      }
    }
  }

  static doorOther(data) {
    let sizes = null
    let colors = null
    let configurations = null
    let metalFinishing = null
    let closer = null
    if (data.options) data.options.forEach(optionItem => {
      if (optionItem.key === 'razmer') sizes = optionItem.options
      if (optionItem.key === 'cvet_dveri') colors = optionItem.options
      if (optionItem.key === 'konfiguraciya') configurations = optionItem.options
      if (optionItem.key === 'otdelka_dveri_iznutri_metallom_vmesto_dermantina') metalFinishing = optionItem
      if (optionItem.key === 'closer') metalFinishing = optionItem
    })

    if (colors) colors.forEach(item => {
      item.raw = {
        cost: item.cost,
        hex: item.hex,
        key: item.key,
        price: item.price,
        title: item.title,
      }
    })

    if (sizes) sizes.forEach(item => {
      item.raw = {
        cost: item.cost,
        width: item.width,
        height: item.height,
        key: item.key,
        price: item.price,
        title: item.title
      }
    })

    if (data.key === 'vorota' || data.key === 'rolstavni') {
      data.width = 237
      data.height = 265
    }

    return {
      id: data.key,
      type: 'door',
      title: data.title,
      section: 'Разное',
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/doors/other/' + data.key + '.svg'),
      size: sizes ? sizes[0] : (data.width ? {width: data.width, height: data.height} : {width: 60, height: 16}),
      sizes,
      lock1Enabled: false,
      lock2Enabled: false,
      boltEnabled: false,
      color: colors ? colors[0] : null,
      colors,
      configuration: configurations ? configurations[0] : null,
      configurations,
      inside: false,
      metalFinishingEnabled: false,
      metalFinishing,
      closerEnabled: false,
      closer,
      external: true,
      data: {
        cost: sizes ? sizes[0].price : data.price,
        raw: data,
        sizes: sizes
      }
    }
  }

  static doorInternal(data) {
    let sizes = null
    let colors = null
    let configurations = null
    let metalFinishing = null
    let closer = null
    if (data.options) data.options.forEach(optionItem => {
      if (optionItem.key === 'razmer') sizes = optionItem.options
      if (optionItem.key === 'cvet_dveri') colors = optionItem.options
      if (optionItem.key === 'konfiguraciya') configurations = optionItem.options
      if (optionItem.key === 'otdelka_dveri_iznutri_metallom_vmesto_dermantina') metalFinishing = optionItem
      if (optionItem.key === 'closer') metalFinishing = optionItem
    })

    if (colors) colors.forEach(item => {
      item.raw = {
        cost: item.cost,
        hex: item.hex,
        key: item.key,
        price: item.price,
        title: item.title,
      }
    })

    if (sizes) sizes.forEach(item => {
      item.raw = {
        cost: item.cost,
        width: item.width,
        height: item.height,
        key: item.key,
        price: item.price,
        title: item.title
      }
    })

    return {
      id: data.key,
      type: 'door',
      title: data.title,
      section: 'Межкомнатные двери',
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/doors/internal/' + data.key + '.svg'),
      size: sizes ? sizes[0] : {width: data.width, height: data.height},
      sizes,
      lock1Enabled: true,
      lock2Enabled: false,
      boltEnabled: false,
      color: colors ? colors[0] : null,
      colors,
      configuration: configurations ? configurations[0] : null,
      configurations,
      inside: true,
      metalFinishingEnabled: false,
      metalFinishing,
      closerEnabled: false,
      closer,
      external: false,
      data: {
        cost: sizes ? sizes[0].price : 0,
        raw: data,
        sizes: sizes
      }
    }
  }

  static washStand(data) {
    return {
      id: data.key,
      type: 'washing-stand',
      editorType: data.key === 'tulpan_60' ? BoxItem.Types.WashStandBig : BoxItem.Types.WashStandSmall,
      title: data.title,
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/plumbing/' + data.key + '.svg'),
      data: {
        raw: data,
        cost: data.price,
      }
    }
  }

  static toilet(data) {
    return {
      id: data.key,
      type: 'washing-stand',
      editorType: BoxItem.Types.ToiletBowl,
      title: data.title,
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/plumbing/' + data.key + '.svg'),
      data: {
        raw: data,
        cost: data.price,
      }
    }
  }

  static shower(data) {
    return {
      id: data.key,
      type: 'washing-stand',
      editorType: BoxItem.Types.Shower,
      title: data.title,
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/plumbing/' + data.key + '.svg'),
      data: {
        raw: data,
        cost: data.price,
      }
    }
  }

  static internalStair(data) {
    return {
      id: data.key,
      type: 'stairs-internal',
      title: data.title,
      editorType: BoxItem.Types.Stairs,
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/second-floor/' + data.key + '.svg'),
      data: {
        raw: data,
        cost: data.price,
      }
    }
  }

  static externalStair(data, mirrored) {
    data = Object.assign({}, data)
    data.mirrored = mirrored

    let menuIcon
    if (data.mirrored) {
      menuIcon = require('@/assets/images/left-menu/items/second-floor/' + data.key + '.svg')
    } else {
      menuIcon = require('@/assets/images/left-menu/items/second-floor/' + data.key + '_mirror.svg')
    }

    return {
      id: data.key,
      type: 'stairs-external',
      title: data.title,
      editorType: ExternalItem.Types.Stairs,
      icon: '/pics/menu-items/box.png',
      menuIcon: menuIcon,
      mirrored: data.mirrored,
      data: {
        raw: data,
        cost: data.price,
      }
    }
  }

  static stairPlatform(data) {
    return {
      id: data.key,
      type: 'stairs-external',
      title: data.title,
      editorType: ExternalItem.Types.Stairs,
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/second-floor/' + data.key + '.svg'),
      data: {
        raw: data,
        cost: data.price,
      }
    }
  }

  static additionalItem(data) {
    const additionalKeyAlias = {
      'vytyazhka': BoxItem.Types.Ventilation,
      'obogrevatel_elektricheskiy': BoxItem.Types.ElectricHeating,
      'batareya_otopleniya': BoxItem.Types.WaterHeating,
      'sushilka_dlya_ruk': BoxItem.Types.Conditioner,
      'boyler': BoxItem.Types.Boiler,
    }

    return {
      id: data.key,
      type: 'additional',
      title: data.title,
      editorType: additionalKeyAlias[data.key],
      icon: '/pics/menu-items/box.png',
      menuIcon: require('@/assets/images/left-menu/items/additional/' + data.key + '.svg'),
      data: {
        raw: data,
        cost: data.options ? data.options[0].price : data.price,
      }
    }
  }
}

export default MapUtil
