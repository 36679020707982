<template lang="pug">
  .mounting-item(:class="{selected: selected}",
    @click="onMountingItemClick")
    .checkbox
      .dot
    .info
      .label
        span {{data.title}}
        tooltip-icon(:hint="{section: 'montazh', key: data.key}")
      .cost(v-if="data.price > 0") {{resultCost | currency}}
      .cost(v-else) бесплатно

    .result-cost {{resultCost | currency}}
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import TooltipIcon from '@/components/_common/ui/TooltipIcon'

export default {
  name: 'MountingItem',
  props: ['data', 'selected'],
  components: { TooltipIcon },

  methods: {
    ...mapActions([
      'setMounting',
    ]),

    onMountingItemClick() {
      this.setMounting(this.data)
    }
  },

  computed: {
    ...mapGetters([
      'totalCost',
      'roofCost',
      'boxesCount'
    ]),

    totalCostWithoutRoof() {
      return this.totalCost - this.roofCost.total
    },

    resultCost() {
      return Math.ceil((this.data.price / 100) * this.totalCostWithoutRoof)
    }
  }
}
</script>
