import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import VueTextareaAutosize from 'vue-textarea-autosize'
import VTooltip from 'v-tooltip'

import './assets/scss/main.scss'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import './assets/scss/cropper.css'

import './filters'

import YmapPlugin from 'vue-yandex-maps'
Vue.use(YmapPlugin)

Vue.use(VueTextareaAutosize)
Vue.use(VTooltip)

export const eventBus = new Vue()

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
