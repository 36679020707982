import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import {Rectangle } from '@createjs/easeljs';
import FastVector from 'fast-vector';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import ExternalItemActionsView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem/ExternalItemActionsView';
import StairsView from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem/StairsView';

/**
 * Элемент, который располагается внутри Box
 */
class ExternalItem extends BaseEditorElement {

  static _ID = 0

  static Types = {
    Stairs: 'Stairs',
  }

  /**
   * @type {ExternalItem[]}
   */
  static ExternalItemsList = []

  /**
   * @type String
   */
  _type
  dictionaryData

  /**
   * @type Number
   */
  _width
  /**
   * @type Number
   */
  _height

  /**
   * @type {BaseEditorElement}
   * @private
   */

  /**
   * @type {ExternalItemView}
   * @private
   */
  _view

  /**
   * @type {number}
   * @private
   */
  _id

  isSetuped = true

  /**
   * @type {easeljs.Rectangle}
   * @private
   */
  _rectangle = new Rectangle(0, 0, 0, 0)

  _position = FastVector.zero
  rotation = 0

  /**
   * @type {ExternalItemActionsView}
   * @private
   */
  _externalItemActionsView

  /**
   * @param id
   * @return {ExternalItem|null}
   * @constructor
   */
  static GetById(id) {
    const list = this.ExternalItemsList.filter(item => item.id === id)
    return list.length > 0 ? list[0] : null
  }

  /**
   * @param dictionaryData
   * @return {ExternalItem}
   * @constructor
   */
  static Create(dictionaryData) {
    ExternalItem._ID ++

    const externalItem = new ExternalItem()
    externalItem.isSetuped = false
    externalItem.setId(ExternalItem._ID)
    externalItem.setDictionaryData(dictionaryData)

    ExternalItem.ExternalItemsList.push(externalItem)

    return externalItem
  }

  static FromJSON(data) {
    if (data.id > ExternalItem._ID) ExternalItem._ID = data.id

    const externalItem = new ExternalItem()
    externalItem.isSetuped = true
    externalItem.setId(data.id)
    externalItem.rotation = data.rotation
    externalItem.setDictionaryData(data.dictionaryData)
    externalItem.x = data.position.x
    externalItem.y = data.position.y

    ExternalItem.ExternalItemsList.push(externalItem)
  }

  constructor() {
    super()
    this.elementType = ExternalItem.ELEMENT_TYPE_EXTERNAL_ITEM
    Grid.Instance.addExternalItem(this)

    CreateJsBlocksEditor.Instance.addEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
  }

  toJson() {
    const data = {
      id: this.id,
      dictionaryData: this.dictionaryData,
      position: {
        x: this.x,
        y: this.y
      },
      rotation: this.rotation
    }

    return data
  }

  _onElementSelected = (e) => {
    this.draw()
  }

  get rotationAvailable() {
    let available = true
    const testRect = this.rectangle.clone()
    const center = new FastVector(testRect.x + testRect.width / 2, testRect.y + testRect.height / 2)
    const oldWidth = testRect.width
    const oldHeight = testRect.height

    testRect.width = oldHeight
    testRect.height = oldWidth
    testRect.x = center.x - testRect.width / 2
    testRect.y = center.y - testRect.height / 2

    if (this.isSetuped) {
      Box.BoxesList.forEach((box) => {
        if (box.rectangle.intersects(testRect)) {
          available = false
        }
      })
    }

    if (available) {
      ExternalItem.ExternalItemsList.forEach((item) => {
        if (item !== this && item.rectangle.intersects(testRect)) {
          available = false
        }
      })
    }

    return available
  }

  /**
   * @return {number}
   */
  get id() {
    return this._id
  }

  get width() {
    return this._width
  }

  get height() {
    return this._height
  }

  /**
   * @return {String}
   */
  get type() {
    return this._type
  }

  /**
   * @param id {number}
   */
  setId(id) {
    this._id = id
  }

  setDictionaryData(dictionaryData) {
    this.dictionaryData = dictionaryData
    this.setType(dictionaryData.editorType)
  }

  setType(type) {
    this._type = type
    this._initSize()
    this._createView()
  }

  _initSize() {
    switch (this.dictionaryData.data.raw.key) {
      case 'lestnica_vneshnyaya_ulichnaya':
        this._width = 136
        this._height = 416
        break
    }
  }

  _createView() {
    let view = null
    switch (this.type) {
      case ExternalItem.Types.Stairs:
        view = new StairsView(this)
        break
    }

    if (view) {
      this._view = view
      this._externalItemActionsView = new ExternalItemActionsView(this)
    }
  }

  trySetup() {
    if (!this.isSetuped) {
      if (this.canBeSetuped()) {
        this.isSetuped = true
        CreateJsBlocksEditor.Instance.setSelectedElement(null)
        this.emitChangeByTimeout()
      }
    } else {
      CreateJsBlocksEditor.Instance.setSelectedElement(this)
    }

    this.draw()
  }

  setPosition(x, y) {
    this.x = Math.round(x)
    this.y = Math.round(y)
    this.draw()
  }

  /**
   * @returns {easeljs.Rectangle}
   */
  get rectangle() {
    const [width, height] = this.rotation === 90 || this.rotation === 270 ? [this._height, this._width] : [this._width, this._height]

    this._rectangle.x = this.x - width / 2
    this._rectangle.y = this.y - height / 2
    this._rectangle.width = width
    this._rectangle.height = height

    return this._rectangle
  }

  /**
   * @param delta {FastVector}
   * @return {FastVector}
   */
  getUpdatedMoveDelta(delta) {
    const selfCenter = new FastVector(this.x, this.y)

    Box.BoxesList.forEach((box) => {
      const testRect = this.rectangle.clone()
      testRect.x = this.x - testRect.width / 2 + delta.x
      testRect.y = this.y - testRect.height / 2 + delta.y

      const hitRectangle = testRect.intersection(box.rectangle)

      if (hitRectangle) {
        const boxCenter = box.center
        const centerDelta = boxCenter.sub(selfCenter)

        const isHorizontalCheck = this.x + testRect.width / 2 <= box.x || this.x - testRect.width / 2 >= box.x + box.width

        if (isHorizontalCheck) {
          if (centerDelta.x > 0) {
            delta.x -= hitRectangle.width
          } else {
            delta.x += hitRectangle.width
          }
        } else {
          if (centerDelta.y > 0) {
            delta.y -= hitRectangle.height
          } else {
            delta.y += hitRectangle.height
          }
        }
      }
    })

    ExternalItem.ExternalItemsList.forEach((externalItem) => {
      if (this !== externalItem) {
        const testRect = this.rectangle.clone()
        testRect.x = this.x - testRect.width / 2 + delta.x
        testRect.y = this.y - testRect.height / 2 + delta.y

        const externtalItemRect = externalItem.rectangle
        const hitRectangle = testRect.intersection(externalItem.rectangle)

        if (hitRectangle) {
          const externalItemCenter = new FastVector(externalItem.x, externalItem.y)
          const centerDelta = externalItemCenter.sub(selfCenter)

          const isHorizontalCheck = this.x + testRect.width / 2 <= externtalItemRect.x || this.x - testRect.width / 2 >= externtalItemRect.x + externtalItemRect.width

          if (isHorizontalCheck) {
            if (centerDelta.x > 0) {
              delta.x -= hitRectangle.width
            } else {
              delta.x += hitRectangle.width
            }
          } else {
            if (centerDelta.y > 0) {
              delta.y -= hitRectangle.height
            } else {
              delta.y += hitRectangle.height
            }
          }
        }
      }
    })

    return delta
  }

  /**
   *
   * @param vector {FastVector}
   */
  move(vector) {
    if (this.isSetuped && this.isSelected()) {
      this.x = Math.round(this.x + vector.x)
      this.y = Math.round(this.y + vector.y)

      this.draw()
      this.emitChangeByTimeout()
    }
  }

  checkIntersections() {
    const selfRect = this.rectangle.clone()
    Box.BoxesList.forEach((boxItem) => {
      const boxRect = boxItem.rectangle.clone()
      boxRect.x -= 2
      boxRect.y -= 2
      boxRect.width += 4
      boxRect.height += 4
      const rect = this.rectangle.intersection(boxRect)
      if (rect) {
        const isVerticalCorrection = selfRect.y < boxRect.y && selfRect.y + selfRect.height <= boxRect.y + this._mouseVector.y
          || selfRect.y - this._mouseVector.y >= boxRect.y + boxRect.height && selfRect.y + selfRect.height > boxRect.y + boxRect.height

        if (isVerticalCorrection) {
          if (this._mouseVector.y > 0) {
            this.move(new FastVector(0, -rect.height))
          } else if (this._mouseVector.y < 0) {
            this.move(new FastVector(0, rect.height))
          }
        } else {
          if (this._mouseVector.x > 0) {
            this.move(new FastVector(-rect.width, 0))
          } else if (this._mouseVector.x < 0) {
            this.move(new FastVector(rect.width, 0))
          }
        }
      }
    })

    ExternalItem.ExternalItemsList.forEach((externalItem) => {
      if (externalItem !== this) {
        const boxRect = externalItem.rectangle.clone()
        const rect = this.rectangle.intersection(boxRect)
        if (rect) {
          const isVerticalCorrection = selfRect.y < boxRect.y && selfRect.y + selfRect.height <= boxRect.y + this._mouseVector.y
            || selfRect.y - this._mouseVector.y >= boxRect.y + boxRect.height && selfRect.y + selfRect.height > boxRect.y + boxRect.height

          if (isVerticalCorrection) {
            if (this._mouseVector.y > 0) {
              this.move(new FastVector(0, -rect.height))
            } else if (this._mouseVector.y < 0) {
              this.move(new FastVector(0, rect.height))
            }
          } else {
            if (this._mouseVector.x > 0) {
              this.move(new FastVector(-rect.width, 0))
            } else if (this._mouseVector.x < 0) {
              this.move(new FastVector(rect.width, 0))
            }
          }
        }
      }
    })
  }

  draw() {
    super.draw()
    if (this._externalItemActionsView) this._externalItemActionsView.draw()
  }

  rotate() {
    this.rotation += 90
    if (this.rotation >= 360) this.rotation = 0
    this.draw()
    this.emitChangeByTimeout()
  }

  canBeSetuped() {
    let can = true

    Box.BoxesList.forEach((box) => {
      if (box.rectangle.intersects(this.rectangle)) {
        can = false
      }
    })

    if (can) {
      ExternalItem.ExternalItemsList.forEach((externalItem) => {
        if (externalItem !== this && externalItem.rectangle.intersects(this.rectangle)) can = false
      })
    }

    return can
  }

  destroy() {
    if (this._externalItemActionsView) this._externalItemActionsView.destroy()
    CreateJsBlocksEditor.Instance.removeEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)

    Grid.Instance.removeExternalItem(this)
    ExternalItem.ExternalItemsList = ExternalItem.ExternalItemsList.filter((externalItem) => externalItem !== this)

    this.emitChangeByTimeout()

    super.destroy()
  }
}

export default ExternalItem
