<template lang="pug">
  .blocks-renderer(:style="rendererStyle")
    canvas(ref="canvas")
    left-menu(:visible="true")
    .walls-mode-select
      .modes-switcher
        .label Прозрачные стены
        .switcher
          kraus-switcher-small(v-model="wallsTransparent")

    .roof-mode-select(v-if="hasRoof")
      .modes-switcher
        .label Не показывать крышу
        .switcher
          kraus-switcher-small(v-model="roofHidden")
      //kraus-select(:values="wallsModes", v-model="wallsMode")

    .tooltip-button.no-select(@click="onShowTooltipClick")
    .made-in-immercyb <a href="https://immercyb.com/" target="_blank">Сделано в Immersive Cybernetics</a>

    tooltip-point(
      v-for="(data, index) in tooltipPoints",
      :key="index",
      :data="data",
      :blocksRenderer="blocksRenderer")

    result-information
</template>
<script>
import Blocks3DRenderer from '@/components/BlocksRenderer/Blocks3DRenderer';
import { mapActions, mapGetters } from 'vuex';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import LeftMenu from '@/components/BlocksEditor/LeftMenu';
import KrausSelect from '@/components/_common/ui/KrausSelect';
import { Texture } from '@babylonjs/core';
import ResultInformation from '@/components/BlocksEditor/ResultInformation';
import pointsData from '@/components/BlocksRenderer/TooltipPoint/pointsData';
import TooltipPoint from '@/components/BlocksRenderer/TooltipPoint';
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';

export default {
  name: 'BlocksRenderer',
  components: { KrausSwitcherSmall, TooltipPoint, ResultInformation, KrausSelect, LeftMenu },
  data() {
    return {
      blocksRenderer: null,
      windowSize: {
        width: 0,
        height: 0,
      },
      wallsTransparent: false,
      roofHidden: false,
      hasRoof: false,
      screenShotsList: []
    }
  },

  mounted() {
    this.createRenderer()
    this.setJsonForRender(this.stagesData)

    window.addEventListener('resize', this.onWindowResize)
    eventBus.$on(EventBusEvents.PrepareImageForDownload, this.onPrepareImageForDownload)

    //document.addEventListener('keydown', this._onKeyDown)

    this.updateWindowSize()

    this.checkScreenShots()
  },

  destroyed() {
    this.disableRendererTooltips()
    this.blocksRenderer.dispose()
    this.blocksRenderer = null
    window.removeEventListener('resize', this.onWindowResize)
    document.removeEventListener('keydown', this._onKeyDown)
    eventBus.$off(EventBusEvents.PrepareImageForDownload, this.onPrepareImageForDownload)
  },

  computed: {
    ...mapGetters([
      'rendererTooltipsEnabled',
      'stagesData',
      'cornerPosts',
      'carcassColor',
      'wallColor',
      'insideWallMaterial',
      'insideWallDifferentMaterial',
      'wallMaterial',
      'floorCoating',
      'additionalMainFloor',
      'mainFloor',
      'containerType',
      'roofType',
      'roofColor',
      'screenshotsType'
    ]),

    tooltipPoints() {
      const points =  this.containerType.improved ? Object.assign(pointsData.improved) : Object.assign(pointsData.simple)
      return this.rendererTooltipsEnabled ? points : []
    },

    rendererStyle() {
      return {
        height: this.windowSize.height - 80 + 'px'
      }
    },

    carcass() {
      return {
        width: this.cornerPosts?.raw?.width || (this.containerType?.improved ? 150 : 90), // 'this.cornerPosts.raw.width,
        color: this.carcassColor.raw.hex
      }
    },

    walls() {
      return {
        color: this.wallColor.raw.hex,
        insideWallMaterial: this.insideWallMaterial,
        insideWallDifferentMaterial: this.insideWallDifferentMaterial,
        external: {
          material: this.wallMaterial.raw.key,
          colorKey: this.wallColor.raw.key,
          color: this.wallColor.raw.key === 'ocinkovka' ? null : this.wallColor.raw.hex,
          metallic: this.wallColor.raw.key === 'ocinkovka' ? 0.8 : 0.1,
          roughness: this.wallColor.raw.key === 'ocinkovka' ? 0.15 : 1
        }
      }
    },

    roof() {
      return {
        type: this.roofType.key,
        color: {
          data: {
            hex: this.roofColor.raw.key === 'ocinkovka' ? null : this.roofColor.raw.hex,
            key: this.roofColor.raw.key
          },
          metallic: this.roofColor.raw.key === 'ocinkovka' ? 0.8 : 0.1,
          roughness: this.roofColor.raw.key === 'ocinkovka' ? 0.15 : 1
        },
      }
    },

    floor() {
      return {
        coating: this.floorCoating.raw.key,
        mainFloor: this.mainFloor.raw.key,
        mainFloorSecondLayer: this.additionalMainFloor.raw.key
      }
    }
  },

  watch: {
    roofHidden(hidden) {
      this.blocksRenderer.setRoofVisibility(!hidden)
    },

    wallsTransparent(transparent) {
      this.blocksRenderer.setWallsTransparency(transparent)
    },

    carcass: {
      deep: true,
      handler: function (data) {
        this.blocksRenderer.setCarcass(data)
      }
    },

    walls: {
      deep: true,
      handler: function (data) {
        this.blocksRenderer.setWalls(data)
      }
    },

    roof: {
      deep: true,
      handler: function (data) {
        this.blocksRenderer.setRoof(data)
      }
    }
  },

  methods: {
    ...mapActions([
      'disableRendererTooltips',
      'setScreenshotsType',
      'setImages3d'
    ]),

    _onKeyDown(e) {
      if (e.code === 'KeyI') {
        this.blocksRenderer.exportToGlb()
      }
    },

    createRenderer() {
      this.blocksRenderer = new Blocks3DRenderer(this.$refs.canvas, {
        carcass: this.carcass,
        walls: this.walls,
        floor: this.floor,
        roof: this.roof,
        pillars: Pillar.StagePillarsList
      })
    },

    onPrepareImageForDownload() {
      this.blocksRenderer.getImage().then((data) => {
        eventBus.$emit(EventBusEvents.ShowDownloadScheme, data)
      })
    },

    setJsonForRender(data) {
      this.blocksRenderer.setDataForRender(data)
      this.hasRoof = this.blocksRenderer.hasRoof
    },

    onWindowResize() {
      this.updateWindowSize()
    },

    updateWindowSize() {
      this.windowSize.width = window.innerWidth
      this.windowSize.height = window.innerHeight
      this.blocksRenderer.setSize({
        width: window.innerWidth,
        height: parseInt(this.rendererStyle.height)
      })
    },

    onShowTooltipClick() {
      eventBus.$emit(EventBusEvents.ShowTooltips)
    },

    createPdfScreenShots() {
      return this.blocksRenderer.createPdfScreenShots()
    },

    checkScreenShots() {
      setTimeout(() => {
        const type = this.screenshotsType
        if (type !== null) {
          this.blocksRenderer.whenSceneReady().then(() => {
            this.createPdfScreenShots().then((imageList) => {
              this.screenShotsList = imageList
              this.setImages3d(imageList)
              eventBus.$emit(EventBusEvents.StageImagesReady)

            })
          })
        }
      }, 1000)
    }
  }
}
</script>
