import {
  Color3, CSG,
  Mesh,
  MeshBuilder,
  PBRMetallicRoughnessMaterial, Ray,
  Vector3,
  Engine, Texture, TransformNode, AbstractMesh
} from '@babylonjs/core'
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor'
import WallItem
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/WallsConstructor/WallItem'
import EquipmentCategories from '@/common/EquipmentCategories'
import Blocks3DRenderer from '@/components/BlocksRenderer/Blocks3DRenderer'

class CachedMaterial {
  key
  material

  constructor({key, material}) {
    this.key = key
    this.material = material
  }
}

class Wall {
  /**
   * @type {CachedMaterial[]}
   * @private
   */
  static _insideMaterialsCache = []
  ///**
  // * @type PBRMetallicRoughnessMaterial
  // * @private
  // */
  //static _outsideMaterial
  /**
   * @type PBRMetallicRoughnessMaterial
   * @private
   */
  static _carcassMaterial

  /**
   * @type PBRMetallicRoughnessMaterial
   * @private
   */
   _outsideMaterial

  _scene
  _data
  _wallData
  _stage

  _insideMaterial
  _wallMeshes = []

  /**
   *
   * @type {{id: number, x: number, y: number}[]}
   * @private
   */
  _connectors = []

  _wallItems = []

  /**
   *
   * @type {WallItem[]}
   * @private
   */
  _wallItems = []

  /**
   * @type {Box}
   * @private
   */
  _box

  /**
   * @type {Mesh}
   * @private
   */
  _mesh

  _carcass
  _wallsOptions

  /**
   * @type {Mesh[]}
   * @private
   */
  _carcassMeshes = []

  _transparent = false
  _transparentCounter = 0

  rayHelper = null

  /**
   * @type {{path: string, fileName: string}}
   */
  modelUrl = {
    path: '/models/container/',
    fileName: 'wall_front.glb'
  }

  get id () {
    return this._wallData.id
  }

  get box () {
    return this._box
  }

  get temp() {
    return this._wallData.temp
  }

  get insideBox () {
    return this._wallData.insideBox
  }

  get mesh() {
    return this._mesh
  }

  get vertical () {
    const [c1, c2] = this._connectors
    return c1.x === c2.x
  }

  get firstConnector () {
    const [c1, c2] = this._connectors
    return this.vertical ? (c1.y < c2.y ? c1 : c2) : (c1.x < c2.x ? c1 : c2)
  }

  get lastConnector () {
    const [c1, c2] = this._connectors
    return this.firstConnector === c1 ? c2 : c1
  }

  get length () {
    return this.vertical ? this.lastConnector.y - this.firstConnector.y : this.lastConnector.x - this.firstConnector.x
  }

  get meshes () {
    return this._wallMeshes
  }

  /**
   * @return {PBRMetallicRoughnessMaterial}
   * @private
   */
  get _carcassMaterial() {
    if (!Wall._carcassMaterial) {
      Wall._carcassMaterial = new PBRMetallicRoughnessMaterial('carcass_material', this._scene)
      this._carcassMaterial.baseColor = Color3.FromHexString(this._carcass.color)
      this._carcassMaterial.metallic = 0.6
      this._carcassMaterial.roughness = 1
      this._carcassMaterial.freeze()
    }

    return Wall._carcassMaterial
  }

  get _material() {
    if (!this._outsideMaterial) {
      const material = new PBRMetallicRoughnessMaterial('wall_outside_material', this._scene)

      if (this._wallsOptions.color) {
        material.baseColor = Color3.FromHexString(this._wallsOptions.color)
      } else {
        material.baseColor = Color3.White()
      }
      material.metallic = this._wallsOptions.external.metallic
      material.roughness = this._wallsOptions.external.roughness

      material.ambientColor = Color3.White()

      this._outsideMaterial = material

      material.freeze()
    }

    return this._outsideMaterial
  }

  constructor({scene, data, wallData, stage, carcass, wallsOptions}) {
    this._scene = scene

    this._connectors = wallData.connectors
    this._wallItems = wallData.wallItems

    this._data = data
    this._wallData = wallData

    this._stage = stage
    this._carcass = carcass
    this._wallsOptions = wallsOptions
  }

  /**
   * @param length
   * @param textureUrl
   * @return {PBRMetallicRoughnessMaterial}
   */
  getInsideMaterial(length, textureUrl) {
    const key = length.toString() + textureUrl
    const list = Wall._insideMaterialsCache.filter(item => item.key === key)
    let material

    if (list.length > 0) {
      material = list[0].material
    } else {
      material = new PBRMetallicRoughnessMaterial('inside_box_material', this._scene)
      material.baseColor = Color3.White()
      material.metallic = 0
      material.roughness = 1
      material.baseTexture = new Texture(textureUrl, this._scene)

      if (EquipmentCategories.containerType !== 'obreshotka') {
        material.baseColor = this.getInsideMaterialColor()
      }

      material.freeze()

      Wall._insideMaterialsCache.push(new CachedMaterial({
        key: key,
        material
      }))
    }

    return material
  }

  /**
   * @param box {Box}
   */
  setBox(box) {
    if (!this._box) {
      this._box = box

      this._material.unfreeze()
      this._material.baseTexture = this.getOutsideWallTexture()

      if (this._wallsOptions.external.color) {
        this._material.baseColor = Color3.FromHexString(this._wallsOptions.external.color)
      } else {
        this._material.baseColor = Color3.White()
      }

      if (this._wallsOptions.external.material !== 'prooflist_s8' && this._wallsOptions.external.colorKey === 'white') {
        this._material.emissiveColor = Color3.FromHexString(this._wallsOptions.external.color)
      } else {
        this._material.emissiveColor = Color3.Black()
      }

      this._createMesh()
      this._updateMeshRotations()

      setTimeout(() => {
        this._material.freeze()
      }, 10)
    }
  }

  getInsideMaterialColor() {
    const wallMaterialData = this._wallsOptions.insideWallDifferentMaterial && this._box.insideWallMaterial ?
      this._box.insideWallMaterial :
      this._wallsOptions.insideWallMaterial

    return Color3.FromHexString(wallMaterialData.raw.hex)
  }

  getInsideWallTextureUrl() {
    const wallMaterialData = this._wallsOptions.insideWallDifferentMaterial && this._box.insideWallMaterial ?
      this._box.insideWallMaterial :
      this._wallsOptions.insideWallMaterial

    if (EquipmentCategories.containerType === 'obreshotka') {
      return '/textures/intnernal-finishing/' + wallMaterialData.raw.key + '.jpg'
    }

    return '/textures/intnernal-finishing/bez_otdelki.jpg'
  }

  getInsideWallTextureByData(wallMaterialData) {
    if (EquipmentCategories.containerType === 'obreshotka') {
      return new Texture('/textures/intnernal-finishing/' + wallMaterialData.raw.key + '.jpg', this._scene)
    }

    return new Texture('/textures/intnernal-finishing/bez_otdelki.jpg', this._scene)
  }

  getOutsideWallTexture() {
    const textureName = this._wallsOptions.external.material
    if (['prooflist_s8', 'metal_045'].indexOf(textureName) < 0) {
      const path = '/textures/external-finishing/' + textureName + '.jpg'
      const texture = new Texture(path, this._scene)
      return texture
    }
    return null
  }

  getBoxById(id) {
    const list = this._data.boxes.filter(box => box.id === id)
    return list.length > 0 ? list[0] : null
  }

  setTransparency(transparency) {
    this._transparent = transparency

    if (!transparency) {
      this._wallMeshes.forEach(item => {
        item.mesh.getChildMeshes().forEach(mesh => {
          mesh.visibility = 1
        })
      })
    }
  }

  updateTransparency() {
    this._transparentCounter++

    if (this._transparent && this._wallMeshes.length > 0 && this._transparentCounter % 25 === 0) {
      const origin = Blocks3DRenderer.camera.position.clone() //new Vector3(500,100,100)
      origin.y = (this._stage * BlocksConstructor.StageHeight - BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale

      let ray = new Ray(Vector3.Zero(), Vector3.Zero())

      this._wallMeshes.forEach(item => {

        ray.direction = item.origin.subtract(origin).normalize()
        ray.origin = origin

        const hitInfo = this._scene.pickWithRay(ray, null, false)

        if (hitInfo.hit && this.isParentMesh(hitInfo.pickedMesh, item.mesh)) {
          this._material.alphaMode = Engine.ALPHA_COMBINE
          this._insideMaterial.alphaMode = Engine.ALPHA_COMBINE
          item.mesh.getChildMeshes().forEach(mesh => {
            // console.log(mesh.name)
            //if (mesh.material) mesh.material.alpha = 0.1
            mesh.visibility = 0.3
          })
        } else {
          this._material.alphaMode = Engine.ALPHA_COMBINE
          this._insideMaterial.alphaMode = Engine.ALPHA_COMBINE
          item.mesh.getChildMeshes().forEach(mesh => {
            mesh.visibility = 1
          })
        }
      })
    }
  }

  isParentMesh(mesh, parentMesh) {
    let isParent = false


    isParent = mesh.parent === parentMesh || parentMesh === mesh
    if (!isParent && mesh.parent) {
      isParent = this.isParentMesh(mesh.parent, parentMesh)
    }

    return isParent
  }

  setCarcass(carcass) {
    this._carcass = carcass
    this._carcassMaterial.unfreeze()
    this._carcassMaterial.baseColor = Color3.FromHexString(this._carcass.color)
    setTimeout(() => {
      this._carcassMaterial.freeze()
    }, 10)
  }

  setWallOptions(options) {
    this._material.unfreeze()
    this._wallsOptions = options

    if (this._wallsOptions.color) {
      this._material.baseColor = Color3.FromHexString(this._wallsOptions.color)
    } else {
      this._material.baseColor = Color3.White()
    }

    //console.log(this._wallsOptions)
    // console.log(this._wallsOptions)
    if (this._wallsOptions.external.material !== 'prooflist_s8' && this._wallsOptions.external.colorKey === 'white') {
      this._material.emissiveColor = Color3.FromHexString(this._wallsOptions.external.color)
    } else {
      this._material.emissiveColor = Color3.Black()
    }

    this._material.metallic = this._wallsOptions.external.metallic //EquipmentCategories.containerType === 'obreshotka' ? 0.1 : 0
    this._material.roughness = this._wallsOptions.external.roughness

    setTimeout(() => {
      this._material.unfreeze()
    }, 10)
  }

  getWallParts() {
    const allConnectors = [this.firstConnector, this.lastConnector]

    const isConnectorExists = (c) => {
      return allConnectors.filter(item => item.x === c.x && item.y === c.y).length > 0
    }

    const getTempWall = (c1, c2) => {
      let foundedWall = null
      this._tempsWallsData.forEach(tempWall => {
        tempWall.connectors.sort((c1, c2) => this.vertical ? (c1.y < c2.y ? -1 : 1) : (c1.x < c2.x ? -1 : 1))
        const [tempWallC1, tempWallC2] = tempWall.connectors

        if (tempWallC1.x === c1.x && tempWallC2.x === c2.x && tempWallC1.y === c1.y && tempWallC2.y === c2.y) {
          foundedWall = tempWall
        }
      })

      return foundedWall
    }

    this._tempsWallsData.forEach(tempWallData => {
      tempWallData.connectors.forEach(c => {
        if (!isConnectorExists(c)) allConnectors.push(c)
      })
    })

    allConnectors.sort((c1, c2) => this.vertical ? (c1.y < c2.y ? -1 : 1) : (c1.x < c2.x ? -1 : 1))

    const wallParts = []

    for (let i = 1; i < allConnectors.length; i++) {
      const c1 = allConnectors[i - 1]
      const c2 = allConnectors[i]

      const tempWall = getTempWall(c1, c2)
      if (tempWall) {
        if (tempWall.tempWallActive) wallParts.push(tempWall)
      } else {
        wallParts.push({
          connectors: [c1, c2]
        })
      }
    }

    return wallParts
  }

  _createMesh() {
    const connectors = [this.firstConnector, this.lastConnector]
    const delta = 5 * BlocksConstructor.Scale
    const insideDelta = 6.5 * BlocksConstructor.Scale

    const [firstConnector, lastConnector] = connectors
    const stageY = ((this._stage - 1) * BlocksConstructor.StageHeight) * BlocksConstructor.Scale
    const length = Math.abs(this.vertical ? lastConnector.y - firstConnector.y : lastConnector.x - firstConnector.x)

    if (this._material.baseTexture) this._material.baseTexture.uScale = length * BlocksConstructor.Scale

    const box = MeshBuilder.CreateBox('wall_box', {
      width: length * BlocksConstructor.Scale + delta,
      height: BlocksConstructor.StageHeight * BlocksConstructor.Scale,
      depth: 4.5 * BlocksConstructor.Scale
    }, this._scene)


    let insideBox = MeshBuilder.CreateBox('inside_box', {
      width: length * BlocksConstructor.Scale + insideDelta,
      height: BlocksConstructor.StageHeight * BlocksConstructor.Scale,
      depth: 1 * BlocksConstructor.Scale
    }, this._scene)

    const parentMesh = new TransformNode('wall_parent_mesh_' + this.id, this._scene)

    box.parent = parentMesh
    box.position.x = (length * BlocksConstructor.Scale + delta)  / 2 - delta / 2
    box.position.y = (BlocksConstructor.StageHeight  * BlocksConstructor.Scale) / 2

    this._insideMaterial = this.getInsideMaterial(length, this.getInsideWallTextureUrl())

    insideBox.parent = parentMesh
    insideBox.position.x = box.position.x
    insideBox.position.y = box.position.y
    insideBox.position.z = -3.1 * BlocksConstructor.Scale

    insideBox.material = this._insideMaterial
    if (this._insideMaterial.baseTexture) {
      this._insideMaterial.baseTexture.uScale = (length * BlocksConstructor.Scale + insideDelta)
    }

    const edgeMeshes = []

    const edgeDistance = EquipmentCategories.containerType === 'obreshotka' ? 10 : 15
    for (let i = 0; i < 700; i += edgeDistance * 2) {
      const edgeBox = MeshBuilder.CreateBox('edge_box', {
        width: edgeDistance * BlocksConstructor.Scale,
        height: (BlocksConstructor.StageHeight) * BlocksConstructor.Scale,
        depth: 3 * BlocksConstructor.Scale
      }, this._scene)

      edgeBox.parent = parentMesh
      edgeBox.position.z = 2 * BlocksConstructor.Scale
      edgeBox.position.x = (i - edgeDistance * 2) * BlocksConstructor.Scale
      edgeBox.position.y = (BlocksConstructor.StageHeight * BlocksConstructor.Scale) / 2

      edgeMeshes.push(edgeBox)
    }

    const holeMeshes = []

    // console.log(this._wallItems)
    this._wallItems.forEach(wallItemData => {
      const isGate = ['vorota', 'rolstavni'].indexOf(wallItemData.dictionaryData.id) >= 0
      const isVentilation = wallItemData.dictionaryData.id === 'vytyazhka'

      let size
      if (isGate) {
        size = {width: 205, height: 205}
      } else if (isVentilation) {
        size = {width: 10, height: 10}
      } else {
        size = wallItemData.dictionaryData.size || {width: wallItemData.dictionaryData.data.raw.width, height: wallItemData.dictionaryData.data.raw.height}
      }

      const holeBox = MeshBuilder.CreateBox('hole_box', {
        width: size.width * BlocksConstructor.Scale,
        height: size.height * BlocksConstructor.Scale,
        depth: 20 * BlocksConstructor.Scale
      }, this._scene)

      holeBox.parent = parentMesh

      let flippedPosition = false
      const boxCenter = this.box.center

      if (this.vertical) {
        if (firstConnector.x < boxCenter.x) {
          flippedPosition = true
        }
      } else {
        if (firstConnector.y > boxCenter.y) {
          flippedPosition = true
        }
      }

      if (flippedPosition) {
        if (this.insideBox) {
          holeBox.position.x = (length - wallItemData.pointOnWall) * BlocksConstructor.Scale
        } else {
          holeBox.position.x = (length - wallItemData.pointOnWall) * BlocksConstructor.Scale
        }
      } else {
        if (this.insideBox) {
          holeBox.position.x = (wallItemData.pointOnWall) * BlocksConstructor.Scale
        } else {
          holeBox.position.x = ( wallItemData.pointOnWall) * BlocksConstructor.Scale
        }
      }


      if (wallItemData.type === 'Door') {
        if (wallItemData.dictionaryData.id === 'luk') {
          holeBox.position.y = (200 - size.height / 2 + 12) * BlocksConstructor.Scale
        } else {
          holeBox.position.y = (size.height / 2 + 12) * BlocksConstructor.Scale
        }
      } else {
        if (isVentilation) {
          holeBox.position.y = (200 - size.height / 2 + 12) * BlocksConstructor.Scale
        } else {
          holeBox.position.y = BlocksConstructor.StageHeight / 2 * BlocksConstructor.Scale
        }
      }

      holeMeshes.push(holeBox)

      const wallItem = new WallItem({
        scene: this._scene,
        mesh: parentMesh,
        position: holeBox.position.clone(),
        data: wallItemData,
        wallData: this._wallData,
        wallConnectors: connectors
      })
    })

    const edgesMesh = Mesh.MergeMeshes(edgeMeshes, true)
    const holesMesh = holeMeshes.length > 0 ? Mesh.MergeMeshes(holeMeshes, true) : null

    const csgBox = CSG.FromMesh(box)
    const csgEdges = CSG.FromMesh(edgesMesh)
    const csgHoles = holesMesh ? CSG.FromMesh(holesMesh) : null

    let csgInsideWall = CSG.FromMesh(insideBox)
    let csgWall

    if (this.insideBox) {
      if (csgHoles) {
        csgWall = csgBox.subtract(csgHoles)
      } else {
        csgWall = csgBox
      }
    } else {
      if (['prooflist_s8', 'metal_045'].indexOf(this._wallsOptions.external.material) >= 0)  {
        csgWall = csgBox.subtract(csgEdges)
      } else {
        csgWall = csgBox
      }
      if (csgHoles) {
        csgWall = csgWall.subtract(csgHoles)
        csgInsideWall = csgInsideWall.subtract(csgHoles)
      }
    }

    const insideWallMesh = csgInsideWall.toMesh('internal_wall', this._insideMaterial, this._scene)
    const wallMesh = csgWall.toMesh('external_wall', this.insideBox ? this._insideMaterial : this._material, this._scene)

    if (this.insideBox) {
      insideWallMesh.dispose()

      let connectedBoxId = null
      if (this._wallData.insideBox) {
        this._wallData.intersectedWalls.forEach(wallItem => {
          if (wallItem.boxId && wallItem.boxId !== this._box.id) connectedBoxId = wallItem.boxId
        })
      }

      const secondMesh = wallMesh.clone()

      wallMesh.scaling.z = 0.5
      wallMesh.position.z = -1.25 * BlocksConstructor.Scale

      secondMesh.scaling.z = 0.5
      secondMesh.position.z = 1.25 * BlocksConstructor.Scale

      wallMesh.parent = parentMesh
      secondMesh.parent = parentMesh

      if (connectedBoxId) {
        const connectedBox = this.getBoxById(connectedBoxId)
        const material = this._insideMaterial.clone()
        const insideWallMaterialData = connectedBox.dictionaryData.wallMaterial ? connectedBox.dictionaryData.wallMaterial : this._wallsOptions.insideWallMaterial
        const texture = this.getInsideWallTextureByData(insideWallMaterialData)

        if (EquipmentCategories.containerType !== 'obreshotka') {
          material.baseColor = Color3.FromHexString(insideWallMaterialData.raw.hex)
        }
        material.baseTexture = texture
        material.baseTexture.uScale = (length * BlocksConstructor.Scale + insideDelta)
        secondMesh.material = material
      }
    } else {
      wallMesh.parent = parentMesh
      insideWallMesh.parent = parentMesh
    }

    box.dispose()
    insideBox.dispose()
    edgesMesh.dispose()
    if (holesMesh) holesMesh.dispose()

    // boxIndicator.parent = parentMesh
    // boxIndicator.position.y = 25

    this._wallMeshes.push({
      connectors,
      mesh: parentMesh,
      origin: Vector3.Zero(),
      wallMesh
    })

    this._mesh = parentMesh

    if (!this.insideBox) {
      const cornersSize = 9

      const bottomCarcass = MeshBuilder.CreateBox('carcass-bottom', {
        width: (length + 7) * BlocksConstructor.Scale,
        depth: 1 * BlocksConstructor.Scale,
        height: cornersSize * BlocksConstructor.Scale,
      }, this._scene)

      bottomCarcass.position.x = (length / 2) * BlocksConstructor.Scale
      bottomCarcass.position.z = 3 * BlocksConstructor.Scale
      bottomCarcass.position.y = (cornersSize / 2) * BlocksConstructor.Scale
      bottomCarcass.parent = this._mesh

      const topCarcass = MeshBuilder.CreateBox('carcass-top', {
        width: (length + 7) * BlocksConstructor.Scale,
        depth: 1 * BlocksConstructor.Scale,
        height: cornersSize * BlocksConstructor.Scale,
      }, this._scene)

      topCarcass.position.x = (length / 2) * BlocksConstructor.Scale
      topCarcass.position.z = 3 * BlocksConstructor.Scale
      topCarcass.position.y = (BlocksConstructor.StageHeight - cornersSize / 2) * BlocksConstructor.Scale
      topCarcass.parent = this._mesh

      const leftCarcass = MeshBuilder.CreateBox('carcass-left', {
        width: cornersSize * BlocksConstructor.Scale,
        depth: 1 * BlocksConstructor.Scale,
        height: BlocksConstructor.StageHeight * BlocksConstructor.Scale,
      }, this._scene)

      leftCarcass.position.x = (length - (cornersSize - 7) / 2) * BlocksConstructor.Scale
      leftCarcass.position.z = 3 * BlocksConstructor.Scale
      leftCarcass.position.y = (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale
      leftCarcass.parent = this._mesh

      const rightCarcass = MeshBuilder.CreateBox('carcass-right', {
        width: cornersSize * BlocksConstructor.Scale,
        depth: 1 * BlocksConstructor.Scale,
        height: BlocksConstructor.StageHeight * BlocksConstructor.Scale,
      }, this._scene)

      rightCarcass.position.x = ((cornersSize - 7) / 2) * BlocksConstructor.Scale
      rightCarcass.position.z = 3 * BlocksConstructor.Scale
      rightCarcass.position.y = (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale
      rightCarcass.parent = this._mesh

      this._carcassMeshes = [bottomCarcass, topCarcass, leftCarcass, rightCarcass]
      this._carcassMeshes.forEach(mesh => mesh.material = this._carcassMaterial)
    }

    parentMesh.getChildMeshes().forEach((mesh) => {
      // mesh.freezeWorldMatrix()
      mesh.doNotSyncBoundingInfo = true
      mesh.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY
    })
  }

  setCarcassColor(color) {
    this._carcassMaterial.freeze()
    this._carcassMaterial.baseColor = color
    this._carcassMaterial.unfreeze()
  }

  _updateMeshRotations() {
    const boxCenter = this._box.center
    this._wallMeshes.forEach(item => {
      const {connectors, mesh} = item
      const [c1, c2] = connectors
      const length = Math.abs(this.vertical ? c2.y - c1.y : c2.x - c1.x)

      mesh.position.y = BlocksConstructor.StageHeight * BlocksConstructor.Scale * (this._stage - 1)

      if (this.vertical) {
        if (c1.x > boxCenter.x) {
          mesh.rotation.y = Math.PI + Math.PI / 2
          mesh.position.x = (-c1.x) * BlocksConstructor.Scale
          mesh.position.z = (c1.y) * BlocksConstructor.Scale

          item.origin = mesh.position.clone()
          item.origin.z += (length / 2) * BlocksConstructor.Scale
          item.origin.y += (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale
        } else {
          mesh.rotation.y = Math.PI / 2
          mesh.position.x = (-c1.x) * BlocksConstructor.Scale
          mesh.position.z = (c1.y + length) * BlocksConstructor.Scale

          item.origin = mesh.position.clone()
          item.origin.z -= (length / 2) * BlocksConstructor.Scale
          item.origin.y += (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale
        }
      } else {
        if (c1.y > boxCenter.y) {
          mesh.rotation.y = 0
          mesh.position.x = (-c1.x - length) * BlocksConstructor.Scale
          mesh.position.z = (c1.y) * BlocksConstructor.Scale

          item.origin = mesh.position.clone()
          item.origin.x += (length / 2) * BlocksConstructor.Scale
          item.origin.y += (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale

        } else {
          mesh.rotation.y = Math.PI
          mesh.position.x = (-c1.x) * BlocksConstructor.Scale
          mesh.position.z = (c1.y) * BlocksConstructor.Scale

          item.origin = mesh.position.clone()
          item.origin.x -= (length / 2) * BlocksConstructor.Scale
          item.origin.y += (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale
        }
      }

      // const forward = new Vector3(0, 0, 1)
      // const ray = new Ray(mesh.position, wallMesh.getDirection(forward).normalize(), 100)
    })
  }

  _createWallItems() {
    this._wallData.wallItems.forEach((wallItemData) => {
      const wallItem = new WallItem({
        scene: this._scene,
        wall: this,
        data: wallItemData,
        stage: this._stage
      })

      this._wallItems.push(wallItem)
    })
  }
}

export default Wall
