<template lang="pug">
  .content.content-floor

    template(v-if="!containerType.improved")
      .content-header
        .content-option-name.bold Черновой пол

      .included-in-price(:style="{marginTop: '30px'}")
        .included-in-price-info
          .included-in-price-info-text
            span Обрезная доска 23 мм
            tooltip-icon(:hint="{section: 'floor', key: 'edged-board-23'}")
          .included-in-price-info-cost входит в стоимость
        .included-in-price-checkbox

      .content-header
        .content-option-name Дополнительное дно
        .content-option-cost(v-if="floorCost.additionalFloor > 0") {{floorCost.additionalFloor | currency}}
      .content-actions
        kraus-equipment-select(:values="additionalFloorData.items", v-model="additionalFloorData.value")

    template(v-else)
      .content-header
        .content-option-name.bold Дно
        .content-option-cost(v-if="floorCost.bottomFloor > 0") {{floorCost.bottomFloor | currency}}
      .content-actions
        kraus-equipment-select(:values="bottomFloorData.items", v-model="bottomFloorData.value")
      .included-in-price
        .included-in-price-info
          .included-in-price-info-text
            span Плиточный утеплитель
          .included-in-price-info-cost входит в стоимость
        .included-in-price-checkbox

    .small-switcher(v-if="!metalLagsData")
      .small-switcher-info
        .text
          span Дополнительные лаги
          tooltip-icon(:hint="{section: 'floor', key: 'additional-lags'}")
        .cost(v-if="floorCost.additionalLags") {{floorCost.additionalLags | currency}}
      .small-switcher-switcher
        kraus-switcher-small(v-model="additionalLagsData")

    .small-switcher
      .small-switcher-info
        .text
          span Металлические лаги
          tooltip-icon(:hint="{section: 'floor', key: 'metal-lags'}")
        .cost(v-if="floorCost.metalLags") {{floorCost.metalLags | currency}}
      .small-switcher-switcher
        kraus-switcher-small(v-model="metalLagsData")

    .number-input(v-if="metalLagsData")
      .number-input-info
        .text
          span Кол-во доп металл лагов
          tooltip-icon(:hint="{section: 'floor', key: 'lags-count'}")
        .cost(v-if="floorCost.metalLagsCustomCount") {{floorCost.metalLagsCustomCount | currency}}
      .input
        input(type="text", @keypress="IsNumber", v-model="metalLagsCountData")

    .content-header(:style="{marginTop: '12px'}")
      .content-option-name.bold  Чистовой пол

    .content-header(:style="{marginTop: '30px'}")
      .content-option-name
        span Основной пол
        tooltip-icon(:hint="{section: 'floor', key: 'main-floor'}")
      .content-option-cost(v-if="floorCost.mainFloor > 0") {{floorCost.mainFloor | currency}}
    .content-actions
      kraus-equipment-select(:values="mainFloorData.items", v-model="mainFloorData.value")

    .content-header
      .content-option-name
        span Дополнительный слой
        tooltip-icon(:hint="{section: 'floor', key: 'additional-layer'}")
      .content-option-cost(v-if="floorCost.additionalMainFloor > 0") {{floorCost.additionalMainFloor | currency}}
    .content-actions
      kraus-equipment-select(:values="additionalMainFloorData.items", v-model="additionalMainFloorData.value")

    .content-header
      .content-option-name
        span Покрытие
        tooltip-icon(:hint="{section: 'floor', key: 'coating'}")
      .content-option-cost(v-if="floorCost.mainFloorCoating > 0") {{floorCost.mainFloorCoating | currency}}
    .content-actions
      kraus-equipment-select(:values="floorCoatingData.items", v-model="floorCoatingData.value")

    .small-switcher
      .small-switcher-info.color-light
        .text
          span Разное покрытие для<br/>отдельных контейнеров
          tooltip-icon(:hint="{section: 'floor', key: 'different-coating'}")
      .small-switcher-switcher
        kraus-switcher-small(v-model="differentFloorCoatingData")

    template(v-if="differentFloorCoatingData")
      .stage-items-wrapper(v-for="(data, index) in items", :key="index")
        .stage-selector(:class="{opened: data.opened}", @click="onArrowClick(data)")
          .label {{data.label}}
          .arrow
        .coatings-list(v-if="data.opened")
          .coating-item(v-for="(item, index) in data.items", :key="index", @click="onCoatingItemClick(item)")
            .coating-item-icon(:style="{backgroundImage: 'url(' + item.icon + ')'}")
            .coating-item-content
              .coating-item-title {{item.title}}
              .coating-item-info
                .coating-item-info-options(:alt="item.options", :title="item.options") {{item.options}}
                .coating-item-info-cost(v-if="item.cost > 0") {{item.cost | currency }}
</template>
<script>
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
import KrausSelect from '@/components/_common/ui/KrausSelect';
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';
import TooltipIcon from '../../../../_common/ui/TooltipIcon';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
export default {
  name: 'Floor',
  components: { TooltipIcon, KrausEquipmentSelect, KrausSelect, KrausSwitcherSmall },
  data() {
    return {
      bottomFloorData: {
        value: null,
        items: []
      },
      mainFloorData: {
        value: null,
        items: []
      },
      additionalMainFloorData: {
        value: null,
        items: []
      },
      additionalFloorData: {
        value: null,
        items: []
      },
      floorCoatingData: {
        value: null,
        items: []
      },
      additionalLagsData: false,
      metalLagsData: false,
      metalLagsCountData: 0,
      differentFloorCoatingData: false,
      items: []
    }
  },

  created() {
    this.init()
  },

  watch: {
    differentFloorCoatingData(val) {
      this.setDifferentFloorCoating(val)
    },

    metalLagsData(val) {
      if (val) {
        this.metalLagsCountData = 0
        this.additionalLagsData = false
      }
      this.setMetalLags(val)
    },

    metalLagsCountData(val) {
      if (parseInt(val) > 50) {
        this.metalLagsCountData = val = 50
      }
      this.setMetalLagsCount(val)
    },

    additionalLagsData(val) {
      this.setAdditionalLags(val)
    },

    'additionalFloorData.value': {
      handler: function (val) {
        this.setAdditionalFloor(val)
      }
    },

    'bottomFloorData.value' : {
      handler: function (val) {
        this.setBottomFloor(val)
      }
    },

    'mainFloorData.value': {
      handler: function (val) {
        this.setMainFloor(val)
      }
    },

    'additionalMainFloorData.value': {
      handler: function (val) {
        this.setAdditionalMainFloor(val)
      }
    },

    'floorCoatingData.value': {
      handler: function (val) {
        this.setFloorCoating(val)
      }
    },

    floorCoating(val) {
      this.floorCoatingData.value = val
    },

    floorCost: {
      deep: true,
      handler: function () {
        this.updateItems()
      }
    }
  },

  computed: {
    ...mapGetters([
      'additionalFloor',
      'additionalLags',
      'metalLags',
      'mainFloor',
      'bottomFloor',
      'additionalMainFloor',
      'floorCoating',
      'differentFloorCoating',
      'floorCost',
      'containerType',
      'stage'
    ])
  },

  methods: {
    ...mapActions([
      'setAdditionalFloor',
      'setAdditionalLags',
      'setMetalLags',
      'setMetalLagsCount',
      'setMainFloor',
      'setBottomFloor',
      'setAdditionalMainFloor',
      'setFloorCoating',
      'setDifferentFloorCoating'
    ]),

    IsNumber(event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    },

    onArrowClick(data) {
      data.opened = !data.opened
    },

    init() {
      const additionalFloorOptions = EquipmentCategories.getItems({
        categoryKey: 'chernovoy_pol',
        optionKey: 'dop_dno'
      }).options

      if (additionalFloorOptions) {
        this.additionalFloorData.items = additionalFloorOptions.map(item => {
          return {
            title: item.title,
            cost: item.price,
            raw: item
          }
        })

        if (this.additionalFloor === null) this.setAdditionalFloor(this.additionalFloorData.items[0])
        this.additionalFloorData.value = this.additionalFloor

        if (this.additionalLags === null) this.setAdditionalLags(false)
        this.additionalLagsData = this.additionalLags

        if (this.metalLags === null) this.setMetalLags(false)
        this.metalLagsData = this.metalLags

        if (this.differentFloorCoating === null) this.setDifferentFloorCoating(false)
        this.differentFloorCoatingData = this.differentFloorCoating
      }

      this.mainFloorData.items = EquipmentCategories.getItems({
        categoryKey: 'chistovoy_pol',
        optionKey: 'osnovnoy_pol'
      }).options.map((item) => {
        return {
          title: item.title,
          cost: item.price,
          raw: item
        }
      })

      if (this.mainFloor === null) this.setMainFloor(this.mainFloorData.items[0])
      this.mainFloorData.value = this.mainFloor

      this.additionalMainFloorData.items = EquipmentCategories.getItems({
        categoryKey: 'chistovoy_pol',
        optionKey: 'dop_pol'
      }).options.map((item) => {
        return {
          title: item.title,
          cost: item.price,
          raw: item
        }
      })

      if (this.additionalMainFloor === null) this.setAdditionalMainFloor(this.additionalMainFloorData.items[0])
      this.additionalMainFloorData.value = this.additionalMainFloor

      this.floorCoatingData.items = EquipmentCategories.getItems({
        categoryKey: 'chistovoy_pol',
        optionKey: 'linoleym_bitovoy'
      }).options.map((item) => {
        return {
          title: item.title,
          cost: item.price,
          raw: item
        }
      })

      if (this.floorCoating === null) this.setFloorCoating(this.floorCoatingData.items[0])
      this.floorCoatingData.value = this.floorCoating

      const bottomFloorCategory = EquipmentCategories.getCategory('dno')

      if (bottomFloorCategory.options && bottomFloorCategory.options.length > 0) {
        this.bottomFloorData.items = bottomFloorCategory.options
          .filter(item => ['c8', 'dop_dno'].indexOf(item.key) >= 0)
          .map(item => {
            return {
              title: item.title,
              raw: item
            }
          })

        if (!this.bottomFloor) this.setBottomFloor(this.bottomFloorData.items.filter(item => item.raw.key === 'dop_dno')[0])
        this.bottomFloorData.value = this.bottomFloor
      } else {
        this.setBottomFloor(null)
      }
    },

    updateItems() {
      const list = []

      const getStageData = (stage) => {
        const filtered = list.filter(item => item.stage === stage)
        let data = null

        if (filtered.length > 0) {
          data = filtered[0]
        } else {
          data = {
            label: 'Этаж ' + stage,
            opened: stage === this.stage,
            stage: stage,
            items: []
          }

          list.push(data)
        }

        return data
      }

      if (this.floorCost.items) {
        this.floorCost.items.forEach(coatingItem => {
          const data = getStageData(parseInt(coatingItem.stage))
          data.items.push({
            icon: require('@/assets/images/left-menu/icon-box.svg'),
            title: 'Контейнер ' + coatingItem.boxId,
            options: coatingItem.coating.title,
            cost: coatingItem.price,
            stage: coatingItem.stage,
            boxId: coatingItem.boxId
          })
        })
      }

      this.items = list
    },

    onCoatingItemClick(item) {
      eventBus.$emit(EventBusEvents.SelectItemInEditor, {
        type: BaseEditorElement.ELEMENT_TYPE_BOX,
        id: item.boxId,
        stage: item.stage
      })
    }
  }
}
</script>
