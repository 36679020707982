import EquipmentCategories from '@/common/EquipmentCategories';

class HintsData {
  static _instance

  _rawData

  static getInstance() {
    if (!HintsData._instance) HintsData._instance = new HintsData()
    return HintsData._instance
  }

  setData(data) {
    this._rawData = data
  }

  getHint(section, key) {
    let hintText = null
    const sectionsList = this._rawData[EquipmentCategories.containerType] ?
      this._rawData[EquipmentCategories.containerType].filter(sectionItem => sectionItem.key === section) :
      []

    if (sectionsList.length > 0) {
      const optionsList = sectionsList[0].options.filter(item => item.key === key)
      if (optionsList.length > 0) {
        hintText = optionsList[0].hint
      }
    }

    return hintText
  }
}

export default HintsData.getInstance()
