<template lang="pug">
  .ordering-comment-form
    textarea-autosize(v-model="comment",
      ref='textarea',
      placeholder="Здесь вы можете оставить дополнительные пожелания и комментарии",
      :min-height="56"
    )
    .actions(v-if="comment && !saved")
      kraus-button(:label="'Сохранить'",
        :colors="{label: '#FFFFFF', background: '#0558AB'}",
        :border="true",
        @click="onSaveClick")
      kraus-button(:label="'Отмена'",
        :colors="{label: '#0558AB', background: '#FAFBFB'}",
        :border="true",
        @click="onCancelClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'OrderingCommentForm',
  components: { KrausButton },
  data() {
    return {
      comment: '',
      prevComment: '',
      saved: false
    }
  },

  created() {
    this.comment = this.projectComment
    this.$nextTick(() => this.saved = true)
  },

  watch: {
    comment() {
      if (this.saved) {
        this.prevComment = this.comment
      }
      this.saved = false
    }
  },

  computed: {
    ...mapGetters([
      'projectComment'
    ])
  },

  methods: {
    ...mapActions([
      'setProjectComment'
    ]),

    onSaveClick() {
      this.saved = true
      this.setProjectComment(this.comment)
    },

    onCancelClick() {
      this.comment = this.prevComment
      this.$nextTick(() => {
        this.saved = true
      })
    }
  }
}
</script>
