import api from '@/common/http/api';
import constants from '@/common/contants';
import axios from 'axios';

class EquipmentCategories {
  static Instance = null

  _rawData
  _selectedContainer

  constructor(props) {
    if (EquipmentCategories.Instance !== null) {
      throw new Error('Already instantiated')
    }

    constants.modelsList.forEach(url => {
      const xmlHttpRequest = new XMLHttpRequest()
      xmlHttpRequest.open('GET', url)
      xmlHttpRequest.send()
    })
  }

  static getInstance() {
    if (this.Instance === null) this.Instance = new EquipmentCategories()
    return this.Instance
  }

  async loadData() {
    const response = await api.get('/category/all')

    if (response.data['obreshotka']) {
      this._rawData = response.data
    } else {
      throw new Error('Unable to load dictionary')
    }
    return this._rawData
  }

  get rawData() {
    return this._rawData
  }

  setRawData(data) {
    this._rawData = data
  }

  getItems({categoryKey, optionKey, containerType}) {
    const category = this.getCategory(categoryKey, containerType)
    let option = []

    if (category && category.options) {
      const filtered = category.options.filter(optionItem => optionItem.key === optionKey)
      option = filtered.length > 0 ? filtered[0] : null
    }

    return option
  }

  getCategory(categoryKey, containerType) {
    const selectedContainer = this._selectedContainer ? this._selectedContainer : containerType
    const filteredCategory = this._rawData[selectedContainer].filter(categoryItem => categoryItem.key === categoryKey)
    return filteredCategory.length > 0 ? filteredCategory[0] : []
  }

  setContainerType(containerType) {
    this._selectedContainer = containerType
  }

  get containerType() {
    return this._selectedContainer
  }
}

export default EquipmentCategories.getInstance()
