<template lang="pug">
  .content.content-internal-finishing
    .content-header
      .content-option-name
        span Стены{{containerType.improved ? ', металл. лист 0.45 мм' : ''}}
        tooltip-icon(:hint="{section: 'internal-finishing', key: 'walls'}")
      .content-option-cost
    .content-actions
      kraus-equipment-select(:values="wallMaterial.items", v-model="wallMaterial.value")

    .small-switcher(v-if="!containerType.improved")
      .small-switcher-info.color-light
        .text
          span Разный материал для<br/>отдельных контейнеров
          tooltip-icon(:hint="{section: 'internal-finishing', key: 'different-materials'}")
      .small-switcher-switcher
        kraus-switcher-small(v-model="differentMaterial")

    .small-switcher(v-if="containerType.improved")
      .small-switcher-info.color-light
        .text
          span Разный цвет для<br/>отдельных контейнеров
          tooltip-icon(:hint="{section: 'internal-finishing', key: 'different-materials'}")
      .small-switcher-switcher
        kraus-switcher-small(v-model="differentMaterial")

    template(v-if="containerType.improved")
      .content-header
        .content-option-name
          span Тип внутренних перегородок
          tooltip-icon(:hint="{section: 'internal-finishing', key: 'insidewalls-type'}")
        .content-option-cost
      .content-actions
        kraus-equipment-select(:values="partitionWallMaterialData.items", v-model="partitionWallMaterialData.value")

    template(v-if="internalWalls.items.length > 0")
      .content-header.uppercase
        .content-option-name Внутренние перегородки
        .content-option-cost

      .content-actions
        .stage-items-wrapper(v-for="(data, index) in internalWalls.items", :key="index")
          .stage-selector(:class="{opened: data.opened}", @click="onArrowClick(data)")
            .label {{data.label}}
            .arrow
          .internal-walls-list(v-if="data.opened")
            .internal-wall-item(v-for="(item, index) in data.items", :key="index", @click="onWallItemClick(item)")
              .internal-wall-item-icon(:style="{backgroundImage: 'url(' + wallIcon + ')'}")
              .internal-wall-item-content
                .internal-wall-item-title {{item.title}}
                .internal-wall-item-info
                  .internal-wall-item-info-options {{item.options}}
                  .internal-wall-item-info-cost {{item.cost| currency }}

</template>
<script>
import KrausEquipmentSelect
  from '@/components/_common/ui/KrausEquipmentSelect';
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import KrausContainerSelect
  from '@/components/_common/ui/KrausContainerSelect';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import TooltipIcon from '@/components/_common/ui/TooltipIcon';
export default {
  name: 'InternalFinishing',
  components: { TooltipIcon, KrausContainerSelect, KrausSwitcherSmall, KrausEquipmentSelect },
  data () {
    return {
      wallIcon: require('@/assets/images/left-menu/items/contructions/inside-wall.svg'),
      differentMaterial: false,
      wallMaterial: {
        value: null,
        items: []
      },

      partitionWallMaterialData: {
        value: null,
        items: []
      },

      internalWalls: {
        value: null,
        items: [
          // {
          //   stage: 1,
          //   containerId: 1,
          //   label: 'Этаж 1 / Контейнер 1',
          //   items: [
          //     {
          //       icon: '/pics/right-menu/window.svg',
          //       title: 'Окошечко',
          //       options: '100x100, поворотное',
          //       cost: '10000'
          //     },
          //   ]
          // },
        ]
      }
    }
  },

  created() {
    this.wallMaterial.value = this.wallMaterial.items[0]

    this.init()
    // this.updateItems()
  },

  mounted() {
    this.updateItems()
  },

  watch: {
    'insideWallsCost' : {
      deep: true,
      handler: function () {
        this.updateItems()
      }
    },

    'wallMaterial.value': {
      handler: function (val) {
        this.setInsideWallMaterial(val)
        //this.updateItems()
      }
    },

    'partitionWallMaterialData.value' : {
      handler: function (val) {
        this.setPartitionWallMaterial(val)
      }
    },

    differentMaterial(val) {
      this.setInsideWallDifferentMaterial(val)
      //this.updateItems()
    },

    insideWallMaterial(val) {
      this.wallMaterial.value = val
    }
  },

  computed: {
    ...mapGetters([
      'containerType',
      'stagesData',
      'insideWallMaterial',
      'insideWallDifferentMaterial',
      'partitionWallMaterial',
      'insideWallsCost',
      'stage'
    ])
  },

  methods: {
    ...mapActions([
      'setInsideWallMaterial',
      'setInsideWallDifferentMaterial',
      'setPartitionWallMaterial'
    ]),

    onArrowClick(data) {
      data.opened = !data.opened
    },

    init() {
      if (!this.containerType.improved) {
        this.wallMaterial.items = EquipmentCategories
          .getCategory('stena_vnutri')
          .options
          .map((item) => {
            return {
              title: item.title,
              raw: item
            }
          })

      } else {

        this.wallMaterial.items = EquipmentCategories
          .getCategory('stena_vnutri')
          .options[0].options
          .map((item) => {
            return {
              title: item.title,
              raw: item,
              icon: {
                color: item.hex
              }
            }
          })
      }

      if (this.insideWallMaterial === null) this.setInsideWallMaterial(this.wallMaterial.items[0])
      this.wallMaterial.value = this.insideWallMaterial

      if (this.insideWallDifferentMaterial === null) this.setInsideWallDifferentMaterial(false)
      this.differentMaterial = this.insideWallDifferentMaterial

      this.partitionWallMaterialData.items = EquipmentCategories
        .getCategory('peregoronka_vnutri')
        .options
        .map((item) => {
          return {
            title: item.title,
            raw: item
          }
        })

      if (!this.partitionWallMaterial) this.setPartitionWallMaterial(this.partitionWallMaterialData.items[0])
      this.partitionWallMaterialData.value = this.partitionWallMaterial
    },

    updateItems() {
      const list = []

      const getStageData = (stage) => {
        const filtered = list.filter(item => item.stage === stage)
        let data = null

        if (filtered.length > 0) {
          data = filtered[0]
        } else {
          data = {
            label: 'Этаж ' + stage,
            opened: stage === this.stage,
            stage: stage,
            items: []
          }

          list.push(data)
        }

        return data
      }

      if (this.insideWallsCost.items) {
        this.insideWallsCost.items.forEach(wallItem => {
          const data = getStageData(parseInt(wallItem.stage))
          data.items.push({
            icon: null, //wallItem.dictionaryData.menuIcon,
            title: 'Перегородка ' + wallItem.id,
            options: wallItem.material.title,
            cost: wallItem.price,
            id: wallItem.id,
            stage: wallItem.stage
          })
        })
      }

      this.internalWalls.items = list
    },

    onWallItemClick(item) {
      eventBus.$emit(EventBusEvents.SelectItemInEditor, {
        type: BaseEditorElement.ELEMENT_TYPE_WALL,
        id: item.id,
        stage: item.stage
      })
    }
  }
}
</script>
