<template lang="pug">
  .popup-content-wrapper.send-project
    .popup-header Отправить проект на Email
    .popup-content
      .description Вам на почту придет PDF-файл с вашей Заявкой и ссылка на ее редактирование. Вы сможете вернуться к редактированию Заявки в любое время, достаточно перейти по ссылке в почте.
      .email-input(:class="{error: hasError}")
        input(type="text", v-model="email", ref="input")
    .popup-actions
      kraus-button(:label="'Отправить'",
        :colors="{label: '#FFFFFF', background: '#0558AB'}",
        :border="true",
        @click="onContinueClick")
      kraus-button(:label="'Отмена'",
        :colors="{label: '#0558AB', background: '#FFFFFF'}",
        :border="true",
        @click="onCancelClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import PopupsNames from '@/components/_common/ui/Popup/popupNames';
import api from '@/common/http/api';
import { mapGetters } from 'vuex';
export default {
  name: 'SendProject',
  components: { KrausButton },
  data() {
    return {
      email: '',
      hasError: false
    }
  },

  mounted() {
    this.$refs.input.focus()
  },

  computed: {
    ...mapGetters([
      'projectId'
    ])
  },

  methods: {
    onContinueClick() {
      if (this.email.trim() !== '' && /\S+@\S+\.\S+/.test(this.email)) {
        this.hasError = false
        api.post('/project/email', {
          id: this.projectId,
          email: this.email
        })

        this.$store.state.email = this.email

        eventBus.$emit(EventBusEvents.ShowPopup, PopupsNames.ProjectSended)
      } else {
        this.hasError = true
      }
    },

    onCancelClick() {
      eventBus.$emit(EventBusEvents.ShowPopup, null)
    }
  }
}
</script>
