import { Mesh, SceneLoader, Vector3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';

class ExternalItem {
  _scene
  _data
  _stage

  _mesh

  constructor({scene, data, stage}) {
    this._scene = scene
    this._data = data
    this._stage = stage

    this._mesh = new Mesh('external-item', this._scene)

    this._loadModel()
  }

  get _rotation() {
    let radian = Math.PI / 180
    let rotation = radian * this._data.rotation
    return rotation -Math.PI / 2
  }

  get modelUrl() {
    let fileName = this._data.dictionaryData.data.raw.key + '.glb'
    if (this._data.dictionaryData.mirrored) {
      fileName = this._data.dictionaryData.data.raw.key + '_mirrored.glb'
    }
    return {
      path: '/models/external-items/',
      fileName: fileName
    }
  }

  _loadModel() {
    const meshContainer = new Mesh('container', this._scene)
    SceneLoader.LoadAssetContainer(this.modelUrl.path, this.modelUrl.fileName, this._scene, (container) => {
      container.meshes.forEach(meshItem => {
        if (!meshItem.parent) meshItem.parent = meshContainer
      })

      container.addAllToScene()
      meshContainer.parent = this._mesh
      this._onModelLoaded()
    })
  }

  _onModelLoaded() {
    this._mesh.scaling = new Vector3(100 * BlocksConstructor.Scale, 100 * BlocksConstructor.Scale, 100 * BlocksConstructor.Scale)
    this._mesh.position.x = -this._data.position.x * BlocksConstructor.Scale
    this._mesh.position.z = this._data.position.y * BlocksConstructor.Scale
    this._mesh.position.y = ((this._stage - 1) *  BlocksConstructor.StageHeight) * BlocksConstructor.Scale
    this._mesh.rotation.y = this._rotation
  }

  dispose() {
    this._mesh.dispose()
  }
}

export default ExternalItem
