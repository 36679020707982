<template lang="pug">
  .container-items
    container-item(v-for="(type, index) in types", :type="type", :key="index")
</template>
<script>
import ContainerItem from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem'
import Carcass from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Carcass'
import ExternalFinishing
  from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/ExternalFinishing'
import Roof from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Roof'
import Windows from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Windows'
import Doors from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Doors'
import Plumbing from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Plumbing'
import Warming from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Warming'
import InternalFinishing
  from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/InternalFinishing'
import Floor from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Floor'
import Waterproofing from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Waterproofing'
import Electric from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Electric'
import Additional from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Additional'
import { mapGetters } from 'vuex'
import Mounting from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Mounting'

export default {
  name: 'ContainerItems',
  components: { ContainerItem },
  data() {
    return {
      types: [
        {
          id: 'Carcass',
          title: 'Каркас',
          component: Carcass,
        },
        {
          id: 'ExternalFinishing',
          title: 'Внешн. отделка',
          component: ExternalFinishing,
        },
        {
          id: 'Roof',
          title: 'Кровля',
          component: Roof,
        },
        {
          id: 'Windows',
          title: 'Окна',
          component: Windows,
        },
        {
          id: 'Doors',
          title: 'Двери',
          component: Doors,
        },
        {
          id: 'InternalFinishing',
          title: 'Внутр. отделка',
          component: InternalFinishing,
        },
        {
          id: 'Floor',
          title: 'Пол',
          component: Floor,
        },
        {
          id: 'Plumbing',
          title: 'Сантехника',
          component: Plumbing,
        },
        {
          id: 'Warming',
          title: 'Утепление',
          component: Warming,
        },
        {
          id: 'Waterproofing',
          title: 'Гидроизоляция',
          component: Waterproofing,
        },
        {
          id: 'Electric',
          title: 'Электрика',
          component: Electric,
        },
        {
          id: 'Additional',
          title: 'Доп. элементы',
          component: Additional,
        },
        {
          id: 'Mounting',
          title: 'Монтаж',
          component: Mounting,
        }
      ]
    }
  },

  computed: {
    ...mapGetters([
      'carcassCost'
    ])
  }
}
</script>
