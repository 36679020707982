import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Bitmap, Container, Shape } from '@createjs/easeljs';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';

class WallItemActionsView extends BaseEditorElement {
  /**
   * @type {WallItem}
   */
  wallItem

  /**
   *
   * @type {easeljs.Container}
   * @private
   */
  _rotateButton = new Container()
  _deleteButton = new Container()
  _copyButton = new Container()

  constructor(wallItem) {
    super();
    this.wallItem = wallItem

    const rotateButtonShape = new Shape()
    rotateButtonShape.graphics
      .beginFill('#FFFFFF')
      .beginStroke('#efefef')
      .drawCircle(0,0, 40)
    this._rotateButton.addChild(rotateButtonShape)
    this._rotateButton.addEventListener('mousedown', this._onRotateClick)
    this._rotateButton.x = 0
    this._rotateButton.y = 15

    const rotateIconBitmap = new Bitmap(require('@/assets/images/editor/actions/rotate.png'))
    rotateIconBitmap.scale = 0.7
    rotateIconBitmap.x = -30 * rotateIconBitmap.scale
    rotateIconBitmap.y = -30 * rotateIconBitmap.scale
    this._rotateButton.addChild(rotateIconBitmap)

    this.container.addChild(this._rotateButton)

    const deleteButtonShape = new Shape()
    deleteButtonShape.graphics
      .beginFill('#FFFFFF')
      .beginStroke('#efefef')
      .drawCircle(0,0, 40)
    this._deleteButton.addChild(deleteButtonShape)
    this._deleteButton.addEventListener('mousedown', this._onDeleteClick)
    this._deleteButton.x = 90

    const deleteIconBitmap = new Bitmap(require('@/assets/images/editor/actions/delete.png'))
    deleteIconBitmap.scale = 0.7
    deleteIconBitmap.x = -30 * rotateIconBitmap.scale
    deleteIconBitmap.y = -30 * rotateIconBitmap.scale
    this._deleteButton.addChild(deleteIconBitmap)
    this.container.addChild(this._deleteButton)


    const copyButtonShape = new Shape()
    copyButtonShape.graphics
      .beginFill('#FFFFFF')
      .beginStroke('#efefef')
      .drawCircle(0,0, 40)
    this._copyButton.addChild(copyButtonShape)
    this._copyButton.addEventListener('mousedown', this._onCopyClick)
    this._copyButton.x = -90

    const copyIconBitmap = new Bitmap(require('@/assets/images/editor/actions/copy.png'))
    copyIconBitmap.scale = 0.7
    copyIconBitmap.x = -30 * rotateIconBitmap.scale
    copyIconBitmap.y = -30 * rotateIconBitmap.scale
    this._copyButton.addChild(copyIconBitmap)
    this.container.addChild(this._copyButton)

    this.container.scale = 0
    this._rotateButton.scale = 0

    Grid.Instance.actionsContainer.addChild(this)

    CreateJsBlocksEditor.Instance.addEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
    Grid.Instance.addEventListener(Events.GRID_MOVED, this._onGridMoved)
  }

  _onGridMoved = () => {
    this.draw()
  }

  _onElementSelected = (e) => {
    this.draw()
  }

  _onDeleteClick = (e) => {
    this.wallItem.destroy()
    CreateJsBlocksEditor.Instance.setSelectedElement(null)
  }

  _onCopyClick = (e) => {
    setTimeout(() => {
      this.wallItem.copy()
    }, 0)
  }

  _onRotateClick = (e) => {
    this.wallItem.rotate()
  }

  draw() {
    if (this.wallItem.isSelected() && this.wallItem.isSetuped) {
      this.container.scale = 1 / Grid.Instance.container.scale
      // this._copyButton.scale = this.wallItem.copyAvailable ? 1 : 0
      this.updatePosition()
    } else {
      this.container.scale = 0
    }
  }

  updatePosition () {
    const point = {x: this.wallItem.x, y: this.wallItem.y}
    point.y += 74 * this.container.scale

    this.x = point.x
    this.y = point.y

    this._rotateButton.scale = this.wallItem._type === WallItem.Types.Door && this.wallItem.rotationAvailable ? 1 : 0
  }

  destroy() {
    if (!this.destroyed) {
      Grid.Instance.actionsContainer.removeChild(this)
      CreateJsBlocksEditor.Instance.removeEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
      Grid.Instance.removeEventListener(Events.GRID_MOVED, this._onGridMoved)

      super.destroy();
    }
  }
}

export default WallItemActionsView
