import AbstractRoofMesh
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/Roof/AbstractRoofMesh'
import {
  CSG,
  Mesh,
  MeshBuilder,
  TransformNode,
} from '@babylonjs/core'
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor'

class DoubleRoofMesh extends AbstractRoofMesh {

  /**
   *
   * @type {{side: Mesh, top: Mesh, bottom: Mesh}}
   * @private
   */
  meshes = {
    bottom: null,
    top: null,
    side: null
  }


  constructor({...args}) {
    super({
      type: AbstractRoofMesh.Types.single,
      ...args
    })

    this.createMesh()
  }

  createMesh() {
    this._createBottomMesh()
    this._createSideMeshes()
    this._createTopMesh()
  }

  _createBottomMesh() {
    const width = ((this.vertical ? this.width : this.height) + this._ledge) * BlocksConstructor.Scale
    const depth = ((this.vertical ? this.height : this.width) + 8) * BlocksConstructor.Scale

    const mesh = MeshBuilder.CreateBox('Single roof bottom mesh', {
      width,
      height: BlocksConstructor.Scale,
      depth,
    }, this.scene)

    mesh.position = this.center
    mesh.rotation.y = !this.vertical ? Math.PI / 2 : 0
    mesh.material = this.roofMaterial

    mesh.parent = this.rootMesh

    this.meshes.bottom = mesh
  }

  _createSideMeshes() {
    const sideRootMesh = new Mesh('Single roof root of side meshes', this.scene)
    sideRootMesh.parent = this.rootMesh

    const width = ((this.vertical ? this.width : this.height) + this._ledge) * BlocksConstructor.Scale
    const depth = ((this.vertical ? this.height : this.width) + 8) * BlocksConstructor.Scale
    const height = (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale

    let frontMesh = MeshBuilder.CreateBox('Single roof front side mesh', {
      width: width,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    frontMesh = this.createEdges(frontMesh, width, BlocksConstructor.Scale * 4)

    frontMesh.parent = sideRootMesh
    frontMesh.position.y = height / 2
    frontMesh.position.z += depth / 2


    let backMesh = MeshBuilder.CreateBox('Single roof front side mesh', {
      width: width,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    backMesh = this.createEdges(backMesh, width, BlocksConstructor.Scale * 4)

    backMesh.parent = sideRootMesh
    backMesh.position.y = height / 2
    backMesh.position.z -= depth / 2
    backMesh.rotation.y = Math.PI

    sideRootMesh.position = this.center
    sideRootMesh.rotation.y = !this.vertical ? Math.PI / 2 : 0

    let leftMesh = MeshBuilder.CreateBox('Single roof left side mesh', {
      width: depth,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    leftMesh = this.createEdges(leftMesh, depth, BlocksConstructor.Scale * 4)

    leftMesh.parent = sideRootMesh
    leftMesh.position.y = height / 2
    leftMesh.position.x -= width / 2
    leftMesh.rotation.y = Math.PI / 2 + Math.PI

    let rightMesh = MeshBuilder.CreateBox('Single roof left side mesh', {
      width: depth,
      height: height,
      depth: BlocksConstructor.Scale * 4
    }, this.scene)

    rightMesh = this.createEdges(rightMesh, depth, BlocksConstructor.Scale * 4)

    rightMesh.parent = sideRootMesh
    rightMesh.position.y = height / 2
    rightMesh.position.x = width / 2
    rightMesh.rotation.y = Math.PI / 2

    const slicer1 = MeshBuilder.CreateBox('slicer', {
      height: 3,
      width: width + 200 * BlocksConstructor.Scale,
      depth: depth + 200 * BlocksConstructor.Scale,
    }, this.scene)

    const slicer1Pivot = new TransformNode('pivot', this.scene)
    slicer1Pivot.parent = sideRootMesh

    slicer1.parent = slicer1Pivot
    slicer1.position.y += 3 / 2
    slicer1.position.x = width / 2

    const angle1 = -Math.atan( height / (width / 2 + this._angleLedge * BlocksConstructor.Scale))
    slicer1Pivot.position.y = height
    slicer1Pivot.rotation.z = angle1

    const slicer2 = MeshBuilder.CreateBox('slicer2', {
      height: 3,
      width: width + 200 * BlocksConstructor.Scale,
      depth: depth + 200 * BlocksConstructor.Scale,
    }, this.scene)

    const slicer2Pivot = new TransformNode('pivot', this.scene)
    slicer2Pivot.parent = sideRootMesh

    slicer2.parent = slicer2Pivot
    slicer2.position.y += 3 / 2
    slicer2.position.x = -width / 2

    const angle2 = Math.atan( height / (width / 2 + this._angleLedge * BlocksConstructor.Scale))
    slicer2Pivot.position.y = height
    slicer2Pivot.rotation.z = angle2

    const sideMeshes = [frontMesh, backMesh, leftMesh, rightMesh]
    const slicer1Csg = CSG.FromMesh(slicer1)
    const slicer2Csg = CSG.FromMesh(slicer2)

    sideMeshes.forEach(meshItem => {
      const csgItem = CSG.FromMesh(meshItem)
      const csgResult = csgItem.subtract(slicer1Csg).subtract(slicer2Csg)

      const subtractedMesh = csgResult.toMesh('side-mesh', null, this.scene)
      subtractedMesh.material = this.roofMaterial
      subtractedMesh.parent = sideRootMesh

      meshItem.dispose()
    })

    slicer1.dispose()
    slicer2.dispose()

    sideRootMesh.position = this.center
    sideRootMesh.rotation.y = !this.vertical ? Math.PI / 2 : 0

    this.meshes.side = sideRootMesh
  }

  _createTopMesh() {
    const topRootMesh = new Mesh('Single roof top root mesh', this.scene)
    topRootMesh.parent = this.rootMesh

    const width = ((this.vertical ? this.width : this.height ) + this._ledge) * BlocksConstructor.Scale
    const depth = ((this.vertical ? this.height : this.width) + this._ledge) * BlocksConstructor.Scale
    const height = (BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale

    const halfWidth = width / 2
    const topWidth = Math.sqrt(Math.pow(halfWidth, 2) + Math.pow(height,2))
    const delta = topWidth - halfWidth

    let topMesh1 = MeshBuilder.CreateBox('Top mesh', {
      width: depth,
      depth: BlocksConstructor.Scale * 4,
      height: topWidth
    })

    topMesh1 = this.createEdges(topMesh1, depth, topWidth)

    const pivot1 = new TransformNode('pivot', this.scene)
    pivot1.parent = topRootMesh

    const mat = this.roofMaterial

    topMesh1.material = mat

    topMesh1.parent = pivot1
    topMesh1.position.x = 0
    topMesh1.position.y = -topWidth / 2

    const angle1 = Math.atan( height / (width / 2 + this._angleLedge * BlocksConstructor.Scale)) - Math.PI / 2
    pivot1.position.y = height
    pivot1.position.z = 0
    pivot1.rotation.x = angle1

    let topMesh2 = MeshBuilder.CreateBox('Top mesh', {
      width: depth,
      depth: BlocksConstructor.Scale * 4,
      height: topWidth
    })

    topMesh2 = this.createEdges(topMesh2, depth, topWidth)

    const pivot2 = new TransformNode('pivot', this.scene)
    pivot2.parent = topRootMesh

    const mat2 = this.roofMaterial
    topMesh2.material = mat2

    topMesh2.parent = pivot2
    topMesh2.position.y = topWidth / 2

    const angle2 = -Math.atan( height / (width / 2 + this._angleLedge * BlocksConstructor.Scale)) - Math.PI / 2
    pivot2.position.y = height
    pivot2.position.z = 0
    pivot2.rotation.x = angle2

    topRootMesh.position = this.center
    topRootMesh.rotation.y = this.vertical ? Math.PI / 2 : 0
  }

  dispose() {
    this.rootMesh.dispose()
  }
}

export default DoubleRoofMesh
