import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Container, Rectangle, Shape } from '@createjs/easeljs';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';

export default class ResizeBoxActionsView extends BaseEditorElement {
  /**
   * @type {Box}
   * @private
   */
  _box

  /**
   * @type {easeljs.Container}
   * @private
   */
  _resizeItemsContainer = new Container()

  /**
   * @type {easeljs.Shape[]}
   * @private
   */
  _resizeItems = []

  constructor(box) {
    super();

    this._box = box
    this._createResizeItems()

    Grid.Instance.actionsContainer.addChild(this)
  }

  _createResizeItems() {
    for (let i = 0; i < 4; i++) {
      const shape = new Shape()
      shape.graphics
        .clear()
        .setStrokeStyle(4)
        .beginStroke('#F47090')
        .beginFill('#ffffff')
        .drawRect(-8, -8, 16, 16)
        .endFill()
        .endStroke()
      shape.addEventListener('pressmove', this._onResizeItemPressMove)
      shape.addEventListener('pressup', this._onResizeItemMouseUp)
      this._resizeItems[i] = shape
      this.container.addChild(shape)
    }
  }

  _onResizeItemPressMove = (e) => {
    let deltaX = Math.round(e.nativeEvent.movementX * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)
    let deltaY = Math.round(e.nativeEvent.movementY * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)

    const shape = e.target

    let resizeAvailable = false

    const isVertical = this._box.rectangle.height > this._box.rectangle.width

    if (isVertical) {
      deltaX = 0
    } else {
      deltaY = 0
    }

    while (true) {
      const rect = this._getNewResizeRect(shape, deltaX, deltaY)
      const length = isVertical ? rect.height : rect.width

      resizeAvailable = length >= 245 && length <= 700

      if (resizeAvailable) {
        Box.BoxesList.forEach((box) => {
          const boxRect = box.rectangle.clone()
          boxRect.x += 1
          boxRect.y += 1
          boxRect.width -= 2
          boxRect.height -= 2
          if (box !== this._box && boxRect.intersects(rect)) {
            resizeAvailable = false
          }
        })

        if (resizeAvailable) {
          ExternalItem.ExternalItemsList.forEach((externalItem) => {
            const testRect = externalItem.rectangle.clone()
            testRect.x -= 1
            testRect.y -= 1
            testRect.width += 2
            testRect.height += 2
            if (testRect.intersects(rect)) {
              resizeAvailable = false
            }
          })
        }
      }

      if (resizeAvailable) {
        this._box.connectors.forEach((c) => {
          if (shape.x === c.x) {
            c.x = Math.round(c.x + deltaX)
          }
          if (shape.y === c.y) {
            c.y = Math.round(c.y + deltaY)
          }
        })

        this._box.width = rect.width
        this._box.height = rect.height
        this._box.x = rect.x
        this._box.y = rect.y

        Pillar.UpdatePillars()
      }

      if (deltaX !== 0) {
        deltaX += deltaX > 0 ? -1 : 1
      }
      if (deltaY !== 0 && deltaX === 0) {
        deltaY += deltaY > 0 ? -1 : 1
      }
      if (resizeAvailable || deltaY < 0) break
    }

    this._box.updateWalls()
    this._box.draw()
  }

  _getNewResizeRect(shape, deltaX, deltaY) {
    let tempConnectors = []
    let center = this._box.center

    this._box.connectors.forEach(c => {
      if (c.x < center.x && c.y < center.y || c.x > center.x && c.y > center.y) {
        tempConnectors.push({
          x: c.x,
          y: c.y
        })
      }
    })

    tempConnectors.forEach(c => {
      if (shape.x === c.x) {
        c.x = Math.round(c.x + deltaX)
      }
      if (shape.y === c.y) {
        c.y = Math.round(c.y + deltaY)
      }
    })

    const [c1, c2] = tempConnectors
    const topLeftItem = c1.x < c2.x ? c1 : c2
    const bottomRightItem = topLeftItem === c1 ? c2 : c1

    return new Rectangle(topLeftItem.x, topLeftItem.y, bottomRightItem.x - topLeftItem.x, bottomRightItem.y - topLeftItem.y)
  }

  _onResizeItemMouseUp = (e) => {
    this.emitChangeByTimeout()
  }

  draw() {
    super.draw()
    this.container.scale = this._box.isSelected() ? 1 : 0

    this._box.connectors.forEach((c, index) => {
      const resizeItem = this._resizeItems[index]
      resizeItem.x = c.x
      resizeItem.y = c.y
    })
  }

  destroy() {
    super.destroy();

    this._resizeItems.forEach((shape) => {
      shape.addEventListener('pressmove', this._onResizeItemPressMove)
      shape.addEventListener('pressup', this._onResizeItemMouseUp)
    })

    Grid.Instance.actionsContainer.removeChild(this)
  }
}
