<template lang="pug">
  .packet-select
    .packet-item(v-for="(item, index) in items", :key="index", @click="onItemClick(item)")
      .packet-item-radio-button(:class="{enabled: isItemSelected(item)}")
        .dot
      .packet-item-info
        .packet-item-info-title
          span {{item.title}}
          tooltip-icon(v-if="item.hint", :hint="item.hint")
        .packet-item-info-cost-wrapper
          .packet-item-info-power
            span {{item.info}}
            .cost {{!isItemSelected(item) && item.cost && item.cost > 0 ? item.price + ' ₽' : '&nbsp;'}}
          .packet-item-info-cost(v-if="!item.cost || item.cost === 0") входит в стоимость
          .packet-item-info-cost.total(v-if="isItemSelected(item) && item.cost && item.cost > 0") {{item.price | currency}}
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EquipmentCategories from '@/common/EquipmentCategories';
import TooltipIcon from '../../../../../_common/ui/TooltipIcon';

export default {
  name: 'PacketSelect',
  components: { TooltipIcon },
  data() {
    return {
      selectedItem: null,
      items: [
        {
          title: 'Пакет «Стандарт»',
          info: '2,5 кВт на блок',
          cost: 0,
        },
        {
          title: 'Пакет «Евро»',
          info: '5 кВт на блок',
          cost: 52000,
        }
      ]
    }
  },

  created() {
    this.selectedItem = this.items[0]
    this.init()
  },

  watch: {
    selectedItem(val) {
      this.setElectricPacket(val)
    }
  },

  computed: {
    ...mapGetters([
      'electricPacket',
      'containerType'
    ])
  },

  methods: {
    ...mapActions([
      'setElectricPacket'
    ]),

    init() {
      let standart = EquipmentCategories.getItems({
        categoryKey: 'elektrika',
        optionKey: 'paket_standart_'
      })

      let evro =  EquipmentCategories.getItems({
        categoryKey: 'elektrika',
        optionKey: 'paket_evro_'
      })

      const items = []

      if (standart) {
        standart['hint'] = {section: 'electrician', key: 'packet-standart'}
        items.push(standart)
      }
      if (evro) {
        evro['hint'] = {section: 'electrician', key: 'packet-euro'}
        items.push(evro)
      }

      this.items = items

      if (this.electricPacket === null) {
        if (this.containerType.improved) {
          this.setElectricPacket(items[0])
        } else {
          this.setElectricPacket(items[0])
        }
      }
      this.selectedItem = this.electricPacket
    },

    onItemClick(item) {
      this.selectedItem = item
    },

    isItemSelected(item) {
      return this.selectedItem === item
    }
  }
}
</script>
