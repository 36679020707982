<template lang="pug">
  .content.content-carcass
    template(v-if="false")
      .content-header
        .content-option-name Угловые стойки
        .content-option-cost(v-if="carcassCost.cornerPosts > 0") {{carcassCost.cornerPosts| currency }}
      .content-actions
        kraus-switcher(:items="cornerPostsData.items", v-model="cornerPostsData.value")

    .content-header
      .content-option-name Цвет каркаса
      .content-option-cost
    .content-actions
      kraus-equipment-select(:values="carcassColorData.items", v-model="carcassColorData.value")

    .small-switcher
      .small-switcher-info
        .text
          span Усиление каркаса
          tooltip-icon(:hint="{section: 'karkas', key: 'usilenie_karkasa'}")
        .cost(v-if="carcassCost.strengthening") {{carcassCost.strengthening | currency}}
      .small-switcher-switcher
        kraus-switcher-small(v-model="carcassStrengtheningData")

    .item-cost-info
      .item-cost-info_label
        span Угловые стойки
        tooltip-icon(:hint="{section: 'karkas', key: 'uglovie_stoki'}")
      .item-cost-info_cost {{this.containerType.improved ? '150' : '90'}} мм

    .item-cost-info
      .item-cost-info_label
        span Швеллер
        tooltip-icon(:hint="{section: 'karkas', key: 'shveler'}")
      .item-cost-info_cost 120 мм

    template(v-if="this.containerType && !this.containerType.improved")

      .item-cost-info
        .item-cost-info_label
          span Обрешётка
          tooltip-icon(:hint="{section: 'karkas', key: 'obreshetka'}")
        .item-cost-info_cost {{lathing}}
</template>
<script>
import KrausSwitcher from '@/components/_common/ui/KrausSwitcher';
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import KrausEquipmentSelect
  from '@/components/_common/ui/KrausEquipmentSelect';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
import TooltipIcon from '../../../../_common/ui/TooltipIcon';

export default {
  name: 'Carcass',
  components: { TooltipIcon, KrausEquipmentSelect, KrausSwitcherSmall, KrausSwitcher },
  data() {
    return {
      // Угловые стойки отключили
      // cornerPostsData: {
      //   value: null,
      //   items: []
      // },
      carcassColorData: {
        value: null,
        items: []
      },
      carcassStrengtheningData: false
    }
  },

  created() {
    this.init()
  },

  watch: {
    'carcassColorData.value': {
      handler: function (val) {
        this.setCarcassColor(val)
      }
    },

    carcassColor(val) {
      this.carcassColorData.value = val
    },

    // Угловые стойки отключили
    // 'cornerPostsData.value': {
    //   handler: function (val) {
    //     this.setCornerPosts(val)
    //   }
    // },

    carcassStrengtheningData(val) {
      this.setCarcassStrengthening(val)
    },

    warming: {
      deep: true,
      handler: function (val) {
        //console.log('!!!', val)
      }
    }
  },

  computed: {
    ...mapGetters([
      'carcassCost',
      'cornerPosts',
      'carcassColor',
      'carcassStrengthening',
      'warming',
      'containerType'
    ]),

    carcassStrengtheningCost() {
      return 10000
    },

    lathing() {
      return this.warming ? this.warming.thickness.lathing : ''
    }
  },

  methods: {
    ...mapActions([
      'setCornerPosts',
      'setCarcassColor',
      'setCarcassStrengthening',
    ]),

    init() {
      // Угловые стойки

      // Угловые стойки отключили
      // this.cornerPostsData.items = EquipmentCategories.getItems({
      //   categoryKey: 'karkas',
      //   optionKey: 'uglovye_stoyki'
      // }).options.map(item => {
      //   return {
      //     key: item.key,
      //     label: item.title,
      //     cost: item.price,
      //     raw: item
      //   }
      // })
      //
      // if (!this.cornerPosts) {
      //   this.setCornerPosts(this.cornerPostsData.items[0])
      // }
      // this.cornerPostsData.value = this.cornerPostsData.items.filter(item => {
      //   return item.key === this.cornerPosts.key
      // })[0]

      this.carcassStrengtheningData = this.carcassStrengthening
      // Цвет каркаса
      this.carcassColorData.items = EquipmentCategories.getItems({
        categoryKey: 'karkas',
        optionKey: 'cveta_karkasa'
      }).options.map(item => {
        return {
          icon: {
            color: item.hex,
          },
          title: item.title,
          cost: item.price,
          raw: item
        }
      })

      if (!this.carcassColor) {
        if (this.containerType.improved) {
          this.setCarcassColor(this.carcassColorData.items.filter(colorItem => colorItem.raw.key === 'brown')[0])
        } else {
          this.setCarcassColor(this.carcassColorData.items.filter(colorItem => colorItem.raw.key === 'blue')[0])
        }
      }
      this.carcassColorData.value = this.carcassColor

      if (this.carcassStrengthening === null) this.setCarcassStrengthening(false)
    }
  }
}
</script>
