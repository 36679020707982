<template lang="pug">
  .content.content-electric
    packet-select
    .content-header-electric
      .content-option-name РОЗЕТКИ
    .content-actions
      .electric-items
        electric-item(v-for="(socket, index) in sockets", :data="socket", :key="index")

    .content-header-electric
      .content-option-name ОСВЕЩЕНИЕ
    .content-actions
      .electric-items
        electric-item(v-for="(light, index) in lights", :data="light", :key="index")

</template>
<script>
import PacketSelect
  from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Electric/PacketSelect';
import ElectricItem
  from '@/components/BlocksEditor/BuildingEquipment/ContainerItems/ContainerItem/Electric/ElectricItem';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Electric',
  components: { ElectricItem, PacketSelect },
  data() {
    return {
      sockets: [
        // {
        //   title: 'Розетка сдвоенная',
        //   cost: 10,
        //   count: 0,
        //   icon: require('@/assets/images/editor/building-equipment/electric/double-socket.svg')
        // }
      ],

      lights: [
        // {
        //   title: 'Плафон-таблетка',
        //   cost: 10,
        //   count: 0,
        //   icon: require('@/assets/images/editor/building-equipment/electric/light-1.svg')
        // }
      ]
    }
  },

  created() {
    this.init()
  },

  watch: {
    boxesCount(count, prevCount) {
      const delta = count - prevCount

      const sockets = []
      this.sockets.forEach(socketItem => {
        if (socketItem.key === 'rozetka_sdvoennaya') {
          socketItem.count += delta * 2
          if (socketItem.count < 0) socketItem.count = 0
        }
        sockets.push(socketItem)
      })

      const lights = []
      this.lights.forEach(lightItem => {
        if (lightItem.key === 'plafon_tabletka') {
          lightItem.count += delta * 2
          if (lightItem.count < 0) lightItem.count = 0
        }
        lights.push(lightItem)
      })

      this.sockets = []
      this.lights = []
      this.$nextTick(() => {
        this.sockets = sockets
        this.lights = lights
      })
    },
    sockets: {
      deep: true,
      handler: function (val) {
        this.setElectricSockets(val)
      }
    },

    lights: {
      deep: true,
      handler: function (val) {
        this.setElectricLights(val)
      }
    }
  },

  computed: {
    ...mapGetters([
      'electricSockets',
      'electricLights',
      'boxesCount'
    ])
  },

  methods: {
    ...mapActions([
      'setElectricSockets',
      'setElectricLights'
    ]),

    init() {
      const icons = {
        'rozetka_sdvoennaya': require('@/assets/images/editor/building-equipment/electric/double-socket.svg'),
        'rozetka_ulichnaya': require('@/assets/images/editor/building-equipment/electric/street-socket.svg'),
      }
      this.sockets = EquipmentCategories.getItems({
        categoryKey: 'elektrika',
        optionKey: 'rozetki'
      }).options.map(item => {
        return {
          icon: icons[item.key],
          key: item.key,
          title: item.title,
          cost: item.price,
          count: item.key === 'rozetka_sdvoennaya' ? 2 : 0,
          raw: item
        }
      })

      if (this.electricSockets === null) this.setElectricSockets(this.sockets)

      this.electricSockets.forEach(storeSocketItem => {
        this.sockets.forEach(socketItem => {
          if (storeSocketItem.key === socketItem.key) {
            socketItem.count = storeSocketItem.count
          }
        })
      })

      const lightIcons = {
        'plafon_tabletka': require('@/assets/images/editor/building-equipment/electric/light-1.svg'),
        'svetilnik_led': require('@/assets/images/editor/building-equipment/electric/light-2.svg'),
        'ulichnyy_prozhektor': require('@/assets/images/editor/building-equipment/electric/light-3.svg'),
      }

      this.lights = EquipmentCategories.getItems({
        categoryKey: 'elektrika',
        optionKey: 'svet'
      }).options.map(item => {
        return {
          icon: lightIcons[item.key],
          key: item.key,
          title: item.title,
          cost: item.price,
          count: item.key === 'plafon_tabletka' ? 2 : 0,
          raw: item
        }
      })

      if (this.electricLights === null) this.setElectricLights(this.lights)

      this.electricLights.forEach(storeLightItem => {
        this.lights.forEach(lightItem => {
          if (storeLightItem.key === lightItem.key) {
            lightItem.count = storeLightItem.count
          }
        })
      })
    }
  }
}
</script>
