import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Container, Shape, Text } from '@createjs/easeljs';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';

class WallLengthLabelView extends BaseEditorElement {

  /**
   * @type {easeljs.Container}
   * @private
   */
  _textContainer = new Container()

  /**
   *
   * @type {easeljs.Container}
   * @private
   */
  _bgContainer = new Container()
  /**
   *
   * @type {easeljs.Shape}
   * @private
   */
  _bgShape = new Shape()

  /**
   *
   * @private {Wall}
   */
  _wall

  constructor(wall) {
    super()

    this._wall = wall

    this._bgContainer.addChild(this._bgShape)
    this.container.addChild(this._bgContainer);
    this.container.addChild(this._textContainer)
    this.container.mouseEnabled = false

    this.container.scale = 0
    Grid.Instance.actionsContainer.addChild(this)
  }

  draw() {
    if (this._wall && this._wall.box) {
      this._bgShape.graphics.clear()
      this.container.scale = this._wall.insideBoxWall ? (this._wall.isSelected() ? 1 : 0) : (this._wall.box.isSelected() ? 1 : 0)

      const length = this._wall.insideBoxWall ? this._wall.wallLength + 8 : this._wall.wallLength
      const textValue = (length / 100).toFixed(2)
      const text = new Text(textValue + ' м', '11px Inter', '#ffffff')

      this._textContainer.removeAllChildren()
      this._textContainer.addChild(text)

      const { width, height } = text.getBounds()

      if (this._wall.vertical) {
        text.rotation = -90
        text.x = this._wall.firstConnector.x - height / 2
        text.y = this._wall.firstConnector.y + this._wall.wallLength / 2 + width / 2

        this._bgShape.graphics
          .clear()
          .beginFill('#F47090')
          .drawRoundRect(this._wall.firstConnector.x - 8, this._wall.firstConnector.y + this._wall.wallLength / 2 - (8 + width / 2), 16, 16 + width, 8)
          .endFill()
      } else {

        text.x = this._wall.firstConnector.x + this._wall.wallLength / 2 - width / 2
        text.y = this._wall.firstConnector.y - height / 2 + 1

        this._bgShape.graphics
          .clear()
          .beginFill('#F47090')
          .drawRoundRect(this._wall.firstConnector.x + this._wall.wallLength / 2 - (8 + width / 2), this._wall.firstConnector.y - 8, 16 + width, 16, 8)
          .endFill()
      }
    }
  }

  destroy() {
    Grid.Instance.actionsContainer.removeChild(this)
    super.destroy()
  }
}

export default WallLengthLabelView
