<template lang="pug">
  .kraus-small-tooltip(:style="{opacity: opacity, top: (this.opacity > 0 ? 150 : -100) + 'px'}")
    .icon
    .text {{text}}
</template>
<script>
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';

export default {
  name: 'KrausSmallTooltip',
  data() {
    return {
      text: 'Используйте левую кнопку мыши или тачпада для вращения здания, и правую кнопку для перетаскивания здания',
      visible: false,
      opacity: 0,
      timeout: null,
      firstTransitionComplete: false
    }
  },

  created() {
    eventBus.$on(EventBusEvents.ShowSmallTooltip, this.onSmallTooltip)
    eventBus.$on(EventBusEvents.MOUSE_DOWN_ON_3D_SCENE, this.onDocumentClick)
    document.addEventListener('mousedown', this.onDocumentClick)
  },

  mounted() {
    this.$el.addEventListener('transitionend', this.onTransitionEnd)
  },

  destroyed() {
    this.$el.removeEventListener('transitionend', this.onTransitionEnd)
    eventBus.$off(EventBusEvents.ShowSmallTooltip, this.onSmallTooltip)
    eventBus.$off(EventBusEvents.MOUSE_DOWN_ON_3D_SCENE, this.onDocumentClick)
    document.removeEventListener('mousedown', this.onDocumentClick)
  },

  methods: {
    onSmallTooltip(data) {
      this.text = data.text
      this.show()
    },

    onDocumentClick() {
      if (this.visible && this.firstTransitionComplete) {
        if (this.timeout) clearTimeout(this.timeout)
        this.opacity = 0
      }
    },

    show() {
      this.visible = true
      this.opacity = 0
      this.$nextTick(() => {
        this.opacity = 1
      })

      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.opacity = 0
      }, 20000)
    },

    onTransitionEnd() {
      if (this.opacity === 0) {
        this.visible = false
        this.firstTransitionComplete = false
      }

      if (this.visible && this.opacity === 1) {
        this.firstTransitionComplete = true
      }
    }
  }
}
</script>
