<template lang="pug">
  div
    p hello Settings content
</template>

<script>
export default {
  name: "Settings"
}
</script>

<style scoped>

</style>
