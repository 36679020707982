<template lang="pug">
  .site-header
    .logo
    .divider
    .contacts
      a.phone(href="tel:+74955404992") +7 (495) 540 49 92
      .schedule пн-пт 8:00-19:00, сб 9:00-17:00
    template(v-if="!hideEditorUI")
      .switcher-wraper
        kraus-switcher(v-model="editorTypeModel", :items="editorTypes")
      .item-wrapper(:style="{opacity: requestId ? 1 : 0}")
        .order-num Заявка {{requestId}}
      .item-wrapper.clear-project-button
        kraus-button(:label="'Начать заново'", :border="true", @click="onClearClick")
      .item-wrapper
        .camera-button(@click="onCameraClick")
      //.menu-button(@click="onMenuClick")
      site-header-menu()
    template(v-else)
      .switcher-wraper
      .item-wrapper
        kraus-button(:icon="iconBack", :label="'Назад'", @click="onBackClick")

</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import KrausSwitcher from '@/components/_common/ui/KrausSwitcher';
import { mapActions, mapGetters } from 'vuex';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import PopupsNames from '@/components/_common/ui/Popup/popupNames';
import SiteHeaderMenu from '@/components/SiteHeader/SiteHeaderMenu';
export default {
  name: 'SiteHeader',
  components: { SiteHeaderMenu, KrausSwitcher, KrausButton },
  data () {
    return {
      editorTypeModel: null,
      editorTypes: [
        {
          label: '2D'
        },
        {
          label: '3D'
        }
      ],
      iconBack: require('@/assets/images/ui/icon-arrow-back.svg')
    }
  },

  created() {
    this.editorTypeModel = this.editorTypes[0]
  },

  watch: {
    editorTypeModel(val) {
      this.setEditorType(val.label)
      if (val.label === '2D' && this.containerType) {
        this.tooltipsShowed('2D').then((showed) => {
          if (!showed) {
            this.addTooltipsToShowedList('2D')
            eventBus.$emit(EventBusEvents.ShowTooltips)
          }
        })
      }
    },

    editorType(val) {
      // console.log(this.editorTypeModel, val)
      if (this.editorTypeModel.label !== val) {
        this.editorTypeModel = this.editorTypes.filter(item => item.label === val)[0]
      }
    }
  },

  computed: {
    ...mapGetters([
      'editorType',
      'requestId',
      'containerType'
    ]),

    hideEditorUI() {
      return this.$route.name === 'Ordering'
    }
  },

  methods: {
    ...mapActions([
      'setEditorType',
      'tooltipsShowed',
      'addTooltipsToShowedList'
    ]),

    onMenuClick() {
      eventBus.$emit(EventBusEvents.ShowPopup, PopupsNames.SendProject)
    },

    onClearClick() {
      eventBus.$emit(EventBusEvents.ShowPopup, PopupsNames.ClearProject)
    },

    onCameraClick() {
      eventBus.$emit(EventBusEvents.PrepareImageForDownload)
    },

    onBackClick() {
      eventBus.$emit(EventBusEvents.SiteHeaderBackButtonClicked)
    }
  },
}
</script>
