<template lang="pug">
  .stage-select
    .kraus-select.no-select
      .selected-value-wrapper(@click="onSelectedValueClick")
        .selected-value(v-if="selectedValue") {{selectedValue.title}}
        .arrow
      .options-list(:class="{visible: optionsVisible}")
        .option-item(v-for="(item, index) in stagesList", :key="index")
          .label(@click="onItemClick(item)") {{item.title}}
          .option-item-actions
            .action-copy(v-if="copyActionAvailable(item.stage)", @click="onCopyClick")
            .action-delete(v-if="deleteActionAvailable(item.stage)", @click="onDeleteClick(item.stage)")
        .option-add-item(@click="onAddStageClick")
          .icon
          .label Добавить этаж
</template>
<script>
import KrausSelect from '@/components/_common/ui/KrausSelect';
import { mapActions, mapGetters } from 'vuex';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';
import WallConnector from '@/components/BlocksEditor/CreateJsBlocksEditor/WallConnector';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import Wall from '@/components/BlocksEditor/CreateJsBlocksEditor/Wall';
import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import WallItem from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';
export default {
  name: 'StageSelect',
  components: { KrausSelect },
  props: {
    value: null,
  },

  data() {
    return {
      selectedValue: null,
      optionsVisible: false
    }
  },

  mounted() {
    this.selectedValue = this.stagesList[0]
    document.addEventListener('mouseup', this.onMouseUp)
  },

  destroyed() {
    document.removeEventListener('mouseup', this.onMouseUp)
  },

  computed: {
    ...mapGetters([
      'stagesData',
      'stage'
    ]),

    stagesList() {
      const list = [
        {
          stage: 1,
          title: 'Этаж 1'
        }
      ]

      for (let stage = 2; stage <= 3; stage ++) {
        if (this.stagesData[stage]) {
          list.push({
            stage: stage,
            title: 'Этаж ' + stage
          })
        }
      }

      return list
    }
  },

  watch: {
    // value(val) {
    //   this.selectedValue = val
    // },

    selectedValue() {
      this.$emit('input', this.selectedValue)
    },

    stage() {
      this.stagesList.forEach(item => {
        if (item.stage === this.stage) this.selectedValue = item
      })
    }
  },

  methods: {
    ...mapActions([
      'setStage',
      'setJsonData'
    ]),

    setSelectStage(stageNum) {
      const filtered = this.stagesList.filter((item) => item.stage === stageNum)
      if (filtered.length > 0) this.onItemClick(filtered[0])
    },

    onMouseUp(e) {
      if (e.target.closest('.stage-select') == null) {
        this.optionsVisible = false
      }
    },

    onItemClick(item) {
      //this.selectedValue = item
      this.setStage(item.stage)
      this.optionsVisible = false
    },

    onSelectedValueClick() {
      this.optionsVisible = !this.optionsVisible
    },

    copyActionAvailable(stage) {
      return this.stagesList[this.stagesList.length - 1].stage === stage && stage < 3
    },

    deleteActionAvailable(stage) {
      return stage > 1 && this.stagesList[this.stagesList.length - 1].stage === stage
    },

    onAddStageClick() {
      const lastStage = this.stagesList[this.stagesList.length - 1].stage
      if (lastStage < 3) {
        this.setStage(lastStage + 1)
        this.setJsonData({
          connectors: [],
          boxes: [],
          walls: [],
          tempWalls: [],
          insideWalls: [],
          boxItems: [],
          wallItems: [],
          externalItems: []
        })
      }
    },

    onCopyClick() {
      const lastStage = this.stagesList[this.stagesList.length - 1].stage
      if (lastStage < 3) {
        const jsonData = JSON.parse(JSON.stringify(this.stagesData[lastStage]))
        jsonData.externalItems = []

        jsonData.boxItems = jsonData.boxItems.filter(item => item.type !== 'Stairs')
        jsonData.boxes.forEach(boxData => {
          boxData.boxItems = boxData.boxItems.filter(item => item.type !== 'Stairs')
        })

        this.setStage(lastStage + 1)
        this.setJsonData(jsonData)

        // const jsonDataForClone = JSON.parse(JSON.stringify(this.stagesData[lastStage]))
        //
        // this.setStage(lastStage + 1)
        // this.setJsonData( this.cloneStageData(lastStage + 1, jsonDataForClone))
      }
    },

    cloneStageData(stage, data) {
      let maxConnectorId = 0
      let maxBoxId = 0
      let maxWallItemId = 0
      let maxBoxItemId = 0
      let maxExternalItemId = 0
      let maxWallId = 0

      function getMaxConnectorId() {
        data.connectors.forEach(c => {
          if (maxConnectorId < c.id) maxConnectorId = c.id
        })

        maxConnectorId ++
        return maxConnectorId
      }

      function getMaxBoxId() {
        data.boxes.forEach(box => {
          if (maxBoxId < box.id) maxBoxId = box.id
        })

        maxBoxId ++
        return maxBoxId
      }

      function getMaxWallItemId() {
        data.wallItems.forEach(wallItem => {
          if (maxWallItemId < wallItem.id) maxWallItemId = wallItem.id
        })

        maxWallItemId ++
        return maxWallItemId
      }

      function getMaxBoxItemId() {
        data.boxItems.forEach(boxItem => {
          if (maxBoxItemId < boxItem.id) maxBoxItemId = boxItem.id
        })

        maxBoxItemId ++
        return maxBoxItemId
      }

      function getMaxExternalItemId() {
        data.externalItems.forEach(extItem => {
          if (maxExternalItemId < extItem.id) maxExternalItemId = extItem.id
        })

        maxExternalItemId ++
        return maxExternalItemId
      }

      function getMaxWallId() {
        data.walls.forEach(wall => {
          if (maxWallId < wall.id) maxWallId = wall.id
        })

        maxWallId ++
        return maxWallId
      }

      data.connectors.forEach(c => {
        const newId = getMaxConnectorId()

        data.boxes.forEach(box => {
          box.connectors.forEach(boxConnector => {
            if (boxConnector.id === c.id) boxConnector.id = newId
          })

          box.walls.forEach(wall => {
            wall.connectors.forEach(wallConnector => {
              if (wallConnector.id === c.id) wallConnector.id = newId
            })
          })

          box.insideWalls.forEach(wall => {
            wall.connectors.forEach(wallConnector => {
              if (wallConnector.id === c.id) wallConnector.id = newId
            })
          })
        })

        data.walls.forEach(wall => {
          wall.connectors.forEach(wallConnector => {
            if (wallConnector.id === c.id) wallConnector.id = newId
          })
        })

        c.id = newId
      })

      data.boxes.forEach(box => {
        const newId = getMaxBoxId()

        data.wallItems.forEach(wallItem => {
          if (wallItem.boxId === box.id) wallItem.boxId = newId
        })

        data.boxItems.forEach(boxItem => {
          if (boxItem.boxId === box.id) boxItem.boxId = newId
        })

        data.walls.forEach(wall => {
          if (wall.boxId === box.id) wall.boxId = newId
          wall.intersectedWalls.forEach(intWall => {
            if (intWall.box === box.id) intWall.boxId = newId
          })
        })

        box.walls.forEach(wall => {
          if (wall.boxId === box.id) wall.boxId = newId
          wall.intersectedWalls.forEach(intWall => {
            if (intWall.box === box.id) intWall.boxId = newId
          })
        })

        box.insideWalls.forEach(wall => {
          if (wall.boxId === box.id) wall.boxId = newId
          wall.intersectedWalls.forEach(intWall => {
            if (intWall.box === box.id) intWall.boxId = newId
          })
        })

        box.id = newId
      })

      data.wallItems.forEach(wallItem => {
        const newId = getMaxWallItemId()

        data.walls.forEach(wall => {
          wall.wallItems.forEach(item => {
            if (item.id === wallItem.id) item.id = newId
          })
        })

        wallItem.id = newId
      })

      data.boxItems.forEach(boxItem => {
        const newId = getMaxBoxItemId()

        data.boxes.forEach(box => {
          box.boxItems.forEach(item => {
            if (item.id === boxItem.id) item.id = newId
          })
        })

        boxItem.id = newId
      })

      data.externalItems.forEach(extItem => {
        const newId = getMaxExternalItemId()
        extItem.id = newId
      })

      data.walls.forEach(wall => {
        const newId = getMaxWallId()

        data.boxes.forEach(box => {
          box.walls.forEach(boxWall => {
            if (boxWall.id === wall.id) boxWall.id = newId
            boxWall.intersectedWalls.forEach(intWall => {
              if (intWall.id === wall.id) intWall.id = newId
            })
          })

          box.insideWalls.forEach(boxWall => {
            if (boxWall.id === wall.id) boxWall.id = newId
            boxWall.intersectedWalls.forEach(intWall => {
              if (intWall.id === wall.id) intWall.id = newId
            })
          })
        })

        data.walls.forEach(wallItem => {
          wallItem.intersectedWalls.forEach(intWall => {
            if (intWall.id === wall.id) intWall.id = newId
          })
        })

        wall.id = newId
      })

      return JSON.parse(JSON.stringify(data))
    },

    onDeleteClick(stage) {
      const currentStage = this.stage

      this.setStage(stage)
      this.setJsonData(null)

      Pillar.ClearState(stage)

      if (currentStage === stage) {
        this.setStage(stage - 1)
      } else {
        this.setStage(currentStage)
      }

    },
  }
}
</script>
