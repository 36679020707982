<template lang="pug">
  #content
    site-header
    blocks-editor(v-if="showEditor")
    blocks-renderer(v-if="!showEditor")

</template>

<script>
import SiteHeader from '@/components/SiteHeader';
import BlocksEditor from '@/components/BlocksEditor';
import BlocksRenderer from '@/components/BlocksRenderer';
import { mapGetters } from 'vuex';

export default {
  name: "Index",
  components: { BlocksRenderer, BlocksEditor, SiteHeader },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'editorType'
    ]),

    showEditor() {
      return this.editorType === '2D'
    }
  }
}

</script>
