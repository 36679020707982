<template lang="pug">
  .download-scheme(v-if="visible")
    .download-scheme-background
    .download-scheme-content
      .download-scheme-header Скачать скриншот
      .download-scheme-description Вы можете изменить пропорции и размер скриншота. Изображение будет сохранено в формате JPEG.
      .download-scheme-cropper
        cropper(:src="image", ref="cropper")
      .download-scheme-actions
        kraus-button(:label="'Скачать'",
          :colors="{label: '#FFFFFF', background: '#0558AB'}",
          :border="true",
          @click="onDownloadClick")
        kraus-button(:label="'Отмена'",
          :colors="{label: '#0558AB', background: '#FFFFFF'}",
          :border="true",
          @click="onCancelClick")

      .download-scheme-close-button(@click="onCloseClick")
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import KrausButton from '@/components/_common/ui/KrausButton';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
export default {
  name: 'DownloadScheme',
  components: { KrausButton, Cropper },
  data() {
    return {
      image: null,
      visible: false
    }
  },

  mounted() {
    eventBus.$on(EventBusEvents.ShowDownloadScheme, this.onShowDownloadScheme)
  },

  methods: {
    onShowDownloadScheme(data) {
      this.image = data.dataURL
      this.visible = true
    },

    onDownloadClick() {
      const {
        canvas
      } = this.$refs.cropper.getResult()

      const dataUrl = canvas.toDataURL('#ffffff', 'image/png')

      let link = document.createElement("a");
      link.download = 'scheme.png'
      link.href = dataUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    onCancelClick() {
      this.visible = false
    },

    onCloseClick() {
      this.visible = false
    }
  }
}
</script>
