<template lang="pug">
  .first-steps(v-if="visible")
    .first-steps-background
    .first-steps-content
      .first-steps-header Перед тем как начать...
      .first-steps-component
        component(:is="stepComponent")

      .first-steps-actions
        .buttons
          kraus-button(:label="'Назад'",
            :colors="{label: '#FFFFFF', background: '#0558AB'}",
            :border="true",
            @click="onBackClick")
          kraus-button(:label="'Далее'",
            :colors="{label: '#0558AB', background: '#FFFFFF'}",
            :border="true",
            @click="onNextClick")
        .dots
          .dot-item(v-for="(stepComponent, index) in stepsComponents", :key="index", :class="{active: step - 1 === index}")

      .first-steps-close-button(@click="onCloseClick")
</template>
<script>
import EditModes from '@/components/FirstSteps/EditModes';
import KrausButton from '@/components/_common/ui/KrausButton';

export default {
  name: 'FirstSteps',
  components: { KrausButton },
  data() {
    return {
      visible: true,
      step: 1,
      stepsComponents: [
        EditModes,
        EditModes,
        EditModes
      ]
    }
  },

  mounted() {
  },

  computed: {
    stepComponent() {
      return this.stepsComponents[this.step - 1]
    }
  },

  methods: {
    onBackClick() {
      this.step--
      if (this.step < 1) this.step = 1
    },

    onNextClick() {
      this.step++
      if (this.step > this.stepsComponents.length) this.step = this.stepsComponents.length
    },

    onCloseClick() {
      this.visible = false
      this.$emit('close')
    }
  }
}
</script>
