<template lang="pug">
  .wall-options
    .wall-sizes
      .size-item
        .size-item-label Материал
        .size-item-value {{material.title}}
      .size-item
        .size-item-label Длина
        .size-item-value {{(json.length + 8) / 100}} м
</template>
<script>
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';
import { mapGetters } from 'vuex';
export default {
  name: 'WallOptions',
  components: { KrausEquipmentSelect },
  props: {
    item: null,
    json: null
  },

  data() {
    return {

    }
  },

  computed: {
    ...mapGetters([
      'insideWallDifferentMaterial',
      'insideWallMaterial'
    ]),

    material() {
      let material = this.insideWallMaterial
      if (this.insideWallDifferentMaterial && this.item.box.dictionaryData.wallMaterial) {
        material = this.item.box.dictionaryData.wallMaterial
      }
      return material
    }
  },

  watch: {
    json: {
      deep: true,
      handler: function () {
      }
    }
  }
}
</script>
