import axios from 'axios'

const apiUrl = window.location.host !== 'kraus-blocks.ru' ? 'https://api.dev.kraus-blocks.ru/' : 'https://api.kraus-blocks.ru/'

const api = axios.create({
  baseURL: apiUrl,
  timeout: 100000
})

export default api
