import { Bitmap, Container } from '@createjs/easeljs';
import ExternalItemView
  from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem/ExternalItemView';

class StairsView extends ExternalItemView {

  /**
   * @type {Bitmap}
   * @private
   */
  _image

  constructor(externalItem) {
    super(externalItem)

    if (this.externalItem.dictionaryData.mirrored) {
      this._image = new Bitmap(require('@/assets/images/editor/externalItems/stairs-mirrored.png'))
    } else {
      this._image = new Bitmap(require('@/assets/images/editor/externalItems/stairs.png'))
    }
    const imageContainer = new Container()
    imageContainer.addChild(this._image)

    this.container.addChild(imageContainer)

    imageContainer.scale = 0.31

    imageContainer.x = - (432 * imageContainer.scale) / 2
    imageContainer.y = - (1380 * imageContainer.scale) / 2
  }

  draw() {
    super.draw()
  }
}

export default StairsView
