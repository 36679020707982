<template lang="pug">
  .container-select(:class="{opened: optionsVisible}")
    .selected-value(@click="optionsVisible = !optionsVisible")
      .selected-value-text(v-if="selectedValue") {{selectedValue.label}}
      .arrow
    .options-list
      .option-item(v-for="(item, index) in items", :key="index", v-if="!isItemSelected(item)", @click="selectItem(item)") {{item.label}}
</template>
<script>
export default {
  name: 'KrausContainerSelect',
  props: {
    value: {
      type: Object,
      required: false
    },
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },

  mounted() {
    this.selectedValue = this.value
  },

  data() {
    return {
      selectedValue: null,
      optionsVisible: false
    }
  },

  watch: {
    value() {
      this.selectedValue = this.value
    },

    selectedValue() {
      this.$emit('input', this.selectedValue)
    }
  },

  methods: {
    isItemSelected(item) {
      return this.selectedValue === item
    },

    selectItem(item) {
      this.optionsVisible = false
      this.selectedValue = item
    }
  }
}
</script>
