<template lang="pug">
  div
    h1 hello
    div
      router-view
</template>

<script>
export default {
  name: "Admin"
}
</script>

<style scoped>

</style>
