<template lang="pug">
  .zoom-control
    .zoom-buttons
      kraus-button(:icon="buttons.plus", @click="onZoomClick(1)")
      kraus-button(:icon="buttons.minus",  @click="onZoomClick(-1)")
    .full-screen
      kraus-button(:icon="buttons.fullscreen", @click="onFullscreenClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
export default {
  name: 'ZoomControl',
  components: { KrausButton },
  data() {
    return {
      buttons: {
        plus: require('@/assets/images/editor/zoom-control/plus.svg'),
        minus: require('@/assets/images/editor/zoom-control/minus.svg'),
        fullscreen: require('@/assets/images/editor/zoom-control/fullscreen.svg'),
      }
    }
  },

  methods: {
    onZoomClick(direction) {
      eventBus.$emit(direction > 0 ? EventBusEvents.ZoomIn : EventBusEvents.ZoomOut)
    },

    onFullscreenClick() {
      eventBus.$emit(EventBusEvents.FullScreen)
    }
  }
}
</script>
