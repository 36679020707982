<template lang="pug">
  .popup-content-wrapper.clear-project
    .popup-header Начать заново
    .popup-content Внимание! Все данные будут удалены безвозвратно. Вы действительно хотите начать заново?
    .popup-actions
      kraus-button(:label="'Продолжить'",
        :colors="{label: '#FFFFFF', background: '#0558AB'}",
        :border="true",
        @click="onContinueClick")
      kraus-button(:label="'Отмена'",
        :colors="{label: '#0558AB', background: '#FFFFFF'}",
        :border="true",
        @click="onCancelClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { eventBus } from '@/main';
import EventBusEvents from '@/common/EventBusEvents';
import PopupsNames from '@/components/_common/ui/Popup/popupNames';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ClearProject',
  components: { KrausButton },
  data() {
    return {
    }
  },

  computed: {
    ...mapGetters([
      'editorType'
    ])
  },

  methods: {
    ...mapActions([
      'setEditorType',
      'setClearProject'
    ]),

    onContinueClick() {
      eventBus.$emit(EventBusEvents.ShowPopup, null)

      if (this.editorType === '3D') {
        this.setClearProject(true)
        this.setEditorType('2D')
      } else {
        this.setClearProject(false)
      }

      eventBus.$emit(EventBusEvents.ClearProject)
    },

    onCancelClick() {
      eventBus.$emit(EventBusEvents.ShowPopup, null)
    }
  }
}
</script>
