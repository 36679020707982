import SingleRoofMesh
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/Roof/SingleRoofMesh'
import DoubleRoofMesh
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/Roof/DoubleRoofMesh'

class Roof3D {
  _connectedBoxes

  /**
   * @type {Scene}
   * @private
   */
  _scene
  _roofMesh
  _options

  /**
   * @type number
   * @private
   */
  _stage

  constructor({connectedBoxes, scene, stage, options, createMesh = true}) {
    this._options = options
    this._connectedBoxes = connectedBoxes
    this._scene = scene
    this._stage = stage

    if (createMesh && this.isRoofAvailable()) {
      this._createMesh()
    }
  }

  _createMesh() {
    if (this._options.type === 'odnoskatnaya') {
      this._roofMesh = new SingleRoofMesh({
        stage: this._stage,
        scene: this._scene,
        edgeConnectors: this.getEdgeConnectors(),
        options: this._options
      })
    } else if (this._options.type === 'dvuskatanya') {
      this._roofMesh = new DoubleRoofMesh({
        stage: this._stage,
        scene: this._scene,
        edgeConnectors: this.getEdgeConnectors(),
        options: this._options
      })
    }
  }

  setOptions(options) {
    this._options = options
    if (this._roofMesh) this._roofMesh.setOptions(options)
  }

  setVisibility(visible) {
    if (this._roofMesh) this._roofMesh.setVisibility(visible)
  }

  setTransparency(transparent) {
    if (this._roofMesh) this._roofMesh.setTransparency(transparent)
  }

  /**
   * Возможно ли отображение крыши
   * @return {boolean}
   */
  isRoofAvailable() {
    let available = false

    if (this._connectedBoxes.length >= 4) {
      available = this.getBoxesSquare() === this.getEdgeConnectorsSquare()
    }

    return available
  }

  getConnectors() {
    const connectorsList = []

    this._connectedBoxes.forEach(box => {
      box.connectors.forEach(connector => {

        const existsList = connectorsList.filter(item => item.id === connector.id)
        if (existsList.length === 0) {
          connectorsList.push(connector)
        }
      })
    })

    return connectorsList
  }

  getBoxesSquare() {
    let totalSquare = 0
    this._connectedBoxes.forEach(box => {
      totalSquare += box.width * box.height
    })

    return totalSquare
  }

  /**
   * @return {number}
   */
  getEdgeConnectorsSquare() {
    const edges = {
      minx: null,
      miny: null,
      maxx: null,
      maxy: null
    }

    this.getConnectors().forEach((connector) => {
      if (edges.minx === null || edges.minx > connector.x) {
        edges.minx = connector.x
      }
      if (edges.maxx === null || edges.maxx < connector.x) {
        edges.maxx = connector.x
      }
      if (edges.miny === null || edges.miny > connector.y) {
        edges.miny = connector.y
      }
      if (edges.maxy === null || edges.maxy < connector.y) {
        edges.maxy = connector.y
      }
    })

    const [width, height] = [edges.maxx - edges.minx, edges.maxy - edges.miny]

    return width * height
  }

  /**
   * @return {{width: number, height: number}}
   */
  getSize() {
    const {tl, br} = this.getEdgeConnectors()
    const width = Math.abs(br.x - tl.x)
    const height = Math.abs(br.y - tl.y)
    return {width, height}
  }

  /**
   * @return {{tl: null, br: null}}
   */
  getEdgeConnectors() {
    let tl = null
    let tr = null
    let bl = null
    let br = null


    this.getConnectors().forEach(connector => {
      if (tl === null) {
        tl = connector
      } else {
        const vector = {
          x: connector.x - tl.x,
          y: connector.y - tl.y
        }

        if (vector.x <= 0 && vector.y <= 0) tl = connector
      }

      if (tr === null) {
        tr = connector
      } else {
        const vector = {
          x: connector.x - tr.x,
          y: connector.y - tr.y
        }

        if (vector.x >= 0 && vector.y <= 0) tr = connector
      }

      if (bl === null) {
        bl = connector
      } else {
        const vector = {
          x: connector.x - bl.x,
          y: connector.y - bl.y
        }

        if (vector.x <= 0 && vector.y >= 0) bl = connector
      }

      if (br === null) {
        br = connector
      } else {
        const vector = {
          x: connector.x - br.x,
          y: connector.y - br.y
        }

        if (vector.x >= 0 && vector.y >= 0) br = connector
      }
    })

    return {
      tl,
      tr,
      bl,
      br
    }
  }

  dispose() {
    if (this._roofMesh) {
      this._roofMesh.dispose()
    }
  }
}

export default Roof3D
