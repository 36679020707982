import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Shape } from '@createjs/easeljs';
import FastVector from 'fast-vector';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';

class ExternalItemView extends BaseEditorElement {

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _selectedShape

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _hitAreaShape

  /**
   * @type {ExternalItem}
   */
  _externalItem

  get externalItem() {
    return this._externalItem
  }

  /**
   * @param externalItem {ExternalItem}
   */
  constructor(externalItem) {
    super()
    this._externalItem = externalItem

    this._hitAreaShape = new Shape()
    this.container.hitArea = this._hitAreaShape
    //this.container.addChild(this._hitAreaShape)

    this._selectedShape = new Shape()
    this.container.addChild(this._selectedShape)

    setTimeout(() => {
      this._externalItem.addChild(this)
    })

    this.container.addEventListener('click', this._onClick)
    this.container.addEventListener('pressmove', this._onPressMove)
  }

  _onClick = (e) => {
    if (!this.externalItem.isSetuped) {
      this.externalItem.trySetup()
    } else {
      CreateJsBlocksEditor.Instance.setSelectedElement(this.externalItem)
    }
  }

  _onPressMove = (e) => {
    const delta = new FastVector(
      Math.round(e.nativeEvent.movementX * BaseEditorElement.PixelRatio / Grid.Instance.container.scale),
      Math.round(e.nativeEvent.movementY * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)
    )

    const movement = this.externalItem.getUpdatedMoveDelta(delta)

    this.externalItem.move(movement)
  }

  draw() {
    super.draw()

    this.container.rotation = this._externalItem.rotation

    const [x, y] = [-this._externalItem.width / 2, -this._externalItem.height / 2]
    const [width, height] = [this._externalItem.width, this._externalItem.height]

    this._hitAreaShape.graphics
      .clear()
      .beginFill('#000000')
      .drawRect(x - 2, y - 2, width + 4, height + 4)
      .endFill()

    if (this._externalItem.isSelected()) {
      this._selectedShape.graphics
        .clear()
        .setStrokeDash([2, 2], -(this._externalItem.x + this._externalItem.y))
        .beginStroke('#F47090')
        .setStrokeStyle(2)
        .drawRect(x - 8, y - 4, width + 16, height + 8)
        .endStroke()
    } else {
      this._selectedShape.graphics.clear()
    }
  }

  destroy() {
    this.container.removeEventListener('click', this._onClick)
    this.container.removeEventListener('pressmove', this._onPressMove)
    super.destroy();
  }
}

export default ExternalItemView
