import { Shape } from '@createjs/easeljs';
import WallItemView from '@/components/BlocksEditor/CreateJsBlocksEditor/WallItem/WallItemView';

class WindowView extends WallItemView {

  /**
   *
   * @type {easeljs.Shape}
   * @private
   */
  _windowShape

  constructor(wallItem) {
    super(wallItem)

    this._windowShape = new Shape()
    this.container.addChild(this._windowShape)

    this.draw()
  }

  get isVentilation() {
    return this.wallItem.dictionaryData.id === 'vytyazhka'
  }

  draw() {
    super.draw()

    const [x, y] = [-this.wallItem._width / 2, -this.wallItem._height / 2]
    if (this._windowShape) {

      this._windowShape.graphics
        .clear()
        .setStrokeStyle(6)
        .beginStroke(this.isVentilation ? '#889EBF' : '#ffffff')
        .moveTo(x, y + this.wallItem._height / 2)
        .lineTo(x + this.wallItem._width, y + this.wallItem._height / 2)

        .setStrokeStyle(4)
        .beginStroke(this.isVentilation ? '#889EBF' : '#0558AB')
        .moveTo(x, y)
        .lineTo(x, y + this.wallItem._height)
        .moveTo(x + this.wallItem._width, y)
        .lineTo(x + this.wallItem._width, y + this.wallItem._height)
    }
  }

  destroy() {
    this.container.removeEventListener('click', this._onMouseClick)
    super.destroy();
  }
}

export default WindowView
