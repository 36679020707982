import { Color3, PBRMetallicRoughnessMaterial } from '@babylonjs/core';
import PillarItem
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/PillarsConstructor/PillarItem';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';

class PillarsConstructor {
  static material

  _pillarsList = []
  _pillars

  /**
   * @type {Scene}
   * @private
   */
  _scene

  constructor({pillars, scene}) {
    this._pillars = pillars
    this._scene = scene

    PillarsConstructor.material = new PBRMetallicRoughnessMaterial('pillars', this._scene)
    PillarsConstructor.material.baseColor = Color3.Gray()
    PillarsConstructor.material.roughness = 0.7
    PillarsConstructor.material.metallic = 1

    this._createPillars()
  }

  _createPillars() {
    for (let stageKey in this._pillars) {
      if (stageKey) {
        const stage = parseInt(stageKey)
        const stagePillars = this._pillars[stage]

        stagePillars.forEach(position => {
          if (Pillar.enabled || position.static) {
            this._pillarsList.push(new PillarItem({
              scene: this._scene,
              stage,
              position
            }))
          }
        })
      }
    }
  }
}

export default PillarsConstructor
