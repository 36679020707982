import BaseEditorElement from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';
import { Shape } from '@createjs/easeljs';
import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import FastVector from 'fast-vector';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';

class BoxItemView extends BaseEditorElement {

  /**
   * @type {BoxItem}
   */
  _boxItem

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _bgShape = new Shape()

  get boxItem() {
    return this._boxItem
  }

  /**
   * @param boxItem {BoxItem}
   */
  constructor(boxItem) {
    super()
    this._boxItem = boxItem
    this._boxItem.container.addChild(this._bgShape)

    this.container.mouseEnabled = false
    this._boxItem.addChild(this)

    this._bgShape.addEventListener('click', this._onClick)
    this._bgShape.addEventListener('pressmove', this._onPressMove)
  }

  draw() {
    super.draw()
    this.container.alpha = this._boxItem.canBeSetuped() ? 1 : 0.5
    this.container.rotation = this._boxItem.rotation

    if (!this._bgShape.hitArea) this._bgShape.hitArea = new Shape()

    this._bgShape.hitArea.graphics
      .clear()
      .beginFill('#ff0000')
      .drawRect(-this._boxItem.width / 2, -this._boxItem.height / 2 , this._boxItem.width, this._boxItem.height)
      .endFill()
  }

  _onClick = (e) => {
    this._boxItem.trySetup()
  }

  _onPressMove = (e) => {
    if (this._boxItem.isSetuped && this._boxItem.isSelected()) {
      const delta = new FastVector(
        Math.round(e.nativeEvent.movementX * BaseEditorElement.PixelRatio / Grid.Instance.container.scale),
        Math.round(e.nativeEvent.movementY * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)
      )

      const movement = this._boxItem.getUpdatedMoveDelta(delta)
      this._boxItem.move(movement)
    }
  }

  destroy() {
    this._bgShape.removeEventListener('click', this._onClick)
    this._bgShape.removeEventListener('pressmove', this._onPressMove)
    this._boxItem.removeChild(this)

    super.destroy()
  }
}

export default BoxItemView
