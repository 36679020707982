<template lang="pug">
  .content.content-warming
    .content-header
      .content-option-name
        span Тип утеплителя
        tooltip-icon(:hint="{section: 'warming', key: 'insulation-type'}")
      .content-option-cost
    .content-actions
      kraus-equipment-select(:values="heaterTypes", v-model="singleData.type")

    .content-header
      .content-option-name
        span Толщина утеплителя
        tooltip-icon(:hint="{section: 'warming', key: 'insulation-thickness'}")
      .content-option-cost
    .content-actions
      kraus-equipment-select(:hideCost="true", :values="heaterThickness", v-model="singleData.thickness")
</template>
<script>
import KrausSelect from '@/components/_common/ui/KrausSelect';
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import KrausEquipmentSelect
  from '@/components/_common/ui/KrausEquipmentSelect';
import KrausContainerSelect
  from '@/components/_common/ui/KrausContainerSelect';
import EquipmentCategories from '@/common/EquipmentCategories';
import { mapActions, mapGetters } from 'vuex';
import TooltipIcon from '@/components/_common/ui/TooltipIcon';
export default {
  name: 'Warming',
  components: { TooltipIcon, KrausContainerSelect, KrausEquipmentSelect, KrausSwitcherSmall, KrausSelect },
  data() {
    return {
      selectedSurface: null,

      singleData: {
        type: null,
        thickness: null
      },
      heaterTypes: [],
    }
  },

  created() {
    this.init()
  },

  watch: {
    'singleData.type': {
      handler: function (type) {
        this.singleData.thickness = this.containerType.improved && type.raw.key === "plitka_50" ?
          type.thickness.filter(item => item.raw.key === 'plitka_100')[0] :
          type.thickness[0]
        this.setWarming(this.singleData)
      }
    },
    'singleData.thickness': {
      handler: function () {
        this.setWarming(this.singleData)
      }
    }
  },

  computed: {
    ...mapGetters([
      'warming',
      'containerType'
    ]),

    heaterThickness() {
      return this.singleData.type.thickness
    }
  },

  methods: {
    ...mapActions([
      'setWarming',
    ]),

    init() {
      this.heaterTypes = EquipmentCategories
        .getCategory('uteplenie')
        .options.map(item => {
          return {
            title: item.title,
            thickness: item.options.map(thicknessItem => {
              return {
                title: thicknessItem.title,
                cost: thicknessItem.cost,
                raw: thicknessItem
              }
            }),
            raw: item
          }
        })

      if (this.warming === null) {
        const type = this.containerType.improved ?
          this.heaterTypes.filter(data => data.raw.key === 'plitka_50')[0] :
          this.heaterTypes.filter(data => data.raw.key === 'default')[0]

        this.singleData.type = type

      } else {
        this.singleData.type = this.warming.type
        this.singleData.thickness = this.warming.thickness
      }
    }
  }
}
</script>
