<template lang="pug">
  .window-options
    .window-type(v-if="!containerType.improved")
      .select-label Тип
      .select-wrapper
        kraus-equipment-select(v-model="type.value", :values="type.items")

    .window-size(:style="{marginTop: !containerType.improved ? 0 : '24px'}")
      .select-label Размер
      .select-wrapper
        kraus-equipment-select(v-model="size.value", :values="size.items")

    .window-color(v-if="false")
      .select-label Цвет
      .select-wrapper
        span Белый

    .window-lamination(v-if="false")
      .small-switcher
        .small-switcher-info
          .text Ламинация
          .cost + 10 000 ₽
        .small-switcher-switcher
          kraus-switcher-small

    template(v-if="json.dictionaryData.lattice || json.dictionaryData.rollerShutters")
      .divider

      .label-item Доп. Элементы

      .window-additional
        .additional-item(v-if="json.dictionaryData.lattice")
          .small-switcher
            .small-switcher-info
              .text Решетка
              .cost {{json.dictionaryData.lattice.price| currency }}
            .small-switcher-switcher
              kraus-switcher-small(v-model="latticeEnabled")

        .additional-item(v-if="json.dictionaryData.rollerShutters")
          .small-switcher
            .small-switcher-info
              .text Рольставни
              .cost {{json.dictionaryData.rollerShutters.price| currency }}
            .small-switcher-switcher
              kraus-switcher-small(v-model="rollerShuttersEnabled")

</template>
<script>
import KrausEquipmentSelect from '@/components/_common/ui/KrausEquipmentSelect';
import KrausSwitcherSmall from '@/components/_common/ui/KrausSwitcherSmall';
import KrausSelect from '@/components/_common/ui/KrausSelect';
import EquipmentCategories from '@/common/EquipmentCategories';
import MapUtil from '@/utils/MapUtil';
import { mapGetters } from 'vuex';
export default {
  name: 'WindowOptions',
  components: { KrausSelect, KrausSwitcherSmall, KrausEquipmentSelect },
  props: {
    item: null,
    json: null
  },

  data() {
    return {
      prevId: null,
      latticeEnabled: false,
      rollerShuttersEnabled: false,
      type: {
        value: null,
        items: [
          {
            alias: 'wood',
            title: 'Деревянное окно'
          },
          {
            alias: 'plastic',
            title: 'Пластиковое окно'
          }
        ]
      },

      size: {
        value: null,
        items: []
      },

      color: {
        value: null,
        items: []
      },
    }
  },

  created() {
    this.init()
  },

  watch: {
    latticeEnabled(val) {
      const dictionaryData = this.json.dictionaryData
      dictionaryData.latticeEnabled = val
      this.item.setDictionaryData(dictionaryData)
    },

    rollerShuttersEnabled(val) {
      const dictionaryData = this.json.dictionaryData
      dictionaryData.rollerShuttersEnabled = val
      this.item.setDictionaryData(dictionaryData)
    },

    'json.dictionaryData.id': {
      deep: true,
      handler: function (val) {
        if (this.prevId !== val) this.updateType()
        this.prevId = val
      }
    },

    'type.value': {
      handler: function (val) {
        this.updateSizes(val.alias)
      }
    },

    'size.value': {
      handler: function (val) {
        this.item.setDictionaryData(val)
      }
    }
  },

  computed: {
    ...mapGetters([
      'containerType'
    ])
  },

  methods: {
    init() {

      this.updateType()

      this.latticeEnabled = this.item.dictionaryData.latticeEnabled
      this.rollerShuttersEnabled = this.item.dictionaryData.rollerShuttersEnabled
    },

    updateType() {
      const woodenKeys = []
      const plasticKeys = []

      EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'derevyannye'
      })?.options.forEach(item => {
        woodenKeys.push(item.key)
      })

      EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'plastikovye'
      }).options.forEach(item => {
        plasticKeys.push(item.key)
      })

      const typeAlias = woodenKeys.indexOf(this.json.dictionaryData.data.raw.key) >= 0 ? 'wood' : 'plastic'
      this.type.value = this.type.items.filter(item => item.alias === typeAlias)[0]

      this.updateSizes(typeAlias)
    },

    updateSizes(typeAlias) {
      const woodenRawList = []
      const plasticRawList = []

      EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'derevyannye'
      })?.options.forEach(item => {
        woodenRawList.push(item)
      })

      EquipmentCategories.getItems({
        categoryKey: 'okna',
        optionKey: 'plastikovye'
      }).options.forEach(item => {
        plasticRawList.push(item)
      })

      const rawList = typeAlias === 'wood' ? woodenRawList : plasticRawList

      this.size.items = []

      this.size.value = null

      rawList.forEach(item => {
        let data = typeAlias === 'wood' ? MapUtil.windowWood(item) : MapUtil.windowPlastic(item)

        if (data.id === this.json.dictionaryData.id) {
          data = this.json.dictionaryData
          this.size.value = data
        }

        this.size.items.push(data)
      })

      this.size.items.forEach(item => {
        item.icon = {
          dataURL: item.menuIcon
        }
      })

      if (this.size.value == null) {
        let value = this.size.items[0]
        value.latticeEnabled = this.json.dictionaryData.latticeEnabled
        value.rollerShuttersEnabled = this.json.dictionaryData.rollerShuttersEnabled
        this.size.value = value
      }
    }
  }
}
</script>
