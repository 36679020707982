import { MeshBuilder } from '@babylonjs/core';
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor';
import PillarsConstructor
  from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor/PillarsConstructor';

class PillarItem {
  _mesh

  constructor({scene, stage, position}) {
    this._mesh = MeshBuilder.CreateBox('pillar', {
      width: 8 * BlocksConstructor.Scale,
      depth: 8 * BlocksConstructor.Scale,
      height: BlocksConstructor.StageHeight * BlocksConstructor.Scale
    }, scene)

    this._mesh.position.x = -position.x * BlocksConstructor.Scale
    this._mesh.position.z = position.y * BlocksConstructor.Scale
    this._mesh.position.y = ((stage - 1) * BlocksConstructor.StageHeight + BlocksConstructor.StageHeight / 2) * BlocksConstructor.Scale

    this._mesh.material = PillarsConstructor.material
  }
}

export default PillarItem
