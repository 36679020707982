import {
  Color3,
  CSG,
  Mesh,
  MeshBuilder,
  PBRMetallicRoughnessMaterial,
  Texture
} from '@babylonjs/core'
import BlocksConstructor from '@/components/BlocksRenderer/Blocks3DRenderer/BlocksConstructor'

class CachedCoatingMaterial {
  key
  material
  constructor({key, material}) {
    this.key = key
    this.material = material
  }
}

class Box {

  /**
   * @type {PBRMetallicRoughnessMaterial}
   * @private
   */
  static _groundMaterial
  static _coatingMaterials = []

  _scene
  _data
  _stageData
  _stage

  /**
   * @type {Mesh}
   * @private
   */
  _groundMesh
  _coatingMesh

  /**
   * @type {PBRMetallicRoughnessMaterial}
   * @private
   */
  _coatingMaterial

  _stairsData

  get groundMesh() {
    return this._groundMesh
  }

  /**
   * @returns {Number}
   */
  get x () {
    return this._data.x
  }

  /**
   * @returns {Number}
   */
  get y () {
    return this._data.y
  }

  /**
   * @returns {Number}
   */
  get width () {
    return this._data.width
  }

  /**
   * @returns {Number}
   */
  get height() {
    return this._data.height
  }

  get length() {
    return this._data.height > this._data.width ? this._data.height : this._data.width
  }

  get shortLength() {
    return this._data.height < this._data.width ? this._data.height : this._data.width
  }

  get vertical() {
    return this.height > this.width
  }

  get center() {
    return {
      x: this.x + this.width / 2,
      y: this.y + this.height / 2
    }
  }

  get id () {
    return this._data.id
  }

  get insideWallMaterial() {
    return this._data.dictionaryData.wallMaterial
  }

  get _material() {
    if (!Box._groundMaterial) {
      Box._groundMaterial = new PBRMetallicRoughnessMaterial('ground_material', this._scene)
      Box._groundMaterial.roughness = 0.5
      Box._groundMaterial.metallic = 0.3
      Box._groundMaterial.baseColor = Color3.White()
      Box._groundMaterial.freeze()
    }

    return Box._groundMaterial
  }

  constructor({scene, data, stageData, stage,  floor, stairsData}) {
    this._scene = scene
    this._data = data
    this._stageData = stageData

    this._stage = stage
    this._stairsData = stairsData

    // this._coatingMaterial = new PBRMetallicRoughnessMaterial('floor_coating_material', this._scene)
    // this._coatingMaterial.roughness = 0.5
    // this._coatingMaterial.metallic = 0.3
    // this._coatingMaterial.baseColor = Color3.White()

    const coating = data.dictionaryData.floorCoating ? data.dictionaryData.floorCoating.raw.key : floor.coating

    if (coating === 'no_linoleum' && floor.mainFloorSecondLayer === 'no_material') {
      this._coatingMaterial = this.getMaterialByTextureUrl('/textures/floor/first-layer/' + floor.mainFloor + '.jpg')
    } else if (coating === 'no_linoleum') {
      this._coatingMaterial = this.getMaterialByTextureUrl('/textures/floor/second-layer/' + floor.mainFloorSecondLayer + '.jpg')
    } else {
      this._coatingMaterial = this.getMaterialByTextureUrl('/textures/floor/coating/' + coating + '.jpg')
    }

    this._createMesh()
  }

  getMaterialByTextureUrl(textureUrl) {
    const list = Box._coatingMaterials.filter(item => item.key === textureUrl)
    let material = null

    if (list.length > 0) {
      material = list[0].material
    } else {
      material = new PBRMetallicRoughnessMaterial('floor_coating_material', this._scene)
      material.roughness = 0.5
      material.metallic = 0.3
      material.baseColor = Color3.White()
      material.baseTexture = new Texture(textureUrl, this._scene)
      material.freeze()

      Box._coatingMaterials.push(new CachedCoatingMaterial({
        key: textureUrl,
        material
      }))
    }
    return material
  }

  /**
   * @param id {number}
   * @return {number}
   * @private
   */
  _getWallById(id) {
    const list = this._stageData.walls.concat(this._stageData.insideWalls).filter((wall) => wall.id === id)
    return list[0]
  }

  isContainWallId(id) {
    //return this._data.walls.filter(data => data.id === id).length > 0 ||
    //this._data.insideWalls.filter(data => data.id === id).length > 0

    let contain = false
    this._data.walls.forEach((wallId) => {
      const wallData = this._getWallById(wallId)
      if (wallData.intersectedWalls.indexOf(id) >= 0) {
        contain = true
      }
    })

    if (!contain) {
      const wall = this._getWallById(id)
      if (wall && wall.boxId === this._data.id) contain = true
    }

    return contain
  }

  _createMesh() {
    const groundMeshHeight = 3
    const coatingMeshHeight = 1
    const groundPlane = MeshBuilder.CreateBox('ground_' + this.id, {
      height: this.height * BlocksConstructor.Scale,
      width: this.width * BlocksConstructor.Scale,
      depth: groundMeshHeight * BlocksConstructor.Scale,
    }, this._scene)

    const coatingPlane = MeshBuilder.CreateBox('ground_' + this.id, {
      height: this.height * BlocksConstructor.Scale,
      width: this.width * BlocksConstructor.Scale,
      depth: coatingMeshHeight * BlocksConstructor.Scale,
    }, this._scene)

    // const plane = MeshBuilder.CreateBox('temp', {width: 100, height: 1, depth: 1})

    groundPlane.position.x = (- this.x - this.width / 2) * BlocksConstructor.Scale
    groundPlane.position.z = (this.y + this.height / 2) * BlocksConstructor.Scale
    groundPlane.position.y = (this._stage - 1) * BlocksConstructor.StageHeight * BlocksConstructor.Scale + (groundMeshHeight / 2) * BlocksConstructor.Scale
    groundPlane.rotation.x = Math.PI / 2

    coatingPlane.position.x = (- this.x - this.width / 2) * BlocksConstructor.Scale
    coatingPlane.position.z = (this.y + this.height / 2) * BlocksConstructor.Scale
    coatingPlane.position.y = (this._stage - 1) * BlocksConstructor.StageHeight * BlocksConstructor.Scale +
      (groundMeshHeight) * BlocksConstructor.Scale +
      (coatingMeshHeight / 2) * BlocksConstructor.Scale
    coatingPlane.rotation.x = Math.PI / 2

    if (this.vertical) {
      this._coatingMaterial.baseTexture.uScale = (this.shortLength * BlocksConstructor.Scale)
      this._coatingMaterial.baseTexture.vScale = (this.length * BlocksConstructor.Scale)
      this._coatingMaterial.baseTexture.wAng = Math.PI / 2
    } else {
      this._coatingMaterial.baseTexture.uScale = (this.length * BlocksConstructor.Scale)
      this._coatingMaterial.baseTexture.vScale = (this.shortLength * BlocksConstructor.Scale)
      this._coatingMaterial.baseTexture.wAng = Math.PI / 2
    }

    coatingPlane.material = this._coatingMaterial

    const holesMesh = this._getHolesMesh()

    if (holesMesh) {
      const csgGround = CSG.FromMesh(groundPlane)
      const csgCoating = CSG.FromMesh(coatingPlane)
      const csgHolesMesh = CSG.FromMesh(holesMesh)

      this._groundMesh = csgGround.subtract(csgHolesMesh)
        .toMesh('ground_' + this.id, this._material, this._scene)

      this._coatingMesh = csgCoating.subtract(csgHolesMesh)
        .toMesh('coating_' + this.id, this._coatingMaterial, this._scene)

      holesMesh.dispose()
      groundPlane.dispose()
      coatingPlane.dispose()
    } else {
      this._groundMesh = groundPlane
      this._coatingMesh = coatingPlane
    }
  }

  _getHolesMesh() {
    const holesMeshes = []

    if (this._stairsData) {
      this._stairsData.forEach(boxItem => {
        const holeMesh = MeshBuilder.CreateBox('hole', {
          width: 254 * BlocksConstructor.Scale,
          height: 100 * BlocksConstructor.Scale,
          depth: 210 * BlocksConstructor.Scale
        }, this._scene)

        holeMesh.position.x = -(boxItem.position.x) * BlocksConstructor.Scale
        holeMesh.position.z = (boxItem.position.y) * BlocksConstructor.Scale
        holeMesh.position.y = ((this._stage - 1) * BlocksConstructor.StageHeight) * BlocksConstructor.Scale
        holeMesh.rotation.y = (Math.PI / 180) * (boxItem.rotation + 90)

        holesMeshes.push(holeMesh)
      })
    }

    return holesMeshes.length > 0 ? Mesh.MergeMeshes(holesMeshes, true) : null
  }

}

export default Box
