<template lang="pug">
  .ordering-success
    .success-content
      .success-content-header
        .icon
        .label Заявка оформлена

      .success-message Заявка №{{pid}} успешно оформлена. Наш менеджер свяжется с вами в ближайшее время для подтверждения заказа. Копия заявки была отправлена на ваш e-mail.

      kraus-button(:icon="iconDownload", :label="'Скачать заявку (PDF)'", :border="true" @click="onDownloadClick")
</template>
<script>
import KrausButton from '@/components/_common/ui/KrausButton';
import { eventBus } from '../../main';
import { mapActions, mapGetters } from 'vuex';
import EventBusEvents from '../../common/EventBusEvents';
export default {
  name: 'OrderingSuccess',
  components: { KrausButton },
  props: ['projectId', 'pid'],
  data() {
    return {
      iconDownload: require('@/assets/images/ui/icon-download.svg')
    }
  },

  computed: {
    ...mapGetters([
      'production'
    ])
  },

  methods: {
    ...mapActions([
      'setEditorType'
    ]),

    onDownloadClick() {
      const a = document.createElement('a')
      a.href = this.production ? 'https://api.kraus-blocks.ru/order/pdf/' + this.projectId : 'https://api.dev.kraus-blocks.ru/order/pdf/' + this.projectId
      a.target = '_blank'
      a.click()
      setTimeout(() => {
        a.remove()
      })
    }
  }
}
</script>
