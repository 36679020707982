import CreateJsBlocksEditor from '@/components/BlocksEditor/CreateJsBlocksEditor';
import { Bitmap, Container, Shape } from '@createjs/easeljs';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import Events from '@/components/BlocksEditor/CreateJsBlocksEditor/Events';
import Box from '@/components/BlocksEditor/CreateJsBlocksEditor/Box';
import Pillar from '@/components/BlocksEditor/CreateJsBlocksEditor/Pillar';
import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';

export default class BoxActionsView extends BaseEditorElement {

  /**
   *
   * @private {Box}
   */
  _box


  /**
   *
   * @type {easeljs.Container}
   * @private
   */
  _rotateButton = new Container()
  _deleteButton = new Container()
  _copyButton = new Container()

  _rotateIconBitmap
  _deleteIconBitmap

  _rotationButtonVisible = true

  /**
   * @param box {Box}
   */
  constructor(box) {
    super()
    this._box = box
    Grid.Instance.actionsContainer.addChild(this)

    const rotateButtonShape = new Shape()
    rotateButtonShape.graphics
      .beginFill('#FFFFFF')
      .beginStroke('#efefef')
      .drawCircle(0,0, 40)
    this._rotateButton.addChild(rotateButtonShape)
    this._rotateButton.addEventListener('mousedown', this._onRotateClick)
    this._rotateButton.x -= 45

    this._rotateIconBitmap = new Bitmap(require('@/assets/images/editor/actions/rotate.png'))
    this._rotateIconBitmap.scale = 0.7
    this._rotateIconBitmap.x = -30 * this._rotateIconBitmap.scale
    this._rotateIconBitmap.y = -30 * this._rotateIconBitmap.scale
    this._rotateButton.addChild(this._rotateIconBitmap)

    this.container.addChild(this._rotateButton)

    const deleteButtonShape = new Shape()
    deleteButtonShape.graphics
      .beginFill('#FFFFFF')
      .beginStroke('#efefef')
      .drawCircle(0,0, 40)
    this._deleteButton.addChild(deleteButtonShape)
    this._deleteButton.addEventListener('mousedown', this._onDeleteClick)
    this._deleteButton.x = 45

    this._deleteIconBitmap = new Bitmap(require('@/assets/images/editor/actions/delete.png'))
    this._deleteIconBitmap.scale = 0.7
    this._deleteIconBitmap.x = -30 * this._deleteIconBitmap.scale
    this._deleteIconBitmap.y = -30 * this._deleteIconBitmap.scale
    this._deleteButton.addChild(this._deleteIconBitmap)
    this.container.addChild(this._deleteButton)

    this.container.addChild(this._copyButton)

    this.container.scale = 0

    CreateJsBlocksEditor.Instance.addEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
    Grid.Instance.addEventListener(Events.GRID_MOVED, this._onGridMoved)
  }

  _onGridMoved = () => {
    this.draw()
  }

  _onElementSelected = () => {
    this.draw()
  }

  _onRotateClick = () => {
    if (this._box) this._box.rotate()
  }

  _onDeleteClick = () => {
    if (this._box) this._box.destroy()
    CreateJsBlocksEditor.Instance.setSelectedElement(null)
    Pillar.UpdatePillars()
  }

  updatePosition() {
    const point = {x: this._box.x, y: this._box.y}
    point.x += this._box.width / 2
    point.y -= 70 * this.container.scale

    this.x = point.x
    this.y = point.y
  }

  draw() {
    if (this._box.isSelected()) {
      this.container.scale = 1 / Grid.Instance.container.scale
      this._rotateButton.alpha = this._box.isRotationAvailable() ? 1 : 0
      this.updatePosition()
    } else {
      this.container.scale = 0
    }
  }

  destroy() {
    Grid.Instance.actionsContainer.removeChild(this)
    Grid.Instance.removeEventListener(Events.GRID_MOVED, this._onGridMoved)
    CreateJsBlocksEditor.Instance.removeEventListener(Events.ELEMENT_SELECTED, this._onElementSelected)
  }
}
