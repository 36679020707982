import { Shape } from '@createjs/easeljs';
import Grid from '@/components/BlocksEditor/CreateJsBlocksEditor/Grid';
import FastVector from 'fast-vector';
import BaseEditorElement
  from '@/components/BlocksEditor/CreateJsBlocksEditor/abstract/BaseEditorElement';

export default class WallView {
  static Colors = {
    line: {
      selected: '#F47090',
      normal: '#0558AB',
      inside: '#FFFF00',
      temp: '#00ff00'
    }
  }
  /**
   * @type {Wall}
   * @private
   */
  _wall

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _hitArea

  /**
   * @type {easeljs.Container}
   * @private
   */
  _container

  /**
   * @type {easeljs.Shape}
   * @private
   */
  _lineShape

  /**
   * @param wall {Wall}
   */
  constructor(wall) {
    this._wall = wall
    this._container = wall.container

    this._lineShape = new Shape()
    this._container.addChild(this._lineShape)

    setTimeout(() => {
      if (this._wall.insideBoxWall) {
        this._hitArea = new Shape()
        this._container.hitArea = this._hitArea

        this._container.addEventListener('click', this._onClick)
        this._container.addEventListener('pressmove', this._pressMove)
        this.draw()
      } else {
        this._container.mouseEnabled = false
      }
    })
  }

  _onClick = (e) => {
    if (this._wall.insideBoxWall) {
      this._wall.trySetup()
    }
  }

  _pressMove = (e) => {
    if (this._wall.insideBoxWall) {
      const delta = new FastVector(
        Math.round(e.nativeEvent.movementX * BaseEditorElement.PixelRatio / Grid.Instance.container.scale),
        Math.round(e.nativeEvent.movementY * BaseEditorElement.PixelRatio / Grid.Instance.container.scale)
      )

      this._wall.move(delta, true)
    }
  }

  draw() {
    const [connector1, connector2] = [this._wall.firstConnector, this._wall.lastConnector]
    const [width, height] = [connector2.x - connector1.x, connector2.y - connector1.y]

    if (this._hitArea) {
      this._hitArea.graphics
        .clear()
        .beginFill('#000000')
        .drawRect(connector1.x - 8, connector1.y - 8, width + 16, height + 16)
        .endFill()
    }

    if (this._wall.hidden) {
      this._lineShape.graphics
        .clear()
    } else if (this._wall.temp) {
      this._lineShape.graphics.clear()

      this._lineShape.alpha = this._wall.tempDashed ? 0.5 : 1
      this._lineShape.graphics.setStrokeDash(this._wall.tempDashed ? [10, 10] : [0, 0], 0)

      this._lineShape.graphics
        .setStrokeStyle(this._wall.tempDashed ? 2 : 4, 'square')
        .beginStroke(this._wall.isSelected() ? WallView.Colors.line.selected : WallView.Colors.line.normal)
        .moveTo(connector1.x, connector1.y)
        .lineTo(connector2.x, connector2.y)
        .endStroke()

    } else {
      this._lineShape.graphics
        .clear()
        .setStrokeStyle(5, 'square')
        .beginStroke( this._wall.isSelected() ? WallView.Colors.line.selected : this._wall.insideBoxWall ? WallView.Colors.line.inside : WallView.Colors.line.normal)
        .moveTo(connector1.x, connector1.y)
        .lineTo(connector2.x, connector2.y)
        .endStroke()
    }
  }

  destroy() {
    this._container.removeEventListener('click', this._onClick)
    this._container.removeEventListener('click', this._pressMove)
    // this._container.removeChild(this._lineShape)
  }
}
