import BoxItem from '@/components/BlocksEditor/CreateJsBlocksEditor/BoxItem';
import ExternalItem from '@/components/BlocksEditor/CreateJsBlocksEditor/ExternalItem';
import EquipmentCategories from '@/common/EquipmentCategories';

const LeftMenuData = {
  box: {
    items: [
    ]
  },

  window: {
    items: [
    ]
  },

  door: {
    items: [
    ]
  },

  bathroom: {
    items: [
    ]
  },

  stairs: {
    items: [
    ]
  },

  additional: {
    items: [
      {
        id: 14,
        type: 'additional',
        title: 'Обогреватель',
        editorType: BoxItem.Types.ElectricHeating,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 15,
        type: 'additional',
        title: 'Вытяжка',
        editorType: BoxItem.Types.Ventilation,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 16,
        type: 'additional',
        title: 'Сушилка для рук',
        editorType: BoxItem.Types.Conditioner,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 14,
        type: 'additional',
        title: 'Обогреватель',
        editorType: BoxItem.Types.ElectricHeating,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 15,
        type: 'additional',
        title: 'Вытяжка',
        editorType: BoxItem.Types.Ventilation,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 16,
        type: 'additional',
        title: 'Сушилка для рук',
        editorType: BoxItem.Types.Conditioner,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 14,
        type: 'additional',
        title: 'Обогреватель',
        editorType: BoxItem.Types.ElectricHeating,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 15,
        type: 'additional',
        title: 'Вытяжка',
        editorType: BoxItem.Types.Ventilation,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 16,
        type: 'additional',
        title: 'Сушилка для рук',
        editorType: BoxItem.Types.Conditioner,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 14,
        type: 'additional',
        title: 'Обогреватель',
        editorType: BoxItem.Types.ElectricHeating,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 15,
        type: 'additional',
        title: 'Вытяжка',
        editorType: BoxItem.Types.Ventilation,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 16,
        type: 'additional',
        title: 'Сушилка для рук',
        editorType: BoxItem.Types.Conditioner,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 14,
        type: 'additional',
        title: 'Обогреватель',
        editorType: BoxItem.Types.ElectricHeating,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 15,
        type: 'additional',
        title: 'Вытяжка',
        editorType: BoxItem.Types.Ventilation,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      },
      {
        id: 16,
        type: 'additional',
        title: 'Сушилка для рук',
        editorType: BoxItem.Types.Conditioner,
        icon: '/pics/menu-items/box.png',
        data: {
          cost: 100000,
        }
      }
    ]
  }
}

//      {
//         id: 3,
//         type: 'window',
//         title: '750 x 800 мм',
//         section: 'Деревянные окна',
//         icon: '/pics/menu-items/box.png',
//         data: {
//           size: {
//             width: 75,
//             height: 80,
//           },
//           cost: 100000,
//         }
//       },

export default LeftMenuData
